import {useDispatch, useSelector} from 'react-redux'
import {
  AddProductToPaymentRequest,
  BooleanApiData,
  ProductDTO,
  RemoveProductFromPaymentRequest,
} from '../../api/data-contracts'
import {useNavigate} from 'react-router-dom'
import {postRequestAsync} from '../../redux/commonSlice'
import {Api_Payment_AddProduct, Api_Payment_RemoveProduct} from '../../apiClient/apiClient'
import {getProductState, loadPricesAsync, openPaymentPageAsync} from '../../redux/productSlice'
import _ from 'lodash'
import Translator from '../common/translate/Translator'
import DynamicValueTranslator from '../common/translate/DynamicValueTranslator'
import NormalButton from '../form/button/NormalButton'
import {useState} from 'react'
import {ServicePeriodEnum} from '../../apiClient/enums'

function ClemtaProduct(props: {products: ProductDTO[]}) {
  const productState = useSelector(getProductState)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [product, setProduct] = useState<ProductDTO | undefined>(
    props.products.length > 1
      ? props.products.filter((f) => f.serviceType == ServicePeriodEnum.Aylık).at(0)
      : props.products.at(0)
  )

  async function addProduct() {
    const body: AddProductToPaymentRequest = {
      productId: product?.id,
      shouldCreateNewPayment: true,
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Payment_AddProduct,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        await dispatch<any>(loadPricesAsync())
        await dispatch<any>(openPaymentPageAsync({navigate: navigate}))
      })
  }

  async function click() {
    addProduct()
  }

  const monthlyPrice =
    product?.serviceType == ServicePeriodEnum.Aylık
      ? product.priceWithoutTax?.toFixed(0)
      : ((product?.priceWithoutTax ?? 0) / 12).toFixed(0)
  return (
    <div className='col-xl-4 col-xxl-4 col-md-12 col-sm-12 mb-10 h-825px'>
      <div className='card card-xl-stretch shadow mb-xl-10 theme-dark-bg-body h-100'>
        <div className='card-body d-flex flex-column'>
          <div className='d-flex flex-column mb-auto'>
            <div className='text-dark fw-bolder fs-4 text-center'>
              <DynamicValueTranslator
                translations={{
                  tr: product?.title,
                  en: product?.titleEng,
                }}
              />
            </div>
          </div>
          <div className='pt-5 d-flex align-items-start flex-column h-100'>
            <div className='h-100px'>
              <div>
                <DynamicValueTranslator
                  translations={{
                    tr: product?.description,
                    en: product?.descriptionENG,
                  }}
                />
              </div>
            </div>
            <div className='d-flex justify-content-center w-100 mb-5'>
              {props.products?.length > 1 ? (
                <ul className='nav' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 active'
                      data-bs-toggle='tab'
                      href='#kt_table_widget_5_tab_1'
                      aria-selected='true'
                      role='tab'
                      onClick={() => {
                        setProduct(
                          props.products
                            .filter((f) => f.serviceType == ServicePeriodEnum.Aylık)
                            .at(0)
                        )
                      }}
                    >
                      <Translator name='CLEMTA_BILLED_MONTHLY' />
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                      data-bs-toggle='tab'
                      href='#kt_table_widget_5_tab_2'
                      aria-selected='false'
                      role='tab'
                      onClick={() => {
                        setProduct(
                          props.products
                            .filter((f) => f.serviceType == ServicePeriodEnum.Yıllık)
                            .at(0)
                        )
                      }}
                    >
                      <Translator name='CLEMTA_BILLED_YEARLY' />
                    </a>
                  </li>
                </ul>
              ) : (
                <a
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 active'
                  data-bs-toggle='tab'
                  href='#kt_table_widget_5_tab_1'
                  aria-selected='true'
                  role='tab'
                >
                  <Translator name='CLEMTA_BILLED_YEARLY' />
                </a>
              )}
            </div>

            <div className='px-2 card-footer w-100 d-flex justify-content-center'>
              <div className='text-dark fw-bolder fs-2'>
                <Translator name='AYLIK' />{' '}
                <Translator name='CLEMTA_PRODUCT_PRICE' params={{Price: monthlyPrice}} />
              </div>
            </div>

            <div className=' d-flex flex-column w-100'>
              <NormalButton onClick={click} text={'SATIN_AL'} className='btn-light-success' />
            </div>

            <div className='mt-5'>
              {_.orderBy(product?.subProducts, (p) => p.rowIndex)?.map((m) => {
                return (
                  <li className='d-flex align-items-center py-1' key={m.id}>
                    <span className='bullet me-5'></span>
                    <DynamicValueTranslator
                      translations={{
                        tr: m?.title,
                        en: m?.titleEng,
                      }}
                    />
                  </li>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClemtaProduct
