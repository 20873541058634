import React from 'react'
import MediaQuery from 'react-responsive'

function ForibaInvoiceLinePriceDetail(props: {description: string; value: number}) {
  return (
    <div className='d-flex fw-semibold align-items-center'>
      <div className='bullet w-8px h-3px rounded-2 bg-success me-1'></div>
      <MediaQuery minWidth={500}>
        <div className='col-6 text-gray-500 me-1'>{props.description}</div>
      </MediaQuery>
      <MediaQuery maxWidth={499}>
        <div className='col-6 text-gray-500 me-1 fs-9'>{props.description}</div>
      </MediaQuery>
      <div className='col-6 fw-bolder text-gray-700 text-end px-1'>{props.value.toFixed(2)} ₺</div>
    </div>
  )
}

export default ForibaInvoiceLinePriceDetail
