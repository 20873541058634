import React, {useEffect, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import {Calendar, EventInput, EventSourceInput} from '@fullcalendar/core'
import {CalendarData} from '@fullcalendar/core/internal'
import moment from 'moment'
import {useDispatch} from 'react-redux'
import {
  CalendarDataListApiData,
  CalendarRecord,
  CalendarRecordListApiData,
} from '../../../api/data-contracts'
import {Api_Admin_GetCalendar, Api_Admin_GetCalendarData} from '../../../apiClient/apiClient'
import {getRequestAsync} from '../../../redux/commonSlice'

function CustomerCalendar() {
  const dispatch = useDispatch()
  const [calendars, setCalendars] = useState<CalendarRecord[] | null | undefined>([])

  function loadCalendar() {
    dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetCalendar,
      })
    )
      .unwrap()
      .then(async function (response: CalendarRecordListApiData) {
        setCalendars(response.data ?? [])
      })
  }

  useEffect(() => {
    loadCalendar()
  }, [])

  return (
    <div className='w-100 h-600px'>
      <div className='card h-100'>
        <div className='card-body h-100'>
          <FullCalendar
            plugins={[dayGridPlugin, listPlugin, momentTimezonePlugin]}
            timeZone='Turkey'
            navLinks={true}
            locale={'tr'}
            height={'100%'}
            initialView='listWeek'
            events={calendars?.map((m) => {
              let event: EventInput = {
                title: m.title ?? '',
                date: m.eventTime,
              }
              return event
            })}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,listMonth',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomerCalendar
