import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import ForibaProduct from './ForibaProduct'
import {useSelector} from 'react-redux'
import {getEInvoiceState} from '../../../../redux/eInvoiceSlice'
import ForibaProductFormPopup, {ForibaProductFormPopupType} from './ForibaProductFormPopup'

function ForibaProducts() {
  const eInvoiceState = useSelector(getEInvoiceState)

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'></h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <ForibaProductFormPopup
            type={ForibaProductFormPopupType.Insert}
            productGuidId={undefined}
          ></ForibaProductFormPopup>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th> */}
                <th className='min-w-300px'>Ürün Adı</th>
                <th className='min-w-140px'>Birim Fiyat</th>
                <th className='min-w-120px'>Vergili Fiyat</th>
                <th className='min-w-100px text-end'>İşlemler</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {eInvoiceState.eInvoiceCommonData?.products?.map((m) => {
                return <ForibaProduct product={m} key={m.guidId}></ForibaProduct>
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default ForibaProducts
