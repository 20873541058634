import {useNavigate, useSearchParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useDispatch} from 'react-redux'

function SubscriptionCancelledPage() {
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  return (
    <div className='w-100 h-100'>
      <div className='w-100 h-100'>
        <div className='card card-flush h-md-100'>
          <div className='mb-10 mt-10'>
            <div className='fs-2 fw-bold text-gray-800 text-center mb-13'>
              <span className='me-2 text-danger'>
                İptal işleminiz tamamlanmıştır. Mali müşavirlik sözleşmenizin iptali için sizinle
                iletişime geçecektir.
              </span>
            </div>
          </div>

          <div
            className='col-xl-4 offset-xl-4'
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/unitedpalms-1/20.png')})`,
              backgroundPosition: 'top',
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              height: '50vh',
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCancelledPage
