import {Route, Routes, useLocation} from 'react-router-dom'
import {Registration} from './subPages/Registration'
import {ForgotPassword} from './subPages/ForgotPassword'
import {Login} from './subPages/Login'
import {AuthLayout} from './AuthLayout'
import {SetNewPassword} from './subPages/SetNewPassword'
import {AdvisorInviteRegister} from './subPages/AdvisorInviteRegister'
import {EofisRegistration} from './subPages/EofisRegistration'
import {defterdar_log} from '../../utils/utilities'

const AuthPage = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='registration' element={<Registration />} />
        <Route path='advisor-registration' element={<AdvisorInviteRegister />} />
        <Route path='eofis-registration' element={<EofisRegistration />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='new-password' element={<SetNewPassword />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
