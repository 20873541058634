import React from 'react'
import {ReportEnum} from '../../../../../types/ReportEnum'
import {useDispatch, useSelector} from 'react-redux'
import {getAdminState, setReport} from '../../../../../redux/adminSlice'
import clsx from 'clsx'

function ReportButton(props: {report: ReportEnum; name: string}) {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  return (
    <div className='row me-5'>
      <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
        <a
          className={clsx(
            'btn btn-lg me-3 col-12',
            adminState.reports.reportEnum === props.report ? 'btn-primary' : 'btn-light'
          )}
          onClick={() => {
            dispatch<any>(
              setReport({
                reportEnum: props.report,
              })
            )
          }}
        >
          <span className='indicator-label'>{props.name}</span>
        </a>
      </div>
    </div>
  )
}

export default ReportButton
