import {useSelector, useDispatch} from 'react-redux'
import FormikForm from '../../../../../components/form/FormikForm'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'
import {nextStep} from '../../../../../redux/commonSlice'
import {
  getCompanyState,
  setClemtaWizardData,
  setClemtaWizardDataAsync,
} from '../../../../../redux/companySlice'
import {CompanyTypeEnum} from '../../../../../apiClient/enums'
import SmallTitle from '../../../../../components/common/title/SmallTitle'
import FormInput from '../../../../../components/form/FormInput'
import NormalText from '../../../../../components/common/title/NormalText'
import CustomDropdown, {
  DropdownOption,
} from '../../../../../components/form/dropdown/CustomDropdown'
import {getProductState} from '../../../../../redux/productSlice'
import Translator from '../../../../../components/common/translate/Translator'
import * as Yup from 'yup'
import InputMask from 'react-input-mask'

const schema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z ]*$/, 'CLEMTA_NAME_VALIDATION')
    .required(),
  entityEnding: Yup.string()
    .matches(/^[a-zA-Z ]*$/, 'CLEMTA_ENTITY_ENDING_VALIDATION')
    .required(),
  operationFieldId: Yup.number().required().positive(),
  operationFieldDescription: Yup.string().required(),
  typeId: Yup.number().required().positive(),
  cityId: Yup.number().required().positive(),
  ein: Yup.string().required(),
  addressLineFirst: Yup.string()
    .matches(/^[a-zA-Z0-9 &.,:;'#-]+$/, 'CLEMTA_ADDRESS_LINE_VALIDATION')
    .required(),
  addressLineSecond: Yup.string()
    .matches(/^[a-zA-Z0-9 &.,:;'#-]+$/, 'CLEMTA_ADDRESS_LINE_VALIDATION')
    .required(),
  postalCode: Yup.string().required(),
  cityDistrict: Yup.string()
    .matches(/^[a-zA-Z ]*$/, 'CLEMTA_CITY_DISTRICT_VALIDATION')
    .required(),
})

function ClemtaCompanyDetailedStep() {
  const companyState = useSelector(getCompanyState)
  const productState = useSelector(getProductState)
  const dispatch = useDispatch()

  const initValues = {
    name: companyState?.clemtaWizardData?.name ?? '',
    entityEnding: companyState?.clemtaWizardData?.entityEnding ?? '',
    operationFieldId: companyState?.clemtaWizardData?.operationFieldId ?? 0,
    operationFieldDescription: companyState?.clemtaWizardData?.operationFieldDescription ?? '',
    typeId:
      companyState?.clemtaWizardData?.typeId ?? CompanyTypeEnum.C_Corporation__General_Corporation_,
    cityId: companyState?.clemtaWizardData?.cityId ?? 1,
    ein: companyState?.clemtaWizardData?.ein ?? '',
    addressLineFirst: companyState?.clemtaWizardData?.addressLineFirst ?? '',
    addressLineSecond: companyState?.clemtaWizardData?.addressLineSecond ?? '',
    postalCode: companyState?.clemtaWizardData?.postalCode ?? '',
    cityDistrict: companyState?.clemtaWizardData?.cityDistrict ?? '',
  }

  const submit = async (values: {
    name: string
    entityEnding: string
    operationFieldId: number
    operationFieldDescription: string
    cityId: number
    typeId: number
    ein: string
    addressLineFirst: string
    addressLineSecond: string
    postalCode: string
    cityDistrict: string
  }) => {
    await dispatch<any>(
      setClemtaWizardDataAsync({
        ...companyState.clemtaWizardData,
        ...values,
      })
    )

    window.location.reload()
  }

  return (
    <FormikForm initialValues={initValues} onSubmit={submit} validationSchema={schema}>
      {(props) => {
        return (
          <>
            <SmallTitle text='CLEMTA_TELL_US' />

            <div className='row'>
              <div className='offset-xl-2 col-xl-8'>
                <NormalText text='CLEMTA_TELL_US_DETAIL' />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <CustomDropdown
                  fieldAttributes={{
                    name: 'typeId',
                    placeholder: 'CLEMTA_COMPANY_TYPE',
                    onChange: (event: any) => {
                      //props.setFieldValue('virtualOfficeDistrictId', 0)
                    },
                  }}
                  noSelectionText=''
                  options={[
                    new DropdownOption(
                      CompanyTypeEnum.LLC__Limited_Liability_Company_,
                      'LLC (Limited Liability Company)'
                    ),
                    new DropdownOption(
                      CompanyTypeEnum.C_Corporation__General_Corporation_,
                      'C Corporation (General Corporation)'
                    ),
                  ]}
                  noSelectionValue={0}
                  placeHolderOnTop={true}
                  setFieldValue={props.setFieldValue}
                  valueConverter={parseInt}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <CustomDropdown
                  fieldAttributes={{
                    name: 'cityId',
                    placeholder: 'CLEMTA_STATE',
                    onChange: (event: any) => {
                      //props.setFieldValue('virtualOfficeDistrictId', 0)
                    },
                  }}
                  noSelectionText=''
                  options={[new DropdownOption(82, 'Delaware'), new DropdownOption(83, 'Wyoming')]}
                  noSelectionValue={0}
                  placeHolderOnTop={true}
                  setFieldValue={props.setFieldValue}
                  valueConverter={parseInt}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormInput
                  istouched={props.touched.name ?? false}
                  errorMessage={props.errors.name}
                  placeHolderOnTop={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'name',
                    autoComplete: 'off',
                    placeholder: 'CLEMTA_COMPANY_NAME',
                  }}
                />
            </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormInput
                  istouched={props.touched.entityEnding ?? false}
                  errorMessage={props.errors.entityEnding}
                  placeHolderOnTop={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'entityEnding',
                    autoComplete: 'off',
                    placeholder: 'CLEMTA_ENTITY_ENDING',
                  }}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <CustomDropdown
                  fieldAttributes={{
                    name: 'operationFieldId',
                    placeholder: 'CLEMTA_OPERATION_FIELD',
                    onChange: (event: any) => {
                      //props.setFieldValue('virtualOfficeDistrictId', 0)
                    },
                  }}
                  noSelectionText='CLEMTA_OPERATION_FIELD_SELECT'
                  options={
                    productState.clemtaWizardData?.operationFields?.map(
                      (m) => new DropdownOption(m.fieldDetailId, m.feildDetailName ?? '')
                    ) ?? []
                  }
                  noSelectionValue={0}
                  placeHolderOnTop={true}
                  setFieldValue={props.setFieldValue}
                  valueConverter={parseInt}
                />
              </div>
            </div>

            {props.values.operationFieldId == 2283 && (
              <div className='row mt-3'>
                <div className='offset-xl-2 col-xl-8 col-12'>
                  <FormInput
                    fieldAttributes={{
                      type: 'text',
                      name: 'operationFieldDescription',
                      placeholder: 'CLEMTA_OPERATION_FIELD_DETAIL',
                    }}
                    istouched={props.touched.operationFieldDescription ?? false}
                    errorMessage={props.errors.operationFieldDescription}
                    placeHolderOnTop={true}
                    checkValid={false}
                  />
                </div>
              </div>
            )}

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                  <span className='required'>
                    <Translator name={'CLEMTA_EIN_DESCRIPTON'} />
                  </span>
                </label>
                <InputMask
                  mask='99-9999999'
                  placeholder=''
                  className='form-control form-control-solid'
                  name='ein'
                ></InputMask>
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormInput
                  istouched={props.touched.addressLineFirst ?? false}
                  errorMessage={props.errors.addressLineFirst}
                  placeHolderOnTop={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'addressLineFirst',
                    autoComplete: 'off',
                    placeholder: 'CLEMTA_ADDRESS_1',
                  }}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormInput
                  istouched={props.touched.addressLineSecond ?? false}
                  errorMessage={props.errors.addressLineSecond}
                  placeHolderOnTop={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'addressLineSecond',
                    autoComplete: 'off',
                    placeholder: 'CLEMTA_ADDRESS_2',
                  }}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormInput
                  istouched={props.touched.postalCode ?? false}
                  errorMessage={props.errors.postalCode}
                  placeHolderOnTop={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'postalCode',
                    autoComplete: 'off',
                    placeholder: 'CLEMTA_POSTAL_CODE',
                  }}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormInput
                  istouched={props.touched.cityDistrict ?? false}
                  errorMessage={props.errors.cityDistrict}
                  placeHolderOnTop={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'cityDistrict',
                    autoComplete: 'off',
                    placeholder: 'CLEMTA_CITY_DISTRICT',
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <WizardStepButtons />
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default ClemtaCompanyDetailedStep
