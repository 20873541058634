import {FormikValues} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {
  getCommonState,
  nextStep,
  postRequestAsync,
  setIsLoading,
} from '../../../../../redux/commonSlice'
import {getCompanyState} from '../../../../../redux/companySlice'
import {getProductState, loadPricesAsync} from '../../../../../redux/productSlice'
import {getUserState} from '../../../../../redux/userSlice'
import {
  AddAddressRequest,
  BooleanApiData,
  SetIdentitiyNumberRequest,
} from '../../../../../api/data-contracts'
import Translator from '../../../../../components/common/translate/Translator'
import FormikForm from '../../../../../components/form/FormikForm'
import NormalTitle from '../../../../../components/common/title/NormalTitle'
import CustomDropdown, {
  DropdownOption,
} from '../../../../../components/form/dropdown/CustomDropdown'
import FormInput from '../../../../../components/form/FormInput'
import SmallRadioButton from '../../../../../components/form/radio/SmallRadioButton'
import TinyTitle from '../../../../../components/common/title/TinyTitle'
import _ from 'lodash'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'
import * as Yup from 'yup'
import {
  Api_Address_SetAddress,
  Api_User_SetUserIdentitiyNumber,
} from '../../../../../apiClient/apiClient'
import {FormStatus} from '../../../../../types/FormStatus'
import FormMessage from '../../../../../components/common/messages/FormMessage'
import moment from 'moment'
import WarningMessage from '../../../../../components/common/title/WarningMessage'
import {defterdar_log} from '../../../../../utils/utilities'
import FormInputErrorMessage from '../../../../../components/common/messages/FormInputErrorMessage'

function PersonalInfoStep() {
  const companyState = useSelector(getCompanyState)
  const commonState = useSelector(getCommonState)
  const productState = useSelector(getProductState)
  const userState = useSelector(getUserState)
  const dispatch = useDispatch()

  const schema = Yup.object({
    countryId: Yup.number().when('operationAddressTypeId', (operationAddressTypeId, schema) => {
      if (operationAddressTypeId != 3) {
        return Yup.number().positive('ULKE_SECINIZ').label('ULKE_SECINIZ')
      }
      return schema
    }),

    cityId: Yup.number().when('operationAddressTypeId', (operationAddressTypeId, schema) => {
      if (operationAddressTypeId != 3) {
        return Yup.number().positive('SEHIR_ZORUNLUDUR').label('SEHIR_ZORUNLUDUR')
      }
      return schema
    }),

    districtId: Yup.number().when('operationAddressTypeId', (operationAddressTypeId, schema) => {
      if (operationAddressTypeId != 3) {
        return Yup.number().positive('ILCE_ZORUNLUDUR').label('ILCE_ZORUNLUDUR')
      }
      return schema
    }),

    details: Yup.string().when('operationAddressTypeId', (operationAddressTypeId, schema) => {
      if (operationAddressTypeId != 3) {
        return Yup.string().required('ADRES_ACIKLAMA_ZORUNLUDUR').label('ADRES_ACIKLAMA_ZORUNLUDUR')
      }
      return schema
    }),

    virtualOfficeCityId: Yup.number().when('operationAddressTypeId', {
      is: 3,
      then: Yup.number().positive('SEHIR_ZORUNLUDUR'),
    }),

    virtualOfficeDistrictId: Yup.number().when('operationAddressTypeId', {
      is: 3,
      then: Yup.number().positive('ILCE_ZORUNLUDUR'),
    }),

    virtualOfficeProductId: Yup.number().when('operationAddressTypeId', {
      is: 3,
      then: Yup.number().positive('SANAL_OFIS_SECINIZ'),
    }),

    idNumber: Yup.string().when('nationality', (nationality, schema) => {
      if (Number(nationality) === 1) {
        return Yup.string().length(11, 'KIMLIK_UZUNLUK_HATASI').required('KIMLIK_ZORUNLUDUR')
      }
      return schema
    }),
    birthDate: Yup.string().required('DOGUM_TARIHI_ZORUNLUDUR'),
    nationality: Yup.number().positive('ULKE_ZORUNLUDUR').label('Ülke'),
  })

  const initData: AddAddressRequest &
    SetIdentitiyNumberRequest & {
      virtualOfficeCityId: number
      virtualOfficeDistrictId: number
    } = {
    countryId: companyState?.data?.address?.countryId ?? 0,
    cityId: companyState?.data?.address?.cityId ?? 0,
    districtId: companyState?.data?.address?.districtId ?? 0,
    details: companyState?.data?.address?.details ?? '',
    operationAddressTypeId: companyState?.data?.company?.operationAddressTypeId ?? 1,
    virtualOfficeId: companyState.data?.company?.virtualAddressId ?? 0,
    rentAmount: companyState?.data?.company?.rentAmount ?? 0,
    nationality: userState.data?.nationality ?? 1,
    birthDate: moment(userState.data?.birthDate ?? '').format('yyyy-MM-DD'),
    idNumber: userState.data?.idNumber ?? '',
    virtualOfficeCityId:
      productState.turkeyWizardData?.addressFormData?.virtualOffices?.find(
        (f) => f.addressId == companyState.data?.company?.virtualAddressId
      )?.address?.cityId ?? 0,
    virtualOfficeDistrictId:
      productState.turkeyWizardData?.addressFormData?.virtualOffices?.find(
        (f) => f.addressId == companyState.data?.company?.virtualAddressId
      )?.address?.districtId ?? 0,
  }

  function GetVirtualOfficeCities(): DropdownOption[] {
    return _.orderBy(
      _.uniqBy(
        productState.turkeyWizardData?.addressFormData?.virtualOffices,
        (item) => item.address?.city
      )
        ?.filter((f) => f.address?.city)
        ?.map((m) => new DropdownOption(m.address?.cityId ?? 0, m.address?.city ?? '')) ?? [],
      (item) => item.label
    )
  }

  function GetVirtualOfficeDistricts(cityId: number): DropdownOption[] {
    return _.orderBy(
      _.uniqBy(
        productState.turkeyWizardData?.addressFormData?.virtualOffices,
        (item) => item.address?.district
      )
        ?.filter((f) => f.address?.district && f.address.cityId == cityId)
        ?.map((m) => new DropdownOption(m.address?.districtId ?? 0, m.address?.district ?? '')) ??
        [],
      (item) => item.label
    )
  }

  const submit = async (
    values: AddAddressRequest &
      SetIdentitiyNumberRequest & {
        virtualOfficeCityId: number
        virtualOfficeDistrictId: number
      },
    actions: FormikValues
  ) => {
    const IdBody: SetIdentitiyNumberRequest = {
      birthDate: values.birthDate,
      idNumber: values.idNumber,
      nationality: values.nationality,
    }

    let virtualOffice = undefined
    if (values.operationAddressTypeId == 3) {
      virtualOffice = productState.turkeyWizardData?.addressFormData?.virtualOffices?.find(
        (f) => f.address?.districtId == values.virtualOfficeDistrictId
      )
    }

    const addressBody: AddAddressRequest = {
      cityId: values.cityId,
      countryId: values.countryId,
      districtId: values.districtId,
      details: values.details,
      operationAddressTypeId: values.operationAddressTypeId,
      rentAmount: values.rentAmount,
      virtualOfficeId: virtualOffice?.addressId,
      virtualOfficeProductId: values.virtualOfficeProductId,
    }

    dispatch(setIsLoading(true))
    actions.setStatus(new FormStatus(false, null))

    const result = await sendIdRequest(IdBody, actions)

    if (result) {
      const result = await sendAddressRequest(addressBody, actions)

      if (result) {
        dispatch<any>(loadPricesAsync())
        dispatch(setIsLoading(false))
        dispatch(nextStep())
      }
    }
    dispatch(setIsLoading(false))
  }

  const sendIdRequest = async (
    values: SetIdentitiyNumberRequest,
    actions: FormikValues
  ): Promise<boolean> => {
    try {
      const response = (await dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_User_SetUserIdentitiyNumber,
          body: values,
        })
      ).unwrap()) as BooleanApiData

      if (!response.data) {
        actions.setStatus(new FormStatus(false, response.serverMessage))
        return false
      } else {
        return true
      }
    } catch (error) {
      return false
    }
  }

  const sendAddressRequest = async (
    values: AddAddressRequest,
    actions: FormikValues
  ): Promise<boolean> => {
    try {
      const response = (await dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_Address_SetAddress,
          body: values,
        })
      ).unwrap()) as BooleanApiData

      if (!response.data) {
        actions.setStatus(new FormStatus(false, response.serverMessage))
        return false
      } else {
        return true
      }
    } catch (error) {
      return false
    }
  }

  return (
    <FormikForm initialValues={initData} onSubmit={submit} validationSchema={schema}>
      {(props) => {
        return (
          <div className=''>
            <NormalTitle text='KISISEL_BILGILER' />

            <div className='row mb-3'>
              <WarningMessage message='KIMLIK_NO_UYARI' />
            </div>
            <div className='row'>
              <div className='d-flex flex-column mb-7 col-xl-4'>
                <CustomDropdown
                  fieldAttributes={{
                    name: 'nationality',
                    placeholder: 'UYRUK',
                  }}
                  noSelectionText='ULKE_SECINIZ'
                  options={
                    productState.turkeyWizardData?.addressFormData?.countries?.map(
                      (m) => new DropdownOption(m.countryId ?? 0, m.name ?? '')
                    ) ?? []
                  }
                  noSelectionValue={0}
                  placeHolderOnTop={true}
                  setFieldValue={props.setFieldValue}
                  valueConverter={parseInt}
                />
              </div>
              <div className='d-flex flex-column mb-7 col-xl-4'>
                <FormInput
                  fieldAttributes={{
                    type: 'text',
                    className: '',
                    name: 'idNumber',
                    placeholder: 'KIMLIK_NO',
                  }}
                  istouched={props.touched.idNumber ?? false}
                  errorMessage={props.errors.idNumber}
                  placeHolderOnTop={true}
                  checkValid={false}
                />
              </div>
              <div className='d-flex flex-column mb-7 col-xl-4'>
                <FormInput
                  fieldAttributes={{
                    type: 'date',
                    name: 'birthDate',
                    placeholder: 'DOGUM_TARIHI',
                  }}
                  istouched={props.touched.idNumber ?? false}
                  errorMessage={props.errors.idNumber}
                  placeHolderOnTop={true}
                  checkValid={false}
                />
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-xl-5 mb-5'>
                <TinyTitle text='MULK_DURUMU' />

                <SmallRadioButton
                  description='MULKIYET_BANA_AIT'
                  name='operationAddressTypeId'
                  value={1}
                  setFieldValue={props.setFieldValue}
                  onChange={(e: any) => {
                    props.setFieldValue('virtualOfficeProductId', 0)
                  }}
                />
                <SmallRadioButton
                  description='KIRACIYIM'
                  name='operationAddressTypeId'
                  value={2}
                  setFieldValue={props.setFieldValue}
                  onChange={(e: any) => {
                    props.setFieldValue('virtualOfficeProductId', 0)
                  }}
                />
                <SmallRadioButton
                  description='SANAL_OFIS_SATIN_ALMAK_ISTIYORUM'
                  name='operationAddressTypeId'
                  value={3}
                  setFieldValue={props.setFieldValue}
                  disabled={!productState.turkeyWizardData?.virtualOfficeProducts}
                  onChange={(e: any) => {
                    props.setFieldValue(
                      'virtualOfficeProductId',
                      productState.turkeyWizardData?.virtualOfficeProducts?.at(0)?.id ?? 0
                    )
                  }}
                />
                <SmallRadioButton
                  description='SANAL_SOZLESMEM_VAR'
                  name='operationAddressTypeId'
                  value={4}
                  setFieldValue={props.setFieldValue}
                  onChange={(e: any) => {
                    props.setFieldValue('virtualOfficeProductId', 0)
                  }}
                />

                {props.values?.operationAddressTypeId == 2 && (
                  <div className='row'>
                    <div className='col-xl-5 mt-5'>
                      <FormInput
                        fieldAttributes={{
                          type: 'text',
                          className: '',
                          name: 'rentAmount',
                          placeholder: 'KIRA_MIKTARI',
                          disabled: props.values.operationAddressTypeId != 2,
                        }}
                        istouched={props.touched.rentAmount ?? false}
                        errorMessage={props.errors.rentAmount}
                        placeHolderOnTop={true}
                        checkValid={false}
                      />
                    </div>
                    <div className='col-xl-7 mt-5'>
                      {(props.values.rentAmount ?? 0) > 0 && (
                        <div className='col mt-10'>
                          <Translator
                            name='STOPAJ_ODEYECEKSINIZ'
                            params={{Price: (props.values.rentAmount ?? 0) / 4}}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className='col-xl-7'>
                <TinyTitle text='MULK_SECIM_AYARLARI' />

                {props.values?.operationAddressTypeId == 3 && (
                  <div>
                    <div className='row mt-2'>
                      <div className='text-gray-400 fw-bold fs-8 ps-15 pb-5'>
                        <Translator name='SANAL_OFIS_ICIN_ARANACAKSIN' />
                      </div>

                      <div className='row mt-2 mb-5'>
                        {productState.turkeyWizardData?.virtualOfficeProducts?.map((m) => {
                          return (
                            <div className='col' key={m.id}>
                              <SmallRadioButton
                                description={m.servicePeriod + '_FIYAT'}
                                name='virtualOfficeProductId'
                                value={m.id ?? 0}
                                setFieldValue={props.setFieldValue}
                                descriptionParams={{
                                  price: m.priceWithoutTax?.toFixed(0),
                                }}
                              />
                            </div>
                          )
                        })}
                      </div>

                      <div className='d-flex flex-column fv-row col-xl-6'>
                        <CustomDropdown
                          fieldAttributes={{
                            name: 'virtualOfficeCityId',
                            placeholder: 'SEHIR',
                            onChange: (event: any) => {
                              props.setFieldValue('virtualOfficeDistrictId', 0)
                            },
                          }}
                          noSelectionText='SEHIR_SECINIZ'
                          options={GetVirtualOfficeCities()}
                          noSelectionValue={0}
                          placeHolderOnTop={true}
                          setFieldValue={props.setFieldValue}
                          valueConverter={parseInt}
                        />
                      </div>

                      <div className='d-flex flex-column fv-row col-xl-6'>
                        <CustomDropdown
                          fieldAttributes={{
                            name: 'virtualOfficeDistrictId',
                            placeholder: 'ILCE',
                            onChange: (event: any) => {},
                          }}
                          noSelectionText='ILCE_SECINIZ'
                          options={GetVirtualOfficeDistricts(props.values.virtualOfficeCityId)}
                          noSelectionValue={0}
                          placeHolderOnTop={true}
                          setFieldValue={props.setFieldValue}
                          valueConverter={parseInt}
                        />
                      </div>
                    </div>

                    <div className='row mt-2'>
                      <span className='fw-bold'>
                        <Translator name='SECILEN_YASAL_ADRES' />
                      </span>
                      <span className='ml-2'>
                        {
                          productState.turkeyWizardData?.addressFormData?.virtualOffices?.find(
                            (f) =>
                              f.address?.districtId == (props.values?.virtualOfficeDistrictId ?? 0)
                          )?.address?.details
                        }
                      </span>
                    </div>
                  </div>
                )}

                {props.values.operationAddressTypeId != 3 && (
                  <div className='row'>
                    <div className='d-flex flex-column mb-7 col-xl-4'>
                      <CustomDropdown
                        fieldAttributes={{
                          name: 'countryId',
                          placeholder: 'ULKE',
                          onChange: (event: any) => {
                            //props.setFieldValue('virtualOfficeDistrictId', 0)
                          },
                        }}
                        noSelectionText='ULKE_SECINIZ'
                        options={
                          productState.turkeyWizardData?.addressFormData?.countries?.map(
                            (m) => new DropdownOption(m.countryId, m.name ?? '')
                          ) ?? []
                        }
                        noSelectionValue={0}
                        placeHolderOnTop={true}
                        setFieldValue={props.setFieldValue}
                        valueConverter={parseInt}
                      />
                    </div>
                    <div className='d-flex flex-column mb-7 col-xl-4'>
                      <CustomDropdown
                        fieldAttributes={{
                          name: 'cityId',
                          placeholder: 'SEHIR',
                        }}
                        noSelectionText='SEHIR_SECINIZ'
                        options={
                          productState.turkeyWizardData?.addressFormData?.cities
                            ?.filter((f) => f.countryId == props.values.countryId)
                            ?.map((m) => new DropdownOption(m.cityId, m.name ?? '')) ?? []
                        }
                        noSelectionValue={0}
                        placeHolderOnTop={true}
                        setFieldValue={props.setFieldValue}
                        valueConverter={parseInt}
                      />
                    </div>
                    <div className='d-flex flex-column mb-7 col-xl-4'>
                      <CustomDropdown
                        fieldAttributes={{
                          name: 'districtId',
                          placeholder: 'ILCE',
                        }}
                        noSelectionText='ILCE_SECINIZ'
                        options={
                          productState.turkeyWizardData?.addressFormData?.districts
                            ?.filter((f) => f.cityId == props.values.cityId)
                            ?.map((m) => new DropdownOption(m.districtId, m.name ?? '')) ?? []
                        }
                        noSelectionValue={0}
                        placeHolderOnTop={true}
                        setFieldValue={props.setFieldValue}
                        valueConverter={parseInt}
                      />
                    </div>
                    <div className='d-flex flex-column mb-7 col-xl-12'>
                      <FormInput
                        fieldAttributes={{
                          type: 'text',
                          as: 'textarea',
                          row: 5,
                          className: '',
                          name: 'details',
                          placeholder: 'ADRES_ACIKLAMA',
                        }}
                        istouched={props.touched.details ?? false}
                        errorMessage={props.errors.details}
                        placeHolderOnTop={true}
                        checkValid={false}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-xl-6 offset-xl-3'>
                <FormMessage {...props.status} />
              </div>
            </div>

            <WizardStepButtons />
          </div>
        )
      }}
    </FormikForm>
  )
}

export default PersonalInfoStep
