import {useSelector, useDispatch} from 'react-redux'
import {getCommonState, setStep} from '../../../../redux/commonSlice'
import {getCompanyState} from '../../../../redux/companySlice'
import {WizardStep} from '../../models/WizardModels'
import BaseWizardPage from '../../BaseWizardPage'
import {useEffect} from 'react'
import {loadClemtaWizardDataAsync} from '../../../../redux/productSlice'
import ClemtaCompanyStatusStep from './steps/ClemtaCompanyStatusStep'
import ClemtaCompanyTypeStep from './steps/ClemtaCompanyTypeStep'
import ClemtaStateSelectionStep from './steps/ClemtaStateSelectionStep'
import ClemtaCompanyInfoStep from './steps/ClemtaCompanyInfoStep'
import ClemtaCompanyDetailedStep from './steps/ClemtaCompanyDetailedStep'
import ClemtaCompanyShareStep from './steps/ClemtaCompanyShareStep'
import ClemtaCompanyShareholdersStep from './steps/ClemtaCompanyShareholdersStep'
import ClemataPackageStep from './steps/ClemataPackageStep'

function ClemtaWizardPage() {
  const commonState = useSelector(getCommonState)
  const companyState = useSelector(getCompanyState)

  useEffect(() => {
    dispatch<any>(loadClemtaWizardDataAsync())
  }, [])

  useEffect(() => {
    if (!companyState.Id) {
      dispatch(setStep(1))
    }
    if (companyState.data) {
      if (companyState.clemtaWizardData?.isNewCompany == false) {
        dispatch(setStep(5))
      } else {
        dispatch(setStep(2))
      }
    }
  }, [companyState.Id])

  const dispatch = useDispatch()

  const steps: WizardStep[] = []

  steps.push(new WizardStep('CLEMTA_HELLO', '', <ClemtaCompanyStatusStep />))

  if (companyState.clemtaWizardData?.isNewCompany == false) {
    steps.push(new WizardStep('CLEMTA_COMPANY_TYPE', '', <ClemtaCompanyTypeStep />))
    steps.push(new WizardStep('CLEMTA_STATE_SELECTION', '', <ClemtaStateSelectionStep />))
    steps.push(new WizardStep('CLEMTA_COMPANY_NAME', '', <ClemtaCompanyInfoStep />))

    steps.push(new WizardStep('CLEMTA_NUMBER_OF_SHARES', '', <ClemtaCompanyShareStep />))
    steps.push(new WizardStep('CLEMTA_SHAREHOLDERS', '', <ClemtaCompanyShareholdersStep />))
    steps.push(new WizardStep('PACKAGES', '', <ClemataPackageStep />))
  } else if (companyState.clemtaWizardData?.isNewCompany == true) {
    steps.push(
      new WizardStep('SIRKET_DURUMU', 'SIRKET_DURUMU_ACIKLAMA', <ClemtaCompanyDetailedStep />)
    )

    steps.push(new WizardStep('CLEMTA_NUMBER_OF_SHARES', '', <ClemtaCompanyShareStep />))
    steps.push(new WizardStep('CLEMTA_SHAREHOLDERS', '', <ClemtaCompanyShareholdersStep />))
    steps.push(new WizardStep('PACKAGES', '', <ClemataPackageStep />))
  }

  return <BaseWizardPage steps={steps} customWidth='col-12' />
}

export default ClemtaWizardPage
