import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import PaymentContractPopup from '../../../purchase/PaymentContractPopup'
import Discount from '../../../purchase/Discount'
import {CardFormValues, CardInitialValues, CardSchema} from '../../../../utils/cardUtilities'
import Translator from '../../../common/translate/Translator'
import FormErrorMessage from '../../../common/messages/FormErrorMessage'
import FormikForm from '../../../form/FormikForm'
import CardForm from './CardForm'
import {useDispatch} from 'react-redux'
import {FormikHelpers} from 'formik'
import {AddCardRequest, BooleanApiData} from '../../../../api/data-contracts'
import {Api_Payment_AddNewCard} from '../../../../apiClient/apiClient'
import {postRequestAsync, loadRegisteredCardsAsync} from '../../../../redux/commonSlice'
import CardRegisterWarningPopup from './CardRegisterWarningPopup'

function AddNewCard(props: {handleClose: () => void}) {
  const dispatch = useDispatch()

  const submitStep = async (values: CardFormValues, actions: FormikHelpers<CardFormValues>) => {
    try {
      const body: AddCardRequest = {
        cardHolderName: values.nameOnCard,
        cardNumber: values.cardNumber,
        cvc: values.cardCvv,
        expireMonth: values.cardExpiryMonth,
        expireYear: values.cardExpiryYear,
        cardName: values.cardName,
      }
      await dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_Payment_AddNewCard,
          body: body,
        })
      )
        .unwrap()
        .then(async function (response: BooleanApiData) {
          await dispatch<any>(loadRegisteredCardsAsync())
          props.handleClose()
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='d-flex flex-row-fluid flex-center bg-body rounded w-100 h-100'>
      <div className={'w-100 h-100 d-flex flex-row-fluid flex-center justify-content-center'}>
        <FormikForm
          initialValues={CardInitialValues}
          onSubmit={submitStep}
          validationSchema={CardSchema}
        >
          {(form_props) => {
            return (
              <div className='d-flex flex-center'>
                <div className='w-xs-100 w-sm-100 w-md-100 w-lg-50 w-xl-50 w-xxl-50'>
                  <div className='d-flex flex-center'>
                    <div className='w-100'>
                      <CardForm
                        isCardRegisterDisabled={true}
                        showCardNameInput={form_props.values.registerCard}
                      />

                      <CardRegisterWarningPopup />

                      <FormErrorMessage message={form_props.status?.message} />

                      <div className='d-flex flex-stack align-items-end mt-2'>
                        <div className='mr-2'></div>
                        <div>
                          <button
                            type='submit'
                            disabled={!form_props.isValid}
                            className='btn btn-lg btn-primary me-3'
                          >
                            <span className='indicator-label'>
                              <Translator name='KAYDET' />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }}
        </FormikForm>
      </div>
    </div>
  )
}

export default AddNewCard
