import React, {ChangeEventHandler} from 'react'
import FormInput, {FormInputProps} from './FormInput'

type PhoneNumberInputProps = FormInputProps & {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined
}

function PhoneNumberInput(props: PhoneNumberInputProps) {
  return (
    <FormInput
      {...props}
      fieldAttributes={{
        ...props.fieldAttributes,
        onChange: (e: any) => {
          if (e.target.value.length < 13) {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            let normValue = `${cleaned.substring(0, 3)}${
              cleaned.length > 3 ? ' ' : ''
            }${cleaned.substring(3, 6)}${cleaned.length > 6 ? ' ' : ''}${cleaned.substring(6, 11)}`
            props.setFieldValue(props.fieldAttributes.name, normValue)
          }
        },
      }}
    />
  )
}

export default PhoneNumberInput
