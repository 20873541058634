import {ProductDTOIEnumerableApiData} from '../../../api/data-contracts'
import {useDispatch, useSelector} from 'react-redux'
import {getProductState, setExtraProducts} from '../../../redux/productSlice'
import {useEffect} from 'react'
import {Api_Product_GetProducts} from '../../../apiClient/apiClient'
import {ProductTypeEnum} from '../../../apiClient/enums'
import {postRequestAsync, setIsLoading} from '../../../redux/commonSlice'
import _ from 'lodash'
import Translator from '../../../components/common/translate/Translator'
import ExtraProductsTab from '../../../components/product/ExtraProductsTab'
import ExtraProducts from '../../../components/product/ExtraProducts'
const ExtrasPage = () => {
  const dispatch = useDispatch()
  const productState = useSelector(getProductState)

  useEffect(() => {
    dispatch(setIsLoading(true))
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Product_GetProducts,
        body: {
          TypeId: ProductTypeEnum.Ek_Çözüm,
        },
      })
    )
      .unwrap()
      .then(async function (response: ProductDTOIEnumerableApiData) {
        dispatch<any>(setExtraProducts(response.data))
        dispatch(setIsLoading(false))
      })
  }, [])

  return (
    <div className='content d-flex flex-column flex-column-fluid '>
      <div className='d-flex w-100 '>
        <div className='rounded w-100'>
          <div className='fw-bold fs-1 text-center py-5'>
            <Translator name='EK_COZUMLER' />
          </div>
          <ExtraProducts inExtrasPage={true} inWizard={false} />
        </div>
      </div>
    </div>
  )
}

export default ExtrasPage
