import React from 'react'
import Translator from '../translate/Translator'

function WarningMessage(props: {message: string}) {
  return (
    <div className='text-danger fw-bolder mt-2 fs-8'>
      <Translator name={props.message} />
    </div>
  )
}

export default WarningMessage
