import {useIntl} from 'react-intl'

export interface ITranslatorInput {
  name: string
  params?: any
}

function Translator(props: ITranslatorInput) {
  const intl = useIntl()
  return (
    <>
      <span>
        {props.name?.length > 0 &&
          intl.formatMessage({id: props.name ?? '', defaultMessage: props.name}, props.params)}
      </span>
    </>
  )
}

export default Translator
