import React from 'react'
import Translator from '../translate/Translator'

function NormalTitle(props: {text: string}) {
  return (
    <div className='text-center mb-5'>
      <h1 className='text-dark fw-bolder mb-3n'>
        <Translator name={props.text} />
      </h1>
    </div>
  )
}

export default NormalTitle
