import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {RootState} from './store'
import {
  CommonDataDTO,
  CommonDataDTOApiData,
  EInvoiceProductDTO,
  PrintedInvoiceDTO,
} from '../api/data-contracts'
import {getRequestAsync, setIsLoading} from './commonSlice'
import {Api_EInvoice_GetCommonData} from '../apiClient/apiClient'

export interface EInvoiceState {
  eInvoiceCommonData: CommonDataDTO | null | undefined
  printedInvoices?: PrintedInvoiceDTO[] | null
}

const initialState: EInvoiceState = {
  eInvoiceCommonData: null,
  printedInvoices: null,
}

export const eInvoiceSlice = createSlice({
  name: 'einvoice',
  initialState: initialState,
  reducers: {
    setEInvoiceCommonData: (state, action: PayloadAction<CommonDataDTO | null | undefined>) => {
      state.eInvoiceCommonData = action.payload
    },

    setPrintedInvoices: (state, action: PayloadAction<PrintedInvoiceDTO[] | null | undefined>) => {
      state.printedInvoices = action.payload
    },
  },
})

export const loadEInvoiceCommonDataAsync = createAsyncThunk(
  'api/loadEInvoiceCommonDataAsync',
  async (payload, thunkAPI) => {
    await thunkAPI.dispatch(setIsLoading(true))
    await thunkAPI.dispatch<any>(setEInvoiceCommonData())
    await thunkAPI
      .dispatch<any>(
        getRequestAsync({
          getRequestFunction: Api_EInvoice_GetCommonData,
        })
      )
      .unwrap()
      .then(async (response: CommonDataDTOApiData) => {
        await thunkAPI.dispatch(setIsLoading(false))
        if (response.isSuccessful) {
          await thunkAPI.dispatch(setEInvoiceCommonData(response.data))
        }
      })
  }
)

export const {setEInvoiceCommonData, setPrintedInvoices} = eInvoiceSlice.actions
export default eInvoiceSlice.reducer

export const getEInvoiceState = (state: RootState) => state.einvioce
