import {
  BooleanApiData,
  CancelPaymentRequest,
  PaymentDTO,
  RefundPaymentRequest,
} from '../../../api/data-contracts'
import moment from 'moment'
import {openPaymentPageAsync} from '../../../redux/productSlice'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {PaymentStateEnum} from '../../../apiClient/enums'
import _ from 'lodash'
import {Guid} from 'guid-typescript'
import Translator from '../../common/translate/Translator'
import {Api_Admin_CancelPayment, Api_Admin_RefundPayment} from '../../../apiClient/apiClient'
import {postRequestAsync} from '../../../redux/commonSlice'
import BasePopup from '../../common/popup/BasePopup'
import clsx from 'clsx'
import AreYouSurePopup from '../../common/popup/AreYouSurePopup'
import {defterdar_log} from '../../../utils/utilities'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import TranslatorText from '../../common/translate/TranslatorText'
import {guid} from '@fullcalendar/core/internal'
import {KTSVG} from '../../../../_metronic/helpers'

function PaymentsTable(props: {
  payments: PaymentDTO[]
  canPay: boolean
  canCancel: boolean
  load?: () => void
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  function startPayment(paymentId: number) {
    dispatch<any>(openPaymentPageAsync({navigate: navigate, paymentId: paymentId}))
  }

  function cancelPayment(paymentId: number) {
    const body: CancelPaymentRequest = {
      paymentId: paymentId,
    }

    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_CancelPayment,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: BooleanApiData) {
        if (response.data) {
          props.load && props.load()
        }
      })
  }

  function refundPayment(paymentId: number) {
    const body: RefundPaymentRequest = {
      paymentId: paymentId,
    }

    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_RefundPayment,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: BooleanApiData) {
        if (response.data) {
          props.load && props.load()
        }
      })
  }

  function getPaymentStateColor(state: number) {
    if (state == PaymentStateEnum.Abonelik_Ödeme_Başarılı || state == PaymentStateEnum.Başarılı) {
      return 'text-success'
    }

    if (state == PaymentStateEnum.Abonelik_Ödeme_Başarısız || state == PaymentStateEnum.Başarısız) {
      return 'text-danger'
    }

    if (state == PaymentStateEnum.Approve_Başarısız || state == PaymentStateEnum.İade_Edildi) {
      return 'text-info'
    }

    if (
      state == PaymentStateEnum.Abonelik_Ödeme_İşleniyor ||
      state == PaymentStateEnum.Bekleyen_Abonelik_Ödemesi ||
      state == PaymentStateEnum.Ödeme_Devam_Ediyor ||
      state == PaymentStateEnum.Ödeme_Oluşturuluyor
    ) {
      return 'text-warning'
    }
  }

  function downloadInvoice(paymentId: string) {}

  const columns: ColumnDescription[] = [
    {
      dataField: 'buyerUserName',
      text: TranslatorText('SATIN_ALAN') ?? '',
      sort: true,
      headerStyle: {width: '10%'},
      formatter: (cell: any, row: PaymentDTO, rowIndex: number, colIndex: number) => (
        <>{row.buyerUserName} </>
      ),
    },
    {
      dataField: 'productName',
      text: TranslatorText('URUN') ?? '',
      sort: true,
      headerStyle: {width: '20%'},
      formatter: (cell: any, row: PaymentDTO, rowIndex: number, colIndex: number) => (
        <div className='d-flex flex-column'>
          {row.details?.map((mp) => {
            return (
              <li key={Guid.create().toString()} className='d-flex align-items-center py-2'>
                <span className='bullet me-5'></span>
                <div>
                  {mp.productType} - {mp.productName}
                </div>
              </li>
            )
          })}
        </div>
      ),
    },
    {
      dataField: 'totalAmount',
      text: TranslatorText('FIYAT') ?? '',
      sort: true,
      headerStyle: {width: '5%'},
      formatter: (cell: any, row: PaymentDTO, rowIndex: number, colIndex: number) => (
        <>{Math.round(row.totalAmount ?? 0)} ₺ </>
      ),
    },
    {
      dataField: 'dueDate',
      text: TranslatorText('ODENMESI_GEREKEN_ZAMAN') ?? '',
      sort: true,
      headerStyle: {width: '10%'},
      formatter: (cell: any, row: PaymentDTO, rowIndex: number, colIndex: number) => (
        <>{moment(row.dueDate).format('DD.MM.yyyy')} </>
      ),
    },
    {
      dataField: 'paymentTime',
      text: TranslatorText('ODEME_ZAMANI') ?? '',
      sort: true,
      headerStyle: {width: '10%'},
      formatter: (cell: any, row: PaymentDTO, rowIndex: number, colIndex: number) => (
        <>{row.paymentTime && moment(row.paymentTime).format('DD.MM.yyyy')} </>
      ),
    },
    {
      dataField: 'paymentState',
      text: TranslatorText('ODEME_DURUMU') ?? '',
      sort: true,
      headerStyle: {width: '15%'},
      headerClasses: 'text-center',
      formatter: (cell: any, m: PaymentDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className={getPaymentStateColor(m.paymentState ?? 0)}>
            <div className='row'>
              <div className='text-center'>{m.paymentStateDescription}</div>
            </div>
          </div>

          {m.canReTry == true && (
            <div className='row'>
              <div className='d-flex justify-content-center mt-2'>
                <button
                  type='submit'
                  onClick={function () {
                    startPayment(m.paymentId ?? 0)
                  }}
                  className='btn btn-sm btn-primary me-3'
                >
                  <span className='indicator-label'>
                    <Translator name='ODEME_YAP' />
                  </span>
                </button>
              </div>
            </div>
          )}

          {m.isCancellable == true && (
            <div className='row'>
              <div className='d-flex justify-content-center mt-2'>
                <AreYouSurePopup
                  modalOpener={
                    <a href='#' className='btn btn-sm btn-danger px-10'>
                      <span className=''>
                        <Translator name='IPTAL_ET' />
                      </span>
                    </a>
                  }
                  message={'IPTAL_ETMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'}
                  onClick={() => cancelPayment(m.paymentId ?? 0)}
                />
              </div>
            </div>
          )}

          {m.isRefundable == true && process.env.REACT_APP_ENV !== 'PROD' && (
            <div className='row'>
              <div className='d-flex justify-content-center mt-2'>
                <AreYouSurePopup
                  modalOpener={
                    <a href='#' className='btn btn-sm btn-info px-10'>
                      <span className=''>
                        <Translator name='IADE_ET' />
                      </span>
                    </a>
                  }
                  message={'IADE_ETMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'}
                  onClick={() => refundPayment(m.paymentId ?? 0)}
                />
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      dataField: 'isInvoicePrinted',
      text: TranslatorText('FATURA_DURUMU') ?? '',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-center',
      formatter: (cell: any, m: PaymentDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className={m.isInvoicePrinted ? 'text-success' : 'text-danger'}>
            <div className='row'>
              <div className='text-center'>{m.isInvoicePrinted ? 'Basıldı' : 'Basılmadı'}</div>
            </div>
          </div>

          {m.invoiceUrl && (
            <div className='row'>
              <div className='d-flex justify-content-center mt-2'>
                <a className='btn btn-sm btn-primary' href={m.invoiceUrl ?? ''} target='_blank'>
                  <span className='indicator-label'>
                    <Translator name='FATURA_PDF_INDIR' />
                  </span>
                </a>
              </div>
            </div>
          )}
        </>
      ),
    },
  ]

  return (
    <>
      <div className='table-responsive'>
        <>
          <div>
            <BootstrapTable
              keyField='paymentId'
              data={_.orderBy(props.payments, (o) => o.dueDate, 'asc') ?? []}
              columns={columns}
              classes='table table-rounded table-striped border gy-7 gs-7'
              headerClasses='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
            />
          </div>
        </>
      </div>
    </>
  )
}

export default PaymentsTable
