import {Field} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import RadioButtonWrapper from './RadioButtonWrapper'
import {AnyIfEmpty} from 'react-redux'

function MediumRadioButton(props: {
  icon: string
  description: string
  details: string
  name: string
  value: any
  setFieldValue?: any
  valueConverter?: any
  onChange?: any
}) {
  return (
    <label className='d-flex flex-stack mb-5 cursor-pointer'>
      <span className='d-flex align-items-center me-2'>
        <span className='symbol symbol-45px me-6'>
          <span className='symbol-label'>
            <img alt='LogoBand' src={toAbsoluteUrl(props.icon)} style={{maxWidth: 45}} />
          </span>
        </span>

        <span className='d-flex flex-column'>
          <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
            {props.description}
          </span>
          <span className='fs-6 fw-bold text-gray-400'>{props.details}</span>
        </span>
      </span>

      <span className='form-check form-check-custom form-check-solid'>
        <RadioButtonWrapper
          fieldAttributes={{
            className: 'form-check-input',
            type: 'radio',
            name: props.name,
            value: props.value,
            onChange: props.onChange,
          }}
          setFieldValue={props.setFieldValue}
          valueConverter={props.valueConverter ?? parseInt}
        />
      </span>
    </label>
  )
}

export default MediumRadioButton
