import {
  BooleanApiData,
  CancelPaymentRequest,
  AdminPaymentDTO,
  RefundPaymentRequest,
} from '../../../api/data-contracts'
import moment from 'moment'
import {openPaymentPageAsync} from '../../../redux/productSlice'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {PaymentStateEnum, UserTypeAuthorizationsEnum} from '../../../apiClient/enums'
import _ from 'lodash'
import {Guid} from 'guid-typescript'
import {Api_Admin_CancelPayment, Api_Admin_RefundPayment} from '../../../apiClient/apiClient'
import {postRequestAsync} from '../../../redux/commonSlice'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import AreYouSurePopup from '../../common/popup/AreYouSurePopup'
import Translator from '../../common/translate/Translator'
import TranslatorText from '../../common/translate/TranslatorText'
import {getAdminState} from '../../../redux/adminSlice'
import AdminPaymentDetailTable from './AdminPaymentDetailTable'
import clsx from 'clsx'
import {getPaymentStateColor} from '../../../utils/utilities'
import AuthorizedComponent from '../../common/auth/AuthorizedComponent'

function AdminPaymentsTable(props: {load?: () => void}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const adminState = useSelector(getAdminState)

  function startPayment(paymentId: number) {
    dispatch<any>(openPaymentPageAsync({navigate: navigate, paymentId: paymentId}))
  }

  function cancelPayment(paymentId: number) {
    const body: CancelPaymentRequest = {
      paymentId: paymentId,
    }

    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_CancelPayment,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: BooleanApiData) {
        if (response.data) {
          props.load && props.load()
        }
      })
  }

  function refundPayment(paymentId: number) {
    const body: RefundPaymentRequest = {
      paymentId: paymentId,
    }

    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_RefundPayment,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: BooleanApiData) {
        if (response.data) {
          props.load && props.load()
        }
      })
  }

  const columns: ColumnDescription[] = [
    {
      dataField: 'buyerUserName',
      text: TranslatorText('SATIN_ALAN') ?? '',
      sort: true,
      headerStyle: {width: '10%'},
      formatter: (cell: any, row: AdminPaymentDTO, rowIndex: number, colIndex: number) => (
        <>{row.buyerUserName}</>
      ),
    },
    {
      dataField: 'totalAmount',
      text: TranslatorText('FIYAT') ?? '',
      sort: true,
      headerStyle: {width: '5%'},
      formatter: (cell: any, row: AdminPaymentDTO, rowIndex: number, colIndex: number) => (
        <>{Math.round(row.totalAmount ?? 0)} ₺ </>
      ),
    },
    {
      dataField: 'dueDate',
      text: TranslatorText('ODENMESI_GEREKEN_ZAMAN') ?? '',
      sort: true,
      headerStyle: {width: '7%'},
      formatter: (cell: any, row: AdminPaymentDTO, rowIndex: number, colIndex: number) => (
        <>{moment(row.dueDate).format('DD.MM.yyyy')} </>
      ),
    },
    {
      dataField: 'paymentTime',
      text: TranslatorText('ODEME_ZAMANI') ?? '',
      sort: true,
      headerStyle: {width: '8%'},
      formatter: (cell: any, row: AdminPaymentDTO, rowIndex: number, colIndex: number) => (
        <>{row.paymentTime && moment(row.paymentTime).format('DD.MM.yyyy')} </>
      ),
    },
    {
      dataField: 'paymentState',
      text: TranslatorText('ODEME_DURUMU') ?? '',
      sort: true,
      headerStyle: {width: '12%'},
      headerClasses: 'text-center',
      formatter: (cell: any, m: AdminPaymentDTO, rowIndex: number, colIndex: number) => (
        <>
          {/* <div className={getPaymentStateColor(m.paymentState ?? 0)}>
            <div className='row'>
              <div className='text-center'>{m.paymentStateDescription}</div>
            </div>
          </div> */}
          <div className='text-center'>
            <span className={clsx('badge', getPaymentStateColor(m.paymentState ?? 0))}>
              {m.paymentStateDescription}
            </span>
          </div>

          {m.canReTry == true && (
            <div className='row'>
              <div className='d-flex justify-content-center mt-2'>
                <button
                  type='submit'
                  onClick={function () {
                    startPayment(m.paymentId ?? 0)
                  }}
                  className='btn btn-sm btn-primary me-3'
                >
                  <span className='indicator-label'>
                    <Translator name='ODEME_YAP' />
                  </span>
                </button>
              </div>
            </div>
          )}

          {m.isCancellable == true && (
            <div className='row'>
              <div className='d-flex justify-content-center mt-2'>
                <AreYouSurePopup
                  modalOpener={
                    <a href='#' className='btn btn-sm btn-danger px-10'>
                      <span className=''>
                        <Translator name='IPTAL_ET' />
                      </span>
                    </a>
                  }
                  message={'IPTAL_ETMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'}
                  onClick={() => cancelPayment(m.paymentId ?? 0)}
                />
              </div>
            </div>
          )}

          {m.isRefundable == true && process.env.REACT_APP_ENV !== 'PROD' && (
            <div className='row'>
              <div className='d-flex justify-content-center mt-2'>
                <AreYouSurePopup
                  modalOpener={
                    <a href='#' className='btn btn-sm btn-info px-10'>
                      <span className=''>
                        <Translator name='IADE_ET' />
                      </span>
                    </a>
                  }
                  message={'IADE_ETMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'}
                  onClick={() => refundPayment(m.paymentId ?? 0)}
                />
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      dataField: 'isInvoicePrinted',
      text: TranslatorText('FATURA_DURUMU') ?? '',
      sort: true,
      headerStyle: {width: '13%'},
      headerClasses: 'text-center',
      formatter: (cell: any, m: AdminPaymentDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className={m.isInvoicePrinted ? 'text-success' : 'text-danger'}>
            <div className='row'>
              <div className='text-center'>{m.isInvoicePrinted ? 'Basıldı' : 'Basılmadı'}</div>
            </div>
          </div>

          <AuthorizedComponent
            auth={UserTypeAuthorizationsEnum.Admin_Panel_Company_Invoice_Download}
          >
            {m.invoiceUrl && (
              <div className='row'>
                <div className='d-flex justify-content-center mt-2'>
                  <a className='btn btn-sm btn-primary' href={m.invoiceUrl ?? ''} target='_blank'>
                    <span className='indicator-label'>
                      <Translator name='FATURA_PDF_INDIR' />
                    </span>
                  </a>
                </div>
              </div>
            )}
          </AuthorizedComponent>
        </>
      ),
    },
    {
      dataField: 'productName',
      text: 'Detaylar',
      sort: true,
      headerStyle: {width: '35%'},
      formatter: (cell: any, row: AdminPaymentDTO, rowIndex: number, colIndex: number) => (
        <div className='d-flex flex-column'>
          <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Company_Payment_Error}>
            {row.errorInfo && (
              <div className='row w-100'>
                <span className='col-3 text-danger fw-bold'>{'İyzico Hata:'}</span>
                <span className='col-9 text-danger ps-5'>{row.errorInfo}</span>
              </div>
            )}
          </AuthorizedComponent>
          <AdminPaymentDetailTable details={row.products ?? []} />
        </div>
      ),
    },
  ]

  return (
    <>
      <div className='table-responsive'>
        <>
          <div>
            <BootstrapTable
              keyField='paymentId'
              data={_.orderBy(adminState.company?.payments, (o) => o.dueDate, 'asc') ?? []}
              columns={columns}
              classes='table table-rounded table-striped border gt-7 gs-7'
              headerClasses='fw-bold fs-6 text-gray-800 border-bottom border-gray-200 bg-info bg-opacity-50'
            />
          </div>
        </>
      </div>
    </>
  )
}

export default AdminPaymentsTable
