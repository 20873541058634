import {FormInvoiceProduct} from './ForibaInvoice'

export function GetTotalPriceWithDiscount(product: FormInvoiceProduct | null | undefined) {
  return GetTotalProductPrice(product) * (1 - (product?.discountRatio ?? 0) / 100)
}

export function GetFinalPrice(product: FormInvoiceProduct | null | undefined) {
  return (
    GetTotalPriceWithDiscount(product) *
    (1 +
      (product?.product?.taxCategories?.reduce(
        (sum, current) => sum + (current.taxRatio ?? 0),
        0
      ) ?? 0) /
        100)
  )
}
export function GetDiscountAmount(product: FormInvoiceProduct | null | undefined) {
  return GetTotalProductPrice(product) - GetTotalPriceWithDiscount(product)
}

export function GetTotalProductPrice(product: FormInvoiceProduct | null | undefined) {
  return (product?.customPrice ?? 0) * (product?.count ?? 0)
}
