import {useDispatch, useSelector} from 'react-redux'
import {getProductState} from '../../../redux/productSlice'
import {getCommonState} from '../../../redux/commonSlice'
import {getCompanyState} from '../../../redux/companySlice'
import Translator from '../../../components/common/translate/Translator'
import FinanceSummary from '../../../components/customer/finance/FinanceSummary'
import RegisteredCards from '../../../components/customer/finance/RegisteredCards'

function FinancePage() {
  const productState = useSelector(getProductState)
  const dispatch = useDispatch()
  const commonState = useSelector(getCommonState)
  const companyState = useSelector(getCompanyState)

  return (
    <div className='d-flex w-100'>
      <div className='rounded w-100'>
        <div className='fw-bold fs-1 text-center py-5'>
          <Translator name='ABONELIKLER_VE_ODEMELER' />
        </div>
        {(companyState?.data?.company?.totalDept ?? 0) > 0 && (
          <div className='fs-3 text-danger text-center py-5'>
            <Translator name='BORC_MESAJI' />
          </div>
        )}

        {(companyState?.data?.company?.suspensionStatusId ?? 0) > 0 && (
          <div className='fs-3 text-danger text-center py-5'>
            <Translator
              name='FESIH_DURUMUNUZ'
              params={{
                State: companyState?.data?.company?.suspensionStatus,
              }}
            />
          </div>
        )}

        <div className='card card-border w-100 d-flex flex-row-fluid flex-center'>
          <div className='w-lg-75 w-xl-75 wxxl-75 w-xs-100 w-sm-100 w-md-100'>
            <div className='card-header card-header-stretch'>
              <div className='card-toolbar'>
                <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
                  <li className='nav-item'>
                    <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_9'>
                      <Translator name='SATIN_ALIMLAR' />
                    </a>
                  </li>

                  <li className='nav-item'>
                    <a className={'nav-link'} data-bs-toggle='tab' href='#kt_tab_pane_7'>
                      <Translator name='KAYITLI_KARTLAR' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='card-body'>
              <div className='tab-content' id='myTabContent'>
                <div className='tab-pane fade show active' id='kt_tab_pane_9' role='tabpanel'>
                  <FinanceSummary />
                </div>

                <div className={'tab-pane fade'} id='kt_tab_pane_7' role='tabpanel'>
                  <RegisteredCards />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinancePage
