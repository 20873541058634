import React, {ReactNode} from 'react'
import BasePopup from './BasePopup'
import Translator from '../translate/Translator'
import NormalButton from '../../form/button/NormalButton'
import CancelButton from '../../form/button/CancelButton'

function AreYouSurePopup(props: {onClick: () => void; modalOpener: ReactNode; message: string}) {
  return (
    <BasePopup title={'ONAY'} modalOpener={props.modalOpener} size='sm'>
      {(providers) => {
        return (
          <>
            <div className='mb-5 fs-5 text-center'>
              <Translator name={props.message} />
            </div>

            <div className='d-flex flex-stack pt-2 align-items-end'>
              <CancelButton className='mr-2' onClick={providers.handleClose} />
              <NormalButton
                text='EVET'
                onClick={() => {
                  props.onClick()
                  providers.handleClose()
                }}
              />
            </div>
          </>
        )
      }}
    </BasePopup>
  )
}

export default AreYouSurePopup
