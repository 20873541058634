import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'
import {
  ServiceProviderDTO,
  ServiceProviderDTOListApiData,
} from '../../../api/data-contracts'
import {UserTypeEnum} from '../../../apiClient/enums'
import {getUserState} from '../../../redux/userSlice'
import {Api_Admin_GetServiceProviders} from '../../../apiClient/apiClient'
import {getRequestAsync} from '../../../redux/commonSlice'
import {useEffect, useState} from 'react'
import AdminReferenceDetailPage from './AdminReferenceDetailPage'

function AdminReferencesPage() {
  const dispatch = useDispatch()
  const role = useSelector(getUserState).data?.roleId as UserTypeEnum

  const [serviceProviders, setServiceProviders] = useState<ServiceProviderDTO[] | null | undefined>(
    null
  )

  const columns: ColumnDescription[] = [
    {
      dataField: 'name',
      text: 'Adı',
      sort: true,
      headerStyle: {width: '20%'},
      formatter: (cell: any, row: ServiceProviderDTO, rowIndex: number, colIndex: number) => (
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div className='text-dark fw-bold fs-6'>{row.name}</div>
          </div>
        </div>
      ),
    },
    {
      dataField: '',
      text: 'Detay',
      sort: true,
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: ServiceProviderDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='d-flex justify-content-center flex-shrink-0'>
            <AdminReferenceDetailPage data={m} />
          </div>
        </>
      ),
    },
  ]

  useEffect(() => {
    loadReferences()
  }, [])

  async function loadReferences() {
    await dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetServiceProviders,
      })
    )
      .unwrap()
      .then(async function (response: ServiceProviderDTOListApiData) {
        setServiceProviders(response.data)
      })
  }

  return (
    <>
      <div className={`card card-stretch`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 d-flex justify-content-center'>
          <h3 className='card-title flex-column'>
            <div className='fw-bolder fs-1 text-center py-5'>Firmalar</div>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {(serviceProviders?.length ?? 0) > 0 && (
              <>
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    totalSize: serviceProviders?.length,
                    sizePerPage: 50,
                    paginationSize: 50,
                    paginationTotalRenderer: (from, to, size) => (
                      <span className='react-bootstrap-table-pagination-total ms-5'>
                        Toplam {size} firmadan {from} - {to} arası gösteriliyor{' '}
                      </span>
                    ),
                  })}
                >
                  {({paginationProps, paginationTableProps}) => (
                    <>
                      <div>
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                        <BootstrapTable
                          {...paginationTableProps}
                          keyField='serviceProviderId'
                          data={_.orderBy(serviceProviders, (o) => o.name, 'asc') ?? []}
                          columns={columns}
                          classes='table table-hover table-row-dashed table-rounded mt-5'
                        />
                      </div>
                    </>
                  )}
                </PaginationProvider>
              </>
            )}
          </div>

          {/* begin::Table container */}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default AdminReferencesPage
