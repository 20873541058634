import BasePopup, {PopupProvides} from '../../../common/popup/BasePopup'
import clsx from 'clsx'
import AddNewCard from './AddNewCard'
import CardRegisterWarningPopup from './CardRegisterWarningPopup'
import Translator from '../../../common/translate/Translator'

function AddNewCardItem() {
  return (
    <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>
      {/* Wrapper */}
      <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
        {/* Content */}
        <div className='mb-3 mb-md-0 fw-semibold'>
          <CardRegisterWarningPopup />
        </div>
        {/* Content */}

        {/* Action */}
        <BasePopup
          title='YENI_KART'
          size='xl'
          modalOpener={
            <a href='#' className='btn btn-primary w-150px'>
              <span className='indicator-label'>
                <Translator name='YENI_KART' />
              </span>
            </a>
          }
        >
          {(providers: PopupProvides) => {
            return <AddNewCard handleClose={providers.handleClose} />
          }}
        </BasePopup>
        {/* Action */}
      </div>
      {/* Wrapper */}
    </div>
  )
}

export default AddNewCardItem
