import clsx from 'clsx'
import React from 'react'

function TextWithClipBoard(props: {text: string; color: string}) {
  return (
    <div className='text-center'>
      {props.text}
      <div
        className='btn btn-link btn-sm py-0'
        onClick={() => {
          navigator.clipboard.writeText(props.text)
        }}
      >
        <i className={clsx('ms-1 far fa-paste fs-4', props.color)}></i>
      </div>
    </div>
  )
}

export default TextWithClipBoard
