import React from 'react'
import {useSelector} from 'react-redux'
import {getProductState} from '../../redux/productSlice'
import Product from './Product'
import clsx from 'clsx'

function ExtraProductsWizard(props: {classNames?: string}) {
  const productState = useSelector(getProductState)

  return (
    <div
      className='card card-border w-100 d-flex flex-row-fluid flex-center'
      style={{boxShadow: 'none'}}
    >
      <div className={clsx(props.classNames ?? 'w-100')}>
        <div className='card-body'>
          <div className='tab-content' id='myTabContent'>
            <div className='row d-flex justify-content-around'>
              {productState?.extraProducts
                ?.filter((f) => f.isActiveOnWizard == true)
                .map((m) => (
                  <Product
                    product={m}
                    key={m.id}
                    payInstant={false}
                    shouldCreateNewPayment={false}
                  ></Product>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExtraProductsWizard
