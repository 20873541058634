import {
  AdminPanelCompaniesStartupDTO,
  AdminPanelCompanyDetailDTO,
  AdminPanelCompanyDetailStartupDTO,
  BooleanApiData,
  UpdateCompanyRequest,
  UpdateUserActiveStatusRequest,
} from '../../../api/data-contracts'
import _ from 'lodash'
import {
  UserTypeAuthorizationsEnum,
  ApplicationStepEnum,
  UserTypeEnum,
} from '../../../apiClient/enums'
import {CanIDoThat} from '../../../utils/CanIDoThat'
import FormMessage from '../../common/messages/FormMessage'
import AreYouSurePopup from '../../common/popup/AreYouSurePopup'
import CustomDropdown from '../../form/dropdown/CustomDropdown'
import FormCheckboxInput from '../../form/FormCheckboxInput'
import FormikForm from '../../form/FormikForm'
import FormInput from '../../form/FormInput'
import PhoneNumberInput from '../../form/PhoneNumberInput'
import {FormikValues} from 'formik'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Api_Admin_ChangeUserActive, Api_Admin_UpdateCompany} from '../../../apiClient/apiClient'
import {setIsLoading, postRequestAsync} from '../../../redux/commonSlice'
import {setFormikStatus} from '../../../utils/utilities'
import * as Yup from 'yup'
import {getAdminState} from '../../../redux/adminSlice'
import CompanyDetailFormInputLabel from './CompanyDetailFormInputLabel'
import CompanyDetailFormDataLabel from './CompanyDetailFormDataLabel'
import AuthorizedComponent from '../../common/auth/AuthorizedComponent'
import CompanySetSuspensionStatus from './CompanySetSuspensionStatus'

function CompanyDetailForm(data: {
  load: () => void
  startUp: AdminPanelCompanyDetailStartupDTO | undefined | null
}) {
  const adminstate = useSelector(getAdminState)

  const schema = Yup.object().shape({
    name: Yup.string().required('SIRKET_KISA_ADI_ZORUNLUDUR'),
    phoneNumber: Yup.string()
      .phone('TR', false, 'TELEFON_NUMARASI_TELEFON')
      .required('TELEFON_NUMARASI_REQUIRED'),
    email: Yup.string()
      .email('EPOSTA_ADRESI_EPOSTA')
      .min(3, 'EPOSTA_ADRESI_MIN')
      .max(50, 'EPOSTA_ADRESI_MAX')
      .required('EPOSTA_ADRESI_REQUIRED'),
  })

  const intl = useIntl()

  const dispatch = useDispatch()

  const submit = async (values: UpdateCompanyRequest, actions: FormikValues) => {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_UpdateCompany,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        setFormikStatus(actions, response)
        data.load()
      })
  }

  let initData: UpdateCompanyRequest = {
    taxNumber: adminstate.company?.company?.taxAdministrationNumber ?? '',
    companyId: adminstate.company?.company?.guid ?? '',
    //canOpenMultipleCompany: false,
    financialAdvisorUserId: adminstate.company?.assignedFinancialAdvisorUserId ?? 0,
    name: adminstate.company?.company?.shortName ?? '',
    stepId: adminstate.company?.company?.stepId ?? 0,
    referenceId: adminstate.company?.users?.at(0)?.referenceId ?? 0,
    isActive: adminstate.company?.company?.isActive ?? false,
    suspensionReasonId: adminstate.company?.company?.suspensionReasonId ?? 0,
    isInspectionApproved: adminstate.company?.company?.isInspectionApproved ?? false,
    phoneNumber: adminstate.company?.users?.at(0)?.phoneNumber ?? '',
    email: adminstate.company?.users?.at(0)?.mailAddress ?? '',
  }

  const changeUserActive = async () => {
    dispatch(setIsLoading(true))
    const body: UpdateUserActiveStatusRequest = {
      companyId: adminstate.company?.company?.guid ?? '',
      isActive: adminstate.company?.users?.at(0)?.isActive ? false : true,
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_ChangeUserActive,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        dispatch(setIsLoading(false))
      })
  }

  return (
    <FormikForm
      initialValues={initData}
      onSubmit={submit}
      validationSchema={schema}
      enableReinitialize={true}
    >
      {(props) => {
        return (
          <>
            <div className='w-100'>
              <div className='row mb-7'>
                <h1 className='mt-5 col-lg-2'>Şirket Bilgileri</h1>
                <div className='p-2 col-lg-6'>
                  <AuthorizedComponent
                    auth={UserTypeAuthorizationsEnum.Admin_Panel_Company_Suspension}
                  >
                    <CompanySetSuspensionStatus load={data.load} />
                  </AuthorizedComponent>
                </div>
              </div>

              <div className='row mb-7'>
                <CompanyDetailFormInputLabel text='Firma Adı' />
                <div className='col-lg-4'>
                  <FormInput
                    fieldAttributes={{
                      type: 'text',
                      name: 'name',
                      placeholder: '',
                      disabled: !CanIDoThat(
                        UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Name
                      ),
                    }}
                    istouched={props.touched.name ?? false}
                    errorMessage={props.errors.name}
                    placeHolderOnTop={false}
                    checkValid={false}
                  />
                </div>

                <CompanyDetailFormInputLabel text='Şirket Tipi' />
                <CompanyDetailFormDataLabel text={adminstate.company?.company?.type ?? ''} />
              </div>

              <div className='separator border-3 my-3 mx-1'></div>

              <div className='row mb-7'>
                <CompanyDetailFormInputLabel text='Vergi Numarası' />

                <div className='col-lg-4'>
                  <FormInput
                    fieldAttributes={{
                      type: 'text',
                      name: 'taxNumber',
                      placeholder: '',
                      disabled: !CanIDoThat(
                        UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Tax_Number
                      ),
                    }}
                    istouched={props.touched.taxNumber ?? false}
                    errorMessage={props.errors.taxNumber}
                    placeHolderOnTop={false}
                    checkValid={false}
                  />
                </div>

                <div className='col-lg-2  d-flex align-items-center'>
                  <label className='fw-bold fs-5 text-muted'>Aktif</label>
                  <div className='ms-5'>
                    <FormCheckboxInput
                      description=''
                      name='isActive'
                      setFieldValue={props.setFieldValue}
                      disabled={
                        !CanIDoThat(
                          UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Active_Passive
                        )
                      }
                    />
                  </div>
                </div>

                <div className='col-lg-3 d-flex align-items-center'>
                  <label className='fw-bold fs-5 text-muted'>Yoklama Onay</label>
                  <div className='ms-5'>
                    <FormCheckboxInput
                      description=''
                      name='isInspectionApproved'
                      setFieldValue={props.setFieldValue}
                      disabled={
                        !CanIDoThat(
                          UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Inspection
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div className='separator border-3 my-3 mx-1'></div>

              <div className='row mb-7'>
                <AuthorizedComponent
                  auth={UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_State}
                >
                  <>
                    <CompanyDetailFormInputLabel text='Firma Durum' />

                    <div className='col-lg-4'>
                      <CustomDropdown
                        fieldAttributes={{
                          name: 'stepId',
                          placeholder: '',
                          onChange: (event: any) => {},
                        }}
                        noSelectionText='FIRMA_DURUM_SECINIZ'
                        options={_.orderBy(
                          Object.entries(ApplicationStepEnum)
                            ?.filter((f) => {
                              return isNaN(Number(f[0]))
                            })
                            .map(([key, value]) => ({
                              label: intl.formatMessage({id: key}) ?? '',
                              value: value ?? '',
                            })) ?? [],
                          (o) => o.label
                        )}
                        noSelectionValue={0}
                        placeHolderOnTop={false}
                        setFieldValue={props.setFieldValue}
                        valueConverter={parseInt}
                      />
                    </div>
                  </>
                </AuthorizedComponent>

                <AuthorizedComponent
                  auth={UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Financial_Advisor}
                >
                  <CompanyDetailFormInputLabel text='Mali Müşavir' />

                  <div className='col-lg-4'>
                    <CustomDropdown
                      fieldAttributes={{
                        name: 'financialAdvisorUserId',
                        placeholder: '',
                        onChange: (event: any) => {},
                      }}
                      noSelectionText='MALI_MUSAVIR_SECINIZ'
                      options={
                        data.startUp?.financialAdvisors?.map((m) => ({
                          label: m.description ?? '',
                          value: m.id ?? '',
                        })) ?? []
                      }
                      noSelectionValue={0}
                      placeHolderOnTop={false}
                      setFieldValue={props.setFieldValue}
                      valueConverter={parseInt}
                    />
                  </div>
                </AuthorizedComponent>
              </div>

              <div className='separator border-3 my-3 mx-1'></div>

              <div className='row mb-7'>
                <CompanyDetailFormInputLabel text='Adres' />
                <CompanyDetailFormDataLabel
                  text={adminstate.company?.address?.fullAddressDescription ?? ''}
                />

                <CompanyDetailFormInputLabel text='Fesih Sebebi' />

                <div className='col-lg-4'>
                  <CustomDropdown
                    fieldAttributes={{
                      name: 'suspensionReasonId',
                      placeholder: '',
                      onChange: (event: any) => {},
                      disabled: !CanIDoThat(
                        UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Suspension_Reason
                      ),
                    }}
                    noSelectionText=''
                    options={
                      data.startUp?.companySuspensionReasons?.map((m) => ({
                        label: m.description ?? '',
                        value: m.id ?? '',
                      })) ?? []
                    }
                    noSelectionValue={0}
                    placeHolderOnTop={false}
                    setFieldValue={props.setFieldValue}
                    valueConverter={parseInt}
                  />
                </div>
              </div>

              {(adminstate.company?.company?.suspensionStatusId ?? 0) > 0 && (
                <div className='row align-items-center'>
                  <CompanyDetailFormInputLabel text='Fesih İşlem Durumu' />
                  <CompanyDetailFormDataLabel
                    text={adminstate.company?.company?.suspensionStatus ?? ''}
                  />

                  <div className='separator border-3 my-3 mx-1'></div>
                </div>
              )}

              <div className='separator border-3 border-dark my-3'></div>
              <div className='row mb-7'>
                <h1 className='mt-7 col-lg-2'>Kişi Bilgileri</h1>
                <div className='p-2 col-lg-4'>
                  <AuthorizedComponent
                    auth={UserTypeAuthorizationsEnum.Admin_Panel_Company_User_Active_Passive}
                  >
                    <AreYouSurePopup
                      modalOpener={
                        <a className='btn btn-lg btn-warning me-3 mt-2'>
                          <span className='indicator-label'>
                            {adminstate.company?.users?.at(0)?.isActive
                              ? 'Kişi Pasife Al'
                              : 'Kişiyi Aktif Et'}
                          </span>
                        </a>
                      }
                      message={'EMIN_MISINIZ'}
                      onClick={() => changeUserActive()}
                    />
                  </AuthorizedComponent>
                </div>
              </div>

              <div className='row mb-7'>
                <CompanyDetailFormInputLabel text='Kullanıcı' />
                <CompanyDetailFormDataLabel
                  text={
                    adminstate.company?.users?.at(0)?.firstName +
                    ' ' +
                    adminstate.company?.users?.at(0)?.lastName
                  }
                />

                <AuthorizedComponent
                  auth={UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Reference}
                >
                  <CompanyDetailFormInputLabel text='Referans' />

                  <div className='col-lg-4'>
                    <CustomDropdown
                      fieldAttributes={{
                        name: 'referenceId',
                        placeholder: '',
                        onChange: (event: any) => {},
                        disabled: !CanIDoThat(
                          UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Reference
                        ),
                      }}
                      noSelectionText=''
                      options={
                        data.startUp?.references?.map((m) => ({
                          label: m.description ?? '',
                          value: m.id ?? '',
                        })) ?? []
                      }
                      noSelectionValue={0}
                      placeHolderOnTop={false}
                      setFieldValue={props.setFieldValue}
                      valueConverter={parseInt}
                    />
                  </div>
                </AuthorizedComponent>
              </div>

              <div className='separator border-3 my-3 mx-1'></div>

              <div className='row mb-7'>
                <CompanyDetailFormInputLabel text='Email' />
                <div className='col-lg-4'>
                  <span className='fw-bolder fs-6 text-dark'>
                    <FormInput
                      istouched={props.touched.email ?? false}
                      errorMessage={props.errors.email}
                      isTransparent={false}
                      fieldAttributes={{
                        type: 'text',
                        name: 'email',
                        autoComplete: 'off',
                        placeholder: 'EMAIL',
                        disabled: !CanIDoThat(
                          UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Email
                        ),
                      }}
                    />
                  </span>
                </div>
                <CompanyDetailFormInputLabel text='Telefon' />
                <div className='col-lg-4'>
                  <span className='fw-bolder fs-6 text-dark'>
                    <PhoneNumberInput
                      istouched={props.touched.phoneNumber ?? false}
                      errorMessage={props.errors.phoneNumber}
                      isTransparent={false}
                      setFieldValue={props.setFieldValue}
                      fieldAttributes={{
                        type: 'text',
                        name: 'phoneNumber',
                        autoComplete: 'off',
                        placeholder: 'BOS_TELEFON',
                        disabled: !CanIDoThat(
                          UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Phone
                        ),
                      }}
                    />
                  </span>
                </div>
              </div>

              <div className='separator border-3 my-3 mx-1'></div>

              <div className='row mb-7'>
                <CompanyDetailFormInputLabel text='Kimlik No' />
                <CompanyDetailFormDataLabel
                  text={adminstate.company?.users?.at(0)?.idNumber ?? ''}
                />
              </div>

              <div className='separator border-3 border-dark my-3'></div>
              <h1 className='mt-7 mb-7'>Diğer</h1>

              <div className='row mb-7'>
                <CompanyDetailFormInputLabel text='Ürünler' />
                <div className='col-lg-4'>
                  {adminstate.company?.products?.map((m) => (
                    <span className='fw-bolder fs-6 text-dark d-flex' key={m}>
                      {m}
                    </span>
                  ))}
                </div>
              </div>

              <div className='separator border-3 my-3 mx-1'></div>

              <div className='row mb-7'>
                <CompanyDetailFormInputLabel text='Ortak Sayısı' />
                <div className='col-lg-4'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {adminstate.company?.company?.partnerCount}
                  </span>
                </div>
                <CompanyDetailFormInputLabel text='Personel Sayısı' />
                <div className='col-lg-4'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {adminstate.company?.company?.personnelCount}
                  </span>
                </div>
              </div>

              <div className='separator border-3 my-3 mx-1'></div>

              <div className='row mb-7'>
                <CompanyDetailFormInputLabel text='Operasyon Adresi Durumu' />
                <div className='col-lg-4'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {adminstate.company?.company?.operationAddressType}
                  </span>
                </div>
                <CompanyDetailFormInputLabel text='Sermaye' />
                <div className='col-lg-4'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {adminstate.company?.company?.capitalAmount}
                  </span>
                </div>
              </div>

              <div className='separator border-3 my-3 mx-1'></div>

              <div className='row mb-7'></div>

              <div className='row'>
                <div className='col-lg-6 offset-3'>
                  <FormMessage {...props.status} />
                </div>
              </div>

              <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Company_Update}>
                <div className='d-flex'>
                  <div className='ms-auto p-2'>
                    <button type='submit' className='btn btn-lg btn-primary mt-2'>
                      <span className='indicator-label'>Kaydet</span>
                    </button>
                  </div>
                </div>
              </AuthorizedComponent>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default CompanyDetailForm
