import * as Yup from 'yup'
import valid from 'card-validator'
import {SchemaLike} from 'yup/lib/types'

export const NewCardTokenValue = 'new'

function CardTokenValidator<T>(baseSchema: any, schema: any): Yup.AnySchema {
  return baseSchema.when(['cardToken'], {
    is: (cardToken: string) => {
      return cardToken == NewCardTokenValue
    },
    then: schema,
  })
}

export function ValidateIBAN(iban: string): boolean {
  const cleanedIBAN = iban.replace(/\s+/g, '').toUpperCase()

  // Move the first four characters to the end
  const rearrangedIBAN = iban.slice(4) + iban.slice(0, 4)

  // Replace each letter in the string with two digits, thereby expanding the string
  const numericIBAN = rearrangedIBAN.replace(/[A-Z]/g, (char) => {
    return (char.charCodeAt(0) - 55).toString()
  })

  // Interpret the string as a decimal integer and compute the remainder of that number on division by 97
  const mod97 = BigInt(numericIBAN) % BigInt(97)

  // If the remainder is 1, the IBAN is valid
  return mod97 === BigInt(1)
}

export const CardSchema = Yup.object({
  nameOnCard: CardTokenValidator(
    Yup.string(),
    Yup.string()
      .required('KART_UZERINDEKI_ISIM_ZORUNLUDUR')
      .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'GECERSIZ_ISIM')
      .label('Name On Card')
  ),

  cardNumber: CardTokenValidator(
    Yup.string(),
    Yup.string()
      .test(
        'test-number',
        'GECERSIZ_KART_NUMARASI',
        (value) => valid.number(value).isValid || process.env.REACT_APP_ENV != 'PROD'
      )
      .required('KART_NUMARASI_ZORUNLUDUR')
      .label('Card Number')
  ),

  cardExpiryMonth: CardTokenValidator(
    Yup.string(),
    Yup.string().required('SON_KULLANMA_AY_ZORUNLUDUR').label('Expiration Month')
  ),

  cardExpiryYear: CardTokenValidator(
    Yup.string(),
    Yup.string().required('SON_KULLANMA_YIL_ZORUNLUDUR').label('Expiration Year')
  ),

  cardCvv: CardTokenValidator(
    Yup.string(),
    Yup.string()
      .required('CVV_ZORUNLUDUR')
      .min(3, 'CVV_3_HANELI_OLMALIDIR')
      .max(3, 'CVV_3_HANELI_OLMALIDIR')
      .label('CVV')
  ),

  cardName: Yup.string().when(['registerCard', 'cardToken'], {
    is: (registerCard: boolean, cardToken: string) => {
      return registerCard && cardToken == NewCardTokenValue
    },
    then: Yup.string().required('KART_ISMI_ZORUNLUDUR'),
  }),
})

export interface CardFormValues {
  nameOnCard: string
  cardNumber: string
  cardExpiryMonth: string
  cardExpiryYear: string
  cardCvv: string
  registerCard: boolean
  cardName: string
  discount: string
  cardToken: string | null
}

export const CardInitialValues: CardFormValues = {
  nameOnCard: '',
  cardNumber: '',
  cardExpiryMonth: '',
  cardExpiryYear: '',
  cardCvv: '',
  registerCard: true,
  cardName: '',
  discount: '',
  cardToken: null,
}
