import React from 'react'
import {ErrorMessage} from 'formik'
import Translator from '../translate/Translator'

function FormInputErrorMessage(props: {name: string}) {
  return (
    <ErrorMessage
      name={props.name}
      render={(key: any) => (
        <div className='text-danger'>
          <Translator name={key} />
        </div>
      )}
    />
  )
}

export default FormInputErrorMessage
