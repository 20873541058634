import React from 'react'
import {CompanyTimeLineEvent} from '../../../api/data-contracts'
import moment from 'moment'
import clsx from 'clsx'

function TimelineEvent(props: {event: CompanyTimeLineEvent}) {
  return (
    <div className='timeline-item'>
      <div className='w-75px fw-bold text-gray-800 fs-6 me-2'>
        {moment(props.event.eventTime).format('DD.MM HH:mm')}
      </div>
      <div className='timeline-badge'>
        <i className={'fa fa-genderless fs-1 text-' + props.event.eventColoringName}></i>
      </div>
      <div className='timeline-content d-flex'>
        <span
          className={clsx({
            'text-gray-800 ps-3': true,
            'fw-bold': props.event.isBold,
          })}
        >
          <div dangerouslySetInnerHTML={{__html: props.event.description ?? ''}}></div>
        </span>
      </div>
    </div>
  )
}

export default TimelineEvent
