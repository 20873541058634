import React from 'react'
import Translator from '../../../../../../components/common/translate/Translator'
import clsx from 'clsx'

function ClemataShareHolderDetail(props: {description: string; value: any; multiline?: boolean}) {
  return (
    <div className='row w-100 px-5'>
      <div className='col-6 text-gray-500 d-flex'>
        <Translator name={props.description} />
      </div>

      <div
        className={clsx(
          'fw-bold',
          {
            'col-6 text-end': !(props.multiline ?? false),
          },
          {
            'col-12': props.multiline ?? false,
          }
        )}
      >
        {props.value}
      </div>
    </div>
  )
}

export default ClemataShareHolderDetail
