import {useDispatch, useSelector} from 'react-redux'
import {getEInvoiceState, loadEInvoiceCommonDataAsync} from '../../../../redux/eInvoiceSlice'
import * as Yup from 'yup'
import {Formik, Field, ErrorMessage, FormikValues, Form} from 'formik'
import {StringApiData, UpsertEInvoiceParametersRequest} from '../../../../api/data-contracts'
import {getCommonState, postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {Api_eInvoice_UpsertEInvoiceParameters} from '../../../../apiClient/apiClient'
import {getCompanyState} from '../../../../redux/companySlice'
import {useEffect, useState} from 'react'

function ForibaParameters() {
  const eInvoiceState = useSelector(getEInvoiceState)
  const commonState = useSelector(getCommonState)
  const companyState = useSelector(getCompanyState)
  const [value, setValue] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    setValue((value) => value + 1)
  }, [eInvoiceState?.eInvoiceCommonData?.parameters])

  const initData: UpsertEInvoiceParametersRequest = {
    guidId: companyState?.Id ?? null,
    defaultQuantityCode: eInvoiceState.eInvoiceCommonData?.parameters?.defaultQuantityCode ?? '',
    defaultScenario: eInvoiceState.eInvoiceCommonData?.parameters?.defaultScenario ?? '',
    einvoiceIdentifier: eInvoiceState.eInvoiceCommonData?.parameters?.einvoiceIdentifier ?? '',
    foribaPassword: eInvoiceState.eInvoiceCommonData?.parameters?.foribaPassword ?? '',
    foribaSupplierFirstName:
      eInvoiceState.eInvoiceCommonData?.parameters?.foribaSupplierFirstName ?? '',
    foribaSupplierLastName:
      eInvoiceState.eInvoiceCommonData?.parameters?.foribaSupplierLastName ?? '',
    foribaUserName: eInvoiceState.eInvoiceCommonData?.parameters?.foribaUserName ?? '',
    infoEmail: eInvoiceState.eInvoiceCommonData?.parameters?.infoEmail ?? '',
    phoneNumber: eInvoiceState.eInvoiceCommonData?.parameters?.phoneNumber ?? '',
    taxAdministration: eInvoiceState.eInvoiceCommonData?.parameters?.taxAdministration ?? '',
    vknTckn: eInvoiceState.eInvoiceCommonData?.parameters?.vknTckn ?? '',
  }

  const schema = Yup.object({
    defaultQuantityCode: Yup.string().required('Adet Kodu Zorunludur').nullable(),
    defaultScenario: Yup.string().required('Senaryo Zorunludur').nullable(),
    einvoiceIdentifier: Yup.string().required('einvoiceIdentifier Zorunludur').nullable(),
    foribaPassword: Yup.string().required('Foriba Şifresi Zorunludur').nullable(),
    foribaSupplierFirstName: Yup.string().required('foribaSupplierFirstName Zorunludur').nullable(),
    foribaSupplierLastName: Yup.string().required('foribaSupplierLastName Zorunludur').nullable(),
    foribaUserName: Yup.string().required('Müşteri seçimi zorunludur').nullable(),
    infoEmail: Yup.string().required('Müşteri seçimi zorunludur').nullable(),
    phoneNumber: Yup.string().required('Müşteri seçimi zorunludur').nullable(),
    taxAdministration: Yup.string().required('Müşteri seçimi zorunludur').nullable(),
    vknTckn: Yup.string().required('Müşteri seçimi zorunludur').nullable(),
  })

  const submit = async (values: UpsertEInvoiceParametersRequest, actions: FormikValues) => {
    dispatch(setIsLoading(true))
    actions.setStatus('')
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_eInvoice_UpsertEInvoiceParameters,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: StringApiData) => {
        await dispatch<any>(loadEInvoiceCommonDataAsync())
        dispatch(setIsLoading(false))
      })
  }

  return (
    <div>
      <Formik
        validationSchema={schema}
        initialValues={initData}
        onSubmit={submit}
        validateOnMount={true}
      >
        {({values, errors, setFieldValue, validateForm, status}) => (
          <Form noValidate id='id_form_' placeholder={undefined}>
            <div className='w-100'>
              <div className='row'>
                <div className='d-flex align-items-center fs-2 fw-bold form-label mb-5'>
                  Servis Bilgileri
                </div>
                <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Gönderici Birim Etiketi</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='einvoiceIdentifier'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='einvoiceIdentifier' />
                  </div>
                </div>

                <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Foriba Kullanıcı Adı</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='foribaUserName'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='foribaUserName' />
                  </div>
                </div>

                <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Foriba Şifresi</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='foribaPassword'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='foribaPassword' />
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center fs-2 fw-bold form-label mb-5'>
                Firma ve İletişim Bilgileri
              </div>
              <div className='row'>
                <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Vergi Dairesi</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='taxAdministration'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='taxAdministration' />
                  </div>
                </div>

                <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Vergi Numarası</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='vknTckn'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='vknTckn' />
                  </div>
                </div>

                <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Bilgilendirme Email Adresi</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='infoEmail'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='infoEmail' />
                  </div>
                </div>

                <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Bilgilendirme Telefon Numarası</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='phoneNumber'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='phoneNumber' />
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center fs-2 fw-bold form-label mb-5'>
                Diğer Parametreler
              </div>
              <div className='row'>
                <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Varsayılan Fatura Senaryosu</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='defaultScenario'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='defaultScenario' />
                  </div>
                </div>

                <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Varsayılan Adet Kodu</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='defaultQuantityCode'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='defaultQuantityCode' />
                  </div>
                </div>

                <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>İsim</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='foribaSupplierFirstName'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='foribaSupplierFirstName' />
                  </div>
                </div>

                <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Soyisim</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='foribaSupplierLastName'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='foribaSupplierLastName' />
                  </div>
                </div>
              </div>

              <div className='d-flex flex-row-reverse pt-10 align-items-end mb-5'>
                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>Kaydet</span>
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ForibaParameters
