import MediaQuery from 'react-responsive'
import clsx from 'clsx'
import {CurrencyEnum} from '../../apiClient/enums'
import {getCurrencySymbol} from '../../utils/utilities'

function PaymentDetailRow(props: {
  description: string | null
  value: number
  isRecurringPayment: boolean
  currency: CurrencyEnum
}) {
  return (
    <div className='d-flex fw-semibold align-items-center'>
      <div className='bullet w-8px h-3px rounded-2 bg-success me-1'></div>
      <MediaQuery minWidth={500}>
        <div className='col-8 text-gray-500 me-1 d-flex'>
          {props.description}{' '}
          {props.isRecurringPayment && <div className={'text-danger ms-1'}> *</div>}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={499}>
        <div className='col-8 text-gray-500 me-1 fs-9 d-flex'>
          {props.description}{' '}
          {props.isRecurringPayment && <div className={'text-danger ms-1'}> *</div>}
        </div>
      </MediaQuery>

      <div className={clsx('col-4 fw-bolder text-end px-1', {'text-success': props.value < 0})}>
        {props.value.toFixed(1)} {getCurrencySymbol(props.currency)}
      </div>
    </div>
  )
}

export default PaymentDetailRow
