import {useSelector, useDispatch} from 'react-redux'
import FormikForm from '../../../../../components/form/FormikForm'
import BigRadioButton from '../../../../../components/form/radio/BigRadioButton'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'
import {nextStep, previousStep} from '../../../../../redux/commonSlice'
import {getCompanyState, setClemtaWizardDataAsync} from '../../../../../redux/companySlice'
import NormalTitle from '../../../../../components/common/title/NormalTitle'
import NormalText from '../../../../../components/common/title/NormalText'
import * as Yup from 'yup'
import {KTSVG} from '../../../../../../_metronic/helpers'
import Translator from '../../../../../components/common/translate/Translator'

const schema = Yup.object().shape({
  isNewCompany: Yup.boolean().required('').nullable(),
})

function ClemtaCompanyStatusStep() {
  const companyState = useSelector(getCompanyState)
  const dispatch = useDispatch()

  const initValues = {
    isNewCompany: companyState?.clemtaWizardData?.isNewCompany ?? false,
  }

  const submit = async (values: {isNewCompany: boolean}) => {
    setIsNew(values.isNewCompany)

    dispatch(nextStep())
  }

  function setIsNew(isNew: boolean) {
    dispatch<any>(
      setClemtaWizardDataAsync({
        ...companyState.clemtaWizardData,
        isNewCompany: isNew,
      })
    )
  }

  return (
    <FormikForm initialValues={initValues} onSubmit={submit} validationSchema={schema}>
      {(props) => {
        return (
          <>
            <NormalTitle text='CLEMTA_HELLO' />
            <NormalText text='CLEMTA_HELLO_DETAIL' />

            <div className='row mb-10 mt-10'>
              <div className='offset-2 col-8'>
                <div className='mb-5'>
                  <BigRadioButton
                    description={'CLEMTA_START_NEW_COMPANY'}
                    details={'CLEMTA_START_NEW_COMPANY_DETAIL'}
                    value={false}
                    icon={'/media/icons/duotune/files/fil005.svg'}
                    name={'isNewCompany'}
                    setFieldValue={(field: string, value: any, shouldValidate?: boolean) => {
                      setIsNew(value)
                      props.setFieldValue('isNewCompany', value)
                    }}
                    valueConverter={(value: string) => {
                      return value === 'true'
                    }}
                  />
                </div>

                <div className=''>
                  <BigRadioButton
                    description={'CLEMTA_HAVE_COMPANY'}
                    details={'CLEMTA_HAVE_COMPANY_DETAIL'}
                    value={true}
                    icon={'/media/icons/duotune/finance/fin006.svg'}
                    name={'isNewCompany'}
                    setFieldValue={(field: string, value: any, shouldValidate?: boolean) => {
                      setIsNew(value)
                      props.setFieldValue('isNewCompany', value)
                    }}
                    valueConverter={(value: string) => {
                      return value === 'true'
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <WizardStepButtons nextButtonDisabled={!props.isValid} />
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default ClemtaCompanyStatusStep
