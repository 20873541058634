import React from 'react'
import {useSelector} from 'react-redux'
import {getUserState} from '../../../redux/userSlice'

function SidebarEmail() {
  const userState = useSelector(getUserState)

  return (
    <div className='flex-grow-1 me-2'>
      <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>{userState.data?.mailAddress}</span>
    </div>
  )
}

export default SidebarEmail
