import {useEffect} from 'react'
import {Form, Formik, FormikValues} from 'formik'
import * as Yup from 'yup'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useDispatch, useSelector} from 'react-redux'
import {getUserState, setemailFromRegisterForm, setRegisterStatus} from '../../../redux/userSlice'
import {BooleanApiData, RegisterRequest, SmsValidationRequest} from '../../../api/data-contracts'

import {
  getCommonState,
  postRequestAsync,
  setIsLoading,
  setIsSmsVerificationSendSuccess,
} from '../../../redux/commonSlice'
import {
  Api_User_Register,
  Api_User_SendSmsVerificationCode,
  getLanguage,
} from '../../../apiClient/apiClient'
import 'yup-phone'
import YupPassword from 'yup-password'
import FormLogger from '../../../components/form/FormLogger'
import FormErrorMessage from '../../../components/common/messages/FormErrorMessage'
import FormInput from '../../../components/form/FormInput'
import PhoneNumberInput from '../../../components/form/PhoneNumberInput'
import NormalTitle from '../../../components/common/title/NormalTitle'
import SubmitButton from '../../../components/form/button/SubmitButton'
import CountdownTimer from '../../../components/other/CountdownTimer'
import Translator from '../../../components/common/translate/Translator'

YupPassword(Yup)

export type RegisterRequestExtended = RegisterRequest & {
  changepassword: string
  acceptTerms: boolean
  isSmsCodeValid: boolean
  smsCode: string
}

const registrationSchema = Yup.object().shape({
  firstName: Yup.string().min(3, 'AD_MIN').max(50, 'AD_MAX').required('AD_REQUIRED'),
  emailAddress: Yup.string()
    .email('EPOSTA_ADRESI_EPOSTA')
    .min(3, 'EPOSTA_ADRESI_MIN')
    .max(50, 'EPOSTA_ADRESI_MAX')
    .required('EPOSTA_ADRESI_REQUIRED'),
  phoneNumber: Yup.string()
    .phone('TR', false, 'TELEFON_NUMARASI_TELEFON')
    .required('TELEFON_NUMARASI_REQUIRED'),
  smsCode: Yup.string().required('SMS_KODU_REQUIRED'),
  lastName: Yup.string().min(2, 'SOYAD_MIN').max(50, 'SOYAD_MAX').required('SOYAD_REQUIRED'),
  password: Yup.string()
    //.password()
    .min(6, 'ŞIFRE_MIN')
    .minLowercase(1, 'ŞIFRE_KÜÇÜKHARF')
    .minUppercase(1, 'ŞIFRE_BÜYÜKHARF')
    .minNumbers(1, 'ŞIFRE_RAKAM')
    .max(50, 'ŞIFRE_MAX')
    .required('ŞIFRE_REQUIRED'),
  changepassword: Yup.string()
    .required('ŞIFRETEKRARI_REQUIRED')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'ŞIFRETEKRARI_BİRLEŞMİYOR'),
    }),
  acceptTerms: Yup.bool().required('ŞARTLARIKABULET_REQUIRED'),
})

export function Registration(props: {customInitValues?: RegisterRequestExtended}) {
  const initialValues: RegisterRequestExtended = props.customInitValues ?? {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    password: '',
    changepassword: '',
    smsCode: '',
    isSmsCodeValid: false,
    acceptTerms: false,
  }

  const dispatch = useDispatch()
  const userState = useSelector(getUserState)
  const navigate = useNavigate()
  const commonState = useSelector(getCommonState)

  useEffect(() => {
    dispatch(setIsSmsVerificationSendSuccess(false))
  }, [])

  const onSubmit = async (values: RegisterRequestExtended, actions: FormikValues) => {
    dispatch(setIsLoading(true))
    actions.setStatus('')
    try {
      const body: RegisterRequest = {
        emailAddress: values.emailAddress,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        phoneNumber: values.phoneNumber?.replaceAll(' ', ''),
        smsCode: values.smsCode,
      }

      await dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_User_Register,
          body: body,
        })
      )
        .unwrap()
        .then((response: BooleanApiData) => {
          dispatch<any>(setRegisterStatus(response.data ?? false))
          if (response.data) {
            dispatch<any>(setemailFromRegisterForm(body.emailAddress))
          } else {
            actions.setStatus(response.serverMessage)
          }
        })

      dispatch(setIsLoading(false))
    } catch (error) {
      actions.setSubmitting(false)
      dispatch(setIsLoading(false))
    }
  }

  async function SendSms(phoneNumber: string, setStatus: any) {
    setStatus('')
    const body: SmsValidationRequest = {
      phoneNumber: phoneNumber.replaceAll(' ', ''),
    }
    dispatch(setIsSmsVerificationSendSuccess(false))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_User_SendSmsVerificationCode,
        body: body,
      })
    )
      .unwrap()
      .then((response: BooleanApiData) => {
        dispatch(setIsSmsVerificationSendSuccess(response.data ?? false))
        setStatus(response.serverMessage)
      })
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  useEffect(() => {
    if (userState.registerStatus) {
      navigate('/auth' + location.search)
    }
  }, [userState.registerStatus])

  const location = useLocation()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={registrationSchema}
        onSubmit={onSubmit}
        validateOnMount={true}
        enableReinitialize={true}
        className='form w-100'
      >
        {({values, errors, touched, isSubmitting, isValid, status, setFieldValue, setStatus}) => (
          <Form placeholder={undefined}>
            <FormLogger
              values={values}
              touched={touched}
              isValid={isValid}
              errors={errors}
              isSubmitting={isSubmitting}
              status={status}
            />
            <NormalTitle text='KAYIT_OL' />

            <FormErrorMessage message={status} />

            <div className='row'>
              <div className='d-flex flex-column mb-7 fv-row col-xl-6'>
                <FormInput
                  istouched={touched.firstName ?? false}
                  errorMessage={errors.firstName}
                  isTransparent={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'firstName',
                    autoComplete: 'off',
                    placeholder: 'ISIM',
                  }}
                />
              </div>
              <div className='d-flex flex-column mb-7 fv-row col-xl-6'>
                <FormInput
                  istouched={touched.lastName ?? false}
                  errorMessage={errors.lastName}
                  isTransparent={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'lastName',
                    autoComplete: 'off',
                    placeholder: 'SOYISIM',
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='d-flex flex-column mb-7 col-xl-6'>
                <PhoneNumberInput
                  istouched={touched.phoneNumber ?? false}
                  errorMessage={errors.phoneNumber}
                  isTransparent={true}
                  setFieldValue={setFieldValue}
                  fieldAttributes={{
                    type: 'text',
                    name: 'phoneNumber',
                    autoComplete: 'off',
                    placeholder: 'BOS_TELEFON',
                  }}
                />
              </div>
              {commonState.isSmsVerificationSendSuccess ? (
                <div className='row mb-7 col-xl-6'>
                  <div className='col-9'>
                    <FormInput
                      istouched={touched.smsCode ?? false}
                      errorMessage={errors.smsCode}
                      isTransparent={true}
                      fieldAttributes={{
                        type: 'text',
                        name: 'smsCode',
                        autoComplete: 'off',
                        placeholder: 'Kod',
                      }}
                    />
                  </div>
                  <div className='col-3'>
                    <a href='#' className='btn btn-primary btn-icon'>
                      <CountdownTimer
                        seconds={299}
                        completedEvent={() => {
                          dispatch(setIsSmsVerificationSendSuccess(false))
                        }}
                      >
                        <i className='bi bi-arrow-clockwise'></i>
                      </CountdownTimer>
                    </a>
                  </div>
                </div>
              ) : (
                <div className='d-flex flex-column mb-7 col-xl-6'>
                  <a
                    href='#'
                    onClick={() => {
                      SendSms(values.phoneNumber ?? '', setStatus)
                    }}
                    className='btn btn-primary'
                  >
                    <Translator name={'SMS_KODU_ONAYLA'} />
                  </a>
                </div>
              )}
            </div>

            <div className='row mb-8'>
              <div>
                <FormInput
                  istouched={touched.emailAddress ?? false}
                  errorMessage={errors.emailAddress}
                  isTransparent={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'emailAddress',
                    autoComplete: 'off',
                    placeholder: 'EMAIL',
                  }}
                />
              </div>
            </div>

            <div className='row mb-5' data-kt-password-meter='true'>
              <div className='mb-1'>
                <div className='position-relative mb-3'>
                  <FormInput
                    istouched={touched.password ?? false}
                    errorMessage={errors.password}
                    isTransparent={true}
                    fieldAttributes={{
                      type: 'password',
                      name: 'password',
                      autoComplete: 'off',
                      placeholder: 'SIFRE',
                    }}
                  />
                </div>
                <div
                  className='d-flex align-items-center mb-3'
                  data-kt-password-meter-control='highlight'
                >
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
              </div>
              <div className='text-muted'></div>
            </div>

            <div className='row mb-5'>
              <div>
                <FormInput
                  istouched={touched.changepassword ?? false}
                  errorMessage={errors.changepassword}
                  isTransparent={true}
                  fieldAttributes={{
                    type: 'password',
                    name: 'changepassword',
                    autoComplete: 'off',
                    placeholder: 'SIFRE_ONAY',
                  }}
                />
              </div>
            </div>

            <div className='fv-row mb-8'>
              <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
                <input className='form-check-input' type='checkbox' name='acceptTerms' />
                {getLanguage() == 'tr' && (
                  <span>
                    <a
                      href='https://defterdar.com/sss'
                      target='_blank'
                      className='ms-1 link-primary'
                    >
                      Şartları ve Koşulları
                    </a>{' '}
                    kabul ediyorum.
                  </span>
                )}

                {getLanguage() == 'en' && (
                  <span>
                    I accepted{' '}
                    <a
                      href='https://defterdar.com/sss'
                      target='_blank'
                      className='ms-1 link-primary'
                    >
                      the terms and conditions
                    </a>
                  </span>
                )}
              </label>
              {touched.acceptTerms && errors.acceptTerms && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.acceptTerms}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='text-center'>
              <SubmitButton
                className='w-100 mb-5'
                isSubmitting={isSubmitting}
                isValid={isValid}
                loadingText='LUTFEN_BEKLEYIN'
                notLoadingText='KAYIT_OL'
              />
              <Link to={'/auth/login' + location.search}>
                <button type='button' className='btn btn-lg btn-light-danger w-100 mb-5'>
                  <Translator name='IPTAL' />
                </button>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
