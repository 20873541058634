import React, {ReactNode} from 'react'
import {UserTypeAuthorizationsEnum} from '../../../api/data-contracts'
import {CanIDoThat} from '../../../utils/CanIDoThat'

interface AuthorizedComponentProps {
  auth: number
  children: ReactNode
}

const AuthorizedComponent: React.FC<AuthorizedComponentProps> = ({auth, children}) => {
  return CanIDoThat(auth) ? <>{children}</> : null
}

export default AuthorizedComponent
