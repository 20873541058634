import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {FormikValues} from 'formik'
import {Api_User_SendPasswordResetEmail} from '../../../apiClient/apiClient'
import {BooleanApiData, SendPasswordResetMailRequest} from '../../../api/data-contracts'
import {useDispatch} from 'react-redux'
import {setIsLoading, postRequestAsync} from '../../../redux/commonSlice'
import FormikForm from '../../../components/form/FormikForm'
import NormalTitle from '../../../components/common/title/NormalTitle'
import FormInput from '../../../components/form/FormInput'
import FormErrorMessage from '../../../components/common/messages/FormErrorMessage'
import SubmitButton from '../../../components/form/button/SubmitButton'
import FormSuccessMessage from '../../../components/common/messages/FormSuccessMessage'
import Translator from '../../../components/common/translate/Translator'

const initialValues: SendPasswordResetMailRequest = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('EPOSTA_YANLIS_FORMAT')
    .min(3, 'EPOSTA_MIN')
    .max(50, 'EPOSTA_MAX')
    .required('EPOSTA_REQUIRED'),
})

export function ForgotPassword() {
  const dispatch = useDispatch()

  const onSubmit = async (values: SendPasswordResetMailRequest, actions: FormikValues) => {
    actions.setStatus(null)

    dispatch(setIsLoading(true))
    try {
      const body: SendPasswordResetMailRequest = {
        email: values.email,
      }

      await dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_User_SendPasswordResetEmail,
          body: body,
        })
      )
        .unwrap()
        .then((response: BooleanApiData) => {
          if (response?.data == true) {
            actions.setStatus({
              isSuccess: true,
              message: 'Şifre yenileme için email gönderildi.',
            })
          } else {
            actions.setStatus({
              isSuccess: false,
              message:
                response?.serverMessage?.length ?? 0 > 0
                  ? response?.serverMessage
                  : 'Bir Sorun Oluştu',
            })
          }
        })
        .catch((error: any) => {})
    } catch (error) {
      console.error(error)
      actions.setStatus({
        isSuccess: false,
        message: 'Bir Sorun Oluştu',
      })
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={forgotPasswordSchema}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <>
            <NormalTitle text='Şifre Yenileme' />
            <div className='text-center mb-10'>
              <div className='text-gray-500 fw-semibold fs-6'>
                <Translator name='SIFREYI_YENILEMEK_ICIN' />
              </div>
            </div>
            {props.status?.isSuccess ? (
              <FormSuccessMessage message={props.status?.message} />
            ) : (
              <FormErrorMessage message={props.status?.message} />
            )}
            <div className='row mb-8'>
              <div>
                <FormInput
                  istouched={props.touched.email ?? false}
                  errorMessage={props.errors.email}
                  isTransparent={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'email',
                    autoComplete: 'on',
                    placeholder: 'EMAIL',
                  }}
                />
              </div>
            </div>
            <div className='text-center'>
              <SubmitButton
                className='w-100 mb-5'
                isSubmitting={props.isSubmitting}
                isValid={props.isValid}
                loadingText='LUTFEN_BEKLEYIN'
                notLoadingText='GONDER'
              />
              <Link to='/auth/login'>
                <button type='button' className='btn btn-lg btn-light-danger w-100 mb-5'>
                  <Translator name='IPTAL' />
                </button>
              </Link>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}
