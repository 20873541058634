import React, {useEffect, useState} from 'react'
import {Banner, BannerListApiData} from '../../../api/data-contracts'
import {Api_Admin_GetBanners} from '../../../apiClient/apiClient'
import {getRequestAsync} from '../../../redux/commonSlice'
import {BannerData} from './BannerData'
import {useDispatch} from 'react-redux'
import HomeBanner from './HomeBanner'

function HomeBanners() {
  const [banners, setBanners] = useState<BannerData[]>([])

  let initData: {
    banners: BannerData[]
  } = {
    banners: [],
  }
  const dispatch = useDispatch()

  function loadBanners() {
    dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetBanners,
      })
    )
      .unwrap()
      .then(async function (response: BannerListApiData) {
        setBanners(
          response.data?.map((m) => {
            return {
              Description: m.description ?? '',
              File: undefined,
              Title: m.title ?? '',
              Image: m.url ?? '',
            }
          }) ?? []
        )
      })
  }

  useEffect(() => {
    loadBanners()
  }, [])

  return <HomeBanner banners={banners} />
}

export default HomeBanners
