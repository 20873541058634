import {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
import {getProductState, setActiveCountries} from '../../redux/productSlice'
import {getRequestAsync, postRequestAsync, setIsLoading} from '../../redux/commonSlice'
import {Api_User_SetCountry, Api_Wizard_GetActiveCountries} from '../../apiClient/apiClient'
import {
  BooleanApiData,
  CountryDTOIEnumerableApiData,
  SetCountryRequest,
} from '../../api/data-contracts'
import {getUserState} from '../../redux/userSlice'
import WizardPage from './WizardPage'

function PreWizardPage(props: {cantPass: boolean}) {
  const dispatch = useDispatch()

  const [isCountriesLoaded, setIsCountriesLoaded] = useState(false)

  const productState = useSelector(getProductState)
  const userState = useSelector(getUserState)

  useEffect(() => {
    loadCountries()
  }, [])

  async function loadCountries() {
    await dispatch<any>(setIsLoading(true))
    await dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Wizard_GetActiveCountries,
      })
    )
      .unwrap()
      .then(async function (response: CountryDTOIEnumerableApiData) {
        if (response.isSuccessful) {
          dispatch<any>(setActiveCountries(response.data))
          setIsCountriesLoaded(true)
        }
        await dispatch<any>(setIsLoading(false))
      })
  }

  function GetCountryFlagFileName(countryId: number) {
    if (countryId == 1) {
      return '/media/flags/turkey.svg'
    }
    if (countryId == 5) {
      return '/media/flags/united-states.svg'
    }

    return ''
  }

  async function SetCountry(countryId: number) {
    const body: SetCountryRequest = {
      countryId: countryId,
    }
    dispatch(setIsLoading(true))

    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_User_SetCountry,
        body: body,
      })
    )
      .unwrap()
      .then((response: BooleanApiData) => {
        if (response.data) {
          window.location.reload()
        } else {
          dispatch(setIsLoading(false))
        }
      })
  }

  const canPass =
    ((userState.data?.countryId ?? 0) > 0 || !((productState.activeCountries?.length ?? 0) > 1)) &&
    !props.cantPass

  if (!isCountriesLoaded) {
    return <></>
  } else {
    return canPass ? (
      <WizardPage />
    ) : (
      <div className='d-flex justify-content-center align-items-center pt-20'>
        <div className='w-25'>
          <div className='d-flex justify-content-between align-items-center pt-20'>
            {productState.activeCountries?.map((m) => {
              return (
                <div
                  className='col-xl-4 align-self-center py-3'
                  key={m.countryId}
                  onClick={() => SetCountry(m.countryId ?? 0)}
                >
                  <span className='symbol symbol-20px me-4'>
                    <img
                      className='rounded-1 w-200px h-200px btn btn-outline btn-outline-dashed btn-outline-success btn-light-success'
                      src={toAbsoluteUrl(GetCountryFlagFileName(m.countryId ?? 0))}
                      alt='metronic'
                    />
                  </span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default PreWizardPage
