import {NavigateFunction, useNavigate} from 'react-router-dom'
import {
  ProductDTO,
  StartPaymentResponseApiData,
  StartManualPaymentRequest,
  PaymentDetailDTO,
  WizardDataDTOApiData,
  ProductAndPaymentInfoDTO,
  CountryDTO,
  WizardDataDTO,
  PaymentDetailDTOApiData,
  PricesForCertainPaymentRequest,
  ClemtaWizardDataDTOApiData,
  ClemtaWizardDataDTO,
} from './../api/data-contracts'
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'
import {
  Api_Payment_PricesForPayment,
  Api_Payment_StartPayment,
  Api_Wizard_GetClemtaWizardData,
  Api_Wizard_GetWizardData,
} from '../apiClient/apiClient'
import {getRequestAsync, postRequestAsync, setIsLoading} from './commonSlice'
import _ from 'lodash'

export interface ProductState {
  extraProducts: ProductDTO[] | undefined | null
  extraProductsCategorized:
    | {
        categoryId: number | undefined | null
        category: string | undefined | null
        products: ProductDTO[] | undefined | null
      }[]
    | undefined
    | null
  productAndPaymentInfo: ProductAndPaymentInfoDTO | undefined | null
  prices: PaymentDetailDTO | undefined | null
  certainPaymentId: number | undefined | null
  activeCountries: CountryDTO[] | undefined | null
  turkeyWizardData: WizardDataDTO | undefined | null
  clemtaWizardData: ClemtaWizardDataDTO | undefined | null
}

const initialState: ProductState = {
  extraProducts: undefined,
  extraProductsCategorized: undefined,
  productAndPaymentInfo: null,
  prices: null,
  certainPaymentId: null,
  activeCountries: null,
  turkeyWizardData: null,
  clemtaWizardData: null,
}

export const productSlice = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    setExtraProducts: (state, action: PayloadAction<ProductDTO[] | undefined | null>) => {
      state.extraProducts = action.payload

      const categories = Object.entries(
        _.groupBy(
          action.payload?.filter((f) => f.category != null && f.isActiveOnExtra == true),
          (p) => p.categoryId
        )
      )

      state.extraProductsCategorized = categories.map((m) => {
        return {
          categoryId: Number(m[0]),
          category: m[1][0].category,
          products: m[1],
        }
      })
    },
    setProductAndPaymentInfo: (
      state,
      action: PayloadAction<ProductAndPaymentInfoDTO | undefined | null>
    ) => {
      state.productAndPaymentInfo = action.payload
    },
    setPrices: (state, action: PayloadAction<PaymentDetailDTO | undefined | null>) => {
      state.prices = action.payload
    },
    setTurkeyWizardData: (state, action: PayloadAction<WizardDataDTO | undefined | null>) => {
      state.turkeyWizardData = action.payload
    },
    setClemtaWizardData: (state, action: PayloadAction<ClemtaWizardDataDTO | undefined | null>) => {
      state.clemtaWizardData = action.payload
    },
    setCertainPaymentId: (state, action: PayloadAction<number | undefined | null>) => {
      state.certainPaymentId = action.payload
    },
    setActiveCountries: (state, action: PayloadAction<CountryDTO[] | undefined | null>) => {
      state.activeCountries = action.payload
    },
  },
})

export const startPaymentAsync = createAsyncThunk(
  'api/startPaymentAsync',
  async (payload: {request: StartManualPaymentRequest}, thunkAPI) => {
    return await thunkAPI
      .dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_Payment_StartPayment,
          body: payload.request,
        })
      )
      .unwrap()
      .then(function (response: StartPaymentResponseApiData) {
        if (response.data?.paymentPageScript?.length ?? 0 > 0) {
          document.open('text/html', 'replace')
          document.write(response.data?.paymentPageScript ?? '')
          document.close()
        } else {
          return response.serverMessage
        }
      })
  }
)

export const openPaymentPageAsync = createAsyncThunk(
  'api/openPaymentPageAsync',
  async (
    payload: {paymentId?: number | undefined | null; navigate: NavigateFunction},
    thunkAPI
  ) => {
    thunkAPI.dispatch(setCertainPaymentId(payload.paymentId))
    thunkAPI.dispatch(loadPricesAsync())
    payload.navigate('/purchase')
  }
)

export const loadTurkeyWizardDataAsync = createAsyncThunk(
  'api/loadTurkeyWizardDataAsync',
  async (payload, thunkAPI) => {
    await thunkAPI.dispatch<any>(setIsLoading(true))
    await thunkAPI
      .dispatch<any>(
        getRequestAsync({
          getRequestFunction: Api_Wizard_GetWizardData,
        })
      )
      .unwrap()
      .then(function (response: WizardDataDTOApiData) {
        if (response.isSuccessful) {
          thunkAPI.dispatch<any>(setTurkeyWizardData(response.data))
          thunkAPI.dispatch<any>(setIsLoading(false))
        }
      })
  }
)

export const loadClemtaWizardDataAsync = createAsyncThunk(
  'api/loadClemtaWizardDataAsync',
  async (payload, thunkAPI) => {
    await thunkAPI.dispatch<any>(setIsLoading(true))
    await thunkAPI
      .dispatch<any>(
        getRequestAsync({
          getRequestFunction: Api_Wizard_GetClemtaWizardData,
        })
      )
      .unwrap()
      .then(function (response: ClemtaWizardDataDTOApiData) {
        if (response.isSuccessful) {
          thunkAPI.dispatch<any>(setClemtaWizardData(response.data))
          thunkAPI.dispatch<any>(setIsLoading(false))
        }
      })
  }
)

export const loadPricesAsync = createAsyncThunk(
  'api/loadPricesAsync',
  async (payload, thunkAPI) => {
    const productState = (thunkAPI.getState() as RootState).product

    const request: PricesForCertainPaymentRequest = {
      paymentId: productState.certainPaymentId ?? undefined,
    }

    await thunkAPI
      .dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_Payment_PricesForPayment,
          body: request,
        })
      )
      .unwrap()
      .then(async (response: PaymentDetailDTOApiData) => {
        thunkAPI.dispatch(setPrices(response.data))
      })
  }
)

export const {
  setExtraProducts,
  setProductAndPaymentInfo,
  setPrices,
  setCertainPaymentId,
  setActiveCountries,
  setTurkeyWizardData,
  setClemtaWizardData,
} = productSlice.actions
export default productSlice.reducer

export const getProductState = (state: RootState) => state.product
