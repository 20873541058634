import {FieldAttributes} from 'formik'
import React from 'react'
import {NumericFormat} from 'react-number-format'
import TranslatorText from '../common/translate/TranslatorText'
import FormInputErrorMessage from '../common/messages/FormInputErrorMessage'
import {defterdar_log} from '../../utils/utilities'
import {CurrencyEnum} from '../../apiClient/enums'

function FormPriceInput(props: {
  currency: CurrencyEnum | 'Perc'
  istouched: boolean
  errorMessage: string | undefined
  fieldAttributes?: FieldAttributes<any>
  placeHolderOnTop?: boolean
  checkValid?: boolean
  isTransparent?: boolean
  value: number
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
}) {
  return (
    <>
      {props.placeHolderOnTop && (
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>{TranslatorText(props.fieldAttributes.placeholder)}</span>
        </label>
      )}

      {props.currency == 'Perc' && (
        <NumericFormat
          {...props.fieldAttributes}
          onValueChange={(values, sourceInfo) => {
            props.setFieldValue?.(props.fieldAttributes.name, values.floatValue)
          }}
          value={props.value}
          thousandSeparator=''
          decimalSeparator=','
          suffix='%'
          placeholder='%'
          decimalScale={10}
          allowNegative={false}
          allowLeadingZeros={false}
          className='form-control form-control-solid'
        />
      )}

      {props.currency == CurrencyEnum.Dolar && (
        <NumericFormat
          {...props.fieldAttributes}
          onValueChange={(values, sourceInfo) => {
            props.setFieldValue?.(props.fieldAttributes.name, values.floatValue)
          }}
          value={props.value}
          thousandSeparator='.'
          decimalSeparator=','
          prefix='$'
          placeholder='$0.00'
          decimalScale={10}
          allowNegative={false}
          allowLeadingZeros={false}
          className='form-control form-control-solid'
        />
      )}

      {props.currency == CurrencyEnum.Türk_Lirası && (
        <NumericFormat
          {...props.fieldAttributes}
          value={props.value}
          thousandSeparator
          suffix=' ₺'
          placeholder='0.00 ₺'
          decimalScale={10}
          allowNegative={false}
          allowLeadingZeros={false}
          className='form-control form-control-solid'
        />
      )}
      <FormInputErrorMessage name={props.fieldAttributes.name} />
    </>
  )
}

export default FormPriceInput
