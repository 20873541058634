import React, {useEffect, useState} from 'react'

function CountdownTimer(props: {children: React.ReactNode; seconds: number; completedEvent: any}) {
  const [timeLeft, setTimeLeft] = useState(props.seconds)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1)
    }, 1000)

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId)
  }, []) // Empty dependency array ensures the effect runs once when the component mounts

  useEffect(() => {
    if (timeLeft == 0) {
      props.completedEvent()
    }
  }, [timeLeft])

  return <> {timeLeft > 0 ? timeLeft : props.children}</>
}

export default CountdownTimer
