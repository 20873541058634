import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  CompanyProductSummaryDTO,
  CompanyProductSummaryDTOApiData,
  CompanyTimeLineEvent,
  CompanyTimeLineEventListApiData,
} from '../../../api/data-contracts'
import {
  Api_Company_GetCompanyTimeline,
  Api_Company_GetProductSummary,
} from '../../../apiClient/apiClient'
import {postRequestAsync} from '../../../redux/commonSlice'
import moment from 'moment'
import Translator from '../../common/translate/Translator'

function SubscriptionInfo() {
  const dispatch = useDispatch()

  const [summary, setSummary] = useState<CompanyProductSummaryDTO>()

  useEffect(() => {
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Company_GetProductSummary,
        body: {},
      })
    )
      .unwrap()
      .then(async (response: CompanyProductSummaryDTOApiData) => {
        setSummary(response.data)
      })
  }, [])

  return (
    <div className='card card-border w-100 d-flex flex-row-fluid flex-center h-100'>
      <div className='card-header card-header-stretch'>
        <div className='card-toolbar'>
          <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
            {summary?.subscriptions?.map((m, i) => (
              <li className='nav-item' key={i}>
                <a
                  className={'nav-link ' + (i == 0 && 'active')}
                  data-bs-toggle='tab'
                  href={'#kt_tab_panel_' + i}
                >
                  {m.activePackageName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='card-body pt-0 fs-6 w-100 pt-1'>
        <div className='mb-3 mt-2'></div>

        <div className='tab-content' id='myTabContent'>
          {summary?.subscriptions?.map((m, i) => (
            <div
              className={'tab-pane fade ' + (i == 0 && 'active show')}
              id={'kt_tab_panel_' + i}
              role='tabpanel'
              key={i}
            >
              <div className='row'>
                <div className='mb-1'>
                  <table className='table fs-6 fw-semibold gs-0 gy-1 gx-2'>
                    <tbody>
                      <tr className=''>
                        <td className='text-gray-500'>
                          <Translator name='ODEME' />
                        </td>
                        <td className='text-gray-800'>
                          <div className='mb-0'>
                            <span className='fw-semibold text-gray-600'>
                              {m.activeSubscriptionAmount}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr className=''>
                        <td className='text-gray-500'>
                          <Translator name='BASLANGIC' />
                        </td>
                        <td className='text-gray-800'>
                          <span className='fw-semibold text-gray-600'>
                            {moment(m.subscriptionStart).format('DD.MM.yyyy')}
                          </span>
                        </td>
                      </tr>
                      <tr className=''>
                        <td className='text-gray-500'>
                          <Translator name='BITIS' />
                        </td>
                        <td className='text-gray-800'>
                          <span className='fw-semibold text-gray-600'>
                            {moment(m.subscriptionFinish).format('DD.MM.yyyy')}
                          </span>
                        </td>
                      </tr>
                      <tr className=''>
                        <td className='text-gray-500'>
                          <Translator name='SONRAKI_ODEME' />
                        </td>
                        <td className='text-gray-800'>
                          <span className='fw-semibold text-gray-600'>
                            {moment(m.subscriptionNextPayment).format('DD.MM.yyyy')}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* <div className='mb-0'>
          <h5 className='mb-0'>Payment Details</h5>

          <div className='mb-0'>
            <div className='fw-semibold text-gray-600 d-flex align-items-center'>Mastercard</div>

            <div className='fw-semibold text-gray-600'>Expires Dec 2024</div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default SubscriptionInfo
