import {ReportEnum} from './../types/ReportEnum'
import {
  AdminPanelCompaniesStartupDTO,
  AdminPanelCompanyDetailDTO,
  AdminPanelCompanyDTO,
  AdminPanelInvoicesStartupDTO,
  AdminProductDTO,
  AdminProductPageData,
  DashboardData,
  GetPaymentInvoicesRequest,
  PaymentInvoiceDTO,
  PaymentInvoiceDTOIEnumerableApiData,
  PaymentReport,
  PotentialCustomerDTO,
  ReferenceCommissionReport,
  SaleReportData,
} from './../api/data-contracts'
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {RootState} from './store'
import {postRequestAsync, setIsLoading} from './commonSlice'
import {Api_Admin_GetPaymentInvoices} from '../apiClient/apiClient'
import {defterdar_log} from '../utils/utilities'

export interface AdminState {
  companies: AdminPanelCompanyDTO[] | null | undefined
  company: AdminPanelCompanyDetailDTO | null | undefined
  invoices: PaymentInvoiceDTO[] | null | undefined
  potentialCustomers: PotentialCustomerDTO[] | null | undefined
  dashboardData: DashboardData | null | undefined
  invoicesStartup: AdminPanelInvoicesStartupDTO | null | undefined
  getPaymentInvoicesRequest: GetPaymentInvoicesRequest | null | undefined
  productsPage: AdminProductPageData | null | undefined
  reports: AdminReports
}

export interface AdminReports {
  reportEnum: ReportEnum | null | undefined
  saleReport?: SaleReportData[] | null | undefined
  referenceCommissionReport?: ReferenceCommissionReport[] | null | undefined
  paymentReport?: PaymentReport[] | null | undefined
}

const initialState: AdminState = {
  companies: null,
  company: null,
  invoices: null,
  potentialCustomers: null,
  dashboardData: null,
  invoicesStartup: null,
  getPaymentInvoicesRequest: null,
  productsPage: null,
  reports: {
    reportEnum: null,
    saleReport: null,
    referenceCommissionReport: null,
  },
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState: initialState,
  reducers: {
    setCompanies: (state, action: PayloadAction<AdminPanelCompanyDTO[] | null | undefined>) => {
      state.companies = action.payload
    },
    setAdminSelectedCompany: (
      state,
      action: PayloadAction<AdminPanelCompanyDetailDTO | null | undefined>
    ) => {
      state.company = action.payload
    },
    setPotentialCustomers: (
      state,
      action: PayloadAction<PotentialCustomerDTO[] | null | undefined>
    ) => {
      state.potentialCustomers = action.payload
    },
    setInvoices: (state, action: PayloadAction<PaymentInvoiceDTO[] | null | undefined>) => {
      state.invoices = action.payload
    },
    setDashboardData: (state, action: PayloadAction<DashboardData | null | undefined>) => {
      state.dashboardData = action.payload
    },
    setGetPaymentInvoicesRequest: (
      state,
      action: PayloadAction<GetPaymentInvoicesRequest | null | undefined>
    ) => {
      state.getPaymentInvoicesRequest = action.payload
    },
    setInvoicesStartup: (
      state,
      action: PayloadAction<AdminPanelInvoicesStartupDTO | null | undefined>
    ) => {
      state.invoicesStartup = action.payload
    },
    setProductsPageData: (
      state,
      action: PayloadAction<AdminProductPageData | null | undefined>
    ) => {
      state.productsPage = action.payload
    },
    setReport: (state, action: PayloadAction<AdminReports>) => {
      state.reports = action.payload
    },
  },
})

export const getPaymentInvoicesAsync = createAsyncThunk(
  'api/getPaymentInvoices',
  async (payload, thunkAPI) => {
    const adminState = (thunkAPI.getState() as RootState).admin

    await thunkAPI.dispatch<any>(setIsLoading(true))

    thunkAPI
      .dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_Admin_GetPaymentInvoices,
          body: adminState.getPaymentInvoicesRequest,
        })
      )
      .unwrap()
      .then(async function (response: PaymentInvoiceDTOIEnumerableApiData) {
        await thunkAPI.dispatch<any>(setInvoices(response.data))
        await thunkAPI.dispatch<any>(setIsLoading(false))
      })
  }
)

export const {
  setCompanies,
  setAdminSelectedCompany,
  setInvoices,
  setPotentialCustomers,
  setInvoicesStartup,
  setDashboardData,
  setGetPaymentInvoicesRequest,
  setProductsPageData,
  setReport,
} = adminSlice.actions
export default adminSlice.reducer

export const getAdminState = (state: RootState) => state.admin
