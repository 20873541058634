import SubscriptionInfo from '../../../components/customer/home/SubscriptionInfo'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import Timeline from '../../../components/customer/home/Timeline'
import CustomerCalendar from '../../../components/customer/home/CustomerCalendar'
import HomeBanners from './HomeBanners'

function HomePage() {
  return (
    <>
      <div className='h-100'>
        <div className='d-flex w-100 row mt-5 h-500px'>
          <div className='col-xl-3 col-12'>
            <SubscriptionInfo />
          </div>
          <div className='col-xl-9 col-12'>
            <HomeBanners />
          </div>
        </div>
        <div className='d-flex w-100 row mt-8 '>
          <div className='col-xl-4 col-12'>
            <Timeline />
          </div>
          <div className='col-xl-8 col-12'>
            <CustomerCalendar />
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage
