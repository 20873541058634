import {Field, FormikContext, useFormikContext} from 'formik'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {PaymentDetailDTOApiData, TryDiscountRequest} from '../../api/data-contracts'
import {Api_Payment_TryDiscount} from '../../apiClient/apiClient'
import {setIsLoading, getRequestAsync, postRequestAsync} from '../../redux/commonSlice'
import {getProductState, setPrices} from '../../redux/productSlice'
import Translator from '../common/translate/Translator'
import {FormStatus} from '../../types/FormStatus'

function Discount() {
  const dispatch = useDispatch()
  const formik = useFormikContext()
  const productState = useSelector(getProductState)

  function tryDiscount() {
    const body: TryDiscountRequest = {
      code: (formik.values as any)?.discount ?? '',
      paymentId: productState?.certainPaymentId,

    }
    formik.setStatus(new FormStatus(false, ''))
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Payment_TryDiscount,
        body: body,        
      })
    )
      .unwrap()
      .then(async (response: PaymentDetailDTOApiData) => {
        dispatch(setPrices(response.data))
        if (response?.serverMessage?.length ?? 0 > 0) {
          formik.setStatus(new FormStatus(false, response.serverMessage))
        }
      })
  }

  function cancelDiscount() {
    const body: TryDiscountRequest = {
      code: '',
      paymentId: productState?.certainPaymentId,
    }
    formik.setStatus('')
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Payment_TryDiscount,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: PaymentDetailDTOApiData) => {
        dispatch(setPrices(response.data))
      })
  }

  return (
    <div className='px-5'>
      {!(productState.prices?.isDiscounted ?? false) ? (
        <div className='d-flex flex-column mb-3 fv-row'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span>
              <Translator name='INDIRIM_KODU' />
            </span>
          </label>
          <div className='row mb-3'>
            <div className='col-6 col-sm-6 col-md-6 col-lg-3'>
              <Field
                type='text'
                className='form-control form-control-solid'
                placeholder=''
                name='discount'
              />
            </div>
            <div className='col-3'>
              <button type='button' className='btn btn-lg btn-primary me-3' onClick={tryDiscount}>
                <span className='indicator-label'>
                  <Translator name='UYGULA' />
                </span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex flex-column mb-3 fv-row'>
          <div className='row mb-3'>
            <div className='col-6'>
              <button
                type='button'
                className='btn btn-lg btn-primary me-3'
                onClick={cancelDiscount}
              >
                <span className='indicator-label'>
                  <Translator name='INDIRIM_IPTAL_ET' />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Discount
