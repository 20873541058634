import _ from 'lodash'
import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  AdminPanelCompanyDetailDTO,
  GetAdminCompanyRequest,
  AdminPanelCompanyDetailDTOApiData,
  AdminPanelCompanyDetailStartupDTO,
  AdminPanelCompanyDetailStartupDTOApiData,
} from '../../../api/data-contracts'
import {Api_Admin_GetCompany, Api_Admin_GetCompanyDetailStartup} from '../../../apiClient/apiClient'
import {setIsLoading, postRequestAsync, getRequestAsync} from '../../../redux/commonSlice'
import PaymentsTable from '../../../components/customer/finance/PaymentsTable'
import {useParams} from 'react-router-dom'
import {CompanyStatusHeader} from '../../../components/admin/company/CompanyStatusHeader'
import CompanyDetailForm from '../../../components/admin/company/CompanyDetailForm'
import {KTSVG} from '../../../../_metronic/helpers'
import {getAdminState, setAdminSelectedCompany} from '../../../redux/adminSlice'
import {defterdar_log} from '../../../utils/utilities'
import AdminPaymentsTable from '../../../components/admin/company/AdminPaymentsTable'
import {ApplicationStepEnum, UserTypeAuthorizationsEnum} from '../../../apiClient/enums'
import clsx from 'clsx'
import DocumentsPage from '../../document/DocumentsPage'
import AuthorizedComponent from '../../../components/common/auth/AuthorizedComponent'

function AdminCompanyDetailPage() {
  const {id} = useParams()

  const adminState = useSelector(getAdminState)
  const [startUp, setStartUp] = useState<AdminPanelCompanyDetailStartupDTO | null | undefined>(null)

  const dispatch = useDispatch()

  const loadCompanyData = () => {
    const body: GetAdminCompanyRequest = {
      companyGuidId: id,
    }
    dispatch(setIsLoading(true))
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetCompany,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelCompanyDetailDTOApiData) {
        dispatch(setAdminSelectedCompany(response.data))
        dispatch(setIsLoading(false))
      })
  }

  async function load() {
    await dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetCompanyDetailStartup,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelCompanyDetailStartupDTOApiData) {
        setStartUp(response.data)
        loadCompanyData()
      })
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <div className='card card-stretch card-border'>
      <div className='card-header pt-5 d-flex'>
        <div className='d-flex flex-column align-items-center w-100'>
          <div className='d-flex justify-content-between w-100'>
            <a href='/admin-companies' className='btn btn-lg btn-light-primary'>
              <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-4 me-1' />
              Geri
            </a>
            <div className='fs-1 text-center fw-bold'>{adminState?.company?.company?.fullName}</div>
            <div className='w-100px'></div>
          </div>

          <div className='w-75'>
            <CompanyStatusHeader />
          </div>
        </div>
      </div>

      <div className='card-body px-0 py-3'>
        <>
          <div className='card-header card-header-stretch'>
            <div className='card-toolbar'>
              <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                    Bilgiler
                  </a>
                </li>
                <AuthorizedComponent
                  auth={UserTypeAuthorizationsEnum.Admin_Panel_Company_Documents}
                >
                  <li className='nav-item'>
                    <a
                      className={clsx('nav-link', {
                        'opacity-50 disabled':
                          adminState?.company?.company?.stepId ==
                          ApplicationStepEnum.Turkey_WizardFinished,
                      })}
                      data-bs-toggle='tab'
                      href={'#kt_tab_panel_document'}
                    >
                      Dökümanlar
                      {adminState?.company?.company?.stepId ==
                        ApplicationStepEnum.Turkey_WizardFinished && (
                        <i className='fa-solid fa-lock px-2'></i>
                      )}
                    </a>
                  </li>
                </AuthorizedComponent>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                    Ödemeler
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className='card-body'>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                <div className='w-100 '>
                  <CompanyDetailForm load={load} startUp={startUp} />
                </div>
              </div>
              <div className={'tab-pane fade'} id={'kt_tab_panel_document'} role='tabpanel'>
                {adminState?.company && (
                  <DocumentsPage companyId={adminState?.company?.company?.guid ?? ''} />
                )}
              </div>
              <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
                <div className='w-100'>
                  <AdminPaymentsTable load={load} />
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default AdminCompanyDetailPage
