import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ProductAndPaymentInfoDTOApiData} from '../../../api/data-contracts'
import {Api_Product_GetProductAndPaymentInfo} from '../../../apiClient/apiClient'
import {postRequestAsync} from '../../../redux/commonSlice'
import {getProductState, setProductAndPaymentInfo} from '../../../redux/productSlice'

import Translator from '../../common/translate/Translator'
import PaymentsTable from './PaymentsTable'
import ActiveProducts from './ActiveProducts'

function FinanceSummary() {
  const dispatch = useDispatch()
  const productState = useSelector(getProductState)

  useEffect(() => {
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Product_GetProductAndPaymentInfo,
        body: {},
      })
    )
      .unwrap()
      .then(async function (response: ProductAndPaymentInfoDTOApiData) {
        dispatch<any>(setProductAndPaymentInfo(response.data))
      })
  }, [])

  return (
    <div className='w-100'>
      <div className='fw-bold fs-1 text-center py-5'>
        <Translator name='URUNLER' />
      </div>
      <ActiveProducts services={productState.productAndPaymentInfo?.activeServices ?? []} />
      <div className='fw-bold fs-1 text-center py-5'>
        <Translator name='ODEMELER' />
      </div>
      <PaymentsTable
        canPay={true}
        payments={productState.productAndPaymentInfo?.payments ?? []}
        canCancel={false}
      />
    </div>
  )
}

export default FinanceSummary
