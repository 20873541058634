import React from 'react'
import Translator from '../translate/Translator'

function NormalText(props: {text: string}) {
  return (
    <div className='text-center mb-5'>
      <h2 className='text-gray-700 fs-7'>
        <Translator name={props.text} />
      </h2>
    </div>
  )
}

export default NormalText
