import React, {useEffect, useState} from 'react'
import {
  BooleanApiData,
  FinancialAdvisorAssignmentRuleDTO,
  FinancialAdvisorAssignmentStartupDTO,
  FinancialAdvisorAssignmentStartupDTOApiData,
  GetFaarsResponseDTOApiData,
  RemoveByIdRequest,
} from '../../../api/data-contracts'
import {useDispatch} from 'react-redux'
import {getRequestAsync, postRequestAsync} from '../../../redux/commonSlice'
import {
  Api_Admin_GetFaars,
  Api_Admin_GetFaarStartup,
  Api_Admin_RemoveFaar,
  Api_Admin_UpsertFaar,
} from '../../../apiClient/apiClient'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import FormikForm from '../../../components/form/FormikForm'
import Select from 'react-select'
import {DropdownOption} from '../../../utils/utilities'
import _ from 'lodash'
import {resolveTxt} from 'dns'
import {FieldArray, FormikProps} from 'formik'
import CustomDropdown from '../../../components/form/dropdown/CustomDropdown'
import FormInput from '../../../components/form/FormInput'

function FaarPage() {
  const [startUp, setStartUp] = useState<FinancialAdvisorAssignmentStartupDTO | null | undefined>(
    null
  )

  const [faars, setFaars] = useState<FinancialAdvisorAssignmentRuleDTO[] | null | undefined>(null)
  const [explanations, setExplanations] = useState<string[] | null | undefined>(null)

  const dispatch = useDispatch()

  const financialAdvisorOptions =
    _.orderBy(
      startUp?.financialAdvisors?.map((m) => {
        const d: DropdownOption = {
          label: m.description ?? '',
          value: m.id?.toString() ?? '0',
        }
        return d
      }),
      (o) => o.label
    ) ?? []

  const companyTypes =
    _.orderBy(
      startUp?.companyTypes?.map((m) => {
        const d: DropdownOption = {
          label: m.description ?? '',
          value: m.id?.toString() ?? '0',
        }
        return d
      }),
      (o) => o.label
    ) ?? []

  const columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: 'Firma Bilgileri',
      sort: false,
      headerStyle: {width: '100%'},
      headerFormatter: (column: any, colIndex: number) => {
        return (
          <div className='row'>
            <div className='col-xl-4 pt-5'>Mali Müşavir</div>
            <div className='col-xl-4 pt-5'>Şirket Tipi</div>
            <div className='col-xl-2 pt-5'>Yüzdesel Ağırlık</div>
          </div>
        )
      },
      formatter: (
        cell: any,
        row: FinancialAdvisorAssignmentRuleDTO,
        rowIndex: number,
        colIndex: number
      ) => (
        <FormikForm initialValues={row} onSubmit={submit} enableReinitialize={true}>
          {(props) => {
            return (
              <>
                <div className='row w-100'>
                  <div className='col-xl-4'>
                    <div className='d-flex justify-content-start flex-column w-100'>
                      <CustomDropdown
                        fieldAttributes={{
                          name: 'serviceProviderId',
                          placeholder: '',
                          onChange: (event: any) => {},
                        }}
                        noSelectionText='MALI_MUSAVIR_SECINIZ'
                        options={financialAdvisorOptions}
                        noSelectionValue={0}
                        placeHolderOnTop={false}
                        setFieldValue={props.setFieldValue}
                        valueConverter={parseInt}
                      />
                    </div>
                  </div>
                  <div className='col-xl-4'>
                    <div className='d-flex justify-content-start flex-column w-100'>
                      <CustomDropdown
                        fieldAttributes={{
                          name: 'companyTypeId',
                          placeholder: '',
                          onChange: (event: any) => {},
                        }}
                        noSelectionText='SIRKET_TIPI_SECINIZ'
                        options={companyTypes}
                        noSelectionValue={0}
                        placeHolderOnTop={false}
                        setFieldValue={props.setFieldValue}
                        valueConverter={parseInt}
                      />
                    </div>
                  </div>
                  <div className='col-xl-2'>
                    <div className='d-flex justify-content-start flex-column w-100'>
                      <FormInput
                        istouched={props.touched.weight ?? false}
                        errorMessage={props.errors.weight}
                        isTransparent={false}
                        fieldAttributes={{
                          type: 'number',
                          name: 'weight',
                          autoComplete: 'off',
                          placeholder: 'AGIRLIK',
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-xl-2'>
                    <div className='w-100 d-flex justify-content-between'>
                      <div className='w-100 text-center'>
                        <button type='submit' className='btn btn-lg btn-primary w-100 me-5'>
                          <span className='indicator-label'>Kaydet</span>
                        </button>
                      </div>
                      <div className='w-100 text-center'>
                        <a
                          className='btn btn-lg btn-danger w-100 ms-5'
                          onClick={() => removeFaar(row.id ?? 0)}
                        >
                          <span className='indicator-label'>Sil</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }}
        </FormikForm>
      ),
    },
  ]

  async function loadFaars() {
    await dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetFaars,
      })
    )
      .unwrap()
      .then(async function (response: GetFaarsResponseDTOApiData) {
        let faars = response.data?.faars
        faars = _.orderBy(faars, (o) => o.serviceProviderId)
        setFaars(faars)
        setExplanations(response.data?.currentStateExplanations)
      })
  }

  async function loadStartup() {
    await dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetFaarStartup,
      })
    )
      .unwrap()
      .then(async function (response: FinancialAdvisorAssignmentStartupDTOApiData) {
        setStartUp(response.data)
      })

    await loadFaars()
  }

  useEffect(() => {
    loadStartup()
  }, [])

  const submit = async (values: FinancialAdvisorAssignmentRuleDTO) => {
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_UpsertFaar,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        loadFaars()
      })
  }

  async function addNewFaar() {
    const newFaar = {
      id: 0,
      serviceProviderId: 0,
      companyTypeId: 0,
      weight: 0,
    }

    setFaars((prevFaars) => [...(prevFaars ?? []), newFaar])
  }

  async function removeFaar(faarId: number) {
    const request: RemoveByIdRequest = {
      id: faarId,
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_RemoveFaar,
        body: request,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        loadFaars()
      })
  }

  return (
    <>
      {faars && (
        <>
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              totalSize: faars?.length,
              sizePerPage: 50,
              paginationSize: 50,
              paginationTotalRenderer: (from, to, size) => (
                <span className='react-bootstrap-table-pagination-total ms-3'>
                  Toplam {size} kuraldan {from} - {to} arası gösteriliyor
                </span>
              ),
            })}
          >
            {({paginationProps, paginationTableProps}) => (
              <>
                <div className='row'>
                  <div className='col-xl-10 text-center pe-10'>
                    {explanations?.map((m, i) => (
                      <div key={i} className='text-center'>
                        <li className='d-flex align-items-center py-2'>
                          <span className='bullet me-5'></span> {m}
                        </li>
                      </div>
                    ))}
                  </div>
                  <div className='col-xl-2 text-center d-flex align-items-end pe-10'>
                    <a className='btn btn-lg btn-info w-100' onClick={() => addNewFaar()}>
                      <span className='indicator-label'>Yeni Kural</span>
                    </a>
                  </div>
                </div>

                <div className='table-wrapper'>
                  <BootstrapTable
                    {...paginationTableProps}
                    keyField='id'
                    data={faars ?? []}
                    columns={columns}
                    classes='table table-responsive table-row-bordered border-dark table-rounded gy-7 gx-5'
                    headerClasses='table-header fw-bolder fs-5'
                  />
                </div>
              </>
            )}
          </PaginationProvider>
        </>
      )}
    </>
  )
}

export default FaarPage
