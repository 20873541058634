import {useDispatch, useSelector} from 'react-redux'
import {
  AddProductToPaymentRequest,
  BooleanApiData,
  ProductDTO,
  RemoveProductFromPaymentRequest,
} from '../../api/data-contracts'
import {useNavigate} from 'react-router-dom'
import {postRequestAsync} from '../../redux/commonSlice'
import {Api_Payment_AddProduct, Api_Payment_RemoveProduct} from '../../apiClient/apiClient'
import {getProductState, loadPricesAsync, openPaymentPageAsync} from '../../redux/productSlice'
import _ from 'lodash'
import Translator from '../common/translate/Translator'
import DynamicValueTranslator from '../common/translate/DynamicValueTranslator'
import NormalButton from '../form/button/NormalButton'

function Product(props: {
  product: ProductDTO
  shouldCreateNewPayment: boolean
  payInstant: boolean
}) {
  const productState = useSelector(getProductState)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const isInCart = productState.prices?.items?.some((s) => s.productId == props.product.id)

  async function addProduct() {
    const body: AddProductToPaymentRequest = {
      productId: props.product.id,
      shouldCreateNewPayment: props.shouldCreateNewPayment,
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Payment_AddProduct,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        if (props.payInstant) {
          await dispatch<any>(openPaymentPageAsync({navigate: navigate}))
        } else {
          await dispatch<any>(loadPricesAsync())
        }
      })
  }

  async function removeProduct() {
    const body: RemoveProductFromPaymentRequest = {
      productId: props.product.id,
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Payment_RemoveProduct,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        await dispatch<any>(loadPricesAsync())
      })
  }

  async function click() {
    if (props.payInstant) {
      addProduct()
    } else {
      if (isInCart) {
        removeProduct()
      } else {
        addProduct()
      }
    }
  }

  return (
    <div className='col-xl-4 col-xxl-4 col-md-12 col-sm-12 mb-10 h-450px'>
      <div className='card card-xl-stretch shadow mb-xl-10 theme-dark-bg-body h-100'>
        <div className='card-body d-flex flex-column'>
          <div className='d-flex flex-column  h-75px mb-auto'>
            <div className='text-dark fw-bolder fs-4 text-center'>
              <DynamicValueTranslator
                translations={{
                  tr: props.product.title,
                  en: props.product.titleEng,
                }}
              />
            </div>
          </div>
          <div className='pt-5 d-flex align-items-start flex-column h-100'>
            <div className='h-200px'>
              <div>
                <DynamicValueTranslator
                  translations={{
                    tr: props.product.description,
                    en: props.product.descriptionENG,
                  }}
                />
              </div>
              {_.orderBy(props.product?.subProducts, (p) => p.rowIndex)?.map((m) => {
                return (
                  <li className='d-flex align-items-center py-2' key={m.id}>
                    <span className='bullet me-5'></span>
                    <DynamicValueTranslator
                      translations={{
                        tr: props.product.title,
                        en: props.product.titleEng,
                      }}
                    />
                  </li>
                )
              })}
            </div>

            <div className='px-2 card-footer w-100 d-flex justify-content-center'>
              <div className='text-dark text-hover-primary fw-bolder fs-2'>
                {props.product.serviceType == 4 && <Translator name='AYLIK' />}{' '}
                <Translator
                  name='URUN_FIYAT'
                  params={{Price: props.product.priceWithoutTax?.toFixed(0)}}
                />
              </div>
            </div>
            {!props.payInstant && (
              <div className=' d-flex flex-column w-100'>
                {isInCart ? (
                  <NormalButton onClick={click} text={'CIKAR'} className='btn-light-danger' />
                ) : (
                  <NormalButton onClick={click} text={'EKLE'} className='btn-light-success' />
                )}
              </div>
            )}

            {props.payInstant && (
              <div className=' d-flex flex-column w-100'>
                <NormalButton onClick={click} text={'SATIN_AL'} className='btn-light-success' />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
