import {ReactNode} from 'react'

export class WizardStep {
  constructor(
    title: string,
    description: string,
    stepPage: ReactNode,
    isDisabled: boolean = false
  ) {
    this.title = title
    this.description = description
    this.stepPage = stepPage
    this.isDisabled = isDisabled
  }

  title: string
  description: string
  stepPage: ReactNode
  isDisabled: boolean
}
