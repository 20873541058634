import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import Translator from '../common/translate/Translator'
import {useDispatch, useSelector} from 'react-redux'
import {getCommonState, previousStep} from '../../redux/commonSlice'

function WizardStepButtons(props: {
  nextButtonDisabled?: boolean
  nextButtonHidden?: boolean
  customNextButtonOnClik?: () => void
}) {
  const commonState = useSelector(getCommonState)
  const dispatch = useDispatch()
  return (
    <>
      <div className='row'>
        <div className='d-flex flex-stack pt-10 align-items-end mb-10'>
          <div className='mr-2'>
            {commonState.currentStep > 1 && (
              <button
                type='button'
                className='btn btn-lg btn-light-primary me-3'
                data-kt-stepper-action='previous'
                onClick={() => {
                  dispatch(previousStep())
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-4 me-1' />
                <Translator name='GERI' />
              </button>
            )}
          </div>

          <div>
            {!(props.nextButtonHidden ?? false) && (
              <button
                type='submit'
                className='btn btn-lg btn-primary me-3'
                disabled={props.nextButtonDisabled ?? false}
                onClick={() => {
                  props.customNextButtonOnClik && props.customNextButtonOnClik()
                }}
              >
                <span className='indicator-label'>
                  <Translator name='ILERI' />

                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default WizardStepButtons
