import React, {useState} from 'react'
import {toAbsoluteUrl, KTSVG} from '../../../../../_metronic/helpers'
import ForibaProductFormPopup, {ForibaProductFormPopupType} from './ForibaProductFormPopup'
import {EInvoiceProductDTO, EinvoiceCustomerDTO} from '../../../../api/data-contracts'
import ForibaCustomerFormPopup, {ForibaCustomerFormPopupType} from './ForibaCustomerFormPopup'

function ForibaCustomer(props: {customer: EinvoiceCustomerDTO}) {
  const [isFormOpen, setIsFormOpen] = useState(false)

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          {/* <div className='symbol symbol-45px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
          </div> */}
          <div className='d-flex justify-content-start flex-column'>
            <div className='text-dark fw-bold fs-6'>{props.customer.typeDescription}</div>

            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
              /HTML, JS, ReactJS //{' '}
            </span> */}
          </div>
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold d-block fs-6'>
          {' '}
          {props.customer.name} {props.customer.surname}
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold d-block fs-6'>{props.customer.identificationNumber}</div>
      </td>
      <td>
        <div className='text-dark fw-bold d-block fs-6'>{props.customer.phoneNumber}</div>
      </td>
      <td>
        <div className='text-dark fw-bold d-block fs-6'>{props.customer.emailAddress}</div>
      </td>
      <td>
        <div className='text-dark fw-bold d-block fs-6'>
          {props.customer.isEinvoiceUser == true ? (
            <KTSVG
              path='/media/icons/duotune/general/gen043.svg'
              className='svg-icon svg-icon-2hx'
            />
          ) : (
            <></>
          )}
        </div>
      </td>

      <td>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <ForibaCustomerFormPopup
            type={ForibaCustomerFormPopupType.Update}
            customerGuidId={props.customer.guidId}
          ></ForibaCustomerFormPopup>
          <ForibaCustomerFormPopup
            type={ForibaCustomerFormPopupType.Delete}
            customerGuidId={props.customer.guidId}
          ></ForibaCustomerFormPopup>
        </div>
      </td>
    </tr>
  )
}

export default ForibaCustomer
