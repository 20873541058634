import React from 'react'
import Translator from '../common/translate/Translator'

function AsideItem(props: {
  Number: number
  Name: string
  Description: string
  IsCurrent?: boolean
  IsLast?: boolean
  IsDisabled?: boolean
}) {
  return (
    <div
      className={
        'stepper-item ' +
        (props.IsCurrent && ' current ') +
        (props.IsDisabled == true && ' opacity-10 ')
      }
      data-kt-stepper-element='nav'
      key={props.Number}
    >
      {/* begin::Wrapper*/}
      <div className='stepper-wrapper'>
        {/* begin::Icon*/}
        <div className='stepper-icon w-40px h-40px'>
          <i className='stepper-check fas fa-check'></i>
          <span className='stepper-number'>{props.Number}</span>
        </div>
        {/* end::Icon*/}

        {/* begin::Label*/}
        <div className='stepper-label'>
          <h3 className='stepper-title'>
            <Translator name={props.Name} />
          </h3>
          <div className='stepper-desc fw-bold fs-8'>{<Translator name={props.Description} />}</div>
        </div>
        {/* end::Label*/}
      </div>
      {/* end::Wrapper*/}

      {/* begin::Line*/}
      {!(props.IsLast ?? false) && <div className='stepper-line h-30px'></div>}
      {/* end::Line*/}
    </div>
  )
}

export default AsideItem
