import React, {ReactNode, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import Translator from '../translate/Translator'
import {guid} from '@fullcalendar/core/internal'
import {Guid} from 'guid-typescript'
import {defterdar_log} from '../../../utils/utilities'

export type PopupProvides = {
  handleClose: () => void
}

function BasePopup(props: {
  title: string
  modalOpener?: ReactNode
  onClick?: () => boolean
  children: ((props: PopupProvides) => React.ReactNode) | React.ReactNode
  size?: 'sm' | 'lg' | 'xl'
  wrapperClassName?: string
  isDisabled?: boolean
}) {
  const [modalShow, setModalShow] = useState(false)
  const handleModalClose = () => setModalShow(false)
  const handleModalShow = () => setModalShow(true)

  const guid = Guid.create().toString()

  return (
    <>
      <div
        className={props.wrapperClassName}
        onClick={() => {
          if (props.isDisabled) return
          if (props.onClick) {
            const result = props.onClick()

            if (result) {
              handleModalShow()
            }
          } else {
            handleModalShow()
          }
        }}
      >
        {props.modalOpener}
      </div>

      <Modal
        size={props.size}
        show={modalShow}
        onHide={handleModalClose}
        centered
        id={guid}
        contentClassName='border'
      >
        <Modal.Header closeButton>
          <h5 className='modal-title'>
            <Translator name={props.title} />
          </h5>
        </Modal.Header>
        <Modal.Body>
          {typeof props.children === 'function'
            ? props.children({
                handleClose: handleModalClose,
              })
            : React.Children.only(props.children)}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BasePopup
