import React, {useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {auto} from '@popperjs/core'
import ForibaProducts from './ForibaProducts'
import {useDispatch, useSelector} from 'react-redux'
import {getRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {Api_EInvoice_GetCommonData} from '../../../../apiClient/apiClient'
import {CommonDataDTOApiData} from '../../../../api/data-contracts'
import {
  getEInvoiceState,
  loadEInvoiceCommonDataAsync,
  setEInvoiceCommonData,
} from '../../../../redux/eInvoiceSlice'
import ForibaCustomer from './ForibaCustomer'
import ForibaCustomers from './ForibaCustomers'
import ForibaInvoice from './ForibaInvoice'
import ForibaParameters from './ForibaParameters'
import ForibaInvoices from './ForibaInvoices'

function ForibaPage() {
  const dispatch = useDispatch()
  const eInvoiceState = useSelector(getEInvoiceState)

  useEffect(() => {
    dispatch<any>(loadEInvoiceCommonDataAsync())
  }, [])

  return (
    <div className='d-flex w-100'>
      <div className='rounded w-100'>
        <div className='fw-bold fs-1 text-center py-5'>E-Fatura</div>

        <div className='card card-border w-100 d-flex flex-row-fluid flex-center'>
          <div className='w-lg-75 w-xl-75 wxxl-75 w-xs-100 w-sm-100 w-md-100'>
            <div className='card-header card-header-stretch'>
              <div className='card-toolbar'>
                <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
                  <li className='nav-item'>
                    <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_10'>
                      Foriba Bilgileri
                    </a>
                  </li>

                  <li className='nav-item'>
                    <a
                      className={
                        'nav-link ' +
                        (eInvoiceState.eInvoiceCommonData?.isParametersFilled
                          ? ''
                          : 'disabled opacity-50')
                      }
                      data-bs-toggle='tab'
                      href='#kt_tab_pane_9'
                    >
                      Fatura Kes
                    </a>
                  </li>

                  <li className='nav-item'>
                    <a
                      className={
                        'nav-link ' +
                        (eInvoiceState.eInvoiceCommonData?.isParametersFilled
                          ? ''
                          : 'disabled opacity-50')
                      }
                      data-bs-toggle='tab'
                      href='#kt_tab_pane_7'
                    >
                      Müşteriler
                    </a>
                  </li>

                  <li className='nav-item'>
                    <a
                      className={
                        'nav-link ' +
                        (eInvoiceState.eInvoiceCommonData?.isParametersFilled
                          ? ''
                          : 'disabled opacity-50')
                      }
                      data-bs-toggle='tab'
                      href='#kt_tab_pane_8'
                    >
                      Ürünler
                    </a>
                  </li>

                  <li className='nav-item'>
                    <a
                      className={
                        'nav-link ' +
                        (eInvoiceState.eInvoiceCommonData?.isParametersFilled
                          ? ''
                          : 'disabled opacity-50')
                      }
                      data-bs-toggle='tab'
                      href='#kt_tab_pane_6'
                    >
                      Faturalar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='card-body'>
              <div className='tab-content' id='myTabContent'>
                <div className='tab-pane fade show active' id='kt_tab_pane_10' role='tabpanel'>
                  {eInvoiceState.eInvoiceCommonData && <ForibaParameters />}
                </div>

                {eInvoiceState.eInvoiceCommonData?.isParametersFilled && (
                  <>
                    <div className='tab-pane fade' id='kt_tab_pane_9' role='tabpanel'>
                      <ForibaInvoice />
                    </div>

                    <div className={'tab-pane fade '} id='kt_tab_pane_7' role='tabpanel'>
                      <ForibaCustomers />
                    </div>

                    <div className={'tab-pane fade '} id='kt_tab_pane_8' role='tabpanel'>
                      <ForibaProducts />
                    </div>
                    <div className={'tab-pane fade '} id='kt_tab_pane_6' role='tabpanel'>
                      <ForibaInvoices />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForibaPage
