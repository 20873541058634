import {useSelector, useDispatch} from 'react-redux'
import FormikForm from '../../../../../components/form/FormikForm'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'
import {nextStep} from '../../../../../redux/commonSlice'
import {getCompanyState, setClemtaWizardDataAsync} from '../../../../../redux/companySlice'
import {CompanyTypeEnum} from '../../../../../apiClient/enums'
import FormInput from '../../../../../components/form/FormInput'
import NormalText from '../../../../../components/common/title/NormalText'
import WarningMessage from '../../../../../components/common/title/WarningMessage'
import CustomDropdown, {
  DropdownOption,
} from '../../../../../components/form/dropdown/CustomDropdown'
import {getProductState} from '../../../../../redux/productSlice'
import NormalTitle from '../../../../../components/common/title/NormalTitle'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  name: Yup.string()
    .required('CLEMTA_NAME_REQUIRED')
    .matches(/^[a-zA-Z ]*$/, 'CLEMTA_NAME_REQUIRED')
    .nullable(),
  entityEnding: Yup.string().required('CLEMTA_ENTITIY_ENDING_REQUIRED').nullable(),
  operationFieldId: Yup.number()
    .positive('CLEMTA_OPERATION_FIELD_REQUIRED')
    .required('CLEMTA_OPERATION_FIELD_REQUIRED')
    .nullable(),
  operationFieldDescription: Yup.string().when(['operationFieldId'], {
    is: (operationFieldId: number) => {
      return operationFieldId == 2283
    },
    then: Yup.string().required('CLEMTA_OPERATION_FIELD_REQUIRED').nullable(),
  }),
})

function ClemtaCompanyInfoStep() {
  const companyState = useSelector(getCompanyState)
  const productState = useSelector(getProductState)
  const dispatch = useDispatch()

  const initValues = {
    name: companyState?.data?.company?.shortName ?? '',
    entityEnding: companyState.clemtaWizardData?.entityEnding ?? '',
    operationFieldId: companyState?.data?.company?.operationFieldId ?? 0,
    operationFieldDescription: companyState?.data?.company?.operationFieldDescription ?? '',
  }

  const submit = async (values: {
    name: string
    entityEnding: string
    operationFieldId: number
    operationFieldDescription: string
  }) => {
    await dispatch<any>(
      setClemtaWizardDataAsync({
        ...companyState.clemtaWizardData,
        name: values.name,
        entityEnding: values.entityEnding,
        operationFieldId: values.operationFieldId,
        operationFieldDescription: values.operationFieldDescription,
      })
    )

    window.location.reload()
  }

  return (
    <FormikForm initialValues={initValues} onSubmit={submit} validationSchema={schema}>
      {(props) => {
        return (
          <>
            <NormalTitle text='CLEMTA_CHOOSE_NAME_AND_INDUSTRY' />

            <div className='row'>
              <div className='offset-xl-2 col-xl-8'>
                <NormalText text='CLEMTA_CHOOSE_NAME_AND_INDUSTRY_DETAIL' />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormInput
                  istouched={props.touched.name ?? false}
                  errorMessage={props.errors.name}
                  placeHolderOnTop={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'name',
                    autoComplete: 'off',
                    placeholder: 'CLEMTA_COMPANY_NAME',
                  }}
                />
              </div>
            </div>

            <div className='row mt-3 mb-5'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <WarningMessage message={'CLEMTA_COMPANY_NAME_WARNING'} />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                {companyState.clemtaWizardData?.typeId ==
                  CompanyTypeEnum.LLC__Limited_Liability_Company_ && (
                  <CustomDropdown
                    fieldAttributes={{
                      name: 'entityEnding',
                      placeholder: 'CLEMTA_ENTITY_ENDING',
                      onChange: (event: any) => {
                        //props.setFieldValue('virtualOfficeDistrictId', 0)
                      },
                    }}
                    noSelectionText=''
                    options={[
                      new DropdownOption('LLC', 'LLC'),
                      new DropdownOption('Limited Liability Company', 'Limited Liability Company'),
                      new DropdownOption('L.L.C.', 'L.L.C.'),
                    ]}
                    noSelectionValue={undefined}
                    placeHolderOnTop={true}
                    setFieldValue={props.setFieldValue}
                  />
                )}
                {companyState.clemtaWizardData?.typeId ==
                  CompanyTypeEnum.C_Corporation__General_Corporation_ && (
                  <CustomDropdown
                    fieldAttributes={{
                      name: 'entityEnding',
                      placeholder: 'CLEMTA_ENTITY_ENDING',
                      onChange: (event: any) => {
                        //props.setFieldValue('virtualOfficeDistrictId', 0)
                      },
                    }}
                    noSelectionText=''
                    options={[
                      new DropdownOption('Inc.', 'Inc.'),
                      new DropdownOption('Incorporated', 'Incorporated'),
                      new DropdownOption('Co.', 'Co.'),
                      new DropdownOption('Corp', 'Corp'),
                      new DropdownOption('Corporation', 'Corporation'),
                    ]}
                    noSelectionValue={0}
                    placeHolderOnTop={true}
                    setFieldValue={props.setFieldValue}
                  />
                )}
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                {companyState.clemtaWizardData?.typeId ==
                  CompanyTypeEnum.LLC__Limited_Liability_Company_ && (
                  <WarningMessage message={'CLEMTA_ENTITY_ENDING_LLC_WARNING'} />
                )}
                {companyState.clemtaWizardData?.typeId ==
                  CompanyTypeEnum.C_Corporation__General_Corporation_ && (
                  <WarningMessage message={'CLEMTA_ENTITY_ENDING_INC_WARNING'} />
                )}
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <CustomDropdown
                  fieldAttributes={{
                    name: 'operationFieldId',
                    placeholder: 'CLEMTA_OPERATION_FIELD',
                    onChange: (event: any) => {
                      //props.setFieldValue('virtualOfficeDistrictId', 0)
                    },
                  }}
                  noSelectionText='CLEMTA_OPERATION_FIELD_SELECT'
                  options={
                    productState.clemtaWizardData?.operationFields?.map(
                      (m) => new DropdownOption(m.fieldDetailId, m.feildDetailName ?? '')
                    ) ?? []
                  }
                  noSelectionValue={0}
                  placeHolderOnTop={true}
                  setFieldValue={props.setFieldValue}
                  valueConverter={parseInt}
                />
              </div>
            </div>

            {props.values.operationFieldId == 2283 && (
              <div className='row mt-3'>
                <div className='offset-xl-2 col-xl-8 col-12'>
                  <FormInput
                    fieldAttributes={{
                      type: 'text',
                      name: 'operationFieldDescription',
                      placeholder: 'CLEMTA_OPERATION_FIELD_DETAIL',
                    }}
                    istouched={props.touched.operationFieldDescription ?? false}
                    errorMessage={props.errors.operationFieldDescription}
                    placeHolderOnTop={true}
                    checkValid={false}
                  />
                </div>
              </div>
            )}

            <div className='row'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <WizardStepButtons nextButtonDisabled={!props.isValid} />
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default ClemtaCompanyInfoStep
