import FormikForm from '../../../../../../components/form/FormikForm'
import {getCompanyState, setClemtaWizardDataAsync} from '../../../../../../redux/companySlice'
import {useDispatch, useSelector} from 'react-redux'
import FormInput from '../../../../../../components/form/FormInput'
import CustomDropdown, {
  DropdownOption,
} from '../../../../../../components/form/dropdown/CustomDropdown'
import FormPriceInput from '../../../../../../components/form/FormPriceInput'
import PhoneNumberInput from '../../../../../../components/form/PhoneNumberInput'
import NormalTitle from '../../../../../../components/common/title/NormalTitle'
import SubmitButton from '../../../../../../components/form/button/SubmitButton'
import {getProductState} from '../../../../../../redux/productSlice'
import InfoMessage from '../../../../../../components/common/title/InfoMessage'
import {ClemtaShareHolder} from '../../../../../../api/data-contracts'

function ClemtaShareHolderForm(props: {initValues: ClemtaShareHolder; modalClose: () => void}) {
  const dispatch = useDispatch()
  const companyState = useSelector(getCompanyState)
  const productState = useSelector(getProductState)

  // Extracted aria-label values
  const ariaLabels = [
    'Sole Owner',
    'CEO (Chief Executive Officer)',
    'COO (Chief Operating Officer)',
    'CFO (Chief Financial Officer)',
    'CTO (Chief Technology Officer)',
    'CMO (Chief Marketing Officer)',
    'CIO (Chief Information Officer)',
    'HR Director (Human Resources Director)',
    'Sales Director',
    'Product Manager',
    'Project Manager',
    'Software Engineer',
    'Data Analyst',
    'UX Designer (User Experience Designer)',
    'UI Designer (User Interface Designer)',
    'Operations Manager',
    'Other',
  ]

  const positions = ariaLabels.map((label, index) => new DropdownOption(index + 1, label))

  const submit = async (values: ClemtaShareHolder) => {
    let shareholders = companyState.clemtaWizardData?.shareholders

    if (!shareholders) {
      shareholders = []
    }

    if ((values.index ?? 0) > 0) {
      shareholders = shareholders?.filter((f) => f.index != values.index)
    }

    shareholders = [
      ...shareholders,
      {
        ...values,
        index: shareholders.length + 1,
      },
    ]

    await dispatch<any>(
      setClemtaWizardDataAsync({
        ...companyState.clemtaWizardData,
        shareholders: shareholders,
      })
    )

    props.modalClose()
  }

  return (
    <>
      <FormikForm initialValues={props.initValues} onSubmit={submit}>
        {(props) => {
          return (
            <>
              <div className='row'>
                <div className='offset-xl-2 col-xl-8 col-12'>
                  <div className='mt-3'>
                    <FormInput
                      istouched={props.touched.firstName ?? false}
                      errorMessage={props.errors.firstName}
                      placeHolderOnTop={true}
                      fieldAttributes={{
                        type: 'text',
                        name: 'firstName',
                        autoComplete: 'off',
                        placeholder: 'CLEMTA_FIRSTNAME',
                      }}
                    />
                  </div>

                  <div className='mt-3'>
                    <FormInput
                      istouched={props.touched.lastName ?? false}
                      errorMessage={props.errors.lastName}
                      placeHolderOnTop={true}
                      fieldAttributes={{
                        type: 'text',
                        name: 'lastName',
                        autoComplete: 'off',
                        placeholder: 'CLEMTA_LASTNAME',
                      }}
                    />
                  </div>

                  <div className='mt-3'>
                    <FormPriceInput
                      currency={'Perc'}
                      istouched={props.touched.percentage ?? false}
                      errorMessage={props.errors.percentage}
                      placeHolderOnTop={true}
                      setFieldValue={props.setFieldValue}
                      value={props.values.percentage ?? 0}
                      fieldAttributes={{
                        name: 'percentage',
                        autoComplete: 'off',
                        placeholder: 'CLEMTA_PERCENTAGE',
                      }}
                    />
                    {(props.values.percentage ?? 0) > 0 && (
                      <InfoMessage
                        message={'CLEMTA_PERCENTAGE_INFO'}
                        params={{
                          Percentage: props.values.percentage,
                          Shares:
                            ((companyState.clemtaWizardData?.numberOfShares ?? 0) *
                              (props.values.percentage ?? 0)) /
                            100,
                        }}
                      />
                    )}
                  </div>

                  <div className='mt-3'>
                    <CustomDropdown
                      fieldAttributes={{
                        name: 'positionSelection',
                        placeholder: 'CLEMTA_POSITION',
                        onChange: (event: any) => {
                          props.setFieldValue(
                            'position',
                            event.target.value == '17'
                              ? ''
                              : positions.find((f) => f.value == parseInt(event.target.value))
                                  ?.label
                          )
                        },
                      }}
                      noSelectionText=''
                      options={positions}
                      noSelectionValue={0}
                      placeHolderOnTop={true}
                      setFieldValue={props.setFieldValue}
                      valueConverter={parseInt}
                    />
                  </div>

                  {props.values.positionSelection == 17 && (
                    <div className='mt-3'>
                      <FormInput
                        fieldAttributes={{
                          type: 'text',
                          name: 'position',
                          placeholder: 'CLEMTA_OTHER_POSITION',
                        }}
                        istouched={props.touched.position ?? false}
                        errorMessage={props.errors.position}
                        placeHolderOnTop={true}
                        checkValid={false}
                      />
                    </div>
                  )}

                  <div className='mt-3'>
                    <FormInput
                      errorMessage={props.errors.email}
                      istouched={props.touched.email ?? false}
                      placeHolderOnTop={true}
                      fieldAttributes={{
                        type: 'email',
                        name: 'email',
                        autoComplete: 'off',
                        placeholder: 'EMAIL',
                      }}
                    />
                  </div>

                  <div className='mt-3'>
                    <PhoneNumberInput
                      istouched={props.touched.phoneNumber ?? false}
                      errorMessage={props.errors.phoneNumber}
                      placeHolderOnTop={true}
                      setFieldValue={props.setFieldValue}
                      fieldAttributes={{
                        type: 'text',
                        name: 'phoneNumber',
                        autoComplete: 'off',
                        placeholder: 'CLEMTA_PHONE_NUMBER',
                      }}
                    />
                  </div>

                  <div className='mt-3'>
                    <FormInput
                      istouched={props.touched.ssn ?? false}
                      errorMessage={props.errors.ssn}
                      placeHolderOnTop={true}
                      fieldAttributes={{
                        type: 'text',
                        name: 'ssn',
                        autoComplete: 'off',
                        placeholder: 'CLEMTA_SSN_IF_AVAILABLE',
                      }}
                    />
                  </div>

                  <div className='mt-10'>
                    <NormalTitle text='CLEMTA_CONTACT_INFO' />
                  </div>

                  <div className='mt-3'>
                    <CustomDropdown
                      fieldAttributes={{
                        name: 'countryId',
                        placeholder: 'ULKE',
                        onChange: (event: any) => {
                          props.setFieldValue(
                            'country',
                            productState.clemtaWizardData?.countries?.find(
                              (f) => f.countryId == parseInt(event.target.value)
                            )?.name
                          )
                        },
                      }}
                      noSelectionText='ULKE_SECINIZ'
                      options={
                        productState.clemtaWizardData?.countries?.map(
                          (m) => new DropdownOption(m.countryId, m.name ?? '')
                        ) ?? []
                      }
                      noSelectionValue={0}
                      placeHolderOnTop={true}
                      setFieldValue={props.setFieldValue}
                      valueConverter={parseInt}
                    />
                  </div>

                  <div className='mt-3'>
                    <FormInput
                      istouched={props.touched.addressLineFirst ?? false}
                      errorMessage={props.errors.addressLineFirst}
                      placeHolderOnTop={true}
                      fieldAttributes={{
                        type: 'text',
                        name: 'addressLineFirst',
                        autoComplete: 'off',
                        placeholder: 'CLEMTA_ADDRESS_1',
                      }}
                    />
                  </div>

                  <div className='mt-3'>
                    <FormInput
                      istouched={props.touched.addressLineSecond ?? false}
                      errorMessage={props.errors.addressLineSecond}
                      placeHolderOnTop={true}
                      fieldAttributes={{
                        type: 'text',
                        name: 'addressLineSecond',
                        autoComplete: 'off',
                        placeholder: 'CLEMTA_ADDRESS_2',
                      }}
                    />
                  </div>

                  <div className='mt-3'>
                    <FormInput
                      istouched={props.touched.firstName ?? false}
                      errorMessage={props.errors.firstName}
                      placeHolderOnTop={true}
                      fieldAttributes={{
                        type: 'text',
                        name: 'postalCode',
                        autoComplete: 'off',
                        placeholder: 'CLEMTA_POSTAL_CODE',
                      }}
                    />
                  </div>

                  <div className='mt-3'>
                    <FormInput
                      istouched={props.touched.cityDistrict ?? false}
                      errorMessage={props.errors.cityDistrict}
                      placeHolderOnTop={true}
                      fieldAttributes={{
                        type: 'text',
                        name: 'cityDistrict',
                        autoComplete: 'off',
                        placeholder: 'CLEMTA_CITY_DISTRICT',
                      }}
                    />
                  </div>

                  <div className='mt-3'>
                    <FormInput
                      istouched={props.touched.stateProvince ?? false}
                      errorMessage={props.errors.stateProvince}
                      placeHolderOnTop={true}
                      fieldAttributes={{
                        type: 'text',
                        name: 'stateProvince',
                        autoComplete: 'off',
                        placeholder: 'CLEMTA_STATE_PROVINCE',
                      }}
                    />
                  </div>

                  <div className='text-center mt-5'>
                    <SubmitButton
                      className='w-100 mb-5'
                      isSubmitting={props.isSubmitting}
                      isValid={props.isValid}
                      loadingText='LUTFEN_BEKLEYIN'
                      notLoadingText='KAYDET'
                    />
                  </div>
                </div>
              </div>
            </>
          )
        }}
      </FormikForm>
    </>
  )
}

export default ClemtaShareHolderForm
