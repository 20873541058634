import React from 'react'
import Translator from '../translate/Translator'

function TinyTitle(props: {text: string}) {
  return (
    <div className='text-center mb-1'>
      <div className='text-dark fw-bolder mb-3 fs-5'>
        <Translator name={props.text} />
      </div>
    </div>
  )
}

export default TinyTitle
