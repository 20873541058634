import {FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {getCompanyState} from '../redux/companySlice'
import {useSelector} from 'react-redux'
import ParasutPage from '../pages/customer/turkey/parasut/ParasutPage'
import NotificationTest from '../components/other/NotificationTest'
import ForibaPage from '../pages/customer/turkey/foriba/ForibaPage'
import PaymentResultPage from '../pages/customer/PaymentResultPage'
import DocumentsPageNew from '../pages/document/DocumentsPage'
import ProfilePage from '../pages/customer/profile/ProfilePage'
import PreWizardPage from '../pages/wizard/PreWizardPage'
import ExtrasPage from '../pages/customer/turkey/ExtrasPage'
import FinancePage from '../pages/customer/finance/FinancePage'
import CartPaymentPage from '../pages/purchase/CartPaymentPage'
import WizardPage from '../pages/wizard/WizardPage'
import HomePage from '../pages/customer/home/HomePage'
import SubscriptionCancelledPage from '../pages/customer/SubscriptionCancelledPage'
import TurkeyWizardPage from '../pages/wizard/customWizards/turkey/TurkeyWizardPage'

const CustomerRoutes = () => {
  const companyState = useSelector(getCompanyState)

  const noDepthRoute = (
    <>
      <Route
        path='extra'
        element={
          <SuspensedView>
            <ExtrasPage />
          </SuspensedView>
        }
      />
      <Route
        path='finance'
        element={
          <SuspensedView>
            {process.env.REACT_APP_ENV?.includes('DEV') ||
            process.env.REACT_APP_ENV?.includes('TEST') ? (
              <ForibaPage />
            ) : (
              <ParasutPage />
            )}
          </SuspensedView>
        }
      />
      <Route
        path='documents'
        element={
          <SuspensedView>
            <DocumentsPageNew companyId={companyState.data?.company?.guid ?? ''} />
          </SuspensedView>
        }
      />
      <Route
        path='services'
        element={
          <SuspensedView>
            <FinancePage />
          </SuspensedView>
        }
      />
      <Route
        path='notification'
        element={
          <SuspensedView>
            <NotificationTest />
          </SuspensedView>
        }
      />

      <Route
        path='home'
        element={
          <SuspensedView>
            <HomePage />
          </SuspensedView>
        }
      />
      {(process.env.REACT_APP_ENV?.includes('DEV') ||
        process.env.REACT_APP_ENV?.includes('TEST')) && (
        <Route path='*' element={<Navigate to='/home' />} />
      )}

      {process.env.REACT_APP_ENV?.includes('PROD') && (
        <Route path='*' element={<Navigate to='/finance' />} />
      )}
    </>
  )

  const deptRoute = (
    <>
      <Route
        path='services'
        element={
          <SuspensedView>
            <FinancePage />
          </SuspensedView>
        }
      />
      <Route path='*' element={<Navigate to='/services' />} />
    </>
  )

  const wizardDidntFinishRoute = (
    <>
      <Route
        path='wizard'
        element={
          <SuspensedView>
            {process.env.REACT_APP_ENV?.includes('PROD') && <TurkeyWizardPage />}
            {!process.env.REACT_APP_ENV?.includes('PROD') && <PreWizardPage cantPass={false} />}
          </SuspensedView>
        }
      />
      <Route
        path='wizard-country'
        element={
          <SuspensedView>
            <WizardPage />
            {/* <PreWizardPage cantPass={true} /> */}
          </SuspensedView>
        }
      />
      <Route path='*' element={<Navigate to='/wizard' />} />
    </>
  )

  return (
    <Routes>
      <Route path='auth/*' element={<Navigate to='/finance' />} />
      <Route
        path='purchase'
        element={
          <SuspensedView>
            <CartPaymentPage />
          </SuspensedView>
        }
      />
      <Route path='paymentResult' element={<PaymentResultPage />} />
      <Route path='subscription-cancelled' element={<SubscriptionCancelledPage />} />
      <Route path='profile' element={<ProfilePage />} />

      <>
        {!(companyState?.data?.company?.isWizardFinished ?? false)
          ? wizardDidntFinishRoute
          : (companyState?.data?.company?.totalDept ?? 0) > 0
          ? deptRoute
          : noDepthRoute}
      </>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {CustomerRoutes}
