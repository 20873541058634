import {SidebarMenuItem} from '../SidebarMenuItem'
import {useSelector} from 'react-redux'
import {getCompanyState} from '../../../../redux/companySlice'

const SidebarCustomerMenu = () => {
  const companyState = useSelector(getCompanyState)

  return (
    <>
      {companyState.data?.company?.isWizardFinished == false && (
        <SidebarMenuItem
          to='/wizard'
          title='KURULUM_VE_PAKET'
          icon='/media/icons/duotune/general/gen022.svg'
        />
      )}

      {companyState.data?.company?.isWizardFinished == true && (
        <>
          {process.env.REACT_APP_ENV != 'PROD' && (
            <SidebarMenuItem
              to='/home'
              title='ANASAYFA'
              icon='/media/icons/duotune/abstract/abs027.svg'
            />
          )}

          <SidebarMenuItem
            to='/extra'
            title='EK_COZUMLER'
            icon='/media/icons/duotune/abstract/abs027.svg'
          />

          <SidebarMenuItem
            to='/finance'
            title='ON_MUHASEBE'
            icon='/media/icons/duotune/finance/fin007.svg'
          />
          <SidebarMenuItem
            to='/services'
            title='ABONELIK_VE_ODEME'
            icon='/media/icons/duotune/finance/fin008.svg'
          />

          {companyState.data?.company?.isActive && (
            <SidebarMenuItem
              to='/documents'
              title='DOSYALAR'
              icon='/media/icons/duotune/files/fil016.svg'
            />
          )}

          {process.env.REACT_APP_ENV != 'PROD' && (
            <SidebarMenuItem
              to='/notification'
              title='NOTIFICATION_TEST'
              icon='/media/icons/duotune/general/gen022.svg'
            />
          )}
        </>
      )}

      <SidebarMenuItem
        to='/profile'
        title='PROFIL_AYARLARI'
        icon='/media/icons/duotune/coding/cod001.svg'
      />
    </>
  )
}

export {SidebarCustomerMenu}
