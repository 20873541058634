import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  AdminPanelCompanyDetailDTOApiData,
  BooleanApiData,
  GetAdminCompanyRequest,
  SetCompanyStepRequest,
} from '../../../api/data-contracts'
import {Api_Admin_GetCompany, Api_Company_SetCompanyStep} from '../../../apiClient/apiClient'
import {getCommonState, postRequestAsync, setIsLoading} from '../../../redux/commonSlice'
import clsx from 'clsx'
import {Button, Modal} from 'react-bootstrap'
import {Guid} from 'guid-typescript'
import {getUserState} from '../../../redux/userSlice'
import {getCompanyState} from '../../../redux/companySlice'
import {getAdminState, setAdminSelectedCompany} from '../../../redux/adminSlice'
import {useParams} from 'react-router-dom'

function CompanyStatusComponent(props: {
  iconName: string
  iconMessage: string
  iconColoring: string
  targerStepId?: number
  visibleSteps?: number[]
  enabledSteps?: number[]
  stepId?: number
  isDisabled?: boolean
}) {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)
  const commonState = useSelector(getCommonState)
  const userState = useSelector(getUserState)
  const companyState = useSelector(getCompanyState)
  const {id} = useParams()

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    if ((props.targerStepId ?? 0) > 0 && isEnabled) {
      setShow(true)
    }
  }
  const loadCompanyData = () => {
    const body: GetAdminCompanyRequest = {
      companyGuidId: id,
    }
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetCompany,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelCompanyDetailDTOApiData) {
        dispatch(setAdminSelectedCompany(response.data))
      })
  }

  function changeStep() {
    if ((props.targerStepId ?? 0) > 0 && isEnabled) {
      const body: SetCompanyStepRequest = {
        companyId: adminState.company?.company?.guid ?? '',
        stepId: props.targerStepId,
      }
      dispatch(setIsLoading(true))
      dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_Company_SetCompanyStep,
          body: body,
        })
      )
        .unwrap()
        .then(async (response: BooleanApiData) => {
          await loadCompanyData()
          dispatch(setIsLoading(false))
          handleClose()
        })
    }
  }

  const stepId = adminState.company?.company?.stepId ?? 0

  const isEnabled =
    (props.enabledSteps?.includes(stepId) ?? true) &&
    adminState.company?.company?.isActive === true &&
    !props.isDisabled

  if (
    ((props.visibleSteps?.length ?? 0) > 0 && (props.visibleSteps?.includes(stepId) ?? false)) ||
    (props.visibleSteps?.length ?? 0) == 0
  ) {
    return (
      <div className={'d-flex flex-column ' + (isEnabled ? '' : 'opacity-25')}>
        {
          <Modal show={show} onHide={handleClose} centered id={'kt_modal_' + Guid.create()}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <p>Firmanın durumunu değiştirmek istediğinizden emin misiniz?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                onClick={handleClose}
              >
                İptal
              </Button>
              <Button
                type='button'
                data-kt-indicator={commonState?.isLoading ? 'on' : ''}
                onClick={changeStep}
                className='btn btn-primary'
              >
                <span className='indicator-label'>Kaydet</span>

                <span className='indicator-progress'>
                  Kaydediliyor...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </Button>
            </Modal.Footer>
          </Modal>
        }

        <div className='d-flex align-items-center mb-2' onClick={handleShow}>
          <div
            className={clsx(
              'btn btn-link mb-2 ',
              props.stepId == adminState.company?.company?.stepId || isEnabled
                ? props.iconColoring
                : '',
              (props?.targerStepId ?? 0) > 0 ? 'mt-5' : ''
            )}
          >
            <i className={props.iconName + ' me-2'}></i>
            <div className='fs-6'>{props.iconMessage}</div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default CompanyStatusComponent
