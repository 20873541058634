import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import clsx from 'clsx'
import {I18N_CONFIG_KEY} from '../../utils/utilities'
import Translator from '../common/translate/Translator'

function LanguageSelector() {
  const languages = [
    {
      lang: 'en',
      name: 'INGILIZCE',
      flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
      lang: 'tr',
      name: 'TURKCE',
      flag: toAbsoluteUrl('/media/flags/turkey.svg'),
    },
  ]

  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  function setLanguage(lang: string) {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
    window.location.reload()
  }

  return (
    <div className='d-flex justify-content-center'>
      {languages.map((l) => (
        <div
          className={clsx('btn me-3', {
            'btn-secondary': l.lang === currentLanguage?.lang,
            'btn-light': l.lang !== currentLanguage?.lang,
          })}
          key={l.lang}
          onClick={() => {
            setLanguage(l.lang)
          }}
        >
          <span className='symbol symbol-20px me-4'>
            <img className='rounded-1' src={l.flag} alt='metronic' />
          </span>
          {<Translator name={l.name} />}
        </div>
      ))}
    </div>
  )
}

export default LanguageSelector
