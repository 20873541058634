import {Field} from 'formik'
import React, {ReactNode} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import RadioButtonWrapper from './RadioButtonWrapper'
import TranslatorText from '../../common/translate/TranslatorText'
import Translator from '../../common/translate/Translator'

function BigRadioButton(props: {
  description: string
  details: string
  value: any
  icon: string
  name: string
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
  valueConverter?: (value: string) => void
  detailHtml?: ReactNode
}) {
  return (
    <>
      <RadioButtonWrapper
        fieldAttributes={{
          className: 'btn-check',
          type: 'radio',
          name: props.name,
          value: props.value,
          id: props.name + '_' + props.value,
        }}
        setFieldValue={props.setFieldValue}
        valueConverter={props.valueConverter}
      />
      <label
        className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center h-125px'
        htmlFor={props.name + '_' + props.value}
      >
        <KTSVG path={props.icon} className='svg-icon-3x me-5' />

        <span className='d-block fw-bold text-start'>
          <span className='text-dark fw-bolder d-block fs-4 mb-2'>
            <Translator name={props.description ?? ''} />
          </span>
          {props.detailHtml ? (
            props.detailHtml
          ) : (
            <span className='text-gray-400 fw-bold fs-7'>
              <Translator name={props.details ?? ''} />
            </span>
          )}
        </span>
      </label>
    </>
  )
}

export default BigRadioButton
