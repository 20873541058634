import {useDispatch, useSelector} from 'react-redux'
import {getAdminState, setReport} from '../../../redux/adminSlice'
import _ from 'lodash'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import {ReportEnum} from '../../../types/ReportEnum'
import AdminSaleReport from './components/AdminSaleReport'
import CommissionReport from './components/CommissionReport'
import ReportButton from '../../../components/layout/sidebar/admin/components/ReportButton'
import {SaleReportDataListApiData} from '../../../api/data-contracts'
import {Api_Admin_GetSaleReport} from '../../../apiClient/apiClient'
import {postRequestAsync} from '../../../redux/commonSlice'
import {CanIDoThat} from '../../../utils/CanIDoThat'
import {UserTypeAuthorizationsEnum} from '../../../apiClient/enums'
import AuthorizedComponent from '../../../components/common/auth/AuthorizedComponent'
import PaymentReport from './components/PaymentReportComponent'

export interface AdminCompaniesFilter {
  textFilter: string
  companyType: number
}

function AdminReportsPage() {
  const adminState = useSelector(getAdminState)

  return (
    <div className={'w-100'}>
      <div className='card card-border w-100 d-flex flex-row-fluid pt-5'>
        <div className='card-header card-header-stretch'>
          <div className='d-flex flex-row'>
            <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Report_Sale}>
              <ReportButton report={ReportEnum.SaleReport} name='Satış Raporu' />
            </AuthorizedComponent>
            <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Report_Commission}>
              <ReportButton report={ReportEnum.ReferenceCommissionReport} name='Komisyon Raporu' />
            </AuthorizedComponent>
            <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Report_Payment}>
              <ReportButton report={ReportEnum.PaymentReport} name='Ödeme Raporu' />
            </AuthorizedComponent>
          </div>
        </div>

        <div className='card-body pt-5 fs-6 w-100 pt-1'>
          {adminState.reports?.reportEnum === ReportEnum.SaleReport && <AdminSaleReport />}
          {adminState.reports?.reportEnum === ReportEnum.ReferenceCommissionReport && (
            <CommissionReport />
          )}
          {adminState.reports?.reportEnum === ReportEnum.PaymentReport && <PaymentReport />}
        </div>
      </div>
    </div>
  )
}

export default AdminReportsPage
