import React, {useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import ForibaProduct from './ForibaProduct'
import {useDispatch, useSelector} from 'react-redux'
import {getEInvoiceState} from '../../../../redux/eInvoiceSlice'
import ForibaProductFormPopup, {ForibaProductFormPopupType} from './ForibaProductFormPopup'
import * as Yup from 'yup'
import {Formik, Field, ErrorMessage, FormikValues, FieldArray, Form} from 'formik'
import {
  CreateInvoiceRequest,
  EInvoiceProductDTO,
  InvoiceProduct,
  StringApiData,
} from '../../../../api/data-contracts'
import AsyncSelect from 'react-select/async'
import CurrencyInput from 'react-currency-input-field'
import {right} from '@popperjs/core'
import ForibaInvoiceLinePriceDetail from './ForibaInvoiceLinePriceDetail'
import ForibaInvoiceLinePrices from './ForibaInvoiceLinePrices'
import {getCommonState, postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import ForibaInvoicePrices from './ForibaInvoicePrices'
import {
  Api_EInvoice_CreateInvoice,
  Api_EInvoice_GetInviocePreview,
} from '../../../../apiClient/apiClient'
import {defterdar_log} from '../../../../utils/utilities'

export interface FieldInputType {
  value: string
  label: string
}

export interface CreateInvoiceFormData {
  /** @format uuid */
  customerId: string | null
  products: FormInvoiceProduct[] | null
  invoiceScenario: string | null
  isSelectedCustomerEInvoiceUser: boolean | null
}

export interface FormInvoiceProduct {
  /** @format uuid */
  productId: string

  /** @format int32 */
  count: number

  /** @format double */
  customPrice: number

  /** @format double */
  discountRatio: number

  product: EInvoiceProductDTO
}

function ForibaInvoice() {
  const eInvoiceState = useSelector(getEInvoiceState)
  const commonState = useSelector(getCommonState)
  const dispatch = useDispatch()

  const schema = Yup.object({
    customerId: Yup.string().required('Müşteri seçimi zorunludur').nullable(),
  })

  const submit = async (values: CreateInvoiceFormData, actions: FormikValues) => {
    const body: CreateInvoiceRequest = {
      customerId: values.customerId ?? '',
      invoiceScenario: values.invoiceScenario,
      products: values?.products?.map((m) => {
        var product: InvoiceProduct = {
          count: m.count,
          customPrice: m.customPrice,
          discountRatio: m.discountRatio,
          productId: m.productId,
        }

        return product
      }),
    }

    dispatch(setIsLoading(true))
    actions.setStatus('')
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_EInvoice_CreateInvoice,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: StringApiData) => {
        dispatch(setIsLoading(false))
      })
  }

  const preview = async (values: CreateInvoiceFormData) => {
    const body: CreateInvoiceRequest = {
      customerId: values.customerId ?? '',
      invoiceScenario: values.invoiceScenario,
      products: values?.products?.map((m) => {
        var product: InvoiceProduct = {
          count: m.count,
          customPrice: m.customPrice,
          discountRatio: m.discountRatio,
          productId: m.productId,
        }

        return product
      }),
    }

    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_EInvoice_GetInviocePreview,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: StringApiData) => {
        var win = window.open(response.data ?? '', '_blank')
        win?.focus()
        dispatch(setIsLoading(false))
      })
  }

  const initData: CreateInvoiceFormData = {
    customerId: '',
    products: null,
    invoiceScenario: '',
    isSelectedCustomerEInvoiceUser: false,
  }

  const filterCustomers = (inputValue: string) => {
    return (
      eInvoiceState.eInvoiceCommonData?.customers
        ?.filter((f) => {
          return (
            f.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
            f.surname?.toLowerCase().includes(inputValue.toLowerCase())
          )
        })
        .map((m) => {
          const data: FieldInputType = {
            label: m.name + ' ' + m.surname ?? '',
            value: m.guidId ?? '',
          }
          return data
        }) ?? []
    )
  }

  const filterProducts = (inputValue: string) => {
    return (
      eInvoiceState.eInvoiceCommonData?.products
        ?.filter((f) => f.description?.toLowerCase().includes(inputValue.toLowerCase()))
        .map((m) => {
          const data: FieldInputType = {
            label: m.description ?? '',
            value: m.guidId ?? '',
          }
          return data
        }) ?? []
    )
  }

  const loadCustomers = (inputValue: string, callback: (options: FieldInputType[]) => void) => {
    callback(filterCustomers(inputValue))
  }

  const loadProducts = (inputValue: string, callback: (options: FieldInputType[]) => void) => {
    callback(filterProducts(inputValue))
  }

  return (
    <div>
      <Formik
        validationSchema={schema}
        initialValues={initData}
        onSubmit={submit}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({values, errors, setFieldValue, validateForm, status}) => (
          <Form noValidate id='id_form_' placeholder={undefined}>
            <div className='w-100'>
              {/* <div>{JSON.stringify(values)}</div>
              <div>{JSON.stringify(errors)}</div> */}

              <div className='row'>
                <div className='d-flex flex-column mb-7 fv-row col-xl-6'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Müşteri</span>
                  </label>

                  <AsyncSelect
                    loadOptions={loadCustomers}
                    defaultOptions={filterCustomers('')}
                    name='customerId'
                    onChange={(value) => {
                      setFieldValue('customerId', value?.value)
                      setFieldValue(
                        'isSelectedCustomerEInvoiceUser',
                        eInvoiceState.eInvoiceCommonData?.customers?.find(
                          (f) => f.guidId == value?.value
                        )?.isEinvoiceUser
                      )
                    }}
                    classNames={{
                      control: (state) => 'form-select form-select-solid',
                    }}
                    styles={{
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        display: 'none',
                      }),
                    }}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='customerId' />
                  </div>
                </div>
                <div className='d-flex flex-column mb-7 fv-row col-xl-6'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Fatura Tipi</span>
                  </label>

                  <Field
                    as='select'
                    name='invoiceScenario'
                    className='form-select form-select-solid'
                    onChange={function (element: any) {
                      setFieldValue('invoiceScenario', element.target.value)
                    }}
                  >
                    {values.isSelectedCustomerEInvoiceUser == true ? (
                      <>
                        <option value={'TEMELFATURA'} key={'TEMELFATURA'}>
                          Temel Fatura
                        </option>
                        <option value={'TICARIFATURA'} key={'TICARIFATURA'}>
                          Ticari Fatura
                        </option>
                        <option value={'YOLCUBERABERFATURA'} key={'YOLCUBERABERFATURA'}>
                          Yolcu Beraber Fatura
                        </option>
                        <option value={'IHRACAT'} key={'IHRACAT'}>
                          İhracat
                        </option>
                      </>
                    ) : (
                      <option value={'EARSIVFATURA'} key={'EARSIVFATURA'}>
                        E-Arşiv
                      </option>
                    )}
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='invoiceScenario' />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='d-flex flex-column mb-7 fv-row col-xl-12'>
                  <FieldArray
                    name='products'
                    render={(arrayHelpers) => (
                      <div className='d-flex flex-column justify-content-center'>
                        <div className='fw-bold fs-2 text-left py-5'>Ürünler</div>

                        {values.products?.map((product, index) => (
                          <div className='card shadow-lg mb-10' key={index}>
                            <div className='card-header border-0' style={{minHeight: 30}}>
                              <h3 className='card-title fw-bold text-dark'></h3>
                              <div className='card-toolbar'>
                                {/* begin::Menu */}
                                <button
                                  type='button'
                                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                  data-kt-menu-flip='top-end'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/abs012.svg'
                                    className='svg-icon-muted svg-icon-2hx'
                                  />
                                </button>
                                {/* end::Menu */}
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='row'>
                                <div className='row col-xl-6'>
                                  <div className='d-flex flex-column mb-7 fv-row col-xl-12'>
                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                      <span className='required'>Ürün</span>
                                    </label>
                                    <AsyncSelect
                                      loadOptions={loadProducts}
                                      defaultOptions={filterProducts('')}
                                      name={`products.${index}.productId`}
                                      onChange={(value) => {
                                        const productData =
                                          eInvoiceState.eInvoiceCommonData?.products?.find(
                                            (f) => f.guidId == value?.value
                                          )
                                        setFieldValue(
                                          `products.${index}.productId`,
                                          productData?.guidId
                                        )
                                        setFieldValue(`products.${index}.product`, productData)
                                        setFieldValue(
                                          `products.${index}.customPrice`,
                                          productData?.unitPrice
                                        )
                                        setFieldValue(`products.${index}.count`, 1)
                                        setFieldValue(`products.${index}.discountRatio`, 0)
                                      }}
                                      classNames={{
                                        control: (state) => 'form-select form-select-solid',
                                      }}
                                      styles={{
                                        dropdownIndicator: (baseStyles, state) => ({
                                          ...baseStyles,
                                          display: 'none',
                                        }),
                                      }}
                                    />
                                  </div>

                                  <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                      <span className='required'>Adet</span>
                                    </label>
                                    <Field
                                      type='number'
                                      className='form-control form-control-solid'
                                      placeholder=''
                                      name={`products.${index}.count`}
                                    />
                                  </div>

                                  <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                      <span className='required'>Birim Fiyat</span>
                                    </label>
                                    <Field
                                      type='number'
                                      className='form-control form-control-solid'
                                      placeholder=''
                                      name={`products.${index}.customPrice`}
                                    />
                                  </div>

                                  <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                      <span className='required'>İndirim Oranı</span>
                                    </label>
                                    <Field
                                      type='number'
                                      className='form-control form-control-solid'
                                      placeholder=''
                                      name={`products.${index}.discountRatio`}
                                    />
                                  </div>
                                </div>

                                <div className='col-xl-6'>
                                  <ForibaInvoiceLinePrices
                                    productData={values.products?.at(index)}
                                  ></ForibaInvoiceLinePrices>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className='text-center'>
                          <button
                            type='button'
                            className='btn btn-sm btn-primary me-3 mt-5'
                            onClick={() => arrayHelpers.push('')}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr009.svg'
                              className='svg-icon-muted svg-icon-hx'
                            />
                            Ürün Ekle
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>

              <ForibaInvoicePrices products={values.products}></ForibaInvoicePrices>

              <div className='d-flex flex-row-reverse pt-10 align-items-end mb-5'>
                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>Kaydet</span>
                  </button>
                </div>
                <div>
                  <div
                    className='btn btn-lg btn-primary me-3'
                    onClick={() => {
                      preview(values)
                    }}
                  >
                    <span className='indicator-label'>Önizleme</span>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ForibaInvoice
