import clsx from 'clsx'
import React from 'react'
import Translator from '../../common/translate/Translator'

type NormalButtonParams = {
  onClick: (params?: any) => void
  disabled?: boolean
  className?: string
}

function CancelButton(props: NormalButtonParams) {
  return (
    <a
      href='#'
      onClick={props.onClick}
      className={clsx('btn btn-light btn-light-danger', props.className, {
        disabled: props.disabled ?? false,
      })}
    >
      <span className='indicator-label'>
        <Translator name={'IPTAL'} />
      </span>
    </a>
  )
}

export default CancelButton
