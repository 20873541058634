/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from 'yup'
import {Link, useLocation} from 'react-router-dom'
import {FormikValues} from 'formik'
//import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getUserState, handleLoginResponse} from '../../../redux/userSlice'
import {useDispatch, useSelector} from 'react-redux'
import {postRequestAsync, setIsLoading} from '../../../redux/commonSlice'
import {useCookies} from 'react-cookie'
import {Api_Auth_Login} from '../../../apiClient/apiClient'
import {} from '../../../redux/store'
import {LoginRequest, LoginResponseApiData} from '../../../api/data-contracts'
import 'react-toastify/dist/ReactToastify.css'
import {ScrollComponent} from '../../../../_metronic/assets/ts/components'
import FormErrorMessage from '../../../components/common/messages/FormErrorMessage'
import FormSuccessMessage from '../../../components/common/messages/FormSuccessMessage'
import FormInput from '../../../components/form/FormInput'
import SubmitButton from '../../../components/form/button/SubmitButton'
import FormikForm from '../../../components/form/FormikForm'
import Translator from '../../../components/common/translate/Translator'
import {FormStatus} from '../../../types/FormStatus'

const loginSchema = Yup.object().shape({
  email: Yup.string().email('MAIL_FORMATI_GECERSIZ').required('KULLANICI_ADI_ZORUNLUDUR'),
  password: Yup.string().required('SIFRE_ZORUNLUDUR'),
})

export function Login() {
  const [cookies, setCookie] = useCookies(['token'])

  const dispatch = useDispatch()
  const userState = useSelector(getUserState)

  const initialValues: LoginRequest = {
    email: userState?.emailFromRegisterForm ?? '',
    password: '',
  }

  const onSubmit = async (values: LoginRequest, actions: FormikValues) => {
    actions.setStatus(new FormStatus(false, null))

    dispatch(setIsLoading(true))
    try {
      await dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_Auth_Login,
          body: {
            email: values.email,
            password: values.password,
          },
        })
      )
        .unwrap()
        .then((response: LoginResponseApiData) => {
          if (response?.result == 1) {
            setCookie('token', response.data?.token, {path: '/'})
          } else {
            actions.setStatus(new FormStatus(false, response.serverMessage))
          }
          dispatch(handleLoginResponse(response))
        })
        .catch((error: any) => {})
    } catch (error) {
      console.error(error)
      handleLoginResponse({})
      actions.setStatus(new FormStatus(false, 'Bir sorun oluştu'))
    } finally {
      dispatch(setIsLoading(false))
      ScrollComponent.reinitialization()
    }
  }

  const location = useLocation()

  return (
    <FormikForm initialValues={initialValues} validationSchema={loginSchema} onSubmit={onSubmit}>
      {(props) => {
        return (
          <>
            <div className='text-center mb-10 mb-lg-20'>
              <h3 className='fs-1'>
                <Translator name={'GIRIS_YAPIN'} />
              </h3>
              <p className='text-muted fw-bold'>
                <Translator name={'KULLANICI_PAROLA_GIRIN'} />
              </p>
              <div className='text-center'>
                <span className='fw-bold text-gray-600'>
                  <Translator name={'HESABIN_YOK_MU'} />
                </span>
                <Link
                  to={'/auth/registration' + location.search}
                  className='link-danger fw-bold ms-2'
                >
                  <Translator name={'KAYIT_OLUN'} />
                </Link>
              </div>
            </div>
            <FormErrorMessage message={props.status?.message} />
            <div id='successRegister'>
              {userState.registerStatus && (
                <FormSuccessMessage message={'KAYIT_BASARILI_GIREBILIRSINIZ'} />
              )}
            </div>
            <div className='row mb-8'>
              <FormInput
                errorMessage={props.errors.email}
                istouched={props.touched.email ?? false}
                isTransparent={true}
                fieldAttributes={{
                  type: 'email',
                  name: 'email',
                  autoComplete: 'off',
                  placeholder: 'EMAIL',
                }}
              />
            </div>
            <div className='row mb-3'>
              <FormInput
                errorMessage={props.errors.password}
                istouched={props.touched.password ?? false}
                isTransparent={true}
                fieldAttributes={{
                  type: 'password',
                  name: 'password',
                  autoComplete: 'off',
                  placeholder: 'SIFRE',
                }}
              />
            </div>
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
              <div />
              <Link to='/auth/forgot-password' className='link-danger'>
                <Translator name={'SIFREMI_UNUTTUM'} />
              </Link>
            </div>
            <div className='row'>
              <SubmitButton
                isSubmitting={props.isSubmitting}
                isValid={props.isValid}
                loadingText='LUTFEN_BEKLEYIN'
                notLoadingText='GIRIS_YAP'
                mode='danger'
              />
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}
