import React from 'react'
import {Button} from 'react-bootstrap'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

function ExportCSV(props: {csvData: any[]; fileName: string}) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = (csvData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = {Sheets: {data: ws}, SheetNames: ['data']}
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const data = new Blob([excelBuffer], {type: fileType})
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <Button
      className='btn btn-primary ms-5'
      onClick={(e) => exportToCSV(props.csvData, props.fileName)}
    >
      Excel Aktar
    </Button>
  )
}

export default ExportCSV
