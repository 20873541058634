import React from 'react'
import MediaQuery from 'react-responsive'
import ForibaInvoiceLinePriceDetail from './ForibaInvoiceLinePriceDetail'
import {FormInvoiceProduct} from './ForibaInvoice'
import {
  GetDiscountAmount,
  GetFinalPrice,
  GetTotalPriceWithDiscount,
  GetTotalProductPrice,
} from './ForibaUtils'
import {defterdar_log} from '../../../../utils/utilities'

function ForibaInvoicePrices(props: {products: FormInvoiceProduct[] | null | undefined}) {
  const finalPriceWithDiscount =
    props.products
      ?.map((m) => {
        return GetTotalPriceWithDiscount(m)
      })
      ?.reduce((sum, current) => sum + current) ?? 0

  const finalPrice =
    props.products
      ?.map((m) => {
        return GetFinalPrice(m)
      })
      ?.reduce((sum, current) => sum + current) ?? 0
  const discountAmount =
    props.products
      ?.map((m) => {
        return GetDiscountAmount(m)
      })
      ?.reduce((sum, current) => sum + current) ?? 0

  const productTotalPrices =
    props.products
      ?.map((m) => {
        return GetTotalProductPrice(m)
      })
      ?.reduce((sum, current) => sum + current) ?? 0
  return (
    <div className='row'>
      <div className='card card-border w-100 d-flex flex-row-fluid flex-center'>
        <div className='w-xl-50 w-xxl-50 w-xs-100 w-sm-100 w-md-100 w-lg-100 pt-5 row ps-5 pe-9'>
          <ForibaInvoiceLinePriceDetail
            description='Ürünler Toplamı'
            value={productTotalPrices}
          ></ForibaInvoiceLinePriceDetail>

          {(discountAmount ?? 0) > 0 && (
            <>
              <div className='separator border-3 my-3'></div>

              <ForibaInvoiceLinePriceDetail
                description='İndirim Miktarı'
                value={discountAmount}
              ></ForibaInvoiceLinePriceDetail>
              <div className='separator border-3 my-3'></div>

              <ForibaInvoiceLinePriceDetail
                description='İndirimli Fiyat'
                value={finalPriceWithDiscount}
              ></ForibaInvoiceLinePriceDetail>
            </>
          )}

          <ForibaInvoiceLinePriceDetail
            description='Son Fiyat'
            value={finalPrice}
          ></ForibaInvoiceLinePriceDetail>
        </div>
      </div>
    </div>
  )
}

export default ForibaInvoicePrices
