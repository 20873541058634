import React from 'react'
import {useSelector} from 'react-redux'
import {getUserState} from '../../../redux/userSlice'

function SidebarName() {
  const userState = useSelector(getUserState)

  return (
    <div className='flex-grow-1 me-2'>
      <a href='#' className='text-white text-hover-primary fs-6 fw-bold'>
        {userState.data?.firstName} {userState.data?.lastName}
      </a>
    </div>
  )
}

export default SidebarName
