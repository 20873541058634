import React from 'react'
import {BaseCardData, CardData} from '../../../../api/data-contracts'
import {KTSVG} from '../../../../../_metronic/helpers'

function DashboardCardItem(props: {data: BaseCardData; index: number; isLast: boolean}) {
  return (
    <div className={'d-flex align-items-center flex-row ' + (!props.isLast ? 'mb-2' : '')}>
      <div className='symbol symbol-40px w-40px me-5'>
        <span className='symbol-label bg-lighten'>
          <i className={'fas fa-' + props.index}></i>
        </span>
      </div>

      <div className='d-flex align-items-center w-100'>
        <div className='mb-1 pe-1 d-flex align-content-center flex-wrap flex-grow-1'>
          <div className='fs-7 text-gray-800 fw-bold'>{props.data.description}</div>
          {/* <div className='text-gray-400 fw-semibold fs-7'>SubText</div> */}
        </div>

        <div className='d-flex justify-content-end min-w-75px'>
          <div className='fw-bold fs-7 text-gray-800 pe-1'> {props.data.valueText}</div>
        </div>
      </div>
    </div>
  )
}

export default DashboardCardItem
