import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getCommonState, getRequestAsync, postRequestAsync} from '../../../redux/commonSlice'
import {getCompanyState} from '../../../redux/companySlice'
import {getUserState} from '../../../redux/userSlice'
import {
  AdminPanelCompaniesStartupDTO,
  AdminPanelCompaniesStartupDTOApiData,
  AdminPanelCompanyDTOIEnumerableApiData,
  GetAdminCompaniesRequest,
} from '../../../api/data-contracts'
import {Api_Admin_GetCompanies, Api_Admin_GetCompaniesStartup} from '../../../apiClient/apiClient'
import moment from 'moment'
import {DropdownOption} from '../../../utils/utilities'
import FormikForm from '../../form/FormikForm'
import FormInput from '../../form/FormInput'
import Select from 'react-select'
import _, {filter} from 'lodash'
import DateRangeSelectorHorizontal from '../../form/dropdown/DateRangeSelectorHorizontal'
import {getAdminState, setCompanies} from '../../../redux/adminSlice'
import {KTSVG} from '../../../../_metronic/helpers'

function CompanyFilter() {
  const adminState = useSelector(getAdminState)

  const [startUp, setStartUp] = useState<AdminPanelCompaniesStartupDTO | null | undefined>(null)

  const [filterDetailed, setFilterDetailed] = useState(false)

  const dispatch = useDispatch()

  const submit = async (values: GetAdminCompaniesRequest) => {
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetCompanies,
        body: values,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelCompanyDTOIEnumerableApiData) {
        dispatch<any>(setCompanies(response.data))
      })
  }

  async function loadStartup() {
    await dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetCompaniesStartup,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelCompaniesStartupDTOApiData) {
        setStartUp(response.data)
      })

    await submit(initFilterValues)
  }

  useEffect(() => {
    loadStartup()
  }, [])

  const activePassiveOptions: DropdownOption[] = [
    {
      label: 'Hepsi',
      value: '-1',
    },
    {
      label:
        'Fesih' +
        ' (' +
        adminState?.companies?.filter((f) => f.isCompanyActive === false).length +
        ')',
      value: '0',
    },
    {
      label:
        'Aktif' +
        ' (' +
        adminState?.companies?.filter((f) => f.isCompanyActive === true).length +
        ')',
      value: '1',
    },
  ]

  const initFilterValues: GetAdminCompaniesRequest = {
    textSearch: '',
    creationDateEnd: moment(new Date()).startOf('month').add(1, 'month').format('yyyy-MM-DD'),
    creationDateStart: moment(new Date()).add(-10, 'year').startOf('month').format('yyyy-MM-DD'),
    activePassive: -1,
    assignedFinancialAdvisorUserId: undefined,
    deptAmount: undefined,
    boughtProducts: [],
    companyTypes: [],
    states: [],
  }

  const allFinancialAdvisorItem: DropdownOption = {
    label: 'Hepsi',
    value: '0',
  }

  const financialAdvisorOptions =
    _.orderBy(
      startUp?.financialAdvisors?.map((m) => {
        const d: DropdownOption = {
          label:
            (m.description ?? '') +
            ' (' +
            adminState?.companies?.filter((f) => f.assignedFinancialAdvisorId == m.id).length +
            ')',
          value: m.id?.toString() ?? '0',
        }
        return d
      }),
      (o) => o.label
    ) ?? []

  financialAdvisorOptions.unshift(allFinancialAdvisorItem)

  return (
    <div className='w-100'>
      <FormikForm initialValues={initFilterValues} onSubmit={submit}>
        {(props) => {
          return (
            <>
              <div className='row w-100'>
                <div className='d-flex flex-column mb-3 fv-row col-xl-5'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className=''>Kayıt Tarihi Aralığı</span>
                  </label>
                  <DateRangeSelectorHorizontal
                    setFieldValue={props.setFieldValue}
                    finishKey='creationDateEnd'
                    startKey='creationDateStart'
                  />
                </div>

                <div className='d-flex flex-column mb-3 fv-row col-xl-3'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className=''>Aktif - Fesih</span>
                  </label>
                  <Select
                    options={activePassiveOptions}
                    closeMenuOnSelect={true}
                    name='activePassive'
                    onChange={(value: any) => {
                      props.setFieldValue(
                        'activePassive',
                        parseInt((value as DropdownOption).value)
                      )
                    }}
                    classNames={{
                      control: (state) => 'form-select form-select-solid fs-6',
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingTop: '0',
                        paddingBottom: '0',
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        display: 'none',
                      }),
                    }}
                  />
                </div>

                {filterDetailed && (
                  <>
                    <div className='d-flex flex-column mb-3 fv-row col-xl-4'>
                      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                        <span className=''>Durum</span>
                      </label>
                      <Select
                        options={
                          _.orderBy(startUp?.states, (o) => o.progressIndex)?.map((m) => {
                            const d: DropdownOption = {
                              label:
                                m.description +
                                ' (' +
                                adminState?.companies?.filter(
                                  (f) => f.stepDescription == m.description
                                ).length +
                                ')',
                              value: m.applicationStepId?.toString() ?? '0',
                            }
                            return d
                          }) ?? []
                        }
                        isMulti
                        closeMenuOnSelect={false}
                        name='states'
                        onChange={(value) => {
                          props.setFieldValue(
                            'states',
                            (value as DropdownOption[]).map((m) => Number(m.value))
                          )
                        }}
                        classNames={{
                          control: (state) => 'form-select form-select-solid fs-6',
                        }}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            paddingTop: '0',
                            paddingBottom: '0',
                          }),
                          dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            display: 'none',
                          }),
                        }}
                      />
                    </div>

                    <div className='d-flex flex-column mb-3 fv-row col-xl-2'>
                      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                        <span className=''>Şirket Tipi</span>
                      </label>
                      <Select
                        options={
                          startUp?.companyTypes?.map((m) => {
                            const d: DropdownOption = {
                              label:
                                (m.description ?? '') +
                                ' (' +
                                adminState?.companies?.filter((f) => f.type == m.description)
                                  .length +
                                ')',
                              value: m.companyTypeId?.toString() ?? '0',
                            }
                            return d
                          }) ?? []
                        }
                        isMulti
                        closeMenuOnSelect={false}
                        name='companyTypes'
                        onChange={(value) => {
                          props.setFieldValue(
                            'companyTypes',
                            (value as DropdownOption[]).map((m) => Number(m.value))
                          )
                        }}
                        classNames={{
                          control: (state) => 'form-select form-select-solid fs-6',
                        }}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            paddingTop: '0',
                            paddingBottom: '0',
                          }),
                          dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            display: 'none',
                          }),
                        }}
                      />
                    </div>

                    <div className='d-flex flex-column mb-3 fv-row col-xl-3'>
                      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                        <span className=''>Fesih Durum</span>
                      </label>
                      <Select
                        options={
                          startUp?.suspensionStates?.map((m) => {
                            const d: DropdownOption = {
                              label:
                                (m.description ?? '') +
                                ' (' +
                                adminState?.companies?.filter((f) => f.suspensionStatusId == m.id)
                                  .length +
                                ')',
                              value: m.id?.toString() ?? '0',
                            }
                            return d
                          }) ?? []
                        }
                        isMulti
                        closeMenuOnSelect={false}
                        name='suspensionStates'
                        onChange={(value) => {
                          props.setFieldValue(
                            'suspensionStates',
                            (value as DropdownOption[]).map((m) => Number(m.value))
                          )
                        }}
                        classNames={{
                          control: (state) => 'form-select form-select-solid fs-8',
                        }}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            paddingTop: '0',
                            paddingBottom: '0',
                          }),
                          dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            display: 'none',
                          }),
                        }}
                      />
                    </div>

                    {financialAdvisorOptions.length > 2 && (
                      <div className='d-flex flex-column mb-3 fv-row col-xl-3'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className=''>Mali Müşavir</span>
                        </label>
                        <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                          <Select
                            options={financialAdvisorOptions}
                            closeMenuOnSelect={true}
                            name='assignedFinancialAdvisorUserId'
                            onChange={(value: any) => {
                              props.setFieldValue(
                                'assignedFinancialAdvisorUserId',
                                parseInt((value as DropdownOption).value)
                              )
                            }}
                            classNames={{
                              control: (state) => 'form-select form-select-solid fs-8',
                            }}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                paddingTop: '0',
                                paddingBottom: '0',
                              }),
                              dropdownIndicator: (baseStyles, state) => ({
                                ...baseStyles,
                                display: 'none',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div className='d-flex flex-column mb-3 fv-row col-xl-3'>
                      <FormInput
                        istouched={props.touched.textSearch ?? false}
                        errorMessage={props.errors.textSearch}
                        isTransparent={true}
                        placeHolderOnTop={true}
                        checkValid={false}
                        fieldAttributes={{
                          type: 'text',
                          name: 'textSearch',
                          autoComplete: 'on',
                          placeholder: 'Arama (İsim, Email, Telefon, Vergi No, TC. No)',
                        }}
                      />
                    </div>

                    <div className='d-flex flex-column mb-3 fv-row col-xl-1'>
                      <FormInput
                        istouched={props.touched.deptAmount ?? false}
                        errorMessage={props.errors.deptAmount}
                        isTransparent={true}
                        placeHolderOnTop={true}
                        checkValid={false}
                        fieldAttributes={{
                          type: 'number',
                          name: 'deptAmount',
                          autoComplete: 'on',
                          placeholder: 'Borç',
                        }}
                      />
                    </div>

                    <div className='d-flex flex-column mb-3 fv-row col-xl-4 offset-xl-4 justify-content-center align-items-center'>
                      <a
                        href='#'
                        onClick={() => {
                          setFilterDetailed(false)
                        }}
                        className='btn btn-sm btn-link btn-color-gray-500 btn-active-color-primary me-5 mb-2'
                      >
                        <i className='las la-angle-double-up fs-2'></i>Daha Az Filtre
                        <i className='las la-angle-double-up fs-2 ms-1'></i>
                      </a>
                    </div>
                  </>
                )}

                <div className='d-flex align-items-end mb-3 fv-row col-xl-2'>
                  <a
                    className='btn btn-lg btn-primary me-3 col-12'
                    onClick={() => {
                      props.resetForm({values: initFilterValues})
                    }}
                  >
                    <span className='indicator-label'>Temizle</span>
                  </a>
                </div>

                <div className='d-flex align-items-end mb-3 fv-row col-xl-2'>
                  <button type='submit' className='btn btn-lg btn-primary me-3 col-12'>
                    <span className='indicator-label'>Filtrele</span>
                  </button>
                </div>
              </div>
              {!filterDetailed && (
                <div className='d-flex flex-column fv-row col-xl-12 justify-content-center align-items-center'>
                  <a
                    href='#'
                    onClick={() => {
                      setFilterDetailed(true)
                    }}
                    className='btn btn-sm btn-link btn-color-gray-500 btn-active-color-primary'
                  >
                    <i className='las la-angle-double-down fs-2'></i>Daha Fazla Filtre
                    <i className='las la-angle-double-down fs-2 ms-1'></i>
                  </a>
                </div>
              )}
            </>
          )
        }}
      </FormikForm>
    </div>
  )
}

export default CompanyFilter
