import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {ProductDTO, ProductDTOIEnumerableApiData} from '../../../../../api/data-contracts'
import {Api_Product_GetProducts} from '../../../../../apiClient/apiClient'
import {ProductTypeEnum} from '../../../../../apiClient/enums'
import {postRequestAsync} from '../../../../../redux/commonSlice'
import {openPaymentPageAsync} from '../../../../../redux/productSlice'
import NormalTitle from '../../../../../components/common/title/NormalTitle'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'
import {useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import {defterdar_log} from '../../../../../utils/utilities'
import ClemtaProduct from '../../../../../components/product/ClemtaProduct'
import _ from 'lodash'

function ClemataPackageStep() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [packages, setPackages] = useState<ProductDTO[] | undefined | null>()

  useEffect(() => {
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Product_GetProducts,
        body: {
          TypeId: ProductTypeEnum.Clemta_Package,
        },
      })
    )
      .unwrap()
      .then(async function (response: ProductDTOIEnumerableApiData) {
        setPackages(response.data)
      })
  }, [])

  return (
    <div className='w-100'>
      <NormalTitle text='CLEMTA_PACKAGE_TITLE' />

      <div
        className='card card-border w-100 d-flex flex-row-fluid flex-center'
        style={{boxShadow: 'none'}}
      >
        <div className={clsx('w-100')}>
          <div className='card-body'>
            <div className='tab-content' id='myTabContent'>
              <div className='row d-flex justify-content-around'>
                {Object.entries(_.groupBy(packages, (p) => p.title)).map((m) => (
                  <ClemtaProduct products={m[1]} key={m[0]}></ClemtaProduct>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='ms-10'>
        <WizardStepButtons
          customNextButtonOnClik={() => {
            dispatch<any>(openPaymentPageAsync({navigate: navigate}))
          }}
          nextButtonHidden={true}
        />
      </div>
    </div>
  )
}

export default ClemataPackageStep
