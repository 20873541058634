import {number} from 'card-validator'
import {UserTypeAuthorizationsEnum} from '../apiClient/enums'
import store from '../redux/store'

export function CanIDoThat(auth: UserTypeAuthorizationsEnum): boolean {
  return (
    store
      .getState()
      .user.data?.authorizations?.map((m) => m.valueOf())
      ?.includes(auth.valueOf()) ?? false
  )
}
