import {
  CompanyDTOApiData,
  CompanyGeneralnfoDTO,
  CompanyGeneralnfoDTOApiData,
  GetCompanyInfoRequest,
  UpsertClemtaCompanyRequest,
} from '../api/data-contracts'
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'
import {
  Api_Company_GetCompanyGeneralInfo,
  Api_Company_UpsertCompanyClemta,
} from '../apiClient/apiClient'
import {postRequestAsync} from './commonSlice'
import _ from 'lodash'
import {defterdar_log} from '../utils/utilities'

export interface CompanyState {
  Id: string | null | undefined
  data: CompanyGeneralnfoDTO | undefined
  clemtaWizardData?: UpsertClemtaCompanyRequest
}

const initialState: CompanyState = {
  Id: null,
  data: undefined,
  clemtaWizardData: undefined,
}

export const companySlice = createSlice({
  name: 'company',
  initialState: initialState,
  reducers: {
    loadSelectedCompanyInfo: (state, action: PayloadAction<CompanyGeneralnfoDTO | undefined>) => {
      state.data = action.payload
      state.Id = action.payload?.company?.guid

      if (action.payload?.company?.clemtaData) {
        state.clemtaWizardData = JSON.parse(action.payload.company.clemtaData)
      }
    },

    startNewCompanyRoute: (state) => {
      state.Id = undefined
      state.data = undefined
      state.clemtaWizardData = undefined
    },

    setClemtaWizardData: (state, action: PayloadAction<UpsertClemtaCompanyRequest | undefined>) => {
      state.clemtaWizardData = action.payload
    },
  },
})

export const loadSelectedCompanyInfoAsync = createAsyncThunk(
  'api/loadSelectedCompanyInfo',
  async (payload, thunkAPI) => {
    const userState = (thunkAPI.getState() as RootState).user
    const commonState = (thunkAPI.getState() as RootState).common

    if (userState.data?.selectedCompanyId) {
      const body: GetCompanyInfoRequest = {
        companyId: userState.data?.selectedCompanyId ?? '',
      }
      await thunkAPI
        .dispatch<any>(
          postRequestAsync({
            postRequestFunction: Api_Company_GetCompanyGeneralInfo,
            body: body,
          })
        )
        .unwrap()
        .then((response: CompanyGeneralnfoDTOApiData) => {
          thunkAPI.dispatch<any>(loadSelectedCompanyInfo(response.data))
        })
    }
  }
)

export const setClemtaWizardDataAsync = createAsyncThunk(
  'api/setClemtaWizardDataAsync',
  async (payload: UpsertClemtaCompanyRequest, thunkAPI) => {
    try {
      const companyState = (thunkAPI.getState() as RootState).company

      payload.shareholders?.forEach((f, i) => {
        f = {
          ...f,
          index: i + 1,
        }
      })

      thunkAPI.dispatch(setClemtaWizardData(payload))

      payload = {
        ...payload,
        companyId: companyState.Id,
      }
      if (payload.name) {
        await thunkAPI
          .dispatch<any>(
            postRequestAsync({
              postRequestFunction: Api_Company_UpsertCompanyClemta,
              body: payload,
            })
          )
          .unwrap()
          .then((response: CompanyDTOApiData) => {})
      }
    } catch (error) {
      defterdar_log(error)
    }
  }
)

export const {loadSelectedCompanyInfo, startNewCompanyRoute, setClemtaWizardData} =
  companySlice.actions
export default companySlice.reducer

export const getCompanyState = (state: RootState) => state.company
