import {ErrorMessage, Field, Form, Formik} from 'formik'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import schema from 'yup/lib/schema'
import {useDispatch, useSelector} from 'react-redux'
import {getCommonState, postRequestAsync} from '../../redux/commonSlice'
import {Api_Notification_Test} from '../../apiClient/apiClient'
import {NotificationModel, ResponseModelApiData} from '../../api/data-contracts'

function NotificationTest() {
  const dispatch = useDispatch()
  const commonState = useSelector(getCommonState)

  interface cardFormValues {
    title: string
    description: string
  }

  const initialValues: cardFormValues = {
    title: '',
    description: '',
  }

  const submitStep = async (values: cardFormValues) => {
    //resetForm()
    try {
      const body: NotificationModel = {
        body: values.description,
        deviceId: commonState.notificationToken,
        isAndroiodDevice: true,
        title: values.title,
      }
      await dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_Notification_Test,
          body: body,
        })
      )
        .unwrap()
        .then(function (response: ResponseModelApiData) {})
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  return (
    <div
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik initialValues={initialValues} onSubmit={submitStep} validateOnMount={true}>
          {({values, errors, setFieldValue, validateForm}) => (
            <Form className='py-10 w-100 w-xl-700px px-9' noValidate id='kt_purchase_form' placeholder={undefined}>
              <div className='w-100'>
                {/* <div className='d-flex flex-column mb-7 fv-row'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>Başlık</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='title'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='title' />
                  </div>
                </div> */}

                <div className='d-flex flex-column mb-7 fv-row'>
                  <label className='required fs-6 fw-bold form-label mb-2'>Açıklama</label>

                  <div className='position-relative'>
                    <Field
                      type='text'
                      className='form-control form-control-solid'
                      name='description'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='description' />
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-stack pt-5 align-items-end'>
                  <div className='mr-2'></div>

                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>Gönder</span>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default NotificationTest
