import Translator from '../translate/Translator'

function FormSuccessMessage(props: {
  message?: String
  translatedMessage?: string | null | undefined
}) {
  return (
    <>
      {props.message && (
        <div className='row mb-8 alert alert-success'>
          <div className='alert-text font-weight-bold'>{props.message}</div>
        </div>
      )}
      {props.translatedMessage && (
        <div className='row mb-8 alert alert-success'>
          <div className='alert-text font-weight-bold'>
            <Translator name={props.translatedMessage} />
          </div>
        </div>
      )}
    </>
  )
}

export default FormSuccessMessage
