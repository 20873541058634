import React, {useEffect, useState} from 'react'
import {CompanyTimeLineEvent, CompanyTimeLineEventListApiData} from '../../../api/data-contracts'
import {Api_Company_GetCompanyTimeline} from '../../../apiClient/apiClient'
import {postRequestAsync} from '../../../redux/commonSlice'
import {useDispatch} from 'react-redux'
import moment from 'moment'
import TimelineEvent from './TimelineEvent'

function Timeline() {
  const dispatch = useDispatch()

  const [timeline, setTimeline] = useState<CompanyTimeLineEvent[]>([])

  useEffect(() => {
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Company_GetCompanyTimeline,
        body: {},
      })
    )
      .unwrap()
      .then(async (response: CompanyTimeLineEventListApiData) => {
        setTimeline(response.data ?? [])
      })
  }, [])

  return (
    <div className='card'>
      <div className='card-header align-items-center border-0 mt-1'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-gray-900'>Activities</span>
        </h3>
      </div>

      <div className='card-body h-350px'>
        <div className='timeline-label overflow-auto'>
          {timeline.map((m, i) => {
            return <TimelineEvent event={m} key={i} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Timeline
