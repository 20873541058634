import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'PROD') {
      const script = document.createElement('script')

      script.src = '/infoset.js'
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'PROD') {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.id = 'hs-script-loader'
      script.async = true
      script.defer = true
      script.src = '//js-eu1.hs-scripts.com/139771552.js'
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <Outlet />
          <MasterInit />
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
