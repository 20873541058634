import React, {Suspense, useState} from 'react'
import FaarPage from './FaarPage'

const HomePageBanners = React.lazy(() => import('./HomePageBanners'))
const HomePageCalendar = React.lazy(() => import('./HomePageCalendar'))
const ContractDocument = React.lazy(() => import('./ContractDocument'))
const AuthorizationPage = React.lazy(() => import('./AuthorizationPage'))

function AdminSettingsPage() {
  const [activeTab, setActiveTab] = useState('authorization')

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId)
  }

  return (
    <div className='card card-border w-100 d-flex flex-row-fluid flex-center h-100 min-h-800px'>
      <div className='card-header card-header-stretch'>
        <div className='card-toolbar'>
          <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
            <li className='nav-item'>
              <a
                className={`nav-link ${activeTab === 'authorization' ? 'active' : ''}`}
                data-bs-toggle='tab'
                href={'#kt_tab_panel_authorization'}
                onClick={() => handleTabClick('authorization')}
              >
                Yetkilendirme
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link ${activeTab === 'faar' ? 'active' : ''}`}
                data-bs-toggle='tab'
                href={'#kt_tab_panel_faar'}
                onClick={() => handleTabClick('faar')}
              >
                Mali Müşavir Atama Kuralları
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link ${activeTab === 'home_banner' ? 'active' : ''}`}
                data-bs-toggle='tab'
                href={'#kt_tab_panel_home_banner'}
                onClick={() => handleTabClick('home_banner')}
              >
                Anasayfa Banner
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link ${activeTab === 'calendar' ? 'active' : ''}`}
                data-bs-toggle='tab'
                href={'#kt_tab_panel_calendar'}
                onClick={() => handleTabClick('calendar')}
              >
                Anasayfa Takvim
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link ${activeTab === 'contract_document' ? 'active' : ''}`}
                data-bs-toggle='tab'
                href={'#kt_tab_panel_contract_document'}
                onClick={() => handleTabClick('contract_document')}
              >
                Sözleşme Dosyası Takvim
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='card-body pt-0 fs-6 w-100 pt-1'>
        <div className='mb-3 mt-2'></div>

        <div className='tab-content' id='myTabContent'>
          <div
            className={`tab-pane fade ${activeTab === 'authorization' ? 'active show' : ''}`}
            id={'kt_tab_panel_authorization'}
            role='tabpanel'
          >
            {activeTab === 'authorization' && (
              <Suspense fallback={<div>Loading Authorization Page...</div>}>
                <AuthorizationPage />
              </Suspense>
            )}
          </div>

          <div
            className={`tab-pane fade ${activeTab === 'faar' ? 'active show' : ''}`}
            id={'kt_tab_panel_faar'}
            role='tabpanel'
          >
            {activeTab === 'faar' && (
              <Suspense fallback={<div>Loading Faar Page...</div>}>
                <FaarPage />
              </Suspense>
            )}
          </div>

          <div
            className={`tab-pane fade ${activeTab === 'home_banner' ? 'active show' : ''}`}
            id={'kt_tab_panel_home_banner'}
            role='tabpanel'
          >
            {activeTab === 'home_banner' && (
              <Suspense fallback={<div>Loading Home Page Banners...</div>}>
                <HomePageBanners />
              </Suspense>
            )}
          </div>

          <div
            className={`tab-pane fade ${activeTab === 'calendar' ? 'active show' : ''}`}
            id={'kt_tab_panel_calendar'}
            role='tabpanel'
          >
            {activeTab === 'calendar' && (
              <Suspense fallback={<div>Loading Calendar...</div>}>
                <HomePageCalendar />
              </Suspense>
            )}
          </div>

          <div
            className={`tab-pane fade ${activeTab === 'contract_document' ? 'active show' : ''}`}
            id={'kt_tab_panel_contract_document'}
            role='tabpanel'
          >
            {activeTab === 'contract_document' && (
              <Suspense fallback={<div>Loading Contract Document...</div>}>
                <ContractDocument />
              </Suspense>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminSettingsPage
