import clsx from 'clsx'
import React from 'react'
import {ProgressBar} from 'react-bootstrap'

function CompanyStateProgressBar(props: {percentage: number; label: string}) {
  function getBackgroundColor() {
    if (props.percentage <= 33) {
      return 'danger'
    } else if (props.percentage <= 80) {
      return 'warning'
    } else if (props.percentage >= 80 && props.percentage < 100) {
      return 'primary'
    } else if (props.percentage == 100) {
      return 'success'
    } else {
      return 'danger'
    }
  }

  return (
    <ProgressBar
      now={props.percentage}
      label={props.label}
      className={clsx('fs-5 fw-bold h-100 border border-dark')}
      variant={getBackgroundColor()}
    />
    // <div
    //   className={clsx('progress-bar ', getBackgroundColor())}
    //   role='progressbar'
    //   style={{width: `${props.percentage}%`}}
    // ></div>
  )
}

export default CompanyStateProgressBar
