/* eslint-disable jsx-a11y/anchor-is-valid */

import {useSelector} from 'react-redux'
import {ApplicationStepEnum} from '../../../apiClient/enums'
import CompanyStatusComponent from './CompanyStatusComponent'
import {getAdminState} from '../../../redux/adminSlice'

function CompanyStatusHeader() {
  const adminState = useSelector(getAdminState)

  return (
    <div className='card-body pt-5 pb-0'>
      <div className='d-flex flex-wrap flex-sm-nowrap'>
        <div className='flex-grow-1'>
          <div className='d-flex justify-content-between flex-wrap'>
            <CompanyStatusComponent
              iconMessage='Bekliyor'
              iconName='las la-tasks fs-3x'
              iconColoring='btn-color-warning'
              visibleSteps={[ApplicationStepEnum.Turkey_WizardFinished]}
              enabledSteps={[ApplicationStepEnum.Turkey_WizardFinished]}
              stepId={ApplicationStepEnum.Turkey_WizardFinished}
            ></CompanyStatusComponent>
            <CompanyStatusComponent
              iconMessage=''
              iconName='las la-arrow-right fs-2x'
              iconColoring='btn-color-success'
              targerStepId={ApplicationStepEnum.Turkey_DocumentInReview}
              visibleSteps={[ApplicationStepEnum.Turkey_WizardFinished]}
              enabledSteps={[ApplicationStepEnum.Turkey_WizardFinished]}
            ></CompanyStatusComponent>

            <CompanyStatusComponent
              iconMessage='Dökümanlar Revize Bekliyor'
              iconName='las la-tasks fs-3x'
              iconColoring='btn-color-danger'
              visibleSteps={[
                ApplicationStepEnum.Turkey_DocumentInReview,
                ApplicationStepEnum.Turkey_DocumentAreApproved,
                ApplicationStepEnum.Turkey_Finished,
                ApplicationStepEnum.Turkey_DocumentsNeedUpdate,
                ApplicationStepEnum.Turkey_EconversionStarted,
                ApplicationStepEnum.Turkey_CompanyCreated,
              ]}
              enabledSteps={[
                ApplicationStepEnum.Turkey_DocumentInReview,
                ApplicationStepEnum.Turkey_DocumentsNeedUpdate,
              ]}
              stepId={ApplicationStepEnum.Turkey_DocumentsNeedUpdate}
            ></CompanyStatusComponent>

            <CompanyStatusComponent
              iconMessage=''
              iconName='las la-arrow-left fs-2x'
              iconColoring='btn-color-danger'
              targerStepId={ApplicationStepEnum.Turkey_DocumentsNeedUpdate}
              visibleSteps={[
                ApplicationStepEnum.Turkey_DocumentInReview,
                ApplicationStepEnum.Turkey_DocumentAreApproved,
                ApplicationStepEnum.Turkey_Finished,
                ApplicationStepEnum.Turkey_EconversionStarted,
                ApplicationStepEnum.Turkey_CompanyCreated,
              ]}
              enabledSteps={[ApplicationStepEnum.Turkey_DocumentInReview]}
            ></CompanyStatusComponent>

            <CompanyStatusComponent
              iconMessage='Belgeler İnceleniyor'
              iconName='las la-file-alt fs-3x'
              iconColoring='btn-color-primary'
              stepId={ApplicationStepEnum.Turkey_DocumentInReview}
              enabledSteps={[ApplicationStepEnum.Turkey_DocumentInReview]}
            ></CompanyStatusComponent>
            <CompanyStatusComponent
              iconMessage=''
              iconName='las la-arrow-right fs-2x'
              iconColoring='btn-color-success'
              targerStepId={ApplicationStepEnum.Turkey_DocumentAreApproved}
              enabledSteps={[ApplicationStepEnum.Turkey_DocumentInReview]}
            ></CompanyStatusComponent>
            <CompanyStatusComponent
              iconMessage='Kuruluş Başlatıldı'
              iconName='las la-file-alt fs-3x'
              iconColoring='btn-color-info'
              stepId={ApplicationStepEnum.Turkey_DocumentAreApproved}
              enabledSteps={[ApplicationStepEnum.Turkey_DocumentAreApproved]}
            ></CompanyStatusComponent>

            <CompanyStatusComponent
              iconMessage=''
              iconName='las la-arrow-right fs-2x'
              iconColoring='btn-color-success'
              targerStepId={ApplicationStepEnum.Turkey_CompanyCreated}
              enabledSteps={[ApplicationStepEnum.Turkey_DocumentAreApproved]}
              isDisabled={
                !(
                  adminState.company?.doesHaveTaxNumberFile &&
                  (adminState.company?.company?.taxAdministrationNumber?.length ?? 0)
                )
              }
            ></CompanyStatusComponent>

            <CompanyStatusComponent
              iconMessage='Kuruluş Tamamlandı'
              iconName='las la-file-alt fs-3x'
              iconColoring='btn-color-success'
              stepId={ApplicationStepEnum.Turkey_CompanyCreated}
              enabledSteps={[ApplicationStepEnum.Turkey_CompanyCreated]}
            ></CompanyStatusComponent>

            <CompanyStatusComponent
              iconMessage=''
              iconName='las la-arrow-right fs-2x'
              iconColoring='btn-color-success'
              targerStepId={ApplicationStepEnum.Turkey_EconversionStarted}
              enabledSteps={[ApplicationStepEnum.Turkey_CompanyCreated]}
            ></CompanyStatusComponent>

            <CompanyStatusComponent
              iconMessage='E-dönüşüm Başladı'
              iconName='las la-bolt fs-3x'
              iconColoring='btn-color-info'
              stepId={ApplicationStepEnum.Turkey_EconversionStarted}
              enabledSteps={[ApplicationStepEnum.Turkey_EconversionStarted]}
            ></CompanyStatusComponent>
            <CompanyStatusComponent
              iconMessage=''
              iconName='las la-arrow-right fs-2x'
              iconColoring='btn-color-success'
              targerStepId={ApplicationStepEnum.Turkey_Finished}
              enabledSteps={[ApplicationStepEnum.Turkey_EconversionStarted]}
            ></CompanyStatusComponent>
            <CompanyStatusComponent
              iconMessage='Tamamlandı'
              iconName='las la-check-circle fs-3x'
              iconColoring='btn-color-success'
              stepId={ApplicationStepEnum.Turkey_Finished}
              enabledSteps={[ApplicationStepEnum.Turkey_Finished]}
            ></CompanyStatusComponent>
          </div>
        </div>
      </div>

      {adminState.company?.company?.stepId == 12 &&
        ((adminState.company?.company?.taxAdministrationNumber?.length ?? 0) < 10 ||
          !adminState.company?.doesHaveTaxNumberFile) && (
          <div className='d-flex overflow-auto h-55px text-danger'>
            * Firma durumunu tamamlandı yapabilmeniz için vergi numarası alanını doldurmanız ve
            vergi levhası belgesini yüklemeniz gerekmektedir.
          </div>
        )}
    </div>
  )
}

export {CompanyStatusHeader}
