/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Languages} from '../../../_metronic/partials/layout/header-menus/Languages'
import LanguageSelector from '../../components/other/LanguageSelector'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Aside */}
      <div
        className='d-flex flex-column w-lg-600px bgi-position-center order-1 order-lg-1 justify-content-between py-10 px-4 px-md-10'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/defterdar/bg-4-green.jpg')})`,
        }}
      >
        {/* begin::Logo */}
        <Link to='/'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/defterdar/logo-letter-1-w.png')}
            className='h-50px'
          />
        </Link>
        {/* end::Logo */}

        <div className='flex-column-fluid d-flex flex-column justify-content-center'>
          <h3 className='fs-1 mb-5 text-white'>360 Derece Şirket Yönetimi!</h3>
          <p className='fw-lighter text-white opacity-80'>
            Defterdar, size 360 derece iş yönetim hizmeti sunar. Şirketleşme sürecinizden; ön
            muhasebe ve ileri eğitim destekleri gibi çeşitli hizmetler sunuyoruz.
          </p>
        </div>

        <div className='flex-column-auto d-lg-block justify-content-between mt-10'>
          <div className='opacity-75 fw-bold text-white mb-1'>
            © 2022 Defterdar - Bilgi Teknolojileri
          </div>
          <div className='d-flex mb-3'>
            <a
              href='http://defterdar.com/sss#gizlilikPolitikasi'
              target='_blank'
              rel='noreferrer'
              className='text-white'
            >
              Gizlilik Politikası
            </a>
            <a
              href='http://defterdar.com/sss#mesafeliSatisSozlesmesi'
              target='_blank'
              rel='noreferrer'
              className='text-white ms-10'
            >
              Mesafeli Satış Sözleşmesi
            </a>
          </div>
        </div>

        <div className='text-left mt-1'>
          <img
            alt='LogoBand'
            src={toAbsoluteUrl('/media/defterdar/logo-band-w.png')}
            style={{maxWidth: 330}}
          />
        </div>
      </div>
      {/* end::Aside */}

      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid p-10 order-2 order-lg-2'>
        <div className='d-flex flex-end'>
          <LanguageSelector />
        </div>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10 w-100'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AuthLayout}
