import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../_metronic/helpers'
import {useLayout} from '../../../../_metronic/layout/core'
import Translator from '../../common/translate/Translator'

export type SideBarMenuItemProps = {
  id?: string
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  alertText?: string
  shouldTranslate?: boolean
  extraIcon?: string
  clickEvent?: () => void
}

const SidebarMenuItem: FC<SideBarMenuItemProps & WithChildren> = ({
  id,
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  alertText = null,
  shouldTranslate = true,
  extraIcon,
  clickEvent,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item menu-accordion'>
      <Link
        className={clsx('menu-link without-sub', {
          active: isActive,
        })}
        to={to}
        onClick={clickEvent}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>
          {shouldTranslate ? <Translator name={title}></Translator> : title}
        </span>
        {alertText && <span className='badge badge-circle badge-sm bg-danger'>{alertText}</span>}
        {extraIcon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className=''>
            <KTSVG path={extraIcon} className='svg-icon svg-icon-danger' />
          </span>
        )}
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
