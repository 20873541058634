import {ErrorMessage, FormikValues} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {
  getCommonState,
  nextStep,
  postRequestAsync,
  setIsLoading,
} from '../../../../../redux/commonSlice'
import {getCompanyState} from '../../../../../redux/companySlice'
import {getProductState} from '../../../../../redux/productSlice'
import {getUserState} from '../../../../../redux/userSlice'
import {
  BooleanApiData,
  OperationFieldDTOIEnumerableApiData,
  OperationFieldSearchRequest,
  SetCompanyExtraInfoRequest,
} from '../../../../../api/data-contracts'
import {
  Api_Company_OperationFieldSearch,
  Api_Company_SetCompanyExtraInfo,
} from '../../../../../apiClient/apiClient'
import AsyncSelect from 'react-select/async'
import * as Yup from 'yup'
import Translator from '../../../../../components/common/translate/Translator'
import FormikForm from '../../../../../components/form/FormikForm'
import NormalTitle from '../../../../../components/common/title/NormalTitle'
import FormInput from '../../../../../components/form/FormInput'
import FormMessage from '../../../../../components/common/messages/FormMessage'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'

export interface FieldInputType {
  value: string
  label: string
}

function CompanyInfoStep() {
  const companyState = useSelector(getCompanyState)
  const userState = useSelector(getUserState)
  var productState = useSelector(getProductState)
  const commonState = useSelector(getCommonState)
  const dispatch = useDispatch()

  const submit = async (values: SetCompanyExtraInfoRequest, actions: FormikValues) => {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Company_SetCompanyExtraInfo,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        dispatch(setIsLoading(false))

        if (response.data == true) {
          dispatch(nextStep())
        } else {
          actions.setStatus({message: response.serverMessage})
        }
      })
  }

  const initData: SetCompanyExtraInfoRequest = {
    capitalAmount: companyState?.data?.company?.capitalAmount ?? 0,
    operationFieldId: companyState?.data?.company?.operationFieldId ?? 0,
    partnerCount: companyState?.data?.company?.partnerCount ?? 0,
    operationFieldDescription: companyState?.data?.company?.operationFieldDescription ?? '',
    personnelCount: companyState?.data?.company?.personnelCount ?? 0,
  }

  const loadOptions = (inputValue: string, callback: (options: FieldInputType[]) => void) => {
    //throttle(() => {
    if ((inputValue?.length ?? 0) > 2) {
      const body: OperationFieldSearchRequest = {
        criteria: inputValue,
      }
      dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_Company_OperationFieldSearch,
          body: body,
        })
      )
        .unwrap()
        .then(async (response: OperationFieldDTOIEnumerableApiData) => {
          callback(
            response?.data?.map((m) => {
              const data: FieldInputType = {
                label: m.feildDetailName ?? '',
                value: m.fieldId?.toString() ?? '',
              }
              return data
            }) ?? []
          )
        })
    }
    // }, 2000)
  }

  const schema = Yup.object({
    partnerCount: Yup.number().when({
      is: () => {
        return [2, 3].includes(companyState.data?.company?.typeId ?? 0)
      },
      then: Yup.number().required('ORTAK_SAYISI_ZORUNLUDUR').positive('ORTAK_SAYISI_ZORUNLUDUR'),
    }),

    operationFieldId: Yup.number().when(['operationFieldDescription'], {
      is: (operationFieldDescription: string | null | undefined) => {
        return (
          [2, 3].includes(companyState.data?.company?.typeId ?? 0) && !operationFieldDescription
        )
      },
      then: Yup.number().positive('NACE_KODU_YADA_FAALIYET_KONUSU_ZORUNLUDUR'),
    }),

    capitalAmount: Yup.number().when({
      is: () => {
        return [2, 3].includes(companyState.data?.company?.typeId ?? 0)
      },
      then: Yup.number()
        .required('SERMAYE_MIKTARI_ZORUNLUDUR')
        .positive('SERMAYE_MIKTARI_ZORUNLUDUR')
        .label('Ortak Sayısı'),
    }),

    personnelCount: Yup.number()
      .required('PERSONEL_SAYISI_ZORUNLUDUR')
      .positive('PERSONEL_SAYISI_ZORUNLUDUR')
      .label('Personel Sayısı'),
  })

  return (
    <>
      <div className='w-100'>
        <FormikForm initialValues={initData} onSubmit={submit} validationSchema={schema}>
          {(props) => {
            return (
              <>
                <NormalTitle text='SIRKET_BILGILERI' />

                <div className='row'>
                  <div className='d-flex flex-column col-xl-3 offset-xl-3'>
                    <FormInput
                      fieldAttributes={{
                        type: 'number',
                        name: 'capitalAmount',
                        placeholder: 'SERMAYE_MIKTARI',
                      }}
                      istouched={props.touched.capitalAmount ?? false}
                      errorMessage={props.errors.capitalAmount}
                      placeHolderOnTop={true}
                      checkValid={false}
                    />
                  </div>
                  <div className='d-flex flex-column mb-7 col-xl-3'>
                    <FormInput
                      fieldAttributes={{
                        type: 'number',
                        name: 'partnerCount',
                        placeholder: 'ORTAK_SAYISI',
                      }}
                      istouched={props.touched.partnerCount ?? false}
                      errorMessage={props.errors.partnerCount}
                      placeHolderOnTop={true}
                      checkValid={false}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='d-flex flex-column mb-7 fv-row offset-xl-3 col-xl-3'>
                    <FormInput
                      fieldAttributes={{
                        type: 'number',
                        name: 'personnelCount',
                        placeholder: 'CALISAN_SAYINIZ',
                      }}
                      istouched={props.touched.personnelCount ?? false}
                      errorMessage={props.errors.personnelCount}
                      placeHolderOnTop={true}
                      checkValid={false}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='d-flex flex-column mb-7 fv-row offset-xl-3 col-xl-6'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>
                        <Translator name='NACE_KOD' />
                      </span>
                    </label>
                    <AsyncSelect
                      loadOptions={loadOptions}
                      defaultOptions
                      name='operationFieldId'
                      onChange={(value) => {
                        props.setFieldValue('operationFieldId', parseInt(value?.value ?? ''))
                      }}
                      classNames={{
                        control: (state) => 'form-select form-select-solid',
                      }}
                      styles={{
                        dropdownIndicator: (baseStyles, state) => ({
                          ...baseStyles,
                          display: 'none',
                        }),
                      }}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='operationFieldId' />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='d-flex flex-column mb-7 fv-row offset-xl-3 col-xl-6'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className=''>
                        <Translator name='FAALIYET_KONUSU' />
                      </span>
                    </label>
                    <div className='fs-7'>
                      <Translator name='FAALIYET_KONUSU_ACIKLAMA' />
                    </div>

                    <FormInput
                      fieldAttributes={{
                        type: 'text',
                        name: 'operationFieldDescription',
                        placeholder: 'FAALIYET_KONUSU',
                      }}
                      istouched={props.touched.operationFieldDescription ?? false}
                      errorMessage={props.errors.operationFieldDescription}
                      placeHolderOnTop={false}
                      checkValid={false}
                    />
                  </div>
                </div>

                <div className='row mt-5'>
                  <div className='col-xl-12'>
                    <FormMessage {...props.status} />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-6 offset-xl-3'>
                    <WizardStepButtons />
                  </div>
                </div>
              </>
            )
          }}
        </FormikForm>
      </div>
    </>
  )
}

export default CompanyInfoStep
