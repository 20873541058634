import React from 'react'
import MediaQuery from 'react-responsive'
import ForibaInvoiceLinePriceDetail from './ForibaInvoiceLinePriceDetail'
import {FormInvoiceProduct} from './ForibaInvoice'
import {
  GetDiscountAmount,
  GetFinalPrice,
  GetTotalPriceWithDiscount,
  GetTotalProductPrice,
} from './ForibaUtils'

function ForibaInvoiceLinePrices(props: {productData: FormInvoiceProduct | null | undefined}) {
  return (
    <div className='row'>
      <div className='card card-border w-100 d-flex flex-row-fluid flex-center'>
        <div className='pt-5 row ps-5 pe-9'>
          <ForibaInvoiceLinePriceDetail
            description='Ürünler Toplamı'
            value={GetTotalProductPrice(props.productData)}
          ></ForibaInvoiceLinePriceDetail>

          {(props?.productData?.discountRatio ?? 0) > 0 && (
            <>
              <div className='separator border-3 my-3'></div>
              <ForibaInvoiceLinePriceDetail
                description='İndirim Miktarı'
                value={GetDiscountAmount(props.productData)}
              ></ForibaInvoiceLinePriceDetail>
              <ForibaInvoiceLinePriceDetail
                description='İndirimli Fiyat'
                value={GetTotalPriceWithDiscount(props.productData)}
              ></ForibaInvoiceLinePriceDetail>
              <div className='separator border-3 my-3'></div>
            </>
          )}

          {props?.productData?.product?.taxCategories?.map((m) => {
            return (
              <ForibaInvoiceLinePriceDetail
                key={m.taxCategoryId}
                description={m.taxName ?? ''}
                value={(GetTotalPriceWithDiscount(props.productData) * (m.taxRatio ?? 0)) / 100}
              ></ForibaInvoiceLinePriceDetail>
            )
          })}

          <ForibaInvoiceLinePriceDetail
            description='Son Fiyat'
            value={GetFinalPrice(props.productData)}
          ></ForibaInvoiceLinePriceDetail>

          {/* <div className='separator border-3 my-3'></div> */}

          {/* <ForibaInvoiceLinePriceDetail
            description='Ürünler Toplamı'
            value={values.products?.at(index)?.finalPrice ?? 0}
          ></ForibaInvoiceLinePriceDetail>
          <ForibaInvoiceLinePriceDetail
            description='KDV'
            value={200}
          ></ForibaInvoiceLinePriceDetail>
          {
            <ForibaInvoiceLinePriceDetail
              description='İndirim'
              value={300}
            ></ForibaInvoiceLinePriceDetail>
          }
          <ForibaInvoiceLinePriceDetail
            description='Toplam'
            value={400}
          ></ForibaInvoiceLinePriceDetail> */}
        </div>
      </div>
    </div>
  )
}

export default ForibaInvoiceLinePrices
