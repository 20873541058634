import {useSelector, useDispatch} from 'react-redux'
import FormikForm from '../../../../../components/form/FormikForm'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'
import {nextStep} from '../../../../../redux/commonSlice'
import {
  getCompanyState,
  setClemtaWizardData,
  setClemtaWizardDataAsync,
} from '../../../../../redux/companySlice'
import SmallTitle from '../../../../../components/common/title/SmallTitle'
import FormInput from '../../../../../components/form/FormInput'
import NormalText from '../../../../../components/common/title/NormalText'
import {getProductState} from '../../../../../redux/productSlice'
import InfoMessage from '../../../../../components/common/title/InfoMessage'
import FormPriceInput from '../../../../../components/form/FormPriceInput'
import FormCheckboxInput from '../../../../../components/form/FormCheckboxInput'
import clsx from 'clsx'
import MediumRadioButton from '../../../../../components/form/radio/MediumRadioButton'
import SmallRadioButton from '../../../../../components/form/radio/SmallRadioButton'
import TinyTitle from '../../../../../components/common/title/TinyTitle'
import Translator from '../../../../../components/common/translate/Translator'
import NormalTitle from '../../../../../components/common/title/NormalTitle'
import {KTSVG} from '../../../../../../_metronic/helpers'
import BasePopup, {PopupProvides} from '../../../../../components/common/popup/BasePopup'
import ClemtaShareHolderForm from './components/ClemtaShareHolderForm'
import {errorMonitor} from 'events'
import ClemtaShareHolderCard from './components/ClemtaShareHolderCard'
import _ from 'lodash'
import {ClemtaShareHolder} from '../../../../../api/data-contracts'

function ClemtaCompanyShareholdersStep() {
  const companyState = useSelector(getCompanyState)
  const productState = useSelector(getProductState)
  const dispatch = useDispatch()

  const initValues: {
    shareholders: ClemtaShareHolder[]
    shareType: number
  } = {
    shareholders: companyState?.clemtaWizardData?.shareholders ?? [],
    shareType: 0,
  }

  const submit = async (values: {shareholders: ClemtaShareHolder[]}) => {
    dispatch(nextStep())
  }

  const totalShare = companyState.clemtaWizardData?.numberOfShares ?? 0

  const filledPercentage =
    _.sumBy(companyState.clemtaWizardData?.shareholders, (s) => s.percentage ?? 0) / 100

  const remainingPercentage = 1 - filledPercentage

  const totalRemainingShare = totalShare * remainingPercentage

  return (
    <FormikForm initialValues={initValues} onSubmit={submit}>
      {(props) => {
        return (
          <>
            <SmallTitle text='CLEMTA_LIST_SHAREHOLDERS' />

            <div className='row'>
              <div className='offset-xl-2 col-xl-8'>
                <NormalText text='CLEMTA_LIST_SHAREHOLDERS_DETAILS' />
              </div>
            </div>

            <div className='d-flex w-100 justify-content-center'>
              <div className='card card-dashed flex-row flex-stack flex-wrap w-50 p-6'>
                <div className='row'>
                  <TinyTitle text='CLEMTA_SETTING_SHAREHOLDERS_DISTRIBUTION' />
                  <NormalText text='CLEMTA_SETTING_SHAREHOLDERS_DISTRIBUTION_DETAIL' />

                  <div className='offset-xl-3 col-xl-6 d-flex justify-content-between'>
                    <SmallRadioButton
                      description={'CLEMTA_PERCENTAGE'}
                      name={'shareType'}
                      value={0}
                      setFieldValue={props.setFieldValue}
                    />

                    <SmallRadioButton
                      description={'CLEMTA_SHARES'}
                      name={'shareType'}
                      value={1}
                      setFieldValue={props.setFieldValue}
                    />
                  </div>
                  <div className='mt-3 offset-xl-2 col-xl-8'>
                    <div className='mt-10'>
                      <TinyTitle text='CLEMTA_COMPLETE_SHAREHOLDERS' />
                    </div>

                    <div className='d-flex fw-semibold align-items-center'>
                      <div className='bullet w-8px h-3px rounded-2 bg-success me-1'></div>

                      <div className='col-6 text-gray-500 me-1 d-flex'>
                        <Translator name={'CLEMTA_TOTAL'} />
                      </div>

                      <div className={clsx('col-6 fw-bolder text-end px-1')}>
                        {props.values.shareType == 0
                          ? '%100'
                          : new Intl.NumberFormat().format(totalShare)}
                      </div>
                    </div>

                    <div className='separator border-3 my-3 mx-1'></div>

                    <div className='d-flex fw-semibold align-items-center'>
                      <div className='bullet w-8px h-3px rounded-2 bg-success me-1'></div>

                      <div className='col-6 text-gray-500 me-1 d-flex'>
                        <Translator name={'CLEMTA_REMAINING'} />
                      </div>

                      <div className={clsx('col-6 fw-bolder text-end px-1')}>
                        {props.values.shareType == 0
                          ? '%' + remainingPercentage * 100
                          : new Intl.NumberFormat().format(totalRemainingShare)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row mx-10'>
              {companyState.clemtaWizardData?.shareholders?.map((m) => {
                return (
                  <div className='col-6 mt-10'>
                    <ClemtaShareHolderCard person={m} />
                  </div>
                )
              })}
            </div>

            {remainingPercentage > 0 && (
              <div className='d-flex w-100 justify-content-center mt-10'>
                <BasePopup
                  title='CLEMTA_ADD_SHAREHOLDER'
                  size='lg'
                  wrapperClassName='w-50'
                  modalOpener={
                    <div className='card card-dashed w-100 p-6 btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary'>
                      <div className='text-center'>
                        <h1 className='text-primary fw-bolder mb-3n'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen041.svg'
                            className='svg-icon-muted svg-icon-2hx'
                          />
                          <Translator name={'CLEMTA_ADD_SHAREHOLDER'} />
                        </h1>
                      </div>
                    </div>
                  }
                >
                  {(providers: PopupProvides) => {
                    return (
                      <ClemtaShareHolderForm initValues={{}} modalClose={providers.handleClose} />
                    )
                  }}
                </BasePopup>
              </div>
            )}

            <div className='row'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <WizardStepButtons nextButtonDisabled={remainingPercentage > 0} />
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default ClemtaCompanyShareholdersStep
