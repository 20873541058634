import './loading.scss'

function LoadingPage() {
  return (
    <div className='loader_div'>
      <div
        className='spinner-border spinner-border-xl text-primary'
        style={{width: '6rem', height: '6rem'}}
      ></div>
    </div>
  )
}

export default LoadingPage
