import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {auto} from '@popperjs/core'
import Translator from '../../../../components/common/translate/Translator'

function ParasutPage() {
  return (
    <div className='w-100 h-100'>
      <div className='w-100 h-100'>
        <div className='card card-flush h-md-100'>
          <div className='mb-10 mt-10'>
            <div className='fs-4 fw-bold text-gray-800 text-center mb-13'>
              <span className='me-2'>
                <Translator name='BU_SAYFA_YAPIM_AŞAMASINDADIR' />
              </span>
            </div>
          </div>

          <div
            className='col-xl-4 offset-xl-4'
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/dozzy-1/4.png')})`,
              backgroundPosition: 'top',
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              //width: '50vw',
              height: '100vh',
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default ParasutPage
