import {useRef, useState} from 'react'
import {
  BooleanApiData,
  ServiceProviderDTO,
  ServiceProviderSipayInfo,
} from '../../../api/data-contracts'
import {FormikValues} from 'formik'
import _ from 'lodash'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useSelector, useDispatch} from 'react-redux'
import {KTSVG} from '../../../../_metronic/helpers'
import {Api_Admin_UpdateServiceProviders} from '../../../apiClient/apiClient'
import FormMessage from '../../../components/common/messages/FormMessage'
import FormikForm from '../../../components/form/FormikForm'
import FormInput from '../../../components/form/FormInput'
import {getAdminState} from '../../../redux/adminSlice'
import {setIsLoading, postRequestAsync} from '../../../redux/commonSlice'
import {CanIDoThat} from '../../../utils/CanIDoThat'
import {setFormikStatus} from '../../../utils/utilities'
import * as Yup from 'yup'
import {ValidateIBAN} from '../../../utils/cardUtilities'
import { UserTypeAuthorizationsEnum } from '../../../apiClient/enums'

function AdminReferenceDetailPage(props: {data: ServiceProviderDTO}) {
  const schema = Yup.object().shape({
    full_company_name: Yup.string().required('FIRMA_ADI_ZORUNLUDUR'),
    authorized_person_name: Yup.string().required('KISI_ADI_ZORUNLUDUR'),
    authorized_person_email: Yup.string()
      .email('EPOSTA_ADRESI_EPOSTA')
      .min(3, 'EPOSTA_ADRESI_MIN')
      .max(50, 'EPOSTA_ADRESI_MAX')
      .required('EPOSTA_ADRESI_REQUIRED'),
    authorized_person_phone_number: Yup.string()
      .phone('TR', false, 'TELEFON_NUMARASI_TELEFON')
      .required('TELEFON_NUMARASI_REQUIRED'),
    identity_number: Yup.string().length(11, 'KIMLIK_UZUNLUK_HATASI').required('KIMLIK_ZORUNLUDUR'),
    iban_no: Yup.string()
      .required('IBAN_ZORUNLUDUR')
      .test('is-valid-iban', 'GECERSIZ_IBAN', (value) => {
        if (!value) return false
        const cleanedIBAN = value.replace(/\s+/g, '').toUpperCase()

        return ValidateIBAN(cleanedIBAN)
      }),
  })

  const [modalShow, setModalShow] = useState(false)

  const adminstate = useSelector(getAdminState)

  const formikRef = useRef(null)
  const intl = useIntl()

  const dispatch = useDispatch()

  let sipayInitData: ServiceProviderSipayInfo = props.data.sipayInfo ?? {}
  const handleModalClose = () => setModalShow(false)

  const load = () => {
    setModalShow(true)
  }

  const submit = async (values: ServiceProviderSipayInfo, actions: FormikValues) => {
    dispatch(setIsLoading(true))
    let body: ServiceProviderDTO = props.data
    body.sipayInfo = values
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_UpdateServiceProviders,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        setFormikStatus(actions, response)
        load()
      })
  }

  return (
    <>
      <a
        href='#'
        onClick={() => {
          load()
        }}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
      </a>

      <Modal size='xl' show={modalShow} onHide={handleModalClose} centered id='kt_modal_success'>
        <Modal.Header closeButton>
          <h5 className='modal-title'>{props?.data?.name} Bilgileri </h5>
        </Modal.Header>
        <Modal.Body>
          {
            <>
              <div className='card-header card-header-stretch'>
                <div className='card-toolbar'>
                  <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
                    <li className='nav-item'>
                      <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                        Sipay Bilgileri
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                        Ödemeler
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='card-body'>
                <div className='tab-content' id='myTabContent'>
                  <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                    <FormikForm
                      initialValues={sipayInitData}
                      onSubmit={submit}
                      innerRef={formikRef}
                      validationSchema={schema}
                    >
                      {(props) => {
                        return (
                          <>
                            <div className='w-100 mt-5'>
                              <div className='row mb-7'>
                                <div className='col-lg-2  d-flex align-items-center'>
                                  <label className='fw-bold text-muted'>Sipay Firma Adı</label>
                                </div>
                                <div className='col-lg-4'>
                                  <FormInput
                                    fieldAttributes={{
                                      type: 'text',
                                      name: 'full_company_name',
                                      placeholder: '',
                                    }}
                                    istouched={props.touched.full_company_name ?? false}
                                    errorMessage={props.errors.full_company_name}
                                    placeHolderOnTop={false}
                                    checkValid={false}
                                  />
                                </div>

                                <div className='col-lg-2  d-flex align-items-center'>
                                  <label className='fw-bold text-muted'>Yetkili Kişi Adı</label>
                                </div>
                                <div className='col-lg-4'>
                                  <FormInput
                                    fieldAttributes={{
                                      type: 'text',
                                      name: 'authorized_person_name',
                                      placeholder: '',
                                    }}
                                    istouched={props.touched.authorized_person_name ?? false}
                                    errorMessage={props.errors.authorized_person_name}
                                    placeHolderOnTop={false}
                                    checkValid={false}
                                  />
                                </div>
                              </div>

                              <div className='separator border-3 my-3 mx-1'></div>

                              <div className='row mb-7'>
                                <div className='col-lg-2  d-flex align-items-center'>
                                  <label className='fw-bold text-muted'>Yetkili Email</label>
                                </div>
                                <div className='col-lg-4'>
                                  <FormInput
                                    fieldAttributes={{
                                      type: 'text',
                                      name: 'authorized_person_email',
                                      placeholder: '',
                                    }}
                                    istouched={props.touched.authorized_person_email ?? false}
                                    errorMessage={props.errors.authorized_person_email}
                                    placeHolderOnTop={false}
                                    checkValid={false}
                                  />
                                </div>

                                <div className='col-lg-2  d-flex align-items-center'>
                                  <label className='fw-bold text-muted'>Yetkili Telefon</label>
                                </div>
                                <div className='col-lg-4'>
                                  <FormInput
                                    fieldAttributes={{
                                      type: 'text',
                                      name: 'authorized_person_phone_number',
                                      placeholder: '',
                                    }}
                                    istouched={
                                      props.touched.authorized_person_phone_number ?? false
                                    }
                                    errorMessage={props.errors.authorized_person_phone_number}
                                    placeHolderOnTop={false}
                                    checkValid={false}
                                  />
                                </div>
                              </div>

                              <div className='separator border-3 my-3 mx-1'></div>

                              <div className='row mb-7'>
                                <div className='col-lg-2  d-flex align-items-center'>
                                  <label className='fw-bold text-muted'>Yetkili Kimlik No</label>
                                </div>
                                <div className='col-lg-4'>
                                  <FormInput
                                    fieldAttributes={{
                                      type: 'text',
                                      name: 'identity_number',
                                      placeholder: '',
                                    }}
                                    istouched={props.touched.identity_number ?? false}
                                    errorMessage={props.errors.identity_number}
                                    placeHolderOnTop={false}
                                    checkValid={false}
                                  />
                                </div>

                                <div className='col-lg-2  d-flex align-items-center'>
                                  <label className='fw-bold text-muted'>IBAN</label>
                                </div>
                                <div className='col-lg-4'>
                                  <FormInput
                                    fieldAttributes={{
                                      type: 'text',
                                      name: 'iban_no',
                                      placeholder: '',
                                    }}
                                    istouched={props.touched.iban_no ?? false}
                                    errorMessage={props.errors.iban_no}
                                    placeHolderOnTop={false}
                                    checkValid={false}
                                  />
                                </div>
                              </div>

                              <div className='separator border-3 my-3 mx-1'></div>

                              <div className='row'>
                                <div className='col-lg-6 offset-3'>
                                  <FormMessage {...props.status} />
                                </div>
                              </div>

                              {CanIDoThat(
                                UserTypeAuthorizationsEnum.Admin_Panel_Company_Update
                              ) && (
                                <div className='d-flex'>
                                  <div className='ms-auto p-2'>
                                    <button type='submit' className='btn btn-lg btn-primary mt-2'>
                                      <span className='indicator-label'>Kaydet</span>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )
                      }}
                    </FormikForm>
                  </div>
                  <div className='tab-pane fade show' id='kt_tab_pane_2' role='tabpanel'>
                    <div className='w-100'></div>
                  </div>
                </div>
              </div>
            </>
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminReferenceDetailPage
