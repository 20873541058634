import {Field, FieldAttributes} from 'formik'
import React from 'react'

function RadioButtonWrapper(props: {
  fieldAttributes: FieldAttributes<any>
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
  valueConverter?: (value: string) => void
}) {
  return (
    <Field
      {...props.fieldAttributes}
      type='radio'
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        props.setFieldValue?.(
          props.fieldAttributes.name,
          props.valueConverter ? props.valueConverter(event.target.value) : event.target.value
        )

        props.fieldAttributes.onChange?.(event)
      }}
    />
  )
}

export default RadioButtonWrapper
