import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {ThemeModeProvider} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {useDispatch, useSelector} from 'react-redux'
import {getUserState, setUserDto} from '../../app/redux/userSlice'
import {Api_User_GetUserInfo} from '../../app/apiClient/apiClient'
import {UserDTOApiData} from '../../app/api/data-contracts'
import {loadSelectedCompanyInfoAsync} from '../../app/redux/companySlice'
import {getCommonState, getRequestAsync, setIsGeneralDataLoaded} from '../../app/redux/commonSlice'
import LoadingPage from '../../app/pages/loading/LoadingPage'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const dispatch = useDispatch()
  const commonState = useSelector(getCommonState)

  const userState = useSelector(getUserState)
  useEffect(() => {
    if (!userState.isDataLoaded) {
      dispatch(setIsGeneralDataLoaded(false))
      dispatch<any>(
        getRequestAsync({
          getRequestFunction: Api_User_GetUserInfo,
        })
      )
        .unwrap()
        .then(async (response: UserDTOApiData) => {
          if (response) {
            await dispatch(setUserDto(response.data))
            await dispatch<any>(loadSelectedCompanyInfoAsync())
            await dispatch<any>(setIsGeneralDataLoaded(true))
          }
        })
    }
  }, [])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root h-100' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid h-100' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  {/* <ToolbarWrapper /> */}
                  <Content>
                    {(commonState?.isLoading || !commonState?.isGeneralDataLoaded) && (
                      <LoadingPage />
                    )}
                    {commonState?.isGeneralDataLoaded && <Outlet />}
                  </Content>
                </div>
              </div>
            </div>
            <FooterWrapper />
          </div>
        </div>
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
