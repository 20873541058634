import React, {useState} from 'react'
import {toAbsoluteUrl, KTSVG} from '../../../../../_metronic/helpers'
import ForibaProductFormPopup, {ForibaProductFormPopupType} from './ForibaProductFormPopup'
import {EInvoiceProductDTO} from '../../../../api/data-contracts'

function ForibaProduct(props: {product: EInvoiceProductDTO}) {
  const [isFormOpen, setIsFormOpen] = useState(false)

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          {/* <div className='symbol symbol-45px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
          </div> */}
          <div className='d-flex justify-content-start flex-column'>
            <div className='text-dark fw-bold fs-6'>{props.product?.description}</div>

            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
              /HTML, JS, ReactJS //{' '}
            </span> */}
          </div>
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold d-block fs-6'>
          {props.product?.unitPrice} {props.product?.currencySymbol}
        </div>
      </td>
      <td className='text-dark fw-bold fs-6'>
        {props.product?.finalPrice} {props.product?.currencySymbol}
      </td>
      <td>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <ForibaProductFormPopup
            type={ForibaProductFormPopupType.Update}
            productGuidId={props.product.guidId}
          ></ForibaProductFormPopup>
          <ForibaProductFormPopup
            type={ForibaProductFormPopupType.Delete}
            productGuidId={props.product.guidId}
          ></ForibaProductFormPopup>
        </div>
      </td>
    </tr>
  )
}

export default ForibaProduct
