export class FormStatus {
  constructor(
    isSuccessful: boolean,
    message?: string | null | undefined,
    translatedMessage?: string | null | undefined
  ) {
    this.isSuccessful = isSuccessful
    this.message = message
    this.translatedMessage = translatedMessage
  }

  isSuccessful: boolean
  message?: String | undefined | null
  translatedMessage?: string | undefined | null
}
