import React from 'react'
import {FormStatus} from '../../../types/FormStatus'
import FormSuccessMessage from './FormSuccessMessage'
import FormErrorMessage from './FormErrorMessage'

function FormMessage(props: FormStatus) {
  return props.isSuccessful ? (
    <FormSuccessMessage message={props.message ?? ''} translatedMessage={props.translatedMessage} />
  ) : (
    <FormErrorMessage message={props.message ?? ''} translatedMessage={props.translatedMessage} />
  )
}

export default FormMessage
