import clsx from 'clsx'
import {Field, FieldAttributes} from 'formik'
import FormInputErrorMessage from '../common/messages/FormInputErrorMessage'
import React, {ChangeEventHandler, ReactNode} from 'react'
import Translator from '../common/translate/Translator'
import {ids} from 'webpack'
import TranslatorText from '../common/translate/TranslatorText'

export type FormInputProps = {
  istouched: boolean
  errorMessage: string | undefined
  fieldAttributes?: FieldAttributes<any>
  placeHolderOnTop?: boolean
  checkValid?: boolean
  isTransparent?: boolean
  isRequired?: boolean
}

const FormInput: React.FC<FormInputProps> = (props) => {
  return (
    <>
      {props.placeHolderOnTop && (
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className={clsx({required: props.isRequired})}>
            {TranslatorText(props.fieldAttributes.placeholder)}
          </span>
        </label>
      )}
      <Field
        {...props.fieldAttributes}
        className={clsx(
          {
            'form-control form-control-solid': !(props.isTransparent ?? false),
          },
          {
            'form-control bg-transparent': props.isTransparent ?? false,
          },
          {
            [props.fieldAttributes?.className]: props.fieldAttributes?.className?.length > 0,
          },
          {'is-invalid': props.istouched && props.errorMessage && (props.checkValid ?? true)},
          {
            'is-valid': props.istouched && !props.errorMessage && (props.checkValid ?? true),
          }
        )}
        placeholder={
          props.placeHolderOnTop ? null : TranslatorText(props.fieldAttributes.placeholder)
        }
      ></Field>
      <FormInputErrorMessage name={props.fieldAttributes.name} />
    </>
  )
}

export default FormInput
