import React from 'react'
import {useSelector} from 'react-redux'
import {getProductState} from '../../redux/productSlice'
import Product from './Product'
import clsx from 'clsx'

function ExtraProductsTab() {
  const productState = useSelector(getProductState)

  return (
    <div
      className='card card-border w-100 d-flex flex-row-fluid flex-center'
      style={{boxShadow: 'none'}}
    >
      <div className={clsx('w-75')}>
        <div className='card-header card-header-stretch'>
          <div className='card-toolbar'>
            <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
              {productState?.extraProductsCategorized?.map((m, i) => (
                <li className='nav-item' key={m.categoryId}>
                  <a
                    className={'nav-link ' + (i == 0 && 'active')}
                    data-bs-toggle='tab'
                    href={'#kt_tab_panel_' + m.categoryId}
                  >
                    {m.category}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='card-body'>
          <div className='tab-content' id='myTabContent'>
            {productState?.extraProductsCategorized?.map((m, i) => (
              <div
                className={'tab-pane fade ' + (i == 0 && 'active show')}
                id={'kt_tab_panel_' + m.categoryId}
                role='tabpanel'
                key={m.categoryId}
              >
                <div className='row'>
                  {m.products?.map((m) => (
                    <Product
                      product={m}
                      key={m.id}
                      payInstant={true}
                      shouldCreateNewPayment={true}
                    ></Product>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExtraProductsTab
