import {useSearchParams} from 'react-router-dom'
import 'yup-phone'
import {defterdar_log} from '../../../utils/utilities'
import {RegisterRequestExtended, Registration} from './Registration'

window.Buffer = window.Buffer || require('buffer').Buffer

const initialValues: RegisterRequestExtended = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  password: '',
  changepassword: '',
  smsCode: '',
  isSmsCodeValid: false,
  acceptTerms: false,
}

export function EofisRegistration() {
  const [searchParams, setSearchParams] = useSearchParams()
  try {
    const code = searchParams.get('code')
    defterdar_log(code)
    defterdar_log(Buffer.from(code ?? '', 'base64').toString())
    const initData = JSON.parse(Buffer.from(code ?? '', 'base64').toString())
    initialValues.firstName = initData.firstname
    initialValues.lastName = initData.lastname
    initialValues.emailAddress = initData.email
    initialValues.phoneNumber = initData.phoneNumber
    initialValues.referenceId = 1
    defterdar_log(initData)
  } catch (error) {
    defterdar_log(error)
  }

  return <Registration customInitValues={initialValues}></Registration>
}
