import {useSelector, useDispatch} from 'react-redux'
import FormikForm from '../../../../../components/form/FormikForm'
import BigRadioButton from '../../../../../components/form/radio/BigRadioButton'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'
import {nextStep} from '../../../../../redux/commonSlice'
import {getCompanyState, setClemtaWizardDataAsync} from '../../../../../redux/companySlice'
import {CompanyTypeEnum} from '../../../../../apiClient/enums'
import NormalTitle from '../../../../../components/common/title/NormalTitle'
import NormalText from '../../../../../components/common/title/NormalText'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  typeId: Yup.number().required('').nullable(),
})

function ClemtaCompanyTypeStep() {
  const companyState = useSelector(getCompanyState)
  const dispatch = useDispatch()

  const initValues = {
    typeId:
      companyState?.data?.company?.typeId ?? CompanyTypeEnum.C_Corporation__General_Corporation_,
  }

  const submit = async (values: {typeId: number}) => {
    await dispatch<any>(
      setClemtaWizardDataAsync({
        ...companyState.clemtaWizardData,
        typeId: values.typeId,
      })
    )

    dispatch(nextStep())
  }

  return (
    <FormikForm initialValues={initValues} onSubmit={submit} validationSchema={schema}>
      {(props) => {
        return (
          <>
            <NormalTitle text='CLEMTA_CHOOSE_COMPANY_TYPE' />
            <NormalText text='CLEMTA_CHOOSE_COMPANY_TYPE_DETAIL' />

            <div className='row mb-10'>
              <div className='row mt-10 mb-10 offset-2 col-8'>
                <div className='col-12 mb-5'>
                  <BigRadioButton
                    description={'CLEMTA_CORPORATION'}
                    details={''}
                    value={CompanyTypeEnum.C_Corporation__General_Corporation_}
                    icon={'/media/icons/duotune/files/fil005.svg'}
                    name={'typeId'}
                    setFieldValue={props.setFieldValue}
                    key={CompanyTypeEnum.C_Corporation__General_Corporation_}
                    valueConverter={parseInt}
                    detailHtml={
                      <>
                        <li className='d-flex align-items-center text-gray-600 fw-bold fs-7'>
                          <span className='bullet me-5'></span>
                          Access to venture capital
                        </li>
                        <li className='d-flex align-items-center text-gray-600 fw-bold fs-7'>
                          <span className='bullet me-5'></span>
                          Potential for initial public offering
                        </li>
                        <li className='d-flex align-items-center text-gray-600 fw-bold fs-7'>
                          <span className='bullet me-5'></span>Great for fundraising startups and
                          complex company structures
                        </li>
                      </>
                    }
                  />
                </div>
                <div className='col-12'>
                  <BigRadioButton
                    description={'CLEMTA_LLC'}
                    details={''}
                    value={CompanyTypeEnum.LLC__Limited_Liability_Company_}
                    icon={'/media/icons/duotune/files/fil005.svg'}
                    name={'typeId'}
                    setFieldValue={props.setFieldValue}
                    key={CompanyTypeEnum.LLC__Limited_Liability_Company_}
                    valueConverter={parseInt}
                    detailHtml={
                      <>
                        <li className='d-flex align-items-center text-gray-600 fw-bold fs-7'>
                          <span className='bullet me-5'></span>
                          Flexible management and taxation
                        </li>
                        <li className='d-flex align-items-center text-gray-600 fw-bold fs-7'>
                          <span className='bullet me-5'></span>
                          Simplicity and fewer paperwork&formalities
                        </li>
                        <li className='d-flex align-items-center text-gray-600 fw-bold fs-7'>
                          <span className='bullet me-5'></span>Great for small businesses and family
                          enterprises
                        </li>
                      </>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <WizardStepButtons nextButtonDisabled={!props.isValid} />
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default ClemtaCompanyTypeStep
