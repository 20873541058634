import React, {useEffect, useState} from 'react'
import {Carousel} from 'react-bootstrap'
import {BannerData} from './BannerData'
import {convertBase64, defterdar_log} from '../../../utils/utilities'
import NormalTitle from '../../../components/common/title/NormalTitle'
import NormalText from '../../../components/common/title/NormalText'

function HomeBanner(props: {banners: BannerData[]}) {
  const [imageData, setImageData] = useState<BannerData[] | null>(null)

  useEffect(() => {
    const convertImagesToBase64 = async () => {
      const promises = props.banners.map(async (m) => {
        if ('File' in m && m.File instanceof File) {
          const dataUrl = await convertBase64(m.File)
          return {...m, Image: dataUrl as string}
        }
        return m
      })

      const convertedBanners = await Promise.all(promises)
      setImageData(convertedBanners)
    }

    convertImagesToBase64()
  }, [props.banners])

  return (
    <div className='row w-100'>     
      <Carousel className='col-6 offset-3 h-500px' interval={5000}>
        {imageData?.map((m, i) => {
          return (
            <Carousel.Item key={i}>
              <div className='d-flex justify-content-center align-items-center'>
                {m?.Image && (
                  <img className='d-block img-fluid h-500px' src={m?.Image} alt='Not Found' />
                )}
              </div>
              <Carousel.Caption>
                {m.Title ? <h3>{m.Title}</h3> : null}
                {m.Description ? <div className='text-black'>{m.Description}</div> : null}
              </Carousel.Caption>
            </Carousel.Item>
          )
        })}
      </Carousel>
    </div>
  )
}

export default HomeBanner
