import {useEffect} from 'react'
import {getProductState, loadPricesAsync} from '../../redux/productSlice'
import {useDispatch, useSelector} from 'react-redux'
import PaymentDetailRow from './PaymentDetailRow'
import TranslatorText from '../common/translate/TranslatorText'
import TinyTitle from '../common/title/TinyTitle'
import {Guid} from 'guid-typescript'
import WarningMessage from '../common/title/WarningMessage'
import NormalTitle from '../common/title/NormalTitle'
import {CurrencyEnum, ServicePeriodEnum} from '../../apiClient/enums'
import {AssignPackageRequest, BooleanApiData} from '../../api/data-contracts'
import {Api_Company_AssignPackage} from '../../apiClient/apiClient'
import {postRequestAsync} from '../../redux/commonSlice'
import {FormStatus} from '../../types/FormStatus'

function CartDetails() {
  const productState = useSelector(getProductState)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch<any>(loadPricesAsync())
  }, [])

  const assignPackage = async (serviecType: number): Promise<boolean> => {
    var body: AssignPackageRequest = {
      serviceType: serviecType,
    }

    const result = await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Company_AssignPackage,
        body: body,
      })
    )
      .unwrap()
      .then((response: BooleanApiData) => {
        if (!response.data) {
          return false
        } else {
          dispatch<any>(loadPricesAsync())
          return true
        }
      })

    return result
  }

  const isThereAnyRecurring =
    productState.prices?.items?.some((f) => (f.serviceType ?? 0) == ServicePeriodEnum.Aylık) ??
    false

  const currency: CurrencyEnum =
    productState.prices?.items?.at(0)?.currencyId ?? CurrencyEnum.Türk_Lirası

  return (
    <div className='w-100 flex-center px-5'>
      <NormalTitle text='SEPET' />
      <div className='w-100 pt-5 row ps-5 mb-5'>
        {productState.prices?.items
          ?.filter((f) => (f.price ?? 0) > 0)
          .map((m) => {
            return (
              <div key={Guid.create().toString()}>
                <PaymentDetailRow
                  key={Guid.create().toString()}
                  description={m.productDescription ?? ''}
                  isRecurringPayment={m.serviceType == 4}
                  value={m.priceWithoutTax ?? 0}
                  currency={currency}
                ></PaymentDetailRow>

                {m.alternativeProducts?.map((ma) => {
                  return (
                    <div
                      style={{cursor: 'pointer'}}
                      className='d-flex w-100 justify-content-end'
                      onClick={() => assignPackage(ma.serviceType ?? 0)}
                    >
                      {ma.serviceType == 5 && (
                        <div className='badge badge-success'>
                          <span className='indicator-label'>Yıllık Öde {ma.price} ₺</span>
                        </div>
                      )}
                      {ma.serviceType == 4 && (
                        <div className='badge badge-primary'>
                          <span className='indicator-label'>Aylık Öde {ma.price} ₺</span>
                        </div>
                      )}
                    </div>
                  )
                })}

                <div className='separator border-3 my-3 mx-1'></div>
              </div>
            )
          })}

        <PaymentDetailRow
          description={TranslatorText('URUNLER_TOPLAMI')}
          isRecurringPayment={false}
          value={productState.prices?.totalAmountWithoutTax ?? 0}
          currency={currency}
        ></PaymentDetailRow>
        <PaymentDetailRow
          description={TranslatorText('KDV')}
          isRecurringPayment={false}
          value={productState.prices?.tax ?? 0}
          currency={currency}
        ></PaymentDetailRow>
        {productState.prices?.totalDiscount != 0 && (
          <PaymentDetailRow
            description={TranslatorText('INDIRIM')}
            isRecurringPayment={false}
            value={productState.prices?.totalDiscount ?? 0}
            currency={currency}
          ></PaymentDetailRow>
        )}
        <PaymentDetailRow
          description={TranslatorText('TOPLAM')}
          isRecurringPayment={false}
          value={productState.prices?.totalAmountWithDiscount ?? 0}
          currency={currency}
        ></PaymentDetailRow>

        {productState.prices?.discountDescription && (
          <div className='mt-2 text-success fs-5 text-bold'>
            {productState.prices?.discountDescription}
          </div>
        )}

        {isThereAnyRecurring && <WarningMessage message='AYLIK_ODEME_UYARI' />}
      </div>
    </div>
  )
}

export default CartDetails
