import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import ForibaProduct from './ForibaProduct'
import {useSelector} from 'react-redux'
import {getEInvoiceState} from '../../../../redux/eInvoiceSlice'
import ForibaProductFormPopup, {ForibaProductFormPopupType} from './ForibaProductFormPopup'
import ForibaCustomer from './ForibaCustomer'
import ForibaCustomerFormPopup, {ForibaCustomerFormPopupType} from './ForibaCustomerFormPopup'

function ForibaCustomers() {
  const eInvoiceState = useSelector(getEInvoiceState)

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'></h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <ForibaCustomerFormPopup
            type={ForibaCustomerFormPopupType.Insert}
            customerGuidId={undefined}
          ></ForibaCustomerFormPopup>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-80px'>Tipi</th>
                <th className='min-w-140px'>Adı</th>
                <th className='min-w-140px'>Vergi/Kimlik No</th>
                <th className='min-w-140px'>Telefon No</th>
                <th className='min-w-140px'>Email</th>
                <th className='min-w-140px'>E-Fatura Mükellefi Mi</th>
                <th className='min-w-100px text-end'>İşlemler</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {eInvoiceState.eInvoiceCommonData?.customers?.map((m) => {
                return <ForibaCustomer customer={m} key={m.guidId}></ForibaCustomer>
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default ForibaCustomers
