import {BooleanApiData, RegisteredCardDTO, RemoveCardRequest} from '../../../api/data-contracts'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import Translator from '../../common/translate/Translator'
import AreYouSurePopup from '../../common/popup/AreYouSurePopup'
import {Api_Payment_RemoveCard} from '../../../apiClient/apiClient'
import {postRequestAsync, loadRegisteredCardsAsync} from '../../../redux/commonSlice'
import {useDispatch} from 'react-redux'
import clsx from 'clsx'

function RegisteredCard(props: {
  card: RegisteredCardDTO
  canRemove: boolean
  isSelection?: boolean
}) {
  function GetCardIcon(): string {
    let cardLogo

    switch (props.card.cardAssociation) {
      case 'VISA':
        cardLogo = 'visa.svg'
        break
      case 'MASTER_CARD':
        cardLogo = 'mastercard.svg'
        break
      case 'AMERICAN_EXPRESS':
        cardLogo = 'american-express.svg'
        break
      default:
        cardLogo = 'no-logo.svg'
    }

    return toAbsoluteUrl(`/media/svg/card-logos/${cardLogo}`)
  }

  const dispatch = useDispatch()
  async function RemoveCard() {
    const body: RemoveCardRequest = {
      cardToken: props.card.cardToken,
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Payment_RemoveCard,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: BooleanApiData) {
        await dispatch<any>(loadRegisteredCardsAsync())
      })
  }

  return (
    <div
      style={{boxShadow: 'none'}}
      className={clsx(
        'card h-xl-100 flex-row flex-stack flex-wrap',
        {
          'card-dashed': !(props.isSelection ?? false),
        },
        {
          'p-6': !(props.isSelection ?? false),
        }
      )}
    >
      {/* Info */}
      <div className='d-flex flex-column py-2'>
        {/* Owner */}
        <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
          {props.card.cardAlias}
          {/* <span className='badge badge-light-success fs-7 ms-2'>Primary</span> */}
        </div>
        {/* Wrapper */}
        <div className='d-flex align-items-center'>
          {/* Icon */}
          <img src={GetCardIcon()} alt='' className='me-4' />
          {/* Details */}
          <div>
            <div className='fs-4 fw-bold'>
              {props.card.binNumber?.substring(0, 4)} {props.card.binNumber?.substring(4, 8)}{' '}
              {'****'} {'****'}
            </div>
            <div className='fs-6 fw-semibold text-gray-500 text-start'>
              {props.card.cardBankName}
            </div>
          </div>
        </div>
      </div>
      {/* Info */}

      {/* Actions */}
      <div className='d-flex align-items-center py-2'>
        {props.canRemove && (
          <AreYouSurePopup
            modalOpener={
              <button className='btn btn-sm btn-light btn-active-light-primary me-3'>
                <span className='indicator-label'>
                  <Translator name='SIL' />
                </span>
              </button>
            }
            message='KARTI_SILMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'
            onClick={RemoveCard}
          />
        )}
      </div>
      {/* Actions */}
    </div>
  )
}

export default RegisteredCard
