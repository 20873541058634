import {useSelector, useDispatch} from 'react-redux'
import FormikForm from '../../../../../components/form/FormikForm'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'
import {nextStep} from '../../../../../redux/commonSlice'
import {
  getCompanyState,
  setClemtaWizardData,
  setClemtaWizardDataAsync,
} from '../../../../../redux/companySlice'
import SmallTitle from '../../../../../components/common/title/SmallTitle'
import FormInput from '../../../../../components/form/FormInput'
import NormalText from '../../../../../components/common/title/NormalText'
import {getProductState} from '../../../../../redux/productSlice'
import InfoMessage from '../../../../../components/common/title/InfoMessage'
import FormCheckboxInput from '../../../../../components/form/FormCheckboxInput'
import FormPriceInput from '../../../../../components/form/FormPriceInput'
import {CurrencyEnum} from '../../../../../apiClient/enums'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  numberOfShares: Yup.number()
    .required('CLEMTA_NUMBER_OF_SHARES_REQUIRED')
    .positive('CLEMTA_NUMBER_OF_SHARES_REQUIRED'),
  parValue: Yup.number()
    .required('CLEMTA_PAR_VALUE_REQUIRED')
    .positive('CLEMTA_PAR_VALUE_REQUIRED'),
})

function ClemtaCompanyShareStep() {
  const companyState = useSelector(getCompanyState)
  const productState = useSelector(getProductState)
  const dispatch = useDispatch()

  const initValues = {
    numberOfShares: companyState?.clemtaWizardData?.numberOfShares ?? 0,
    parValue: companyState?.clemtaWizardData?.parValue ?? 0,
    defaultCheck: false,
  }

  const submit = async (values: {
    numberOfShares: number
    parValue: number
    defaultCheck: boolean
  }) => {
    await dispatch<any>(
      setClemtaWizardDataAsync({
        ...companyState.clemtaWizardData,
        numberOfShares: values.numberOfShares,
        parValue: values.parValue,
      })
    )

    dispatch(nextStep())
  }

  return (
    <FormikForm initialValues={initValues} onSubmit={submit} validationSchema={schema}>
      {(props) => {
        return (
          <>
            <SmallTitle text='CLEMTA_CREATE_STRUCTURE' />

            <div className='row'>
              <div className='offset-xl-2 col-xl-8'>
                <NormalText text='CLEMTA_CREATE_STRUCTURE_DETAIL' />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormInput
                  istouched={props.touched.numberOfShares ?? false}
                  errorMessage={props.errors.numberOfShares}
                  placeHolderOnTop={true}
                  fieldAttributes={{
                    type: 'number',
                    name: 'numberOfShares',
                    autoComplete: 'off',
                    placeholder: 'CLEMTA_NUMBER_OF_SHARES',
                    disabled: props.values.defaultCheck,
                  }}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormPriceInput
                  currency={CurrencyEnum.Dolar}
                  istouched={props.touched.parValue ?? false}
                  errorMessage={props.errors.parValue}
                  placeHolderOnTop={true}
                  setFieldValue={props.setFieldValue}
                  value={props.values.parValue}
                  fieldAttributes={{
                    name: 'parValue',
                    autoComplete: 'off',
                    placeholder: 'CLEMTA_PAR_VAULES',
                    disabled: props.values.defaultCheck,
                  }}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <InfoMessage message={'CLEMTA_PAR_VALUE_WARNING'} />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormCheckboxInput
                  description='CLEMTA_APPLY_DEFAULT_PAR_VALUES_SETTINGS'
                  name='defaultCheck'
                  setFieldValue={props.setFieldValue}
                  onChange={(value: boolean) => {
                    if (value) {
                      props.setFieldValue('numberOfShares', 10000000)
                      props.setFieldValue('parValue', 0.000001)
                    }
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <WizardStepButtons nextButtonDisabled={!props.isValid} />
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default ClemtaCompanyShareStep
