import {useDispatch, useSelector} from 'react-redux'
import {AdminProductDTO, AdminProductPageDataApiData} from '../../../api/data-contracts'
import {getAdminState, setProductsPageData} from '../../../redux/adminSlice'
import _ from 'lodash'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import filterFactory, {selectFilter, textFilter} from 'react-bootstrap-table2-filter'

import {Api_Admin_GetProductsPage} from '../../../apiClient/apiClient'
import {postRequestAsync} from '../../../redux/commonSlice'
import {useEffect} from 'react'
import {getCurrencySymbol} from '../../../utils/utilities'
import clsx from 'clsx'
import AdminProductDetailPopup from './AdminProductDetailPopup'

function AdminProductsPage() {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  useEffect(() => {
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetProductsPage,
        body: {},
      })
    )
      .unwrap()
      .then(async function (response: AdminProductPageDataApiData) {
        dispatch<any>(setProductsPageData(response.data))
      })
  }, [])

  function filterByActive(filterVal: any) {
    if (filterVal === '1') {
      return adminState?.productsPage?.products?.filter((p: AdminProductDTO) => p.isActive) ?? []
    } else if (filterVal === '0') {
      return adminState?.productsPage?.products?.filter((p: AdminProductDTO) => !p.isActive) ?? []
    }
    return adminState?.productsPage?.products ?? []
  }

  const columns: ColumnDescription[] = [
    {
      dataField: 'isActive',
      text: 'Aktif',
      sort: true,
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '3%'},

      // formatter: cell => adminState.productsPage?.productTypes?.at(cell),
      filter: selectFilter({
        options: [
          {
            label: 'Aktif',
            value: 1,
          },
          {
            label: 'Pasif',
            value: 0,
          },
        ],
        onFilter: filterByActive,
        defaultValue: 1,
      }),
      formatter: (cell: any, m: AdminProductDTO, rowIndex: number, colIndex: number) => (
        <>{m.isActive && <i className='bi bi-check fs-2x text-success text-center'></i>}</>
      ),
    },
    {
      dataField: 'typeId',
      text: 'Tip',
      sort: true,
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},

      // formatter: cell => adminState.productsPage?.productTypes?.at(cell),
      filter: selectFilter({
        options:
          adminState.productsPage?.productTypes?.map((m) => {
            return {
              value: m.id ?? 0,
              label: m.description ?? '',
            }
          }) ?? [],
      }),
      formatter: (cell: any, m: AdminProductDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>{m.productTypeName}</div>
        </>
      ),
    },
    {
      dataField: 'title',
      text: 'Adı',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      filter: textFilter(),
      formatter: (cell: any, m: AdminProductDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>{m.title}</div>
        </>
      ),
    },
    {
      dataField: 'categoryId',
      text: 'Kategori',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      filter: selectFilter({
        options:
          adminState.productsPage?.productCategories?.map((m) => {
            return {
              value: m.id ?? 0,
              label: m.description ?? '',
            }
          }) ?? [],
      }),
      formatter: (cell: any, m: AdminProductDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>{m.category}</div>
        </>
      ),
    },
    {
      dataField: 'description',
      text: 'Açıklama',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      filter: textFilter(),
      formatter: (cell: any, m: AdminProductDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>{m.description}</div>
        </>
      ),
    },
    {
      dataField: 'defaultPrice',
      text: "Varsayılan Fiyat (KDV''siz)",
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: AdminProductDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.prices?.find((f) => !f.financialAdvisorId)?.totalPriceWithoutTax ?? 0}{' '}
            {getCurrencySymbol(m.currencyId ?? 0)}
          </div>
        </>
      ),
    },
    {
      dataField: 'defaultPriceWithoutTax',
      text: "Varsayılan Fiyat (KDV'li)",
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '9%'},
      formatter: (cell: any, m: AdminProductDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.prices?.find((f) => !f.financialAdvisorId)?.totalPrice ?? 0}{' '}
            {getCurrencySymbol(m.currencyId ?? 0)}
          </div>
        </>
      ),
    },
    {
      dataField: '',
      text: 'Detay',
      sort: true,
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: AdminProductDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='d-flex justify-content-center flex-shrink-0'>
            <AdminProductDetailPopup product={m} />
          </div>
        </>
      ),
    },
  ]

  const rowClasses = (row: AdminProductDTO, rowIndex: number): string => {
    return clsx({
      'bg-light-danger': !row.isActive,
    })
  }

  return (
    <>
      <div className={`card card-stretch`}>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {(adminState.productsPage?.products?.length ?? 0) > 0 && (
              <>
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    totalSize: adminState?.productsPage?.products?.length,
                    sizePerPage: 200,
                    paginationSize: 200,
                    // paginationTotalRenderer: (from, to, size) => (
                    //   <span className='react-bootstrap-table-pagination-total ms-5'>
                    //     Toplam {size} talepten {from} - {to} arası gösteriliyor
                    //   </span>
                    // ),
                  })}
                >
                  {({paginationProps, paginationTableProps}) => (
                    <>
                      <div>
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                        <BootstrapTable
                          {...paginationTableProps}
                          rowClasses={rowClasses}
                          keyField='id'
                          filter={filterFactory()}
                          filterPosition='top'
                          data={
                            _.orderBy(
                              adminState.productsPage?.products,
                              (o) => o.productTypeName,
                              'desc'
                            ) ?? []
                          }
                          columns={columns}
                          classes='table table-hover table-row-dashed table-rounded mt-5'
                        />
                      </div>
                    </>
                  )}
                </PaginationProvider>
              </>
            )}
          </div>

          {/* begin::Table container */}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default AdminProductsPage
