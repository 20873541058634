import {Form, Formik, FormikConfig, FormikProps, FormikValues} from 'formik'
import React from 'react'
import FormLogger from './FormLogger'

function FormikForm<Values extends FormikValues = FormikValues, ExtraProps = {}>(
  properities: FormikConfig<Values> & ExtraProps
) {
  return (
    <Formik {...properities} validateOnMount={true} className='form w-100'>
      {(props: FormikProps<Values>) => (
        <Form className='w-100' placeholder={undefined}>
          <FormLogger
            values={props.values}
            touched={props.touched}
            isValid={props.isValid}
            errors={props.errors}
            isSubmitting={props.isSubmitting}
            status={props.status}
          />
          {typeof properities.children === 'function'
            ? properities.children(props)
            : React.Children.only(properities.children)}
        </Form>
      )}
    </Formik>
  )
}

export default FormikForm
