/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useLocation} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../pages/errors/ErrorsPage'
import {App} from '../App'
import {useDispatch, useSelector} from 'react-redux'
import {getUserState, setToken} from '../redux/userSlice'
import {useCookies} from 'react-cookie'
import {apiUrlHost} from '../apiClient/apiClient'
import {getProductState} from '../redux/productSlice'
import {AuthPage} from '../pages/auth/AuthPage'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
//const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const [cookies] = useCookies(['token'])
  const dispatch = useDispatch()

  const userState = useSelector(getUserState)
  const productState = useSelector(getProductState)

  const [isTokenLoaded, setIsTokenLoaded] = useState(false)

  const {REACT_APP_API_URL} = process.env

  apiUrlHost.apiUrl = REACT_APP_API_URL ?? ''
  useEffect(() => {
    dispatch(setToken(cookies.token))
    setIsTokenLoaded(true)
  }, [cookies.token, dispatch])

  const location = useLocation()

  return (
    <Routes>
      <Route element={<App />}>
        <Route path='error/*' element={<ErrorsPage />} />

        <>
          {isTokenLoaded ? (
            <>
              {userState.token ? (
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                </>
              ) : (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to={'/auth' + location.search} />} />
                </>
              )}
            </>
          ) : (
            <>
              <Route path='*' element={<></>} />
            </>
          )}
        </>
      </Route>
    </Routes>
  )
}

export {AppRoutes}
