import {useIntl} from 'react-intl'

function TranslatorText(name: string, params?: any) {
  const intl = useIntl()

  const messageExists = intl.messages[name]

  return name.length > 0
    ? messageExists
      ? intl.formatMessage({id: name}, params)
      : name // Fallback to name if translation doesn't exist
    : null
}

export default TranslatorText
