import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {useSelector} from 'react-redux'
import {getUserState} from '../redux/userSlice'
import {CustomerRoutes} from './CustomerRoutes'
import {AdminRoutes} from './AdminRoutes'

const PrivateRoutes = () => {
  const userState = useSelector(getUserState)

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/' />} />
        {process.env.REACT_APP_ENV?.includes('ADMIN') == false && (
          <>
            <>
              <Route path='/*' element={<CustomerRoutes />} />
            </>
            <Route path='*' element={<Navigate to='/error/404' />} />
          </>
        )}

        {process.env.REACT_APP_ENV?.includes('ADMIN') == true && (
          <>
            <Route path='/*' element={<AdminRoutes />} />

            <Route path='*' element={<Navigate to='/error/404' />} />
          </>
        )}

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
