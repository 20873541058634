import {useEffect} from 'react'
import * as Yup from 'yup'
import {Link, useSearchParams} from 'react-router-dom'
import {FormikValues} from 'formik'
import {Api_User_SetNewPassword} from '../../../apiClient/apiClient'
import {BooleanApiData, SetNewPasswordRequest} from '../../../api/data-contracts'
import {useDispatch} from 'react-redux'
import {setIsLoading, postRequestAsync} from '../../../redux/commonSlice'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import FormikForm from '../../../components/form/FormikForm'
import NormalTitle from '../../../components/common/title/NormalTitle'
import FormInput from '../../../components/form/FormInput'
import SubmitButton from '../../../components/form/button/SubmitButton'
import {FormStatus} from '../../../types/FormStatus'
import FormMessage from '../../../components/common/messages/FormMessage'
import Translator from '../../../components/common/translate/Translator'

const initialValues = {
  password: '',
  changepassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'ŞIFRE_MIN')
    .minLowercase(1, 'ŞIFRE_KÜÇÜKHARF')
    .minUppercase(1, 'ŞIFRE_BÜYÜKHARF')
    .minNumbers(1, 'ŞIFRE_RAKAM')
    .max(50, 'ŞIFRE_MAX')
    .required('ŞIFRE_REQUIRED'),
  changepassword: Yup.string()
    .required('ŞIFRETEKRARI_REQUIRED')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'ŞIFRETEKRARI_BİRLEŞMİYOR'),
    }),
})

export function SetNewPassword() {
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const onSubmit = async (
    values: {
      password: string
      changepassword: string
    },
    actions: FormikValues
  ) => {
    actions.setStatus(null)

    dispatch(setIsLoading(true))
    try {
      const token = searchParams.get('code')
      const body: SetNewPasswordRequest = {
        password: values.password,
        token: token,
      }

      await dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_User_SetNewPassword,
          body: body,
        })
      )
        .unwrap()
        .then((response: BooleanApiData) => {
          if (response?.data == true) {
            actions.setStatus(new FormStatus(true, 'Şifreniz yenilendi. Giriş yapabilirsiniz.'))
          } else {
            actions.setStatus(
              new FormStatus(
                false,
                response?.serverMessage?.length ?? 0 > 0
                  ? response?.serverMessage
                  : 'Bir Sorun Oluştu'
              )
            )
          }
        })
        .catch((error: any) => {})
    } catch (error) {
      console.error(error)
      actions.setStatus(new FormStatus(false, 'Bir Sorun Oluştu'))
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={forgotPasswordSchema}
    >
      {(props) => {
        return (
          <>
            <NormalTitle text='Yeni Şifre Tanımla' />
            <div className='text-center mb-10'>
              <div className='text-gray-500 fw-semibold fs-6'>Yeni şifrenizi girin.</div>
            </div>

            <FormMessage {...props.status} />

            <div className='row mb-5' data-kt-password-meter='true'>
              <div className='mb-1'>
                <div className='position-relative mb-3'>
                  <FormInput
                    istouched={props.touched.password ?? false}
                    errorMessage={props.errors.password}
                    isTransparent={true}
                    fieldAttributes={{
                      type: 'password',
                      name: 'password',
                      autoComplete: 'off',
                      placeholder: 'SIFRE',
                    }}
                  />
                </div>
                <div
                  className='d-flex align-items-center mb-3'
                  data-kt-password-meter-control='highlight'
                >
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
              </div>
              <div className='text-muted'></div>
            </div>

            <div className='row mb-5'>
              <div>
                <FormInput
                  istouched={props.touched.changepassword ?? false}
                  errorMessage={props.errors.changepassword}
                  isTransparent={true}
                  fieldAttributes={{
                    type: 'password',
                    name: 'changepassword',
                    autoComplete: 'off',
                    placeholder: 'SIFRE_ONAY',
                  }}
                />
              </div>
            </div>

            <div className='text-center'>
              <SubmitButton
                className='w-100 mb-5'
                isSubmitting={props.isSubmitting}
                isValid={props.isValid}
                loadingText='LUTFEN_BEKLEYIN'
                notLoadingText='KAYDET'
              />
              <Link to='/auth/login'>
                <button type='button' className='btn btn-lg btn-light-danger w-100 mb-5'>
                  <Translator name='IPTAL' />
                </button>
              </Link>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}
