import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useState} from 'react'
import {
  BooleanApiData,
  EInvoiceProductDTO,
  EInvoiceProductDTOApiData,
  GetProductRequest,
  TryDiscountRequest,
  UpsertEInvoiceProductRequest,
} from '../../../../api/data-contracts'
import {ErrorMessage, Field, FieldArray, Form, Formik, FormikValues} from 'formik'
import * as Yup from 'yup'
import {useDispatch, useSelector} from 'react-redux'
import {getCommonState, postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {
  Api_EInvoice_GetProduct,
  Api_EInvoice_RemoveProduct,
  Api_EInvoice_UpsertProduct,
} from '../../../../apiClient/apiClient'
import {getEInvoiceState, loadEInvoiceCommonDataAsync} from '../../../../redux/eInvoiceSlice'

export enum ForibaProductFormPopupType {
  Insert,
  Update,
  Delete,
}

function ForibaProductFormPopup(props: {type: ForibaProductFormPopupType; productGuidId?: string}) {
  const schema = Yup.object({
    description: Yup.string()
      .min(3, 'Ürün Adı en az 3 karakter olmalıdır.')
      .required('Ürün Adı Zorunludur'),
    unitPrice: Yup.number()
      .typeError('Birim Fiyat sayısal değer olmalıdır.')
      .positive('Birim Fiyat pozitif bir değer olmalıdır.')
      .required('Birim Fiyat Zorunludur'),
    currencyId: Yup.number().positive('Para Birimi zorunludur').required('Para Birimi zorunludur'),
    //taxCategories: Yup.array(),
    unitId: Yup.number().positive('Birim Kodu zorunludur.'),
  })

  const [modalShow, setModalShow] = useState(false)
  const commonState = useSelector(getCommonState)
  const eInvoiceState = useSelector(getEInvoiceState)
  const [product, setProduct] = useState<EInvoiceProductDTO | null | undefined>(null)

  const initData: UpsertEInvoiceProductRequest = {
    guidId: product?.guidId ?? null,
    currencyId: product?.currencyId ?? 1,
    unitPrice: product?.unitPrice ?? 0,
    description: product?.description ?? '',
    unitId: product?.unitId ?? 0,
    taxCategories: product?.taxCategories ?? [
      {
        taxCategoryId: 1,
        taxRatio: 8,
      },
    ],
  }

  const dispatch = useDispatch()

  const submit = async (values: UpsertEInvoiceProductRequest, actions: FormikValues) => {
    dispatch(setIsLoading(true))
    actions.setStatus('')
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_EInvoice_UpsertProduct,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        dispatch(setIsLoading(false))
        if (!response.data) {
          dispatch(actions.setStatus(response.serverMessage))
        } else {
          await dispatch<any>(loadEInvoiceCommonDataAsync())
          handleModalClose()
        }
      })
  }

  const remove = async () => {
    const body: GetProductRequest = {
      guidId: props.productGuidId ?? '',
    }

    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_EInvoice_RemoveProduct,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        dispatch(setIsLoading(false))
        await dispatch<any>(loadEInvoiceCommonDataAsync())
        handleModalClose()
      })
  }

  const handleModalClose = () => setModalShow(false)

  const load = () => {
    if (props.productGuidId) {
      const body: GetProductRequest = {
        guidId: props.productGuidId ?? '',
      }

      dispatch(setIsLoading(true))
      dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_EInvoice_GetProduct,
          body: body,
        })
      )
        .unwrap()
        .then((response: EInvoiceProductDTOApiData) => {
          dispatch(setIsLoading(false))
          if (response.isSuccessful) {
            setProduct(response.data)
            setModalShow(true)
          }
        })
    } else {
      setModalShow(true)
    }
  }

  return (
    <>
      {props.type == ForibaProductFormPopupType.Insert && (
        <a
          href='#'
          onClick={() => {
            load()
          }}
          className='btn btn-sm btn-light-primary'
        >
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          Yeni Ürün
        </a>
      )}

      {props.type == ForibaProductFormPopupType.Update && (
        <a
          href='#'
          onClick={() => {
            load()
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>
      )}

      {props.type == ForibaProductFormPopupType.Delete && (
        <a
          href='#'
          onClick={() => {
            setModalShow(true)
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      )}

      {props.type == ForibaProductFormPopupType.Delete ? (
        <Modal show={modalShow} onHide={handleModalClose} centered id='kt_modal_success'>
          <Modal.Header closeButton>
            <h5 className='modal-title'>Ürünü Sil</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <p>Ürünü Silmek İstediğinizden Emin misiniz?</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-light'
              data-bs-dismiss='modal'
              onClick={handleModalClose}
            >
              İptal
            </button>
            <button
              type='button'
              data-kt-indicator={commonState?.isLoading ? 'on' : ''}
              onClick={remove}
              className='btn btn-primary'
            >
              <span className='indicator-label'>Sil</span>
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal size='xl' show={modalShow} onHide={handleModalClose} centered id='kt_modal_success'>
          <Modal.Header closeButton>
            <h5 className='modal-title'>Ürün Kayıt</h5>
          </Modal.Header>
          <Modal.Body>
            <Formik
              validationSchema={schema}
              initialValues={initData}
              onSubmit={submit}
              validateOnMount={true}
            >
              {({values, errors, setFieldValue, validateForm, status}) => (
                <Form noValidate id='id_form_' placeholder={undefined}>
                  <div className='w-100'>
                    {/* <div>{JSON.stringify(values)}</div>
                    <div>{JSON.stringify(errors)}</div> */}

                    <div className='row'>
                      <div className='d-flex flex-column mb-7 fv-row col-xl-6'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className='required'>Ürün Adı</span>
                        </label>

                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='description'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='description' />
                        </div>
                      </div>

                      <div className='d-flex flex-column mb-7 fv-row col-xl-3'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className='required'>Birim Fiyat</span>
                        </label>

                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='unitPrice'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='unitPrice' />
                        </div>
                      </div>

                      <div className='d-flex flex-column mb-7 fv-row col-xl-3'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className='required'>Para Birimi</span>
                        </label>

                        <Field
                          className='form-select form-select-solid'
                          data-control='select2'
                          name='currencyId'
                          as='select'
                          onChange={function (element: any) {
                            setFieldValue('currencyId', element.target.value)
                          }}
                        >
                          <option value={0} key={'non'}>
                            Para Birimi Seçiniz
                          </option>
                          {eInvoiceState?.eInvoiceCommonData?.currencies?.map((m) => (
                            <option key={m.currencyId} value={m.currencyId ?? 0}>
                              {m.name}
                            </option>
                          ))}
                        </Field>

                        <div className='text-danger mt-2'>
                          <ErrorMessage name='currencyId' />
                        </div>
                      </div>

                      <div className='d-flex flex-column mb-7 fv-row col-xl-6'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className='required'>Vergiler</span>
                        </label>
                        <FieldArray
                          name='taxCategories'
                          render={(arrayHelpers) => (
                            <div>
                              {values.taxCategories && values.taxCategories.length > 0 ? (
                                values.taxCategories.map((tax, index) => (
                                  <div key={index} className='row'>
                                    <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                                      <Field
                                        className='form-select form-select-solid'
                                        data-control='select2'
                                        name={`taxCategories.${index}.taxCategoryId`}
                                        as='select'
                                      >
                                        <option value={0} key={'non'}>
                                          Kategori Seçiniz
                                        </option>
                                        {eInvoiceState?.eInvoiceCommonData?.taxCategories?.map(
                                          (m) => (
                                            <option key={m.id} value={m.id ?? 0}>
                                              {m.name}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                    </div>
                                    <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                                      <Field
                                        type='text'
                                        className='form-control form-control-solid'
                                        placeholder=''
                                        name={`taxCategories.${index}.taxRatio`}
                                      />
                                    </div>

                                    <div className='d-flex flex-column mb-7 fv-row col-xl-2'>
                                      <button
                                        className='btn btn-sm btn-primary me-3'
                                        type='button'
                                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      >
                                        -
                                      </button>
                                    </div>

                                    <div className='d-flex flex-column mb-7 fv-row col-xl-2'>
                                      <button
                                        className='btn btn-sm btn-primary me-3'
                                        type='button'
                                        onClick={() => arrayHelpers.insert(index + 1, '')} // insert an empty string at a position
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <button
                                  type='button'
                                  className='btn btn-sm btn-primary me-3'
                                  onClick={() => arrayHelpers.push('')}
                                >
                                  Kategori Ekle
                                </button>
                              )}
                            </div>
                          )}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='taxCategories' />
                        </div>
                      </div>

                      <div className='d-flex flex-column mb-7 fv-row col-xl-6'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className='required'>Birim kodu</span>
                        </label>

                        <Field
                          className='form-select form-select-solid'
                          data-control='select2'
                          name='unitId'
                          as='select'
                        >
                          <option value={0} key={'non'}>
                            Birim Kodu Seçiniz
                          </option>
                          {eInvoiceState?.eInvoiceCommonData?.units?.map((m) => (
                            <option key={m.id} value={m.id ?? 0}>
                              {m.name}
                            </option>
                          ))}
                        </Field>

                        <div className='text-danger mt-2'>
                          <ErrorMessage name='unitId' />
                        </div>
                      </div>
                    </div>

                    {status?.message && (
                      <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{status?.message}</div>
                      </div>
                    )}

                    <div className='d-flex flex-row-reverse pt-10 align-items-end mb-5'>
                      <div>
                        <button type='submit' className='btn btn-lg btn-primary me-3'>
                          {/* {commonState.isLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Lütfen bekleyiniz...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )} */}

                          <span className='indicator-label'>Kaydet</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default ForibaProductFormPopup
