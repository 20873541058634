import React from 'react'
import {RegisteredCardDTO} from '../../../../api/data-contracts'
import RadioButtonWrapper from '../../../form/radio/RadioButtonWrapper'
import RegisteredCard from '../RegisteredCard'

function CardSelection(props: {
  card: RegisteredCardDTO
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}) {
  return (
    <>
      <RadioButtonWrapper
        fieldAttributes={{
          className: 'btn-check',
          type: 'radio',
          name: 'cardToken',
          value: props.card.cardToken,
          id: 'cardToken' + '_' + props.card.cardToken,
        }}
        setFieldValue={props.setFieldValue}
      />
      <label
        className='btn btn-outline btn-outline-dashed btn-outline-default w-100'
        htmlFor={'cardToken' + '_' + props.card.cardToken}
      >
        <RegisteredCard card={props.card} isSelection={true} canRemove={false} />
      </label>
    </>
  )
}

export default CardSelection
