import {Formik, Field, ErrorMessage, FormikValues, Form} from 'formik'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {postRequestAsync, setIsLoading} from '../../../redux/commonSlice'
import Translator from '../../../components/common/translate/Translator'
import * as Yup from 'yup'
import {BooleanApiData, UpdateUserRequest} from '../../../api/data-contracts'
import {useDispatch, useSelector} from 'react-redux'
import {getUserState} from '../../../redux/userSlice'
import {setLanguage, useLang} from '../../../../_metronic/i18n/Metronici18n'
import clsx from 'clsx'
import {Api_User_Update} from '../../../apiClient/apiClient'
import LanguageSelector from '../../../components/other/LanguageSelector'
import FormikForm from '../../../components/form/FormikForm'
import NormalTitle from '../../../components/common/title/NormalTitle'
import FormInput from '../../../components/form/FormInput'
import FormErrorMessage from '../../../components/common/messages/FormErrorMessage'
import SubmitButton from '../../../components/form/button/SubmitButton'

const ProfilePage = () => {
  const schema = Yup.object({
    firstName: Yup.string().required('ISIM_ZORUNLUDUR').label('ISIM_ZORUNLUDUR'),
    lastName: Yup.string().required('SOYISIM_ZORUNLUDUR').label('SOYISIM_ZORUNLUDUR'),
  })
  const dispatch = useDispatch()

  const userState = useSelector(getUserState)
  const submit = async (values: UpdateUserRequest, actions: FormikValues) => {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_User_Update,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        if (response.isSuccessful == false) {
          dispatch(setIsLoading(false))
          actions.setStatus({message: response.serverMessage})
        }
        dispatch(setIsLoading(false))
      })
  }

  const initData: UpdateUserRequest = {
    firstName: userState.data?.firstName,
    lastName: userState.data?.lastName,
  }

  return (
    <div className='mt-5'>
      <NormalTitle text={'PROFIL_AYARLARI'} />
      <div className='card h-100 mb-5' style={{boxShadow: 'none'}}>
        <FormikForm initialValues={initData} validationSchema={schema} onSubmit={submit}>
          {(props) => {
            return (
              <div className=''>
                <div className='row pb-5 pb-lg-5'>
                  <div className='col-xl-4 col-12 offset-xl-4 align-self-center py-3'>
                    <LanguageSelector />
                  </div>
                </div>

                <div className='row px-5'>
                  <div className='col-xl-4 col-12 offset-xl-4 align-self-center'>
                    <div className='d-flex flex-column mb-7 fv-row'>
                      <FormInput
                        istouched={props.touched.firstName ?? false}
                        errorMessage={props.errors.firstName}
                        placeHolderOnTop={true}
                        fieldAttributes={{
                          type: 'text',
                          name: 'firstName',
                          autoComplete: 'off',
                          placeholder: 'ISIM',
                        }}
                      />
                    </div>
                    <div className='d-flex flex-column mb-7 fv-row'>
                      <FormInput
                        istouched={props.touched.lastName ?? false}
                        errorMessage={props.errors.lastName}
                        placeHolderOnTop={true}
                        fieldAttributes={{
                          type: 'text',
                          name: 'lastName',
                          autoComplete: 'off',
                          placeholder: 'SOYISIM',
                        }}
                      />
                    </div>
                  </div>
                </div>

                <FormErrorMessage message={props.status?.message} />

                <div className='row px-5'>
                  <div className='d-flex flex-stack pt-10 align-items-end mb-5 col-xl-4 col-12 offset-xl-4'>
                    <div className='mr-2'></div>

                    <div>
                      <SubmitButton
                        isSubmitting={props.isSubmitting}
                        isValid={props.isValid}
                        notLoadingText={'KAYDET'}
                        loadingText={'KAYDET'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          }}
        </FormikForm>
      </div>
    </div>
  )
}
export default ProfilePage
