import {HeaderMenuItem} from '../../../../../app/components/layout/header/HeaderMenuItem'
import {useDispatch, useSelector} from 'react-redux'
import {getUserState, setSelectedCompany} from '../../../../../app/redux/userSlice'
import {useNavigate} from 'react-router-dom'
import {postRequestAsync, setStep} from '../../../../../app/redux/commonSlice'
import {
  getCompanyState,
  loadSelectedCompanyInfoAsync,
  startNewCompanyRoute,
} from '../../../../../app/redux/companySlice'
import {SetSelectedCompanyRequest, BooleanApiData} from '../../../../../app/api/data-contracts'
import {Api_Company_SetSelectedCompany} from '../../../../../app/apiClient/apiClient'
import {setPrices} from '../../../../../app/redux/productSlice'

export function MenuInner() {
  const userState = useSelector(getUserState)
  const companyState = useSelector(getCompanyState)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  async function setSelectedCompanyEvent(menuItemId: string) {
    const request: SetSelectedCompanyRequest = {
      companyId: menuItemId,
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Company_SetSelectedCompany,
        body: request,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        if (response.data == true) {
          dispatch(setSelectedCompany(menuItemId))
          await dispatch<any>(loadSelectedCompanyInfoAsync())
        }
      })
  }

  return (
    <>
      {userState.data?.companies?.map((c) => {
        return (
          <HeaderMenuItem
            title={c.fullName ?? ''}
            key={c.guid}
            hasBullet={true}
            isActive={companyState?.Id == c.guid}
            isTerminatedCompany={
              !(c?.isActive ?? false) &&
              c?.isWizardFinished
            }
            onClick={() => {
              setSelectedCompanyEvent(c.guid ?? '')
            }}
          />
        )
      })}
      {userState?.data?.canOpenMultipleCompany === true && (
        <HeaderMenuItem
          title='Yeni Firma +'
          key='new_company'
          isActive={companyState.Id == undefined}
          isTerminatedCompany={undefined}
          onClick={() => {
            dispatch(startNewCompanyRoute())
            dispatch(setStep(1))
            dispatch(setPrices(null))
            navigate('')
          }}
        />
      )}
    </>
  )
}
