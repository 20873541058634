import React from 'react'
import Translator from '../translate/Translator'

function SmallTitle(props: {text: string}) {
  return (
    <div className='text-center mb-5'>
      <h2 className='text-dark fw-bolder mb-3 '>
        <Translator name={props.text} />
      </h2>
    </div>
  )
}

export default SmallTitle
