import React from 'react'
import {useSelector} from 'react-redux'
import {getCommonState} from '../../../redux/commonSlice'
import clsx from 'clsx'
import Translator from '../../common/translate/Translator'

type SubmitButtonProps = {
  isSubmitting: boolean
  isValid: boolean
  notLoadingText: string
  loadingText: string
  mode?: string
  className?: string
}

function SubmitButton(props: SubmitButtonProps) {
  const commonState = useSelector(getCommonState)

  return (
    <button
      type='submit'
      className={clsx(GetClassNames(props.mode), props.className)}
      disabled={props.isSubmitting || !props.isValid}
    >
      {commonState.isLoading ? (
        <span>
          <Translator name={props.loadingText} />
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      ) : (
        <span>
          <Translator name={props.notLoadingText} />
        </span>
      )}
    </button>
  )
}

function GetClassNames(mode?: string) {
  if (mode == 'primary') {
    return 'btn btn-primary'
  } else if (mode == 'danger') {
    return 'btn btn-danger'
  } else {
    return 'btn btn-primary'
  }
}

export default SubmitButton
