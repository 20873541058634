import {FC, useState} from 'react'
import {FormikValues} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {getProductState, loadPricesAsync} from '../../../../../redux/productSlice'
import {getUserState} from '../../../../../redux/userSlice'
import {
  getCommonState,
  nextStep,
  postRequestAsync,
  setIsLoading,
} from '../../../../../redux/commonSlice'
import {getCompanyState} from '../../../../../redux/companySlice'
import {
  AssignPackageRequest,
  BooleanApiData,
  InviteAdvisorUserRequest,
  SearchAdvisorCompanyRequest,
  StringApiData,
  UpdateAdvisorAssignTypeRequest,
} from '../../../../../api/data-contracts'
import {
  Api_Company_AssignAdvisorCompanyByCode,
  Api_Company_AssignPackage,
  Api_Company_SearchAdvisorCompany,
  Api_Company_UpdateAdvisoriAssignType,
  Api_User_InviteAdvisorUser,
} from '../../../../../apiClient/apiClient'
import _ from 'lodash'
import FormikForm from '../../../../../components/form/FormikForm'
import NormalTitle from '../../../../../components/common/title/NormalTitle'
import BigRadioButton from '../../../../../components/form/radio/BigRadioButton'
import FormSuccessMessage from '../../../../../components/common/messages/FormSuccessMessage'
import FormMessage from '../../../../../components/common/messages/FormMessage'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'
import SmallRadioButton from '../../../../../components/form/radio/SmallRadioButton'
import FormInput from '../../../../../components/form/FormInput'
import NormalButton from '../../../../../components/form/button/NormalButton'
import {FormStatus} from '../../../../../types/FormStatus'

export interface AdvisorSelectionData {
  advisorSelection: number
  advisorManuelSelection: number
  advisorNumber: string
  inviteAdvisorEmail: string
}

const AdvisorCompanyAssignmentStep: FC = () => {
  const companyState = useSelector(getCompanyState)
  const userState = useSelector(getUserState)
  const dispatch = useDispatch()

  const initData: AdvisorSelectionData = {
    advisorSelection: companyState?.data?.company?.advisorAssignType == 1 ? 0 : 1,
    advisorManuelSelection: companyState?.data?.company?.advisorAssignType == 2 ? 1 : 2,
    advisorNumber: '',
    inviteAdvisorEmail: '',
  }

  const [isCodeValid, setIsCodeValid] = useState(false)

  const [message, setMessage] = useState(new FormStatus(false))

  const submit = async (values: AdvisorSelectionData, actions: FormikValues) => {
    let result = false
    if (values.advisorSelection == 1 && values.advisorManuelSelection == 1) {
      result = await advisorCodeSubmit(values, actions)
    } else {
      result = await updateAdvisorType(values, actions)
    }

    if (result) {
      await assignPackage()
      dispatch(nextStep())
    }
  }

  const advisorCodeSubmit = async (
    values: AdvisorSelectionData,
    actions: FormikValues
  ): Promise<boolean> => {
    const body: SearchAdvisorCompanyRequest = {
      companyNumber: values.advisorNumber,
    }

    setMessage(new FormStatus(false))

    const result = await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Company_AssignAdvisorCompanyByCode,
        body: body,
      })
    )
      .unwrap()
      .then((response: BooleanApiData) => {
        if (!response.data) {
          setMessage(new FormStatus(false, response.serverMessage))
          return false
        } else {
          return true
        }
      })

    return result
  }

  const updateAdvisorType = async (
    values: AdvisorSelectionData,
    actions: FormikValues
  ): Promise<boolean> => {
    let assignTypeId = 0

    if (values.advisorSelection == 0) {
      assignTypeId = 1
    }

    if (values.advisorSelection == 1 && values.advisorManuelSelection == 2) {
      assignTypeId = 3
    }

    if (values.advisorSelection == 2) {
      assignTypeId = 4
    }

    const body: UpdateAdvisorAssignTypeRequest = {
      assignTypeId: assignTypeId,
    }

    setMessage(new FormStatus(false))

    const result = await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Company_UpdateAdvisoriAssignType,
        body: body,
      })
    )
      .unwrap()
      .then((response: BooleanApiData) => {
        if (!response.data) {
          setMessage(new FormStatus(false, response.serverMessage))
          return false
        } else {
          return true
        }
      })

    return result
  }

  const assignPackage = async (): Promise<boolean> => {
    setMessage(new FormStatus(false))

    var body: AssignPackageRequest = {
      serviceType: 4,
    }

    const result = await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Company_AssignPackage,
        body: body,
      })
    )
      .unwrap()
      .then((response: BooleanApiData) => {
        if (!response.data) {
          setMessage(new FormStatus(false, response.serverMessage))
          return false
        } else {
          dispatch<any>(loadPricesAsync())
          return true
        }
      })

    return result
  }

  async function searchByCode(code: string) {
    const body: SearchAdvisorCompanyRequest = {
      companyNumber: code,
    }
    setMessage(new FormStatus(false))
    setIsCodeValid(false)
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Company_SearchAdvisorCompany,
        body: body,
      })
    )
      .unwrap()
      .then((response: StringApiData) => {
        dispatch(setIsLoading(false))
        if ((response.data?.length ?? 0) > 0) {
          setMessage(
            new FormStatus(
              true,
              'Girdiğiniz kod ' +
                response.data +
                ' mali müşavirlik firmasına aittir, eğer bu firmanın size atanmasını istiyorsanız devam edebilirsiniz.'
            )
          )

          setIsCodeValid(true)
        } else {
          setMessage(
            new FormStatus(false, 'Girdiğiniz koda ait bir mali müşavirlik firması bulunamadı.')
          )
        }
      })
  }

  async function invite(email: string) {
    const body: InviteAdvisorUserRequest = {
      emailAddress: email,
    }
    setMessage(new FormStatus(false))
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_User_InviteAdvisorUser,
        body: body,
      })
    )
      .unwrap()
      .then((response: BooleanApiData) => {
        dispatch(setIsLoading(false))
        if (response.data) {
          setMessage(new FormStatus(true, 'Davetiye Gönderildi.'))
        } else {
          setMessage(new FormStatus(false, response.serverMessage))
        }
      })
  }

  function handleEnabled(values: AdvisorSelectionData) {
    let enabled = false

    if (values.advisorSelection == 0) {
      enabled = true
    } else if (values.advisorSelection == 1) {
      if (values.advisorManuelSelection == 1) {
        enabled = isCodeValid
      } else {
        enabled = userState?.data?.lastInvitationStatus ?? false
      }
    } else if (values.advisorManuelSelection == 2) {
      enabled = true
    }
    return enabled
  }

  return (
    <FormikForm initialValues={initData} onSubmit={submit}>
      {(props) => {
        return (
          <>
            <NormalTitle text='MALI_MUSAVIR_SECIMI' />

            <div className='row mt-5 mb-3'>
              <div className='col-xl-4 col-12 mb-5'>
                <BigRadioButton
                  description={'MALI_MUSAVIR_OTOMATIK_ATANSIN'}
                  details={'MALI_MUSAVIR_OTOMATIK_ATANSIN_DETAY'}
                  value={0}
                  icon={'/media/icons/duotune/coding/cod001.svg'}
                  name={'advisorSelection'}
                  setFieldValue={props.setFieldValue}
                  valueConverter={parseInt}
                />
              </div>

              <div className='col-xl-4 col-12 mb-5'>
                <BigRadioButton
                  description={'MALI_MUSAVIR_OTOMATIK_MANUEL'}
                  details={'MALI_MUSAVIR_OTOMATIK_MANUEL_DETAY'}
                  value={1}
                  icon={'/media/icons/duotune/finance/fin006.svg'}
                  name={'advisorSelection'}
                  setFieldValue={props.setFieldValue}
                  valueConverter={parseInt}
                />
              </div>

              <div className='col-xl-4 col-12 '>
                <BigRadioButton
                  description={'MALI_MUSAVIR_OTOMATIK_PAS'}
                  details={'MALI_MUSAVIR_OTOMATIK_PAS_DETAY'}
                  value={2}
                  icon={'/media/icons/duotune/finance/fin006.svg'}
                  name={'advisorSelection'}
                  setFieldValue={props.setFieldValue}
                  valueConverter={parseInt}
                />
              </div>
            </div>

            {props.values.advisorSelection == 1 && (
              <div className='row w-100 mt-5'>
                <div className='d-flex flex-column mb-7 col-xl-6 offset-xl-3'>
                  <div className='form-check form-check-custom form-check-solid form-check-lg mt-3'>
                    <SmallRadioButton
                      description='MALI_MUSAVIR_KOD_ILE_BULUN'
                      name='advisorManuelSelection'
                      value={1}
                      setFieldValue={props.setFieldValue}
                    />
                  </div>

                  {props.values.advisorManuelSelection == 1 && (
                    <div className='row mt-3'>
                      <div className='col-7'>
                        <FormInput
                          fieldAttributes={{
                            type: 'text',
                            className: 'form-control form-control-solid ms-5',
                            name: 'advisorNumber',
                            placeholder: 'MALI_MUSAVIR_KODU',
                          }}
                          istouched={props.touched.advisorNumber ?? false}
                          errorMessage={props.errors.advisorNumber}
                          placeHolderOnTop={false}
                          checkValid={false}
                        />
                      </div>

                      <NormalButton
                        className='col-4 ms-5'
                        onClick={() => {
                          searchByCode(props.values.advisorNumber)
                        }}
                        text='KOD_SORGULA'
                      />
                    </div>
                  )}

                  <div className='form-check form-check-custom form-check-solid form-check-lg mt-7'>
                    <SmallRadioButton
                      description='MALI_MUSAVIR_DAVET_EDIN'
                      name='advisorManuelSelection'
                      value={2}
                      setFieldValue={props.setFieldValue}
                    />
                  </div>

                  {props.values.advisorManuelSelection == 2 && (
                    <div className='row mt-3'>
                      {userState?.data?.lastInvitationStatus ?? (
                        <>
                          <div className='col-7'>
                            <FormInput
                              fieldAttributes={{
                                type: 'text',
                                className: 'form-control form-control-solid ms-5',
                                name: 'inviteAdvisorEmail',
                                placeholder: 'EMAIL',
                                disabled: userState.data?.lastInvitationStatus != null,
                              }}
                              istouched={props.touched.inviteAdvisorEmail ?? false}
                              errorMessage={props.errors.inviteAdvisorEmail}
                              placeHolderOnTop={false}
                              checkValid={false}
                            />
                          </div>
                          <NormalButton
                            className='col-4 ms-5'
                            onClick={() => {
                              invite(props.values.inviteAdvisorEmail)
                            }}
                            text='GONDER'
                            disabled={
                              userState.data?.lastInvitationStatus != null ||
                              !props.values.inviteAdvisorEmail
                            }
                          />
                        </>
                      )}

                      {userState?.data?.lastInvitationStatus == false && (
                        <FormMessage
                          isSuccessful={false}
                          translatedMessage={'MALI_MUSAVIR_ONAY_BEKLENIYOR'}
                        />
                      )}
                      {userState?.data?.lastInvitationStatus == true && (
                        <FormMessage
                          isSuccessful={true}
                          translatedMessage={'MALI_MUSAVIR_ONAYLANDI'}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {props.values.advisorSelection == 0 && (
              <div className='col-xl-6 offset-xl-3 col-10 offset-1'>
                <FormSuccessMessage translatedMessage={'MALI_MUSAVIR_OTOMATIK_MESAJ'} />
              </div>
            )}

            <div className='col-xl-6 offset-xl-3 col-10 offset-1'>
              <FormMessage {...message} />
            </div>

            <div className='row mt-5 mb-3'>
              <div className='col-xl-8 offset-xl-2 col-12'>
                <WizardStepButtons nextButtonDisabled={!handleEnabled(props.values)} />
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export {AdvisorCompanyAssignmentStep}
