import companyReducer from './companySlice'
import userReducer from './userSlice'
import {AnyAction, combineReducers, configureStore, createAsyncThunk} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useSelector} from 'react-redux'
import commonReducer from './commonSlice'
import productReducer from './productSlice'
import eInvoiceReducer from './eInvoiceSlice'
import adminReducer from './adminSlice'

const appReducer = combineReducers({
  user: userReducer,
  common: commonReducer,
  company: companyReducer,
  product: productReducer,
  einvioce: eInvoiceReducer,
  admin: adminReducer,
})

const reducerProxy = (state: any, action: AnyAction) => {
  if (action.type === 'logout/LOGOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export const store = configureStore({
  reducer: reducerProxy,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.REACT_APP_ENV !== 'PROD',
})

export const logoutThunk = createAsyncThunk('auth/logout', async function (_payload, thunkAPI) {
  thunkAPI.dispatch({type: 'logout/LOGOUT'})
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store;

