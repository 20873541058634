import {useEffect, useRef} from 'react'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import AsideItem from '../../components/wizard/AsideItem'
import {useSelector} from 'react-redux'
import {getCommonState} from '../../redux/commonSlice'
import _ from 'lodash'
import {useMediaQuery} from 'react-responsive'
import {WizardStep} from './models/WizardModels'
import clsx from 'clsx'
import CartDetails from '../../components/purchase/CartDetails'
import {defterdar_log} from '../../utils/utilities'

const BaseWizardPage = (props: {steps: WizardStep[]; customWidth?: string}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const commonState = useSelector(getCommonState)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  function goToStep(index: number) {
    if (index <= (stepper?.current?.totatStepsNumber ?? 0)) {
      stepper.current?.goto(index)
    } else {
      stepper?.current?.goto(1)
    }
  }

  useEffect(() => {
    goToStep(commonState.currentStep)
  }, [commonState.currentStep])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
    goToStep(commonState.currentStep)
  }, [stepperRef, props.steps.length])

  const isMobile = useMediaQuery({query: '(max-width: 1224px)'})

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid mt-10'
      id='kt_create_account_stepper'
    >
      {/* <MediaQuery minWidth={100}> */}
      {/* begin::Aside*/}
      <div
        className='card justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-350px w-xxl-350px me-9'
        style={{display: isMobile ? 'none' : 'flex'}}
      >
        {/* begin::Wrapper*/}
        <div className='card-body py-10' style={{paddingLeft: 0, paddingRight: 0}}>
          {/* begin::Nav*/}
          <div className='stepper-nav px-6 px-lg-10 px-xxl-15'>
            <div>
              {props.steps.map((m, i) => {
                return (
                  <AsideItem
                    Description={m.description}
                    Name={m.title}
                    Number={i + 1}
                    IsCurrent={i + 1 == commonState?.currentStep}
                    key={i}
                  ></AsideItem>
                )
              })}
            </div>
          </div>
          {/* end::Nav*/}

          <CartDetails />
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* begin::Aside*/}
      {/* </MediaQuery> */}

      <div className='d-flex flex-row-fluid flex-center bg-body rounded w-100 h-100'>
        <div
          className={
            'w-100 h-100 d-flex flex-row-fluid flex-center justify-content-center pt-5 row'
          }
        >
          <div className={props.customWidth ?? 'col-10'}>
            {props.steps.map((m, i) => {
              return (
                <div
                  key={i}
                  className={clsx('w-100 h-100', {current: i + 1 == commonState?.currentStep})}
                  data-kt-stepper-element='content'
                >
                  {m.stepPage}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BaseWizardPage
