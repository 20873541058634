import {AdminPaymentProductDetailDTO} from '../../../api/data-contracts'
import _ from 'lodash'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import {defterdar_log} from '../../../utils/utilities'

function AdminPaymentDetailTable(props: {details: AdminPaymentProductDetailDTO[]}) {
  const columns: ColumnDescription[] = []

  const serviceProviders = _.uniq(
    props.details.flatMap((mp) => mp.serviceProviders || []).map((sp) => sp.serviceProviderName)
  )

  defterdar_log(serviceProviders)

  columns.push({
    dataField: 'productName',
    text: 'Ürün',
    sort: true,
    headerStyle: {width: (100 - 25 * serviceProviders.length).toString() + '%'},
    classes: 'fs-8',

    formatter: (
      cell: any,
      row: AdminPaymentProductDetailDTO,
      rowIndex: number,
      colIndex: number
    ) => <>{row.productName} </>,
  })

  serviceProviders.forEach((sp) => {
    columns.push({
      dataField: 'serviceProvider',
      text: sp ?? '',
      sort: true,
      headerStyle: {width: '25%'},
      classes: 'fs-8',
      formatter: (
        cell: any,
        row: AdminPaymentProductDetailDTO,
        rowIndex: number,
        colIndex: number
      ) => (
        <div className=''>
          {_.sumBy(
            row.serviceProviders?.filter((f) => f.serviceProviderName === sp),
            (s) => s.amount ?? 0
          ).toFixed(0)}{' '}
          {'₺'}
        </div>
      ),
    })
  })

  return (
    <>
      <div className='table-responsive'>
        <>
          <div>
            <BootstrapTable
              keyField='productName'
              data={_.orderBy(props.details, (o) => o.productName, 'asc') ?? []}
              columns={columns}
              classes='table table-rounded table-striped border gy-2 gs-7'
              headerClasses='fw-bold fs-8 text-gray-800 border-bottom border-gray-200 bg-primary bg-opacity-50'
            />
          </div>
        </>
      </div>
    </>
  )
}

export default AdminPaymentDetailTable
