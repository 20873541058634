import React from 'react'
import Translator from '../translate/Translator'

function InfoMessage(props: {message: string; params?: any}) {
  return (
    <div className='text-primary fw-bolder mt-2 fs-8'>
      <Translator name={props.message} params={props.params} />
    </div>
  )
}

export default InfoMessage
