import {
  ActiveServiceDTO,
  BooleanApiData,
  PaymentDetailDTOApiData,
} from '../../../api/data-contracts'
import moment from 'moment'
import {getProductState, openPaymentPageAsync} from '../../../redux/productSlice'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {Guid} from 'guid-typescript'
import Translator from '../../common/translate/Translator'
import clsx from 'clsx'
import {getCompanyState} from '../../../redux/companySlice'
import {
  Api_Company_CancelSuspension,
  Api_Payment_CreateSubscriptionCancelPayment,
} from '../../../apiClient/apiClient'
import {postRequestAsync} from '../../../redux/commonSlice'
import {useNavigate} from 'react-router-dom'
import BasePopup, {PopupProvides} from '../../common/popup/BasePopup'
import SuspensionForm from './SuspensionForm'
import {CompanySuspensionStatusEnum} from '../../../apiClient/enums'
import AreYouSurePopup from '../../common/popup/AreYouSurePopup'

function ActiveProducts(props: {services: ActiveServiceDTO[]}) {
  const productState = useSelector(getProductState)
  const companyState = useSelector(getCompanyState)
  const navigate = useNavigate()

  const cancellableSuspensionStatuses = [
    CompanySuspensionStatusEnum.Mali_Müşavir_Değiştirmek_İstiyor,
    CompanySuspensionStatusEnum.Şirketi_Kapatmak_İstiyor___Tasfiye_Başladı,
  ]

  const dispatch = useDispatch()

  function cancelSuspension() {
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Company_CancelSuspension,
        body: {},
      })
    )
      .unwrap()
      .then(async function (response: BooleanApiData) {
        window.location.reload()
      })
  }

  return (
    <table className='table table-rounded table-striped border gy-7 gs-7'>
      <thead>
        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
          <th>
            <Translator name='URUN_TIPI' />
          </th>
          <th>
            <Translator name='URUN' />
          </th>
          <th>
            <Translator name='DONEM_BASLANGIC' />
          </th>
          <th>
            <Translator name='DONEM_BITIS' />
          </th>
          <th>
            {companyState.data?.company?.isActive &&
              !companyState?.data?.company?.suspensionStatusId && (
                <>
                  <BasePopup
                    title='ABONELIK_IPTAL'
                    size='xl'
                    wrapperClassName='w-75'
                    isDisabled={(companyState.data?.company?.totalDept ?? 0) > 0}
                    modalOpener={
                      <button
                        type='submit'
                        className={clsx('btn btn-sm btn-danger')}
                        disabled={(companyState.data?.company?.totalDept ?? 0) > 0}
                      >
                        <span className='indicator-label'>
                          <Translator name='ABONELIK_IPTAL_ET' />
                        </span>
                      </button>
                    }
                  >
                    {(providers: PopupProvides) => {
                      return <SuspensionForm />
                    }}
                  </BasePopup>
                </>
              )}

            {cancellableSuspensionStatuses.includes(
              companyState.data?.company?.suspensionStatusId ?? 0
            ) && (
              <AreYouSurePopup
                message='FESIH_ISLEMINI_IPTAL_EMIN_MISINIZ'
                modalOpener={
                  <button type='submit' className={clsx('btn btn-sm btn-danger')}>
                    <span className='indicator-label'>
                      <Translator name='FESIH_ISLEMI_IPTAL_ET' />
                    </span>
                  </button>
                }
                onClick={() => {
                  cancelSuspension()
                }}
              />
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {props.services.map((m) => {
          return (
            <tr key={Guid.create().toString()}>
              <td>{m.productType}</td>
              <td>{m.productName}</td>
              <td>{moment(m.startDate).format('DD.MM.yyyy')}</td>
              <td>
                {moment(m.finishDate).isValid() ? moment(m.finishDate).format('DD.MM.yyyy') : ''}
              </td>
              <td></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default ActiveProducts
