import {getCompanyState, setClemtaWizardDataAsync} from '../../../../../../redux/companySlice'
import ClemataShareHolderDetail from './ClemataShareHolderDetail'
import BasePopup, {PopupProvides} from '../../../../../../components/common/popup/BasePopup'
import ClemtaShareHolderForm from './ClemtaShareHolderForm'
import {useDispatch, useSelector} from 'react-redux'
import {ClemtaShareHolder} from '../../../../../../api/data-contracts'
import AreYouSurePopup from '../../../../../../components/common/popup/AreYouSurePopup'

function ClemtaShareHolderCard(props: {person: ClemtaShareHolder}) {
  const companyState = useSelector(getCompanyState)
  const dispatch = useDispatch()

  const share =
    ((companyState?.clemtaWizardData?.numberOfShares ?? 0) * (props.person.percentage ?? 0)) / 100

  async function remove() {
    const newList = companyState.clemtaWizardData?.shareholders?.filter(
      (f) => f.index != props.person.index
    )

    await dispatch<any>(
      setClemtaWizardDataAsync({
        ...companyState?.clemtaWizardData,
        shareholders: newList,
      })
    )
  }

  return (
    <>
      <div className=''>
        <div className='card card-dashed  w-100 p-6'>
          <div className='d-flex justify-content-between'>
            <div className='text-center mb-3'>
              <h1 className='text-dark fw-bolder'>
                {props.person.firstName + ' ' + props.person.lastName}
              </h1>
            </div>

            <div className='card-toolbar'>
              <div className='d-flex'>
                <div className='mx-1'>
                  <BasePopup
                    title='CLEMTA_ADD_SHAREHOLDER'
                    size='lg'
                    modalOpener={
                      <>
                        <a href='#' className='btn btn-icon btn-primary'>
                          <i className='fas fa-user-pen fs-4'></i>
                        </a>
                      </>
                    }
                  >
                    {(providers: PopupProvides) => {
                      return (
                        <ClemtaShareHolderForm
                          initValues={props.person}
                          modalClose={providers.handleClose}
                        />
                      )
                    }}
                  </BasePopup>
                </div>

                <div className='mx-1'>
                  <AreYouSurePopup
                    onClick={() => {
                      remove()
                    }}
                    modalOpener={
                      <>
                        <a href='#' className='btn btn-icon btn-danger'>
                          <i className='fas fa-user-xmark fs-4'></i>
                        </a>
                      </>
                    }
                    message={'CLEMTA_SHAREHOLDER_REMOVE_SURE'}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='text-start mb-3'>
            <h1 className='text-gray-700 fs-7'>{props.person.position}</h1>
          </div>
          <div className='text-start mb-3'>
            <h1 className='text-gray-700 fs-7'>
              {new Intl.NumberFormat().format(share ?? 0)} Shares
            </h1>
          </div>

          <ClemataShareHolderDetail
            description='CLEMTA_PERCENTAGE'
            value={'% ' + props.person.percentage ?? 0}
          />
          <div className='separator border-3 my-3 mx-1'></div>
          <ClemataShareHolderDetail description='CLEMTA_EMAIL' value={props.person.email} />
          <div className='separator border-3 my-3 mx-1'></div>
          <ClemataShareHolderDetail description='CLEMTA_PHONE' value={props.person.phoneNumber} />
          <div className='separator border-3 my-3 mx-1'></div>
          <ClemataShareHolderDetail description='CLEMTA_SSN' value={props.person.ssn} />
          <div className='separator border-3 my-3 mx-1'></div>
          <ClemataShareHolderDetail description='CLEMTA_COUNTRY' value={props.person.country} />
          <div className='separator border-3 my-3 mx-1'></div>
          <ClemataShareHolderDetail
            description='CLEMTA_ADDRESS_1'
            value={props.person.addressLineFirst}
            multiline={true}
          />
          <div className='separator border-3 my-3 mx-1'></div>
          <ClemataShareHolderDetail
            description='CLEMTA_ADDRESS_2'
            value={props.person.addressLineSecond}
            multiline={true}
          />
          <div className='separator border-3 my-3 mx-1'></div>

          <ClemataShareHolderDetail
            description='CLEMTA_CITY_DISTRICT'
            value={props.person.cityDistrict}
          />
          <div className='separator border-3 my-3 mx-1'></div>
          <ClemataShareHolderDetail description='CLEMTA_STATE' value={props.person.stateProvince} />
        </div>
      </div>
    </>
  )
}

export default ClemtaShareHolderCard
