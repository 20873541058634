import React, {useEffect} from 'react'
import {ProductAndPaymentInfoDTOApiData} from '../../../api/data-contracts'
import {Api_Product_GetProductAndPaymentInfo} from '../../../apiClient/apiClient'
import {
  getCommonState,
  loadRegisteredCardsAsync,
  postRequestAsync,
} from '../../../redux/commonSlice'
import {setProductAndPaymentInfo} from '../../../redux/productSlice'
import {useDispatch, useSelector} from 'react-redux'
import RegisteredCard from './RegisteredCard'
import AddNewCardItem from './card/AddNewCardItem'

function RegisteredCards() {
  const dispatch = useDispatch()
  const commonState = useSelector(getCommonState)

  useEffect(() => {
    dispatch<any>(loadRegisteredCardsAsync())
  }, [])

  return (
    <div className='w-100 row'>
      {commonState.registeredCards?.map((card) => (
        <div key={card.cardToken} className='col-xl-6 mb-5'>
          <RegisteredCard card={card} canRemove={(commonState.registeredCards?.length ?? 0) > 1} />
        </div>
      ))}

      <div className='col-xl-6 mb-5'>
        <AddNewCardItem />
      </div>
    </div>
  )
}

export default RegisteredCards
