import {useNavigate, useSearchParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useDispatch} from 'react-redux'

function PaymentResultPage() {
  const [searchParams] = useSearchParams()

  const code = searchParams.get('code')
  const initData = JSON.parse(Buffer.from(code ?? '', 'base64').toString())
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return initData.status === true ? (
    <div className='w-100 h-100'>
      <div className='w-100 h-100'>
        <div className='card card-flush h-md-100'>
          <div className='mb-10 mt-10'>
            <div className='fs-2 fw-bold text-gray-800 text-center mb-13'>
              <span className='me-2 text-success'>{initData.message}</span>
            </div>
          </div>

          <div
            className='col-xl-4 offset-xl-4'
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/unitedpalms-1/6.png')})`,
              backgroundPosition: 'top',
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              height: '50vh',
            }}
          ></div>
        </div>
      </div>
    </div>
  ) : (
    <div className='w-100 h-100'>
      <div className='w-100 h-100'>
        <div className='card card-flush h-md-100'>
          <div className='mb-10 mt-10'>
            <div className='fs-2 fw-bold text-gray-800 text-center mb-13'>
              <span className='me-2 text-danger'>Ödeme işleminde bir sorun oluştu.</span>
            </div>
            <div className='fs-2 fw-bold text-gray-800 text-center mb-13'>
              <span className='me-2'>{initData.message}</span>
            </div>
            <div className='fs-2 fw-bold text-gray-800 text-center mb-13'>
              <span className='me-2'>Destek almak için bizi arayabilirsin 0850 460 1050</span>
            </div>

            <div className='text-center'>
              <button
                className='btn btn-lg btn-primary me-3'
                onClick={() => {
                  navigate('/')
                }}
              >
                <span className='indicator-label'>
                  <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-3' />
                  Ana Sayfaya Dön
                </span>
              </button>
            </div>
          </div>

          <div
            className='col-xl-4 offset-xl-4'
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/unitedpalms-1/5.png')})`,
              backgroundPosition: 'top',
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              height: '50vh',
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default PaymentResultPage
