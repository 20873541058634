import {Field, FieldAttributes} from 'formik'
import React from 'react'
import TranslatorText from '../../common/translate/TranslatorText'
import Translator from '../../common/translate/Translator'
import FormInputErrorMessage from '../../common/messages/FormInputErrorMessage'

export class DropdownOption {
  constructor(value: any, label: string) {
    this.value = value
    this.label = label
  }
  value: any
  label: string
}

function CustomDropdown(props: {
  options: DropdownOption[]
  noSelectionText: string
  noSelectionValue?: number
  fieldAttributes: FieldAttributes<any>
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
  valueConverter?: (value: string) => void
  placeHolderOnTop?: boolean
}) {
  return (
    <>
      {props.placeHolderOnTop && (
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>
            <Translator name={props.fieldAttributes.placeholder} />
          </span>
        </label>
      )}
      <Field
        {...props.fieldAttributes}
        className='form-select form-select-solid'
        data-control='select2'
        as='select'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setFieldValue?.(
            props.fieldAttributes.name,
            props.valueConverter ? props.valueConverter(event.target.value) : event.target.value
          )

          props.fieldAttributes.onChange?.(event)
        }}
        placeholder={
          props.placeHolderOnTop ? null : TranslatorText(props.fieldAttributes.placeholder)
        }
      >
        <option value={props.noSelectionValue} key={props.noSelectionValue}>
          <Translator name={props.noSelectionText} />
        </option>
        {props.options?.map((m) => (
          <option key={m.value} value={m.value}>
            {m.label}
          </option>
        ))}
      </Field>
      <FormInputErrorMessage name={props.fieldAttributes.name} />
    </>
  )
}

export default CustomDropdown
