import {LoginResponseApiData, UserDTO} from './../api/data-contracts'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'

export interface UserState {
  isDataLoaded: boolean
  isTokenLoaded: boolean
  token: string | null | undefined
  data: UserDTO | undefined
  registerStatus: boolean
  emailFromRegisterForm: string | null | undefined
}

const initialState: UserState = {
  isDataLoaded: false,
  isTokenLoaded: false,
  token: '',
  data: undefined,
  registerStatus: false,
  emailFromRegisterForm: '',
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    handleLoginResponse: (state, action: PayloadAction<LoginResponseApiData>) => {
      state.token = action.payload?.data?.token
    },
    setemailFromRegisterForm: (state, action: PayloadAction<string | null | undefined>) => {
      state.emailFromRegisterForm = action.payload
    },
    setToken: (state, action: PayloadAction<string | null | undefined>) => {
      state.token = action.payload
      state.isTokenLoaded = true
    },
    setUserDto: (state, action: PayloadAction<UserDTO | undefined>) => {
      state.data = action.payload
      state.isDataLoaded = true
    },
    setRegisterStatus: (state, action: PayloadAction<boolean>) => {
      state.registerStatus = action.payload
    },
    setSelectedCompany: (state, action: PayloadAction<string | undefined>) => {
      if (state.data) {
        state.data.selectedCompanyId = action.payload
      }
    },
  },
})

export const {
  handleLoginResponse,
  setToken,
  setUserDto,
  setSelectedCompany,
  setRegisterStatus,
  setemailFromRegisterForm,
} = userSlice.actions
export default userSlice.reducer

export const getUserState = (state: RootState) => state.user
