import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {
  EinvoicesResponseDTO,
  EinvoicesResponseDTOApiData,
  GetPrintedInvoicesRequest,
  PrintedInvoiceDTO,
} from '../../../../api/data-contracts'
import {Api_Admin_GetPrintedInvoicesRequest} from '../../../../apiClient/apiClient'
import {KTSVG} from '../../../../../_metronic/helpers'
import _ from 'lodash'
import {getEInvoiceState, setPrintedInvoices} from '../../../../redux/eInvoiceSlice'
import moment from 'moment'
import BootstrapTable, {
  ColumnDescription,
  TableChangeState,
  TableChangeType,
} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'
import {number} from 'card-validator'
import {defterdar_log} from '../../../../utils/utilities'

function PaymentInvoicesPage() {
  const dispatch = useDispatch()
  const eInvoiceState = useSelector(getEInvoiceState)

  const [totalSize, setTotalSize] = useState(0)

  function loadInvoices(body: GetPrintedInvoicesRequest) {
    dispatch(setIsLoading(true))
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetPrintedInvoicesRequest,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: EinvoicesResponseDTOApiData) {
        dispatch<any>(setPrintedInvoices(response?.data?.invoices))
        setTotalSize(response?.data?.totalSize ?? 0)
        dispatch(setIsLoading(false))
      })
  }

  useEffect(() => {
    loadInvoices(init)
  }, [])

  const init: GetPrintedInvoicesRequest = {
    searchCriteria: '',
    pageIndex: 0,
    pageSize: 50,
  }

  function onTableChange(type: TableChangeType, newState: TableChangeState<PrintedInvoiceDTO>) {
    loadInvoices({
      pageIndex: newState.page - 1,
      pageSize: newState.sizePerPage,
    })
  }

  const columns: ColumnDescription[] = [
    {
      dataField: 'invoiceNumber',
      text: 'Fatura No',
      sort: true,
      headerClasses: '',
      classes: '',
      width: 500,
      headerStyle: {width: '3%'},
      formatter: (cell: any, m: PrintedInvoiceDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-7'>{m.invoiceNumber}</div>
        </>
      ),
    },
    {
      dataField: 'printDate',
      text: 'Tarih',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PrintedInvoiceDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-7'>
            {moment(m.printDate).format('DD.MM.yyyy HH:mm')}
          </div>
        </>
      ),
    },
    {
      dataField: 'isUbl',
      text: 'E-Fatura Mı',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PrintedInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>
          {m.isUbl === true ? (
            <KTSVG
              path='/media/icons/duotune/general/gen043.svg'
              className='svg-icon svg-icon-2hx'
            />
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      dataField: 'einvoiceStateDescription',
      text: 'Durum',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PrintedInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>{m.einvoiceStateDescription}</div>
      ),
    },
    {
      dataField: 'customerFullName',
      text: 'Müşteri',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PrintedInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>{m.customerFullName}</div>
      ),
    },
    {
      dataField: 'customerEmailAddress',
      text: 'Email',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '7%'},
      formatter: (cell: any, m: PrintedInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'> {m.customerEmailAddress}</div>
      ),
    },
    {
      dataField: 'customerPhoneNumber',
      text: 'Telefon',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '7%'},
      formatter: (cell: any, m: PrintedInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'> {m.customerPhoneNumber}</div>
      ),
    },
    {
      dataField: 'totalAmount',
      text: 'Tutar',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '7%'},
      formatter: (cell: any, m: PrintedInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'> {m.totalAmount}</div>
      ),
    },
    {
      dataField: 'uuid',
      text: 'PDF',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '7%'},
      formatter: (cell: any, m: PrintedInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>
          {(m.documentLink?.length ?? 0 > 0) && (
            <a href={m.documentLink ?? ''} target='_blank'>
              <KTSVG
                path='/media/icons/duotune/files/fil003.svg'
                className='svg-icon-muted svg-icon-2hx'
              />
            </a>
          )}
        </div>
      ),
    },
  ]

  return (
    <>
      <div className={`card h-100`}>
        {/* begin::Header */}

        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive mh-650px'>
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                totalSize: totalSize,
                sizePerPage: init.pageSize,
                paginationSize: 1,
                alwaysShowAllBtns: false,
                hideSizePerPage: true,
                paginationTotalRenderer: (from, to, size) => (
                  <span className='react-bootstrap-table-pagination-total ms-5'>
                    Toplam {size} faturadan {from} - {to} arası gösteriliyor
                  </span>
                ),
              })}
            >
              {({paginationProps, paginationTableProps}) => (
                <>
                  <div>
                    <SizePerPageDropdownStandalone {...paginationProps} />
                    <PaginationTotalStandalone {...paginationProps} />
                    <PaginationListStandalone {...paginationProps} />
                    <BootstrapTable
                      {...paginationTableProps}
                      keyField='paymentId'
                      data={_.orderBy(eInvoiceState.printedInvoices, 'dueDate', 'asc') ?? []}
                      columns={columns}
                      remote={{
                        pagination: true,
                      }}
                      onTableChange={onTableChange}
                      classes='table table-hover table-row-dashed table-rounded mt-5'
                    />
                  </div>
                </>
              )}
            </PaginationProvider>
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default PaymentInvoicesPage
