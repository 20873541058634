import FormikForm from '../../../../../components/form/FormikForm'
import {FormikValues} from 'formik'
import {CompanyDTOApiData, UpsertCompanyRequest} from '../../../../../api/data-contracts'
import BigRadioButton from '../../../../../components/form/radio/BigRadioButton'
import {getProductState} from '../../../../../redux/productSlice'
import {useDispatch, useSelector} from 'react-redux'
import CompanyTypeRadioButton from '../../../../../components/wizard/CompanyTypeRadioButton'
import {Api_Company_UpsertCompany} from '../../../../../apiClient/apiClient'
import FormInput from '../../../../../components/form/FormInput'
import SmallTitle from '../../../../../components/common/title/SmallTitle'
import * as Yup from 'yup'
import {CompanyTypeEnum} from '../../../../../apiClient/enums'
import {getUserState} from '../../../../../redux/userSlice'
import {setIsLoading, postRequestAsync, previousStep} from '../../../../../redux/commonSlice'
import {getCompanyState} from '../../../../../redux/companySlice'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'
import WarningMessage from '../../../../../components/common/title/WarningMessage'
import {KTSVG} from '../../../../../../_metronic/helpers'
import Translator from '../../../../../components/common/translate/Translator'
import {useNavigate} from 'react-router-dom'

const schema = Yup.object().shape({
  shortName: Yup.string().required('SIRKET_KISA_ADI_ZORUNLUDUR').nullable(),
  fullName: Yup.string().required('SIRKET_TAM_ADI_ZORUNLUDUR').nullable(),
})

function IsPersonalCompany(companyTypeId: number | null | undefined): boolean {
  return (
    companyTypeId == (CompanyTypeEnum.Şahıs_Şirketi as number) ||
    companyTypeId == (CompanyTypeEnum.Serbest_Meslek as number)
  )
}

function CompanyStep() {
  const productState = useSelector(getProductState)
  const userState = useSelector(getUserState)
  const companyState = useSelector(getCompanyState)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initValues: UpsertCompanyRequest = {
    isNewCompany: companyState?.data?.company?.isNewCompany ?? false,
    typeId: companyState?.data?.company?.typeId ?? 1,
    fullName:
      companyState?.data?.company?.fullName ??
      userState.data?.firstName + ' ' + userState.data?.lastName,
    shortName:
      companyState?.data?.company?.shortName ??
      userState.data?.firstName + ' ' + userState.data?.lastName,
    companyId: companyState?.data?.company?.guid,
  }

  const submit = async (values: UpsertCompanyRequest, actions: FormikValues) => {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Company_UpsertCompany,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: CompanyDTOApiData) => {
        if (response.isSuccessful == true) {
          window.location.reload()
        } else {
          dispatch(setIsLoading(false))
          actions.setStatus({message: response.serverMessage})
        }
      })
  }

  return (
    <FormikForm initialValues={initValues} validationSchema={schema} onSubmit={submit}>
      {(props) => {
        return (
          <>
            <div className='row mb-5'>
              <div className='offset-xl-2 col-xl-4 col-12 mb-5'>
                <BigRadioButton
                  description={'YENI_SIRKET'}
                  details={'YENI_SIRKET_KURUYORUM'}
                  value={false}
                  icon={'/media/icons/duotune/files/fil005.svg'}
                  name={'isNewCompany'}
                  setFieldValue={props.setFieldValue}
                  valueConverter={(value: string) => {
                    return value === 'true'
                  }}
                />
              </div>

              <div className='col-xl-4 col-12'>
                <BigRadioButton
                  description={'KURULU_SIRKET'}
                  details={'KURULU_SIRKET_VAR'}
                  value={true}
                  icon={'/media/icons/duotune/finance/fin006.svg'}
                  name={'isNewCompany'}
                  setFieldValue={props.setFieldValue}
                  valueConverter={(value: string) => {
                    return value === 'true'
                  }}
                />
              </div>
            </div>

            <SmallTitle text='SIRKET_TIPI' />
            {productState.turkeyWizardData?.companyTypes?.map((m) => {
              return (
                <div className='row' key={m.id}>
                  <div className='offset-xl-4 col-xl-4 col-12'>
                    <CompanyTypeRadioButton
                      key={m.id}
                      companyType={m}
                      setFieldValue={props.setFieldValue}
                      onChange={(event: any) => {
                        if (IsPersonalCompany(event.target.value)) {
                          props.setFieldValue(
                            'shortName',
                            userState.data?.firstName + ' ' + userState.data?.lastName
                          )
                          props.setFieldValue(
                            'fullName',
                            userState.data?.firstName + ' ' + userState.data?.lastName
                          )
                        }
                      }}
                    />
                  </div>
                </div>
              )
            })}
            {IsPersonalCompany(props.values.typeId) && (
              <div className='row mt-2 mb-2'>
                <div className='offset-xl-2 col-xl-8 col-12'>
                  <WarningMessage message='SIRKET_ADI_BIREYSEL_UYARI' />
                </div>
              </div>
            )}
            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormInput
                  istouched={props.touched.shortName ?? false}
                  errorMessage={props.errors.shortName}
                  placeHolderOnTop={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'shortName',
                    autoComplete: 'off',
                    placeholder: 'SIRKET_KISA_ADI',
                    disabled: IsPersonalCompany(props.values.typeId),
                  }}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='offset-xl-2 col-xl-8 col-12'>
                <FormInput
                  istouched={props.touched.fullName ?? false}
                  errorMessage={props.errors.fullName}
                  placeHolderOnTop={true}
                  fieldAttributes={{
                    type: 'text',
                    name: 'fullName',
                    autoComplete: 'off',
                    placeholder: 'SIRKET_TAM_ADI',
                    disabled: IsPersonalCompany(props.values.typeId),
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='offset-xl-2 col-xl-3 col-3'>
                <button
                  type='button'
                  className='btn btn-lg btn-light-primary mt-10'
                  onClick={() => {
                    navigate('/wizard-country')
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr063.svg'
                    className='svg-icon-4 me-1'
                  />
                  <Translator name='ULKE_SECIM' />
                </button>
              </div>
              <div className='col-xl-5 col-10'>
                <WizardStepButtons />
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default CompanyStep
