import React from 'react'
import {CardData} from '../../../../api/data-contracts'
import DashboardCardItem from './DashboardCardItem'
import _ from 'lodash'

function DashboardCard(props: {cardData: CardData | undefined}) {
  return (
    <div className='col-xl-3'>
      <div className='card card-xl-stretch mb-xl-8'>
        <div className='card-body p-0'>
          <div className={'px-9 pt-7 card-rounded h-150px w-100 ' + props.cardData?.color}>
            <div className='d-flex flex-stack'>
              <h3 className='m-0 text-white fw-bold fs-3'>{props.cardData?.title}</h3>
            </div>

            <div className='d-flex text-center flex-column text-white pt-3'>
              <span className='fw-bold fs-2x pt-1'>{props.cardData?.description}</span>
              <span className='fw-semibold fs-7'>{props.cardData?.explanation}</span>
            </div>
          </div>

          <div
            className='bg-body shadow-sm card-rounded mx-9 mb-6 px-6 pt-4 position-relative z-index-1 h-200px  hover-scroll-overlay-y'
            style={{marginTop: -20}}
          >
            {_.orderBy(props.cardData?.distributions, (o) => o.value, 'desc')?.map((m, i) => {
              return (
                <DashboardCardItem
                  data={m}
                  index={i + 1}
                  key={i}
                  isLast={props.cardData?.distributions?.length == i + 1}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardCard
