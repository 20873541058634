import {FC} from 'react'
import clsx from 'clsx'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  onClick?: () => void
  isActive: boolean
  isTerminatedCompany: boolean | null | undefined
}

const HeaderMenuItem: FC<Props> = ({
  title,
  icon,
  fontIcon,
  onClick,
  hasArrow = false,
  hasBullet = false,
  isTerminatedCompany,
  isActive,
}) => {
  return (
    <div className='menu-item me-lg-1' onClick={onClick}>
      <div
        className={clsx('menu-link py-3', {
          'active menu-here': isActive,
        })}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}

        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('fa-solid fs-3', fontIcon)}></i>
          </span>
        )}

        {isTerminatedCompany && (
          <span className='menu-icon'>
            <i className={clsx('fa-solid fs-3 fa-handshake-slash text-danger', fontIcon)}></i>
          </span>
        )}

        <span className={clsx('menu-title', {'text-danger': isTerminatedCompany})}>{title}</span>

        {hasArrow && <span className='menu-arrow'></span>}
      </div>
    </div>
  )
}

export {HeaderMenuItem}
