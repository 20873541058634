/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {FormikValues} from 'formik'
import * as Yup from 'yup'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useDispatch, useSelector} from 'react-redux'
import {getUserState, setRegisterStatus} from '../../../redux/userSlice'
import {
  BooleanApiData,
  RegisterInvitedAdvisorUserRequest,
  SmsValidationRequest,
} from '../../../api/data-contracts'

import {
  getCommonState,
  postRequestAsync,
  setIsLoading,
  setIsSmsVerificationSendSuccess,
} from '../../../redux/commonSlice'
import {
  Api_User_RegisterInvitedAdvisorUser,
  Api_User_SendSmsVerificationCode,
} from '../../../apiClient/apiClient'
import 'yup-phone'
import YupPassword from 'yup-password'
import FormikForm from '../../../components/form/FormikForm'
import NormalTitle from '../../../components/common/title/NormalTitle'
import FormInput from '../../../components/form/FormInput'
import PhoneNumberInput from '../../../components/form/PhoneNumberInput'
import SubmitButton from '../../../components/form/button/SubmitButton'
import CountdownTimer from '../../../components/other/CountdownTimer'
import FormMessage from '../../../components/common/messages/FormMessage'
import {FormStatus} from '../../../types/FormStatus'

YupPassword(Yup)

const initialValues = {
  firstname: '',
  lastname: '',
  companyName: '',
  phoneNumber: '',
  password: '',
  changepassword: '',
  smsCode: '',
  isSmsCodeValid: false,
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'En az 3 karakter olmalı')
    .max(50, 'En fazla 50 karakter olabilir')
    .required('İsim girilmesi zorunludur'),
  companyName: Yup.string()
    .min(3, 'En az 3 karakter olmalı')
    .max(50, 'En fazla 50 karakter olabilir')
    .required('Firma Adı girilmesi zorunludur'),
  phoneNumber: Yup.string()
    .phone('TR', false, 'Geçersiz telefon numarası formatı.')
    .required('Telefon numarası girilmesi zorunludur.'),
  smsCode: Yup.string().required('Sms Kodu girilmesi zorunludur.'),
  lastname: Yup.string()
    .min(3, 'En az 3 karakter olmalı')
    .max(50, 'En fazla 50 karakter olabilir')
    .required('Soyisim girilmesi zorunludur'),
  password: Yup.string()
    //.password()
    .min(6, 'En az 6 karakter olmalı')
    .minLowercase(1, 'Şifre en az bir küçük harf içermelidir.')
    .minUppercase(1, 'Şifre en az bir büyük harf içermelidir.')
    .minNumbers(1, 'Şifre en az bir rakam içermelidir.')
    .max(50, 'En fazla 50 karakter olabilir')
    .required('Şifre girilmesi zorunludur'),
  changepassword: Yup.string()
    .required('Şifre onayı girilmesi zorunludur.')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Şifre alanları eşleşmiyor.'),
    }),
  acceptTerms: Yup.bool().required('Şartları ve koşulları kabul etmelisiniz'),
})

export function AdvisorInviteRegister() {
  const dispatch = useDispatch()
  const userState = useSelector(getUserState)
  const navigate = useNavigate()
  const commonState = useSelector(getCommonState)
  const [searchParams, setSearchParams] = useSearchParams()

  const onSubmit = async (
    values: {
      companyName: any
      password: any
      firstname: any
      lastname: any
      phoneNumber: string
      smsCode: any
    },
    actions: FormikValues
  ) => {
    dispatch(setIsLoading(true))
    try {
      const token = searchParams.get('code')
      const body: RegisterInvitedAdvisorUserRequest = {
        companyName: values.companyName,
        password: values.password,
        guidId: token ?? '',
        firstName: values.firstname,
        lastName: values.lastname,
        phoneNumber: values.phoneNumber.replaceAll(' ', ''),
        smsCode: values.smsCode,
      }

      await dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_User_RegisterInvitedAdvisorUser,
          body: body,
        })
      )
        .unwrap()
        .then((response: BooleanApiData) => {
          dispatch<any>(setRegisterStatus(response.data ?? false))
          if (response.data) {
            //dispatch<any>(setemailFromRegisterForm(body.emailAddress))
          } else {
            actions.setStatus(new FormStatus(false, response.serverMessage))
          }
        })

      dispatch(setIsLoading(false))
    } catch (error) {
      actions.setSubmitting(false)
      dispatch(setIsLoading(false))
    }
  }

  useEffect(() => {
    dispatch(setIsSmsVerificationSendSuccess(false))
  }, [])

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  useEffect(() => {
    if (userState.registerStatus) {
      navigate('/auth')
    }
  }, [userState.registerStatus])

  async function SendSms(phoneNumber: string, setStatus: any) {
    setStatus('')
    const body: SmsValidationRequest = {
      phoneNumber: phoneNumber.replaceAll(' ', ''),
    }
    dispatch(setIsSmsVerificationSendSuccess(false))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_User_SendSmsVerificationCode,
        body: body,
      })
    )
      .unwrap()
      .then((response: BooleanApiData) => {
        dispatch(setIsSmsVerificationSendSuccess(response.data ?? false))
        setStatus(response.serverMessage)
      })
  }

  return (
    <>
      <FormikForm
        initialValues={initialValues}
        validationSchema={registrationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <>
            <NormalTitle text='Kayıt Ol' />

            <FormMessage {...props.status} />

            <div className='row'>
              <div className='d-flex flex-column mb-7 fv-row col-xl-6'>
                <FormInput
                  istouched={props.touched.firstname ?? false}
                  errorMessage={props.errors.firstname}
                  fieldAttributes={{
                    type: 'text',
                    name: 'firstname',
                    autoComplete: 'off',
                    placeholder: 'İsim',
                  }}
                />
              </div>
              <div className='d-flex flex-column mb-7 fv-row col-xl-6'>
                <FormInput
                  istouched={props.touched.lastname ?? false}
                  errorMessage={props.errors.lastname}
                  fieldAttributes={{
                    type: 'text',
                    name: 'lastname',
                    autoComplete: 'off',
                    placeholder: 'Soyisim',
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='d-flex flex-column mb-7 fv-row col-xl-12'>
                <FormInput
                  istouched={props.touched.companyName ?? false}
                  errorMessage={props.errors.companyName}
                  fieldAttributes={{
                    type: 'text',
                    name: 'companyName',
                    autoComplete: 'off',
                    placeholder: 'Firma Adı',
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='d-flex flex-column mb-7 col-xl-6'>
                <PhoneNumberInput
                  istouched={props.touched.phoneNumber ?? false}
                  errorMessage={props.errors.phoneNumber}
                  setFieldValue={props.setFieldValue}
                  fieldAttributes={{
                    type: 'text',
                    name: 'phoneNumber',
                    autoComplete: 'off',
                    placeholder: '___ ___ __ __',
                  }}
                />
              </div>
              {commonState.isSmsVerificationSendSuccess ? (
                <div className='row mb-7 col-xl-6'>
                  <div className='col-xl-9'>
                    <FormInput
                      istouched={props.touched.smsCode ?? false}
                      errorMessage={props.errors.smsCode}
                      fieldAttributes={{
                        type: 'text',
                        name: 'smsCode',
                        autoComplete: 'off',
                        placeholder: 'Kod',
                      }}
                    />
                  </div>
                  <div className='col-xl-3'>
                    <a href='#' className='btn btn-primary btn-icon'>
                      <CountdownTimer
                        seconds={299}
                        completedEvent={() => {
                          dispatch(setIsSmsVerificationSendSuccess(false))
                        }}
                      >
                        <i className='bi bi-arrow-clockwise'></i>
                      </CountdownTimer>
                    </a>
                  </div>
                </div>
              ) : (
                <div className='d-flex flex-column mb-7 col-xl-6'>
                  <a
                    href='#'
                    onClick={() => {
                      SendSms(props.values.phoneNumber ?? '', props.setStatus)
                    }}
                    className='btn btn-primary'
                  >
                    Sms Kodu İle Onayla
                  </a>
                </div>
              )}
            </div>

            <div className='row mb-5' data-kt-password-meter='true'>
              <div className='mb-1'>
                <div className='position-relative mb-3'>
                  <FormInput
                    istouched={props.touched.password ?? false}
                    errorMessage={props.errors.password}
                    fieldAttributes={{
                      type: 'password',
                      name: 'password',
                      autoComplete: 'off',
                      placeholder: 'Şifre',
                    }}
                  />
                </div>
                <div
                  className='d-flex align-items-center mb-3'
                  data-kt-password-meter-control='highlight'
                >
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
              </div>
              <div className='text-muted'></div>
            </div>

            <div className='row mb-5'>
              <div>
                <FormInput
                  istouched={props.touched.changepassword ?? false}
                  errorMessage={props.errors.changepassword}
                  fieldAttributes={{
                    type: 'password',
                    name: 'changepassword',
                    autoComplete: 'off',
                    placeholder: 'Şifre Onay',
                  }}
                />
              </div>
            </div>

            <div className='fv-row mb-8'>
              <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
                <input className='form-check-input' type='checkbox' name='acceptTerms' />
                <span>
                  <a href='https://defterdar.com/sss' target='_blank' className='ms-1 link-primary'>
                    Şartları ve Koşulları
                  </a>{' '}
                  kabul ediyorum.
                </span>
              </label>
              {props.touched.acceptTerms && props.errors.acceptTerms && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{props.errors.acceptTerms}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='text-center'>
              <SubmitButton
                className='w-100 mb-5'
                isSubmitting={props.isSubmitting}
                isValid={props.isValid}
                loadingText='Lütfen Bekleyiniz...'
                notLoadingText='Kayıt Ol'
              />
              <Link to='/auth/login'>
                <button type='button' className='btn btn-lg btn-light-danger w-100 mb-5'>
                  İptal
                </button>
              </Link>
            </div>
          </>
        )}
      </FormikForm>
    </>
  )
}
