import React from 'react'

function CompanyDetailFormDataLabel(props: {text: string}) {
  return (
    <div className='col-lg-4 d-flex align-items-center'>
      <span className='fw-bolder fs-6 text-dark'>{props.text}</span>
    </div>
  )
}

export default CompanyDetailFormDataLabel
