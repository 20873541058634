import React from 'react'
import MediumRadioButton from '../form/radio/MediumRadioButton'
import {CompanyTypeDTO, IdDescriptionDTO} from '../../api/data-contracts'
import {CompanyTypeEnum} from '../../apiClient/enums'
import {useSelector} from 'react-redux'
import {getProductState} from '../../redux/productSlice'

function CompanyTypeRadioButton(props: {
  companyType: IdDescriptionDTO
  setFieldValue?: any
  onChange?: any
}) {
  const productState = useSelector(getProductState)

  const logoUrl =
    '/media/defterdar/companyType/companyType_' + props.companyType?.id?.toString() + '.png'

  return (
    <MediumRadioButton
      icon={logoUrl}
      description={props.companyType.description ?? ''}
      details={''}
      name={'typeId'}
      value={props.companyType.id ?? 0}
      setFieldValue={props.setFieldValue}
      onChange={props.onChange}
    />
  )
}

export default CompanyTypeRadioButton
