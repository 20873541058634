import './table_fixed_header.css'
import _ from 'lodash'
import moment from 'moment'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import clsx from 'clsx'
import {KTSVG} from '../../../../_metronic/helpers'
import {AdminPanelCompanyDTO} from '../../../api/data-contracts'
import CompanyStateProgressBar from './CompanyStateProgressBar'
import ExportCSV from '../common/ExportCSV'
import {getUserState} from '../../../redux/userSlice'
import {useSelector} from 'react-redux'
import {
  CompanySuspensionStatusEnum,
  UserTypeAuthorizationsEnum,
  UserTypeEnum,
} from '../../../apiClient/enums'
import TextWithClipBoard from '../../other/TextWithClipBoard'
import {Link} from 'react-router-dom'
import AuthorizationPage from '../../../pages/admin/Settings/AuthorizationPage'
import AuthorizedComponent from '../../common/auth/AuthorizedComponent'

function CompaniesTable(props: {companies: AdminPanelCompanyDTO[]}) {
  const role = useSelector(getUserState).data?.roleId as UserTypeEnum

  const columns: ColumnDescription[] = [
    {
      dataField: 'shortName',
      text: 'Firma Bilgileri',
      sort: true,
      headerStyle: {width: '35%'},
      formatter: (cell: any, row: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column w-100'>
            <div className='text-dark fw-bold fs-6 mb-5'>{row.shortName}</div>

            <div className='row'>
              <div className='col-6'>
                <div className='d-flex flex-row'>
                  <span className='w-75px mr-auto'>{'Şirket Tipi'}</span> <span>{':'}</span>
                  <span className='ms-3 fw-bold d-block'>{row.type}</span>
                </div>
              </div>

              {(row.assignedFinancialAdvisorId ?? 0) > 0 && (
                <div className='col-6'>
                  <div className='d-flex flex-row'>
                    <span className='w-75px mr-auto'>{'M. Müşavir'}</span> <span>{':'}</span>
                    <span className='ms-3 fw-bold d-block'>{row.assignedFinancialAdvisor}</span>
                  </div>
                </div>
              )}

              <AuthorizedComponent
                auth={UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Reference}
              >
                <div className='col-6'>
                  <div className='d-flex flex-row'>
                    <span className='w-75px mr-auto'>{'Referans'}</span> <span>{':'}</span>
                    <span className='ms-3 fw-bold d-block'>{row.referenceName}</span>
                  </div>
                </div>
              </AuthorizedComponent>

              <div className='col-6'>
                <div className='d-flex flex-row'>
                  <span className='w-75px mr-auto'>{'Vergi No'}</span> <span>{':'}</span>
                  <span className='ms-3 fw-bold d-block'>
                    {row.taxNumber && (
                      <TextWithClipBoard text={row.taxNumber ?? ''} color='text-primary' />
                    )}
                  </span>
                </div>
              </div>

              {/* <div className='col-6'>
                <div className='d-flex flex-row'>
                  <span className='w-75px mr-auto'>{'Yeni Şirket'}</span> <span>{':'}</span>
                  {row.isNewCompany ? (
                    <i className='ms-3 bi bi-check text-info fs-2'></i>
                  ) : (
                    <i className='ms-3 bi bi-dash text-danger fs-2'></i>
                  )}
                </div>
              </div> */}
            </div>
            <div className='row'>
              {(row.suspensionStatusId ?? 0) > 0 && (
                <div className='col-12'>
                  <div className='d-flex flex-row'>
                    <span className='w-75px mr-auto fs-8'>{'Fesih Durum'}</span> <span>{':'}</span>
                    <span className='ms-3 text-danger fw-semibold d-block'>
                      {row.suspensionStatus}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className='row mt-5'>
              <div className='col-12'>
                <div className='w-100'>
                  <CompanyStateProgressBar
                    percentage={row.progressPercentage ?? 0}
                    label={row.descriptionForAdvisor ?? ''}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      dataField: 'firstName',
      text: 'Kişi Bilgileri',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '25%'},
      formatter: (cell: any, m: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 mb-5'>
            {m.firstName} {m.lastName}{' '}
            {m.isUserActive !== true && (
              <KTSVG
                path='/media/icons/duotune/general/gen042.svg'
                className='svg-icon svg-icon-danger'
              />
            )}
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='d-flex flex-row'>
                <span className='w-75px mr-auto'>{'Kayıt Tarihi'}</span> <span>{':'}</span>
                <span className='ms-3 fw-bold d-block'>
                  {moment(m.createTime).format('DD.MM.yyyy HH:mm')}
                </span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='d-flex flex-row'>
                <span className='w-75px mr-auto'>{'Email'}</span> <span>{':'}</span>
                <span className='ms-3 fw-bold d-block'>
                  {m.mailAddress && (
                    <TextWithClipBoard text={m.mailAddress ?? ''} color='text-primary' />
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='d-flex flex-row'>
                <span className='w-75px mr-auto'>{'Telefon'}</span> <span>{':'}</span>
                <span className='ms-3 fw-bold d-block'>
                  {m.phoneNumber && (
                    <TextWithClipBoard text={m.phoneNumber ?? ''} color='text-primary' />
                  )}
                </span>
              </div>
            </div>
          </div>
          {m.suspendTime && (
            <div className='row'>
              <div className='col-12'>
                <div className='d-flex flex-row'>
                  <span className='w-75px mr-auto'>{'Fesih Tarihi'}</span> <span>{':'}</span>
                  <span className='ms-3 fw-bold d-block'>
                    {moment(m.suspendTime).format('DD.MM.yyyy HH:mm')}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      dataField: 'totalPaymentAmount',
      text: 'Ödeme',
      sort: true,
      headerStyle: {width: '30%'},
      headerClasses: '',
      formatter: (cell: any, m: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='row'>
            <div className='offset-4 col-3 text-dark fw-bold d-block fs-6 text-end'>Toplam</div>
            <div className='col-3 text-dark fw-bold d-block fs-6 text-end'>Gelecek</div>
            <div className='col-2 text-dark fw-bold d-block fs-6 text-end'>Borç</div>
          </div>
          <div className='separator separator-dashed border-dark my-1'></div>
          <div className='row'>
            <div className='col-4 text-dark fw-bold d-block fs-6 text-start'>Toplam</div>
            <div className='col-3 text-dark fw-bold d-block fs-6 text-end'>
              {m.totalPaymentAmount?.toFixed(0)} ₺
            </div>
            <div className='col-3 text-dark fw-bold d-block fs-6 text-end'>
              {m.totalDuePaymentAmount?.toFixed(0)} ₺
            </div>
            <div className='col-2 text-danger fw-bold d-block fs-6 text-end'>
              {m.totalDept?.toFixed(0)} ₺
            </div>
          </div>
          {(m.totalPaymentAmountDetails?.length ?? 0) > 1 &&
            m.totalPaymentAmountDetails?.map((d) => {
              return (
                <div key={d.name}>
                  <div className='separator separator-dashed border-dark my-1'></div>
                  <div className='row'>
                    <div className='col-4 text-dark  d-block fs-6 text-start'>{d.name}</div>
                    <div className='col-3 text-dark  d-block fs-6 text-end'>
                      {d.amount?.toFixed(0)} ₺
                    </div>
                    <div className='col-3 text-dark  d-block fs-6 text-end'>
                      {m.totalDuePaymentAmountDetails
                        ?.find((f) => f.name === d.name)
                        ?.amount?.toFixed(0)}{' '}
                      ₺
                    </div>
                    <div className='col-2 text-danger d-block fs-6 text-end'>
                      {m.totalDeptDetails?.find((f) => f.name === d.name)?.amount?.toFixed(0)} ₺
                    </div>
                  </div>
                </div>
              )
            })}
        </>
      ),
    },
    {
      dataField: '',
      text: 'Detay',
      sort: true,
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='d-flex justify-content-center align-items-center flex-shrink-0 h-100'>
            <Link
              to={'/admin-company/' + m.guid}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
            </Link>
          </div>
        </>
      ),
    },
  ]

  function getExcelData() {
    if (role === UserTypeEnum.Admin) {
      return getExcelDataForAdmin()
    } else if (role === UserTypeEnum.Mali_Müşavir) {
      return getExcelDataForFinancialAdvisor()
    } else {
      return getExcelDataForFinancialAdvisor()
    }
  }

  function getExcelDataForAdmin() {
    return (_.orderBy(props.companies, (o) => o.createTime, 'desc') ?? []).map((m) => {
      return {
        'Şirket Adı': m.shortName,
        'Şirket Tipi': m.type,
        'Kullanıcı Adı': m.firstName,
        'Kullanıcı Soyadı': m.lastName,
        Telefon: m.phoneNumber,
        Email: m.mailAddress,
        'Kayıt Tarihi': m.createTime,
        'Fesih Tarihi': m.suspendTime,
        'Operasyon Adres Tipi': m.operationAddressType,
        'Şirket Durumu': m.descriptionForAdvisor,
        'Toplam Ödeme': m.totalPaymentAmount,
        'Toplam Gelecek Ödeme': m.totalDuePaymentAmount,
        'Toplam Borç': m.totalDept,
        'Aktif Mi': m.isCompanyActive,
        'Mali Müşavir': m.assignedFinancialAdvisor,
        Referans: m.referenceName,
        ...m.totalPaymentAmountDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Ödeme']: v.amount?.toFixed(0)}),
          {}
        ),
        ...m.totalDuePaymentAmountDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Gelecek Ödeme']: v.amount?.toFixed(0)}),
          {}
        ),
        ...m.totalDeptDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Borç']: v.amount?.toFixed(0)}),
          {}
        ),
        ...m.averageRecurringPaymentDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Aylık Ödeme']: v.amount?.toFixed(0)}),
          {}
        ),
      }
    })
  }

  function getExcelDataForFinancialAdvisor() {
    return (_.orderBy(props.companies, (o) => o.createTime, 'desc') ?? []).map((m) => {
      return {
        'Şirket Adı': m.shortName,
        'Şirket Tipi': m.type,
        'Kullanıcı Adı': m.firstName,
        'Kullanıcı Soyadı': m.lastName,
        Telefon: m.phoneNumber,
        Email: m.mailAddress,
        'Kayıt Tarihi': m.createTime,
        'Fesih Tarihi': m.suspendTime,
        'Operasyon Adres Tipi': m.operationAddressType,
        'Şirket Durumu': m.descriptionForAdvisor,
        'Toplam Ödeme': m.totalPaymentAmount,
        'Toplam Gelecek Ödeme': m.totalDuePaymentAmount,
        'Toplam Borç': m.totalDept,
        'Aktif Mi': m.isCompanyActive,
        ...m.totalPaymentAmountDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Ödeme']: v.amount?.toFixed(0)}),
          {}
        ),
        ...m.totalDuePaymentAmountDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Gelecek Ödeme']: v.amount?.toFixed(0)}),
          {}
        ),
        ...m.totalDeptDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Borç']: v.amount?.toFixed(0)}),
          {}
        ),
        ...m.averageRecurringPaymentDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Aylık Ödeme']: v.amount?.toFixed(0)}),
          {}
        ),
      }
    })
  }
  const rowClasses = (row: AdminPanelCompanyDTO, rowIndex: number): string => {
    return clsx(
      {
        'bg-danger bg-opacity-15': !row.isCompanyActive,
        'bg-warning bg-opacity-15':
          row.isCompanyActive &&
          row.suspensionStatusId ==
            CompanySuspensionStatusEnum.Şirketi_Kapatmak_İstiyor___Tasfiye_Başladı,
      },
      'border-dark'
    )
  }

  return (
    <>
      {(props.companies?.length ?? 0) > 0 && (
        <>
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              totalSize: props?.companies?.length,
              sizePerPage: 50,
              paginationSize: 50,
              paginationTotalRenderer: (from, to, size) => (
                <span className='react-bootstrap-table-pagination-total ms-3'>
                  Toplam {size} firmadan {from} - {to} arası gösteriliyor{' '}
                  <ExportCSV csvData={getExcelData()} fileName={'Firmalar'} />
                </span>
              ),
            })}
          >
            {({paginationProps, paginationTableProps}) => (
              <>
                <SizePerPageDropdownStandalone {...paginationProps} className='mx-5' />
                <PaginationTotalStandalone {...paginationProps} />
                <PaginationListStandalone {...paginationProps} />

                <div className='table-wrapper'>
                  <BootstrapTable
                    {...paginationTableProps}
                    rowClasses={rowClasses}
                    keyField='index'
                    data={_.orderBy(props.companies, (o) => o.createTime, 'desc') ?? []}
                    columns={columns}
                    classes='table table-hover table-responsive table-row-bordered border-dark table-rounded gy-7 gx-5'
                    headerClasses='table-header fw-bolder fs-5'
                  />
                </div>
              </>
            )}
          </PaginationProvider>
        </>
      )}
    </>
  )
}

export default CompaniesTable
