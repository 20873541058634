import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {ProductDTOIEnumerableApiData} from '../../../../../api/data-contracts'
import {Api_Product_GetProducts} from '../../../../../apiClient/apiClient'
import {ProductTypeEnum} from '../../../../../apiClient/enums'
import {postRequestAsync} from '../../../../../redux/commonSlice'
import {openPaymentPageAsync, setExtraProducts} from '../../../../../redux/productSlice'
import NormalTitle from '../../../../../components/common/title/NormalTitle'
import WizardStepButtons from '../../../../../components/wizard/WizardStepButtons'
import {useNavigate} from 'react-router-dom'
import ExtraProducts from '../../../../../components/product/ExtraProducts'

function ExtrasStep() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Product_GetProducts,
        body: {
          TypeId: ProductTypeEnum.Ek_Çözüm,
        },
      })
    )
      .unwrap()
      .then(async function (response: ProductDTOIEnumerableApiData) {
        dispatch<any>(setExtraProducts(response.data))
      })
  }, [])
  return (
    <div className='w-100'>
      <NormalTitle text='EK_COZUMLER' />
      <ExtraProducts inWizard={true} inExtrasPage={false} />

      <WizardStepButtons
        customNextButtonOnClik={() => {
          dispatch<any>(openPaymentPageAsync({navigate: navigate}))
        }}
      />
    </div>
  )
}

export default ExtrasStep
