import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useState} from 'react'
import {
  AdminPanelCompanyDetailDTO,
  AdminProductDTO,
  BooleanApiData,
  UpdateProductRequest,
} from '../../../api/data-contracts'
import {FieldArray, FormikValues} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {getCommonState, postRequestAsync} from '../../../redux/commonSlice'
import {getAdminState} from '../../../redux/adminSlice'
import {
  Api_Admin_UpsertProduct,
} from '../../../apiClient/apiClient'
import {getUserState} from '../../../redux/userSlice'
import * as Yup from 'yup'
import FormikForm from '../../../components/form/FormikForm'
import FormInput from '../../../components/form/FormInput'
import FormMessage from '../../../components/common/messages/FormMessage'
import CustomDropdown, {DropdownOption} from '../../../components/form/dropdown/CustomDropdown'
import FormCheckboxInput from '../../../components/form/FormCheckboxInput'
import {FormStatus} from '../../../types/FormStatus'
import _ from 'lodash'

function AdminProductDetailPopup(props: {product?: AdminProductDTO}) {
  const [modalShow, setModalShow] = useState(false)
  const commonState = useSelector(getCommonState)
  const adminState = useSelector(getAdminState)
  const userState = useSelector(getUserState)

  const schema = Yup.object({})

  const [company, setCompany] = useState<AdminPanelCompanyDetailDTO | null | undefined>(null)
  const dispatch = useDispatch()

  const initData: UpdateProductRequest = {
    categoryId: props.product?.categoryId ?? undefined,
    currencyId: props.product?.currencyId,
    description: props.product?.description ?? '',
    descriptionENG: props.product?.descriptionENG ?? '',
    isActive: props.product?.isActive,
    serviceLength: props.product?.serviceLength,
    serviceType: props.product?.serviceType,
    title: props.product?.title ?? '',
    titleEng: props.product?.titleEng ?? '',
    productTypeId: props.product?.typeId,
    prices: props.product?.prices,
  }
  const handleModalClose = () => setModalShow(false)

  const submit = async (values: UpdateProductRequest, actions: FormikValues) => {
    actions.setStatus()
    values.id = props.product?.id
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_UpsertProduct,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        if (response.data == true) {
          actions.setStatus(new FormStatus(true, 'Ürün Kaydedildi.'))
        } else {
          actions.setStatus(new FormStatus(false, response.serverMessage))
        }
      })
  }

  const load = () => {
    setModalShow(true)
  }

  return (
    <>
      <a
        href='#'
        onClick={() => {
          load()
        }}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
      </a>

      <Modal size='xl' show={modalShow} onHide={handleModalClose} centered id='kt_modal_success'>
        <Modal.Header closeButton>
          <h5 className='modal-title'>{props.product?.title} Detay </h5>
        </Modal.Header>
        <Modal.Body>
          <FormikForm initialValues={initData} onSubmit={submit} validationSchema={schema}>
            {(form_props) => {
              return (
                <>
                  <div className='row'>
                    <div className='d-flex flex-column mb-7 col-xl-5 offset-xl-1'>
                      <FormInput
                        fieldAttributes={{
                          type: 'text',
                          name: 'title',
                          placeholder: 'TITLE',
                        }}
                        istouched={form_props.touched.title ?? false}
                        errorMessage={form_props.errors.title}
                        placeHolderOnTop={true}
                        checkValid={false}
                      />
                    </div>
                    <div className='d-flex flex-column mb-7 col-xl-5'>
                      <FormInput
                        fieldAttributes={{
                          type: 'text',
                          name: 'titleEng',
                          placeholder: 'TITLE_ENG',
                        }}
                        istouched={form_props.touched.titleEng ?? false}
                        errorMessage={form_props.errors.titleEng}
                        placeHolderOnTop={true}
                        checkValid={false}
                      />
                    </div>
                  </div>

                  <div className='row h-200px'>
                    <div className='d-flex flex-column mb-7 col-xl-5 offset-xl-1'>
                      <FormInput
                        fieldAttributes={{
                          type: 'text',
                          name: 'description',
                          placeholder: 'DESCRIPTION',
                          as: 'textarea',
                          row: 10,
                          className: 'h-150px',
                        }}
                        istouched={form_props.touched.description ?? false}
                        errorMessage={form_props.errors.description}
                        placeHolderOnTop={true}
                        checkValid={false}
                      />
                    </div>
                    <div className='d-flex flex-column mb-7 col-xl-5'>
                      <FormInput
                        fieldAttributes={{
                          type: 'text',
                          name: 'descriptionENG',
                          placeholder: 'DESCRIPTION_ENG',
                          as: 'textarea',
                          row: 10,
                          className: 'h-150px',
                        }}
                        istouched={form_props.touched.descriptionENG ?? false}
                        errorMessage={form_props.errors.descriptionENG}
                        placeHolderOnTop={true}
                        checkValid={false}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='d-flex flex-column mb-7 col-xl-5 offset-xl-1'>
                      <CustomDropdown
                        fieldAttributes={{
                          name: 'productTypeId',
                          placeholder: 'URUN_TIPI',
                          onChange: (event: any) => {},
                        }}
                        noSelectionText='URUN_TIPI_SECINIZ'
                        options={
                          adminState.productsPage?.productTypes?.map((m) => {
                            const v: DropdownOption = {
                              label: m.description ?? '',
                              value: m.id ?? 0,
                            }
                            return v
                          }) ?? []
                        }
                        noSelectionValue={0}
                        placeHolderOnTop={true}
                        setFieldValue={form_props.setFieldValue}
                        valueConverter={parseInt}
                      />
                    </div>
                    <div className='d-flex flex-column mb-7 col-xl-5'>
                      <CustomDropdown
                        fieldAttributes={{
                          name: 'categoryId',
                          placeholder: 'KATEGORI',
                          onChange: (event: any) => {},
                        }}
                        noSelectionText='KATEGORI_SECINIZ'
                        options={
                          adminState.productsPage?.productCategories?.map((m) => {
                            const v: DropdownOption = {
                              label: m.description ?? '',
                              value: m.id ?? 0,
                            }
                            return v
                          }) ?? []
                        }
                        noSelectionValue={0}
                        placeHolderOnTop={true}
                        setFieldValue={form_props.setFieldValue}
                        valueConverter={parseInt}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='d-flex flex-column mb-7 col-xl-10 offset-xl-1'>
                      <div className='row'>
                        <div className='col-xl-4'>
                          <CustomDropdown
                            fieldAttributes={{
                              name: 'currencyId',
                              placeholder: 'PARA_BIRIMI',
                              onChange: (event: any) => {},
                            }}
                            noSelectionText='PARA_BIRIMI_SECINIZ'
                            options={
                              adminState.productsPage?.currencies?.map((m) => {
                                const v: DropdownOption = {
                                  label: m.description ?? '',
                                  value: m.id ?? 0,
                                }
                                return v
                              }) ?? []
                            }
                            noSelectionValue={0}
                            placeHolderOnTop={true}
                            setFieldValue={form_props.setFieldValue}
                            valueConverter={parseInt}
                          />
                        </div>
                        <div className='col-xl-4'>
                          <CustomDropdown
                            fieldAttributes={{
                              name: 'serviceType',
                              placeholder: 'SERVIS_PERIYOD',
                              onChange: (event: any) => {},
                            }}
                            noSelectionText='SERVIS_PERIYOD_SECINIZ'
                            options={
                              adminState.productsPage?.servicePeriods?.map((m) => {
                                const v: DropdownOption = {
                                  label: m.description ?? '',
                                  value: m.id ?? 0,
                                }
                                return v
                              }) ?? []
                            }
                            noSelectionValue={0}
                            placeHolderOnTop={true}
                            setFieldValue={form_props.setFieldValue}
                            valueConverter={parseInt}
                          />
                        </div>
                        <div className='col-xl-4'>
                          <FormInput
                            fieldAttributes={{
                              type: 'text',
                              name: 'serviceLength',
                              placeholder: 'SERVIS_SURESI',
                            }}
                            istouched={form_props.touched.serviceLength ?? false}
                            errorMessage={form_props.errors.serviceLength}
                            placeHolderOnTop={true}
                            checkValid={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='d-flex flex-column mb-7 col-xl-10 offset-xl-1'>
                      <FormCheckboxInput
                        description='AKTIF'
                        name='isActive'
                        setFieldValue={form_props.setFieldValue}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='d-flex flex-column mb-7 fv-row col-xl-10 offset-xl-1'>
                      <FieldArray
                        name='prices'
                        render={(arrayHelpers) => (
                          <div className='d-flex flex-column justify-content-center'>
                            <div className='fw-bold fs-2 text-left py-5'>Fiyatlar</div>

                            <div className='row'>
                              {props.product?.prices?.map((price, index) => (
                                <div className='col-xl-6 px-2' key={index}>
                                  <div className='card card-dashed p-6'>
                                    <div className=''>
                                      <div className='row mb-2'>
                                        <div className='col-xl-6 fw-bold fs-6 text-left py-5'>
                                          {price.financialAdvisor
                                            ? price.financialAdvisor + ' İçin Fiyatlar'
                                            : 'Varsayılan Fiyatlar'}
                                        </div>
                                        <div className='col-xl-3 fw-bold fs-6 text-left py-5'>
                                          KDV'siz
                                        </div>
                                        <div className='col-xl-3 fw-bold fs-6 text-left py-5'>
                                          KDV'li
                                        </div>
                                      </div>
                                      {adminState.productsPage?.serviceProviders?.map((m, i) => {
                                        return (
                                          <div className='row mb-2' key={i}>
                                            <div className='col-xl-6 fs-6 text-left py-3'>
                                              <div>{m.description}</div>
                                            </div>
                                            <div className='col-xl-3'>
                                              <FormInput
                                                fieldAttributes={{
                                                  type: 'number',
                                                  name: `prices.${index}.shares.${i}.totalPriceWithoutTax`,
                                                  placeholder: '',
                                                  onChange: (event: any) => {
                                                    const value = parseFloat(
                                                      event.target.value == ''
                                                        ? 0
                                                        : event.target.value
                                                    )
                                                    form_props.setFieldValue(
                                                      `prices.${index}.shares.${i}.totalPriceWithoutTax`,
                                                      value
                                                    )

                                                    form_props.setFieldValue(
                                                      `prices.${index}.shares.${i}.totalPrice`,
                                                      value * 1.2
                                                    )
                                                  },
                                                }}
                                                istouched={true}
                                                errorMessage={undefined}
                                                placeHolderOnTop={false}
                                                checkValid={false}
                                              />
                                            </div>
                                            <div className='col-xl-3'>
                                              <FormInput
                                                fieldAttributes={{
                                                  type: 'number',
                                                  name: `prices.${index}.shares.${i}.totalPrice`,
                                                  placeholder: '',
                                                  onChange: (event: any) => {
                                                    const value = parseFloat(
                                                      event.target.value == ''
                                                        ? 0
                                                        : event.target.value
                                                    )
                                                    form_props.setFieldValue(
                                                      `prices.${index}.shares.${i}.totalPrice`,
                                                      value
                                                    )

                                                    // form_props.setFieldValue(
                                                    //   `prices.${index}.shares.${i}.totalPriceWithoutTax`,
                                                    //   value / 1.2
                                                    // )
                                                  },
                                                }}
                                                istouched={true}
                                                errorMessage={undefined}
                                                placeHolderOnTop={false}
                                                checkValid={false}
                                              />
                                            </div>
                                          </div>
                                        )
                                      })}

                                      <div className='row'>
                                        <div className='col-xl-6 fs-6 text-left py-3'>
                                          <div>Toplam</div>
                                        </div>
                                        <div className='col-xl-3 fs-6 text-left py-3'>
                                          <div>
                                            {_.sumBy(
                                              form_props.values.prices?.at(index)?.shares,
                                              (s) => s.totalPriceWithoutTax ?? 0
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                        <div className='col-xl-3 fs-6 text-left py-3'>
                                          {_.sumBy(
                                            form_props.values.prices?.at(index)?.shares,
                                            (s) => s.totalPrice ?? 0
                                          ).toFixed(2)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className='row mt-5'>
                    <div className='col-xl-10 offset-xl-1'>
                      <FormMessage {...form_props.status} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='text-center'>
                      <button type='submit' className='btn btn-lg btn-primary me-3 mt-5'>
                        Kaydet
                      </button>
                    </div>
                  </div>
                </>
              )
            }}
          </FormikForm>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminProductDetailPopup
