import {useSelector} from 'react-redux'
import {getAdminState} from '../../../redux/adminSlice'
import _ from 'lodash'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import CompaniesTable from '../../../components/admin/company/CompaniesTable'
import {Company} from '../../../api/Company'
import CompanyFilter from '../../../components/admin/company/CompanyFilter'

export interface AdminCompaniesFilter {
  textFilter: string
  companyType: number
}

function AdminCompaniesPage() {
  const adminState = useSelector(getAdminState)

  return (
    <div>
      <div className='card card-stretch card-border'>
        <div className='card-header pt-5 d-flex justify-content-center'>
          <CompanyFilter />
        </div>

        <div className='card-body px-0 py-3'>
          <CompaniesTable companies={adminState.companies ?? []} />
        </div>
      </div>
    </div>
  )
}

export default AdminCompaniesPage
