import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useState} from 'react'
import {
  BooleanApiData,
  EInvoiceProductDTO,
  EInvoiceProductDTOApiData,
  EinvoiceCustomerDTO,
  GetCustomerRequest,
  GetProductRequest,
  TryDiscountRequest,
  UpsertEInvoiceCustomerRequest,
  UpsertEInvoiceProductRequest,
} from '../../../../api/data-contracts'
import {ErrorMessage, Field, FieldArray, Form, Formik, FormikValues} from 'formik'
import * as Yup from 'yup'
import {useDispatch, useSelector} from 'react-redux'
import {getCommonState, postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {
  Api_EInvoice_GetCustomer,
  Api_EInvoice_GetProduct,
  Api_EInvoice_RemoveCustomer,
  Api_EInvoice_RemoveProduct,
  Api_EInvoice_UpsertCustomer,
  Api_EInvoice_UpsertProduct,
} from '../../../../apiClient/apiClient'
import {getEInvoiceState, loadEInvoiceCommonDataAsync} from '../../../../redux/eInvoiceSlice'

export enum ForibaCustomerFormPopupType {
  Insert,
  Update,
  Delete,
}

function ForibaCustomerFormPopup(props: {
  type: ForibaCustomerFormPopupType
  customerGuidId?: string
}) {
  const schema = Yup.object({
    typeId: Yup.number().required(),
    identificationNumber: Yup.string().required('Vergi/Kimlik no zorunludur.'),
    name: Yup.string().required('İsim Zorunludur'),
  })

  const [modalShow, setModalShow] = useState(false)
  const commonState = useSelector(getCommonState)
  const eInvoiceState = useSelector(getEInvoiceState)
  const [customer, setCustomer] = useState<EinvoiceCustomerDTO | null | undefined>(null)

  const initData: UpsertEInvoiceCustomerRequest = {
    guidId: customer?.guidId ?? null,
    address: customer?.address ?? '',
    cityId: customer?.cityId ?? 0,
    countryId: customer?.countryId ?? 0,
    districtId: customer?.districtId ?? 0,
    emailAddress: customer?.emailAddress ?? '',
    identificationNumber: customer?.identificationNumber ?? '',
    name: customer?.name ?? '',
    phoneNumber: customer?.phoneNumber ?? '',
    surname: customer?.surname ?? '',
    typeId: customer?.typeId ?? 0,
  }

  const dispatch = useDispatch()

  const submit = async (values: UpsertEInvoiceCustomerRequest, actions: FormikValues) => {
    dispatch(setIsLoading(true))
    actions.setStatus('')
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_EInvoice_UpsertCustomer,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        dispatch(setIsLoading(false))
        if (!response.data) {
          dispatch(actions.setStatus(response.serverMessage))
        } else {
          await dispatch<any>(loadEInvoiceCommonDataAsync())
          handleModalClose()
        }
      })
  }

  const remove = async () => {
    const body: GetCustomerRequest = {
      guidId: props.customerGuidId ?? '',
    }

    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_EInvoice_RemoveCustomer,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        dispatch(setIsLoading(false))
        await dispatch<any>(loadEInvoiceCommonDataAsync())
        handleModalClose()
      })
  }

  const handleModalClose = () => setModalShow(false)

  const load = () => {
    if (props.customerGuidId) {
      const body: GetCustomerRequest = {
        guidId: props.customerGuidId ?? '',
      }

      dispatch(setIsLoading(true))
      dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_EInvoice_GetCustomer,
          body: body,
        })
      )
        .unwrap()
        .then((response: EInvoiceProductDTOApiData) => {
          dispatch(setIsLoading(false))
          if (response.isSuccessful) {
            setCustomer(response.data)
            setModalShow(true)
          }
        })
    } else {
      setModalShow(true)
    }
  }

  return (
    <>
      {props.type == ForibaCustomerFormPopupType.Insert && (
        <a
          href='#'
          onClick={() => {
            load()
          }}
          className='btn btn-sm btn-light-primary'
        >
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          Yeni Müşteri
        </a>
      )}

      {props.type == ForibaCustomerFormPopupType.Update && (
        <a
          href='#'
          onClick={() => {
            load()
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>
      )}

      {props.type == ForibaCustomerFormPopupType.Delete && (
        <a
          href='#'
          onClick={() => {
            setModalShow(true)
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      )}

      {props.type == ForibaCustomerFormPopupType.Delete ? (
        <Modal show={modalShow} onHide={handleModalClose} centered id='kt_modal_success'>
          <Modal.Header closeButton>
            <h5 className='modal-title'>Müşteriyi Sil</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <p>Müşteriyi Silmek İstediğinizden Emin misiniz?</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-light'
              data-bs-dismiss='modal'
              onClick={handleModalClose}
            >
              İptal
            </button>
            <button
              type='button'
              data-kt-indicator={commonState?.isLoading ? 'on' : ''}
              onClick={remove}
              className='btn btn-primary'
            >
              <span className='indicator-label'>Sil</span>
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal size='xl' show={modalShow} onHide={handleModalClose} centered id='kt_modal_success'>
          <Modal.Header closeButton>
            <h5 className='modal-title'>Müşteri Kayıt</h5>
          </Modal.Header>
          <Modal.Body>
            <Formik
              validationSchema={schema}
              initialValues={initData}
              onSubmit={submit}
              validateOnMount={true}
            >
              {({values, errors, setFieldValue, validateForm, status}) => (
                <Form noValidate id='id_form_' placeholder={undefined}>
                  <div className='w-100'>
                    {/* <div>{JSON.stringify(values)}</div>
                    <div>{JSON.stringify(errors)}</div> */}

                    <div className='row'>
                      <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className='required'>Müşteri Tipi</span>
                        </label>

                        <Field
                          className='form-select form-select-solid'
                          data-control='select2'
                          name='typeId'
                          as='select'
                        >
                          <option value={0} key={'non'}>
                            Müşteri Tipi
                          </option>
                          <option value={1} key={1}>
                            Kişi
                          </option>
                          <option value={2} key={2}>
                            Şirket
                          </option>
                        </Field>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='typeId' />
                        </div>
                      </div>

                      <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className='required'>
                            {values.typeId == 1 ? 'Müşteri Adı' : 'Şirket Adı'}
                          </span>
                        </label>

                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='name'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='name' />
                        </div>
                      </div>

                      {values.typeId == 1 && (
                        <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                            <span className='required'>Müşteri Soyadı</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder=''
                            name='surname'
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='surname' />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='row'>
                      <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className='required'>
                            {values.typeId == 1 ? 'Kimlik No' : 'Vergi Numarası'}
                          </span>
                        </label>

                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='identificationNumber'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='identificationNumber' />
                        </div>
                      </div>

                      <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className=''>Telefon Numarası</span>
                        </label>

                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='phoneNumber'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='phoneNumber' />
                        </div>
                      </div>

                      <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className=''>Email</span>
                        </label>

                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='emailAddress'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='emailAddress' />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className=''>Ülke</span>
                        </label>

                        <Field
                          className='form-select form-select-solid'
                          data-control='select2'
                          name='countryId'
                          as='select'
                        >
                          <option value={0} key={'non'}>
                            Ülke Seçiniz
                          </option>
                          {eInvoiceState.eInvoiceCommonData?.countries?.map((m) => {
                            return (
                              <option value={m.countryId} key={m.countryId}>
                                {m.name}
                              </option>
                            )
                          })}
                        </Field>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='countryId' />
                        </div>
                      </div>

                      <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className=''>İl</span>
                        </label>

                        <Field
                          className='form-select form-select-solid'
                          data-control='select2'
                          name='cityId'
                          as='select'
                        >
                          <option value={0} key={'non'}>
                            İl Seçiniz
                          </option>
                          {eInvoiceState.eInvoiceCommonData?.cities
                            ?.filter((f) => f.countryId == values.countryId)
                            ?.map((m) => {
                              return (
                                <option value={m.cityId} key={m.cityId}>
                                  {m.name}
                                </option>
                              )
                            })}
                        </Field>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='cityId' />
                        </div>
                      </div>

                      <div className='d-flex flex-column mb-7 fv-row col-xl-4'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className=''>İlçe</span>
                        </label>

                        <Field
                          className='form-select form-select-solid'
                          data-control='select2'
                          name='districtId'
                          as='select'
                        >
                          <option value={0} key={'non'}>
                            İlçe Seçiniz
                          </option>
                          {eInvoiceState.eInvoiceCommonData?.districts
                            ?.filter((f) => f.cityId == values.cityId)
                            .map((m) => {
                              return (
                                <option value={m.districtId} key={m.districtId}>
                                  {m.name}
                                </option>
                              )
                            })}
                        </Field>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='districtId' />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='d-flex flex-column mb-7 fv-row col-xl-12'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className=''>Adres</span>
                        </label>

                        <Field
                          type='text'
                          as='textarea'
                          className='form-control form-control-solid'
                          placeholder=''
                          row={5}
                          name='address'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='address' />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex flex-row-reverse pt-10 align-items-end mb-5'>
                      <div>
                        <button type='submit' className='btn btn-lg btn-primary me-3'>
                          {/* {commonState.isLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Lütfen bekleyiniz...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )} */}

                          <span className='indicator-label'>Kaydet</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default ForibaCustomerFormPopup
