import React from 'react'
import BigRadioButton from '../../form/radio/BigRadioButton'
import FormikForm from '../../form/FormikForm'
import Translator from '../../common/translate/Translator'
import clsx from 'clsx'
import {
  CreateSubscriptionCancelCartRequest,
  PaymentDetailDTOApiData,
} from '../../../api/data-contracts'
import {useDispatch} from 'react-redux'
import {Api_Payment_CreateSubscriptionCancelPayment} from '../../../apiClient/apiClient'
import {postRequestAsync} from '../../../redux/commonSlice'
import {openPaymentPageAsync} from '../../../redux/productSlice'
import {useNavigate} from 'react-router-dom'
import AreYouSurePopup from '../../common/popup/AreYouSurePopup'
import CustomDropdown from '../../form/dropdown/CustomDropdown'
import {CompanySuspensionReasonEnum, CompanySuspensionStatusEnum} from '../../../apiClient/enums'
import FormInput from '../../form/FormInput'

function SuspensionForm() {
  const initValues: CreateSubscriptionCancelCartRequest = {
    cancelStatusId: 1,
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  function submit(values: CreateSubscriptionCancelCartRequest) {
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Payment_CreateSubscriptionCancelPayment,
        body: values,
      })
    )
      .unwrap()
      .then(async function (response: PaymentDetailDTOApiData) {
        if (response.isSuccessful) {
          if ((response.data?.totalAmount ?? 0) > 0) {
            await dispatch<any>(openPaymentPageAsync({navigate: navigate}))
          } else {
            navigate('/subscription-cancelled')
            window.location.reload()
          }
        }
      })
  }

  return (
    <FormikForm initialValues={initValues} onSubmit={submit}>
      {(props) => {
        return (
          <div className='w-100 row d-flex justify-content-center'>
            <div className='row col-xl-10'>
              <div className='col-xl-6'>
                <BigRadioButton
                  description={'SIRKETIMI_KAPATMAK_ISTIYORUM'}
                  details={''}
                  value={1}
                  icon={'/media/icons/duotune/general/gen034.svg'}
                  name={'cancelStatusId'}
                  setFieldValue={props.setFieldValue}
                  key={1}
                  valueConverter={parseInt}
                  detailHtml={
                    <>
                      <div className='fs-7 text-gray-700'>
                        <Translator name='SIRKETIMI_KAPATMAK_ISTIYORUM_ACIKLAMA' />
                      </div>
                    </>
                  }
                />
                <div className='text-danger fw-bold mt-1 mx-5'>
                  <Translator name={'TASFIYE_BITENE_KADAR'} />
                </div>
              </div>
              <div className='col-xl-6'>
                <BigRadioButton
                  description={'MALI_MUSAVIR_DEGISTIRMEK_ISTIYORUM'}
                  details={''}
                  value={3}
                  icon={'/media/icons/duotune/maps/map009.svg'}
                  name={'cancelStatusId'}
                  setFieldValue={props.setFieldValue}
                  key={1}
                  valueConverter={parseInt}
                  detailHtml={
                    <>
                      <div className='fs-7 text-gray-700'>
                        <Translator name='MALI_MUSAVIR_DEGISTIRMEK_ISTIYORUM_ACIKLAMA' />
                      </div>
                    </>
                  }
                />

                {props.values.cancelStatusId ==
                  CompanySuspensionStatusEnum.Mali_Müşavir_Değiştirmek_İstiyor && (
                  <div className='mt-5'>
                    <CustomDropdown
                      fieldAttributes={{
                        name: 'suspensionReasonId',
                        placeholder: 'FESIH_SEBEBI',
                        onChange: (event: any) => {},
                        required: false,
                      }}
                      noSelectionText=''
                      options={[
                        {
                          value: CompanySuspensionReasonEnum.Paket_Fiyatları_Pahalı,
                          label: 'Pakey fiyatları pahalı',
                        },
                        {
                          value: CompanySuspensionReasonEnum.Mali_Müşavir_Sorunları,
                          label: 'Mali müşavir sorunları',
                        },
                        {
                          value: CompanySuspensionReasonEnum.Diğer,
                          label: 'Diğer',
                        },
                      ]}
                      noSelectionValue={0}
                      placeHolderOnTop={true}
                      setFieldValue={props.setFieldValue}
                      valueConverter={parseInt}
                    />
                    <div className='mt-5'>
                      {props.values.suspensionReasonId == CompanySuspensionReasonEnum.Diğer && (
                        <FormInput
                          fieldAttributes={{
                            type: 'text',
                            name: 'suspensionReasonText',
                            placeholder: 'FESIH_SEBEP_ACIKLAMA',
                          }}
                          istouched={props.touched.suspensionReasonText ?? false}
                          errorMessage={props.errors.suspensionReasonText}
                          placeHolderOnTop={true}
                          checkValid={false}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mt-10'>
              <div className='offset-xl-9 col-xl-3 col-12'>
                <AreYouSurePopup
                  modalOpener={
                    <a className={clsx('btn btn-sm btn-danger')} href='#'>
                      <span className='indicator-label'>
                        <Translator name='ABONELIK_IPTAL_ET' />
                      </span>
                    </a>
                  }
                  message={
                    props.values.cancelStatusId == 1
                      ? 'FESIH_VE_TASFIYE_EMIN_MISINIZ'
                      : 'FESIH_EMIN_MISINIZ'
                  }
                  onClick={() => props.submitForm()}
                />
              </div>
            </div>
          </div>
        )
      }}
    </FormikForm>
  )
}

export default SuspensionForm
