import React from 'react'
import {useIntl} from 'react-intl'
import {getLanguage} from '../../../apiClient/apiClient'

type LanguageTranslations = {
  [key: string]: string | null | undefined
}

export interface IDynamicValueTranslatorInput {
  translations: LanguageTranslations
}

function DynamicValueTranslator(props: IDynamicValueTranslatorInput) {
  return <>{props.translations[getLanguage()]}</>
}

export default DynamicValueTranslator
