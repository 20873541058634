import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {KTSVG} from '../../../_metronic/helpers'
import {
  BooleanApiData,
  ChangeDocumentStatusRequest,
  DocumentBaseRequest,
  FolderDTO,
  FolderDTOApiData,
  GetDocumentsRequest,
  UploadDocumentRequest,
} from '../../api/data-contracts'
import {
  Api_Document_DeleteDocument,
  Api_Document_GetDocuments,
  Api_Document_SetDocumentStatus,
  Api_Document_UploadDocument,
} from '../../apiClient/apiClient'
import {
  closeAreYouSureModal,
  getCommonState,
  openAreYouSureModal,
  postRequestAsync,
} from '../../redux/commonSlice'
import {getUserState} from '../../redux/userSlice'
import {getCompanyState} from '../../redux/companySlice'
import _ from 'lodash'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'
import {convertBase64, defterdar_log} from '../../utils/utilities'
import moment from 'moment'
import Translator from '../../components/common/translate/Translator'
import AreYouSurePopup from '../../components/common/popup/AreYouSurePopup'

function DocumentsPage(props: {companyId: string}) {
  const dispatch = useDispatch()
  const commonState = useSelector(getCommonState)
  const userState = useSelector(getUserState)
  const companyState = useSelector(getCompanyState)

  const [documents, setDocuments] = useState<FolderDTO | null | undefined>(null)
  const [documentPageId, setDocumentPageId] = useState<number | null | undefined>(null)

  useEffect(() => {
    setDocumentPage(null)
  }, [])

  useEffect(() => {
    defterdar_log(documentPageId)
    reloadDocuments()
  }, [documentPageId])

  function setDocumentPage(pageId: number | undefined | null) {
    setDocumentPageId(pageId)
  }

  async function reloadDocuments() {
    const body: GetDocumentsRequest = {
      parentId: documentPageId,
      companyId: props.companyId,
    }

    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Document_GetDocuments,
        body: body,
      })
    )
      .unwrap()
      .then((response: FolderDTOApiData) => {
        setDocuments(response.data)
      })
  }

  async function uploadDocument(request: UploadDocumentRequest) {
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Document_UploadDocument,
        body: request,
      })
    )
      .unwrap()
      .then((response: BooleanApiData) => {})

    reloadDocuments()
  }

  async function deleteDocument(documentId: string) {
    const body: DocumentBaseRequest = {
      documentId: documentId,
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Document_DeleteDocument,
        body: body,
      })
    )
      .unwrap()
      .then((response: BooleanApiData) => {})

    reloadDocuments()
  }

  function handleClick(row: FolderFileEntity) {
    if (row.isFolder) {
      setDocumentPage(Number(row.id))
    }
  }

  async function setDocumentStatusToSuccess(documentId: string) {
    const body: ChangeDocumentStatusRequest = {
      documentId: documentId,
      statusId: 2,
      statusMessage: 'Onaylandı',
    }

    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Document_SetDocumentStatus,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        await reloadDocuments()
        dispatch(closeAreYouSureModal())
      })
  }

  async function setDocumentStatusToDeny(documentId: string) {
    const body: ChangeDocumentStatusRequest = {
      documentId: documentId,
      statusId: 3,
      statusMessage: 'Onaylanmadı',
    }

    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Document_SetDocumentStatus,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        await reloadDocuments()
        dispatch(closeAreYouSureModal())
      })
  }

  const columns: ColumnDescription[] = [
    {
      dataField: 'name',
      text: 'Dosya Adı',
      sort: true,
      headerStyle: {width: '60%'},
      headerClasses: 'text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0 ms-5',
      formatter: (cell: any, row: FolderFileEntity, rowIndex: number, colIndex: number) => (
        <div className='d-flex align-items-center'>
          {row.isFolder && (
            <KTSVG
              path='/media/icons/duotune/files/fil012.svg'
              className='svg-icon-2hx svg-icon-primary'
            />
          )}
          <div className='d-flex align-items-center ms-5'>{row.name}</div>
        </div>
      ),
    },
    {
      dataField: 'lastModified',
      text: 'Yüklenme Tarihi',
      sort: true,
      headerStyle: {width: '20%'},
      headerClasses: 'text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0',
      formatter: (cell: any, row: FolderFileEntity, rowIndex: number, colIndex: number) => (
        <div className='d-flex align-items-center'>
          {row.lastModified && moment(row.lastModified).format('DD.MM.yyyy HH:mm')}
        </div>
      ),
    },
    {
      dataField: 'state',
      text: 'Onay Durumu',
      sort: true,
      headerStyle: {width: '20%'},
      headerClasses: 'text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0',
      formatter: (cell: any, row: FolderFileEntity, rowIndex: number, colIndex: number) => (
        <>
          {row.state == 3 && (
            <>
              <div className='badge badge-danger'>
                <Translator name='ONAYSIZ' />
              </div>
              {documents?.canApprove && (
                <a
                  type='button'
                  onClick={() => {
                    dispatch(
                      openAreYouSureModal(() => {
                        setDocumentStatusToSuccess(row.id)
                      })
                    )
                  }}
                  className='ms-5 btn btn-sm btn-icon btn-light btn-success'
                >
                  <i className='bi bi-hand-thumbs-up-fill fs-5'></i>
                </a>
              )}
            </>
          )}
          {row.state == 2 && (
            <>
              <div className='badge badge-success'>
                <Translator name='ONAYLI' />
              </div>
              {documents?.canApprove && (
                <a
                  type='button'
                  onClick={() => {
                    dispatch(
                      openAreYouSureModal(() => {
                        setDocumentStatusToDeny(row.id)
                      })
                    )
                  }}
                  className='ms-5 btn btn-sm btn-icon btn-light btn-danger'
                >
                  <i className='bi bi-hand-thumbs-down-fill fs-5'></i>
                </a>
              )}
            </>
          )}
          {row.state == 1 && (
            <>
              <div className='badge badge-warning'>
                <Translator name='ONAY_BEKLIYOR' />
              </div>
              {documents?.canApprove && (
                <a
                  type='button'
                  onClick={() => {
                    dispatch(
                      openAreYouSureModal(() => {
                        setDocumentStatusToSuccess(row.id)
                      })
                    )
                  }}
                  className='ms-5 btn btn-sm btn-icon btn-light btn-success'
                >
                  <i className='bi bi-hand-thumbs-up-fill fs-5'></i>
                </a>
              )}
              {documents?.canApprove && (
                <a
                  type='button'
                  onClick={() => {
                    dispatch(
                      openAreYouSureModal(() => {
                        setDocumentStatusToDeny(row.id)
                      })
                    )
                  }}
                  className='ms-5 btn btn-sm btn-icon btn-light btn-danger'
                >
                  <i className='bi bi-hand-thumbs-down-fill fs-5'></i>
                </a>
              )}
            </>
          )}
        </>
      ),
    },
    {
      dataField: 'uuid',
      text: 'İşlemler',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0',
      formatter: (cell: any, row: FolderFileEntity, rowIndex: number, colIndex: number) => (
        <>
          {!row.isFolder && (
            <div className='d-flex justify-content-end'>
              <div className='ms-2'>
                <a
                  type='button'
                  href={row.uri ?? ''}
                  target={'_blank'}
                  className='btn btn-sm btn-icon btn-light btn-active-light-primary'
                >
                  <i className='fa-solid fa-arrow-up-right-from-square'></i>
                </a>
              </div>
              <div className='ms-2'>
                <AreYouSurePopup
                  message='Dosyayı Silmek İstediğinizden Emin misiniz?'
                  modalOpener={
                    <a
                      type='button'
                      className='btn btn-sm btn-icon btn-light btn-active-light-primary'
                    >
                      <i className='fa-solid fa-trash'></i>
                    </a>
                  }
                  onClick={() => {
                    deleteDocument(row.id)
                  }}
                  key={row.id}
                />
              </div>
            </div>
          )}
        </>
      ),
    },
  ]

  interface FolderFileEntity {
    isFolder: boolean
    name: string
    id: string
    lastModified?: string
    uri?: string
    state?: number
    isAdvisorFolder: boolean
  }

  const hiddenFileInput = React.useRef<HTMLInputElement>(null)

  const handleUploadClick = (event: any) => {
    hiddenFileInput?.current?.click()
  }

  async function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length ?? 0 > 0) {
      const file = (event.target.files ?? [])[0]

      const base64 = await convertBase64(file)

      const request: UploadDocumentRequest = {
        base64Content: base64 as string,
        companyId: props.companyId,
        description: '',
        //documentDate: moment(file.lastModified).format('DD.MM.YYYY HH:mm:ss'),
        documentType: documents?.id ?? 0,
        extension: file.type,
        fileName: file.name,
        userId: undefined,
        guidId: undefined,
      }

      await uploadDocument(request)
    }
  }

  return (
    <div className='d-flex w-100 mt-4'>
      <div className='rounded w-100'>
        <div className='card card-flush'>
          <div className='card-header pt-8'>
            <div className='card-title'>
              <div className='d-flex align-items-center position-relative my-1'>
                {documents?.id != null && (
                  <a
                    href='#'
                    className='btn btn-icon-primary btn-light-primary me-3'
                    onClick={() => {
                      setDocumentPage(documents?.parentId)
                    }}
                  >
                    <i className='fa-solid fa-arrow-up'></i>
                    {documents.parentName}
                  </a>
                )}
              </div>
            </div>

            <div className='fw-bold fs-1 text-center py-5'>{documents?.name}</div>

            <div className='card-toolbar'>
              <div
                className='d-flex justify-content-start'
                data-kt-filemanager-table-toolbar='base'
              ></div>
              {documents?.canUpload && (
                <div
                  className='d-flex justify-content-end'
                  data-kt-filemanager-table-toolbar='base'
                >
                  <input
                    type='file'
                    className='form-control form-control-solid'
                    // name={props.document?.description ?? ''}
                    // data-id={props.document?.documentTypeId}
                    onChange={onFileChange}
                    ref={hiddenFileInput}
                    style={{display: 'none'}}
                  />
                  <button
                    type='button'
                    className='btn btn-flex btn-primary'
                    onClick={handleUploadClick}
                  >
                    <i className='ki-duotone ki-folder-up fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    Dosya Yükle
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className='card-body'>
            <div className='table-responsive mt-5'>
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  totalSize:
                    (documents?.documents?.length ?? 0) + (documents?.subFolders?.length ?? 0),
                  sizePerPage: 50,
                  paginationSize: 50,
                  showTotal: false,
                  paginationTotalRenderer: (from, to, size) => (
                    <span className='react-bootstrap-table-pagination-total ms-5'>
                      Toplam {size} dosyadan {from} - {to} arası gösteriliyor
                    </span>
                  ),
                })}
              >
                {({paginationProps, paginationTableProps}) => (
                  <>
                    <div>
                      <SizePerPageDropdownStandalone {...paginationProps} />
                      <PaginationTotalStandalone {...paginationProps} />
                      <PaginationListStandalone {...paginationProps} />
                      <BootstrapTable
                        {...paginationTableProps}
                        keyField='id'
                        data={
                          (
                            documents?.documents?.map<FolderFileEntity>((m) => {
                              return {
                                id: m.guid ?? '',
                                isFolder: false,
                                name: m.fileName ?? '',
                                uri: m.uri ?? '',
                                lastModified: m.uploadDate ?? '',
                                state: m.approvalStatusId ?? 0,
                                isAdvisorFolder: false,
                              }
                            }) ?? []
                          ).concat(
                            documents?.subFolders?.map<FolderFileEntity>((m) => {
                              return {
                                id: m.id?.toString() ?? '',
                                isFolder: true,
                                name: m.name ?? '',
                                isAdvisorFolder: false,
                              }
                            }) ?? []
                          ) ?? []
                        }
                        columns={columns}
                        rowClasses='text-hover-inverse-secondary'
                        rowStyle={{cursor: 'pointer'}}
                        classes='table table-hover table-row-dashed table-rounded mt-5'
                        rowEvents={{
                          onClick: (e, row: FolderFileEntity, rowIndex) => {
                            handleClick(row)
                          },
                        }}
                      />
                    </div>
                  </>
                )}
              </PaginationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentsPage
