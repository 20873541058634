import React from 'react'

function CompanyDetailFormInputLabel(props: {text: string}) {
  return (
    <div className='col-lg-2 d-flex align-items-center'>
      <label className='fw-bold fs-5 text-muted'>{props.text}</label>
    </div>
  )
}

export default CompanyDetailFormInputLabel
