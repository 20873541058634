import {Field} from 'formik'
import {toAbsoluteUrl, KTSVG} from '../../../../../_metronic/helpers'
import FormikErrorMessage from '../../../../utils/FormikErrorMessage'
import Translator from '../../../common/translate/Translator'

function CardForm(props: {isCardRegisterDisabled: boolean; showCardNameInput: boolean}) {
  return (
    <div className='px-5'>
      <div className='d-flex flex-column mb-3'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>
            <Translator name='KART_UZERINDEKI_ISIM' />
          </span>
        </label>

        <Field
          type='text'
          className='form-control form-control-solid'
          placeholder=''
          name='nameOnCard'
        />
        <div className='text-danger mt-2'>
          <FormikErrorMessage name='nameOnCard' />
        </div>
      </div>

      <div className='d-flex flex-column mb-3'>
        <label className='required fs-6 fw-bold form-label mb-2'>
          <Translator name='KART_NUMARASI' />
        </label>

        <div className='position-relative'>
          <Field type='text' className='form-control form-control-solid' name='cardNumber' />

          <div className='position-absolute translate-middle-y top-50 end-0 me-1'>
            <img src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')} alt='' className='h-25px' />
            <img
              src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
              alt=''
              className='h-25px'
            />
            <img
              src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
              alt=''
              className='h-25px'
            />
          </div>
        </div>
        <div className='text-danger mt-2'>
          <FormikErrorMessage name='cardNumber' />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-md-8'>
          <label className='required fs-6 fw-bold form-label mb-2'>
            <Translator name='KART_SON_KULLANMA' />
          </label>
          <div className='row fv-row'>
            <div className='col-6'>
              <Field as='select' name='cardExpiryMonth' className='form-select form-select-solid'>
                <option></option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>
                <option value='11'>11</option>
                <option value='12'>12</option>
              </Field>
              <div className='text-danger mt-2'>
                <FormikErrorMessage name='cardExpiryMonth' />
              </div>
            </div>

            <div className='col-6'>
              <Field as='select' name='cardExpiryYear' className='form-select form-select-solid'>
                <option></option>
                <option value='2023'>2023</option>
                <option value='2024'>2024</option>
                <option value='2025'>2025</option>
                <option value='2026'>2026</option>
                <option value='2027'>2027</option>
                <option value='2028'>2028</option>
                <option value='2029'>2029</option>
                <option value='2030'>2030</option>
                <option value='2031'>2031</option>
                <option value='2032'>2032</option>
                <option value='2033'>2033</option>
                <option value='2034'>2034</option>
              </Field>
              <div className='text-danger mt-2'>
                <FormikErrorMessage name='cardExpiryYear' />
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-4'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>CVV</span>
          </label>

          <div className='position-relative'>
            <Field
              type='text'
              className='form-control form-control-solid'
              minLength={3}
              maxLength={3}
              placeholder='CVV'
              name='cardCvv'
            />
            <div className='text-danger mt-2'>
              <FormikErrorMessage name='cardCvv' />
            </div>

            <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
              <KTSVG path='/media/icons/duotune/finance/fin002.svg' className='svg-icon-2hx' />
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-stack mb-3'>
        <div className='me-5'>
          <label className='fs-9 fw-bold form-label'>
            {props.isCardRegisterDisabled ? (
              <Translator name='AYLIK_ODEMELERINIZ_ICIN_KARTI_SAKLIYORUZ' />
            ) : (
              <Translator name='KARTINIZI_SAKLAYIN' />
            )}
          </label>
        </div>
        <label className='form-check form-switch form-check-custom form-check-solid'>
          <Field
            className='form-check-input'
            type='checkbox'
            disabled={props.isCardRegisterDisabled}
            name='registerCard'
          />
          <span className='form-check-label fw-bold text-gray-400'>
            <Translator name='KART_KAYDET' />
          </span>
        </label>
      </div>

      {props.showCardNameInput && (
        <div className='d-flex flex-column mb-3'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>
              <Translator name='KART_ADI' />
            </span>
          </label>

          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder=''
            name='cardName'
          />
          <div className='text-danger mt-2'>
            <FormikErrorMessage name='cardName' />
          </div>
        </div>
      )}
    </div>
  )
}

export default CardForm
