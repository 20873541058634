import React from 'react'
import {RegisteredCardDTO} from '../../../../api/data-contracts'
import RadioButtonWrapper from '../../../form/radio/RadioButtonWrapper'
import RegisteredCard from '../RegisteredCard'
import {NewCardTokenValue} from '../../../../utils/cardUtilities'
import Translator from '../../../common/translate/Translator'

function CardSelectionNew(props: {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}) {
  return (
    <>
      <RadioButtonWrapper
        fieldAttributes={{
          className: 'btn-check',
          type: 'radio',
          name: 'cardToken',
          value: NewCardTokenValue,
          id: 'cardToken_new',
        }}
        setFieldValue={props.setFieldValue}
      />
      <label
        className='btn btn-outline btn-outline-dashed btn-outline-default w-100'
        htmlFor={'cardToken_new'}
      >
        <Translator name='YENI_KART' />
      </label>
    </>
  )
}

export default CardSelectionNew
